(function() {
    'use strict';

    angular
        .module('app.reports.statisticalReports.athletesByBirthYear')
        .controller('AthletesByBirthYearController', AthletesByBirthYearController);

    AthletesByBirthYearController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'authenticationService', '$scope', '$compile', '$state', 'paramHelper', '$timeout'];
    /* @ngInject */
    function AthletesByBirthYearController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $compile, $state, paramHelper) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AthletesByBirthYear');
        vm.sectionList = [];
        vm.athleteList = [];
        vm.gridToolbar = ['printGrid', 'excelExport'];
        vm.columns = [{
            field: 'SzuletesiEv',
            headerText: gettextCatalog.getString('BirthYear'),
            textAlign: 'left'
        }, {
            field: 'Szam',
            headerText: gettextCatalog.getString('NumberOfAthletes'),
            textAlign: 'left',
            template: true, templateID: '#atbybiyetemplateDataColumn1'
        }, {
            field: 'SzamFiu',
            headerText: gettextCatalog.getString('NumberOfBoys'),
            textAlign: 'left',
            template: true, templateID: '#atbybiyetemplateDataColumn2'
        }, {
            field: 'SzamLany',
            headerText: gettextCatalog.getString('NumberOfGirls'),
            textAlign: 'left',
            template: true, templateID: '#atbybiyetemplateDataColumn3'
        }];
        vm.gridExcelExport = dataservice.getURL() + 'SportolokSzuletesiIdoAlapjan/ExcelExport/';
        vm.compile = compile;
        vm.toolbarHandler = toolbarHandler;
        vm.Date = new Date();
        vm.selectedSection = 0;
        vm.listAthletes = listAthletes;
        var SectionName = '';

        activate();

        function activate() {
            authenticationService.getRight('KMSPORTELETKOR').then(function(results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([paramHelper.getParams([], ['sectionID', 'year'])]).then(function (results) {
                        // Ha paraméter nélkül érkezik akkor tovább arra az oldalra ahol a gridben kattintva eljuthat ide újra
                        if (results[0].sectionID == null || results[0].year == null) {
                            $state.go('reports.statisticalReports.activeAthletesByAge');
                        } else {
                            vm.params = results[0];
                            vm.selectedSection = vm.params.sectionID;
                            getSections();
                            getNumbers(vm.params.year, vm.params.sectionID);
                        }
                    });
                }
            });
        }

        function listAthletes(year, gender) {
            paramHelper.setParams([
                {'state': 'activeAthletesByBirthYear'},
                {'year': year},
                {'sectionId': vm.selectedSection},
                {'sectionName': SectionName},
                {'dateFrom': null},
                {'dateTo': null},
                {'gender': gender}
            ]);
            $state.go('reports.statisticalReports.listAthletes');
        }

        function getNumbers(year, sectionId) {
            var date = new Date(year, 0, 1); // First day of year
            dataservice.activeAthletesByAge(date, sectionId)
                .then(function(data) {
                    vm.athleteList = data.itemsList;
                });
        }

        function getSections() {
            return dataservice.sectionDropDownList(0)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                    SectionName = ej.DataManager(vm.sectionList).executeLocal(new ej.Query().where('value', 'equal', vm.selectedSection))[0].text;
                    $rootScope.title = gettextCatalog.getString('activeAthletesByBirthYear') + ' (' + gettextCatalog.getString(SectionName) + ', ' + vm.params.year + ')';
                });
        }

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                var grid = angular.element('#Grid').ejGrid('instance');
                grid.ignoreOnExport.splice(grid.ignoreOnExport.indexOf('dataSource'), 1);
                this.export(dataservice.getURL() + 'SportolokSzuletesiIdoAlapjan/ExcelExport/');
            }
        }

        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }
    }
})();
