(function () {
    'use strict';

    angular
        .module('app.administration.annualSeasonPlan.annualSeasonPlanReportPage4')
        .controller('AnnualSeasonPlanReportPage4Controller', AnnualSeasonPlanReportPage4Controller);

    AnnualSeasonPlanReportPage4Controller.$inject = ['$rootScope', 'gettextCatalog', 'dataservice', 'log', '$q', 'authenticationService', 'paramHelper', '$state', '$window', 'DOMAIN', '$timeout', '$scope'];

    /* @ngInject */
    function AnnualSeasonPlanReportPage4Controller($rootScope, gettextCatalog, dataservice, log, $q, authenticationService, paramHelper, $state, $window, DOMAIN, $timeout, $scope) {
        var vm = this;
        $rootScope.vm = vm; // jshint ignore: line

        // Page title
        $rootScope.title = gettextCatalog.getString('AnnualSeasonPlanReport');
        vm.egyeni = false;
        vm.editable = true;
        vm.disable = false;
        vm.columnsPopup = [];
        vm.annualSeasonPlanReportId = 0;
        vm.head = {};
        vm.data = {};
        vm.dataPopup = [];
        vm.selectedRowIndexPopup = 0;

        vm.file = {};
        // jump page
        vm.JumpPageList = [
            '1 - ' + gettextCatalog.getString('TheMostImportantDataOfAGroupsMembers'),
            '2 - ' + gettextCatalog.getString('PreparationAndCompetitionPast'),
            '3 - ' + gettextCatalog.getString('OutstandingResults'),
            '4 - ' + gettextCatalog.getString('CapitalObjectives'),
            '5 - ' + gettextCatalog.getString('CompetitionPlansAndObjectives'),
            '6 - ' + gettextCatalog.getString('YearlyTablePlan')
        ];
        // functions
        vm.goToNextPage = goToNextPage;
        vm.goToPreviousPage = goToPreviousPage;
        vm.closeOutstandingResultsWindow = closeOutstandingResultsWindow;
        vm.selectOutstandingResults = selectOutstandingResults;
        vm.numberChange = numberChange;
        vm.openTechnicalTrainingObjectives = openTechnicalTrainingObjectives;
        vm.removeTechnicalTrainingObjectives = removeTechnicalTrainingObjectives;
        vm.openTacticalTrainingObjectives = openTacticalTrainingObjectives;
        vm.removeTacticalTrainingObjectives = removeTacticalTrainingObjectives;
        vm.openAbilitydevelopmentObjectives = openAbilitydevelopmentObjectives;
        vm.removeAbilitydevelopmentObjectives = removeAbilitydevelopmentObjectives;
        vm.openEducationalObjectives = openEducationalObjectives;
        vm.removeEducationalObjectives = removeEducationalObjectives;
        vm.openEfficiencyObjectives = openEfficiencyObjectives;
        vm.removeEfficiencyObjectives = removeEfficiencyObjectives;
        vm.openSportSpecificTrainingObjectives = openSportSpecificTrainingObjectives;
        vm.removeSportSpecificTrainingObjectives = removeSportSpecificTrainingObjectives;
        vm.closeEarlierAnnualSeasonPlanWindow = closeEarlierAnnualSeasonPlanWindow;
        vm.selectEarlierAnnualSeasonPlan = selectEarlierAnnualSeasonPlan;

        vm.search = search;
        vm.closeEarlierAnnualSeasonPlanWindow = closeEarlierAnnualSeasonPlanWindow;
        vm.selectEarlierAnnualSeasonPlan = selectEarlierAnnualSeasonPlan;
        vm.jumpPageDataBound = jumpPageDataBound;
        vm.jumpPageSelect = jumpPageSelect;

        vm.columnsPopup = [
            {
                field: 'Id',
                visible: false,
                isPrimaryKey: true,
                isIdentity: true
            },
            {
                field: 'Szakosztaly',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'center',
                width: 100,
            },
            {
                field: 'Korosztaly',
                headerText: gettextCatalog.getString('AgeGroup'),
                textAlign: 'center',
                width: 100,
            },
            {
                field: 'DatumTol',
                headerText: gettextCatalog.getString('DatumTol'),
                textAlign: 'center',
                width: 100,
            },
            {
                field: 'DatumIg',
                headerText: gettextCatalog.getString('DatumIg'),
                textAlign: 'center',
                width: 100,
            }
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            allowTextWrap: true,
            allowSorting: true,
            allowScrolling: true,
            locale: 'hu-HU',
            gridLines: 'both',
            columns: vm.columnsPopup,
            selectedRowIndex: vm.selectedRowIndexPopup,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#GridPopup'), $scope);
                }
            }
        });
        grid.appendTo('#GridPopup');

        activate();

        function activate() {
            vm.jumpedPage = '4 - ' + gettextCatalog.getString('CapitalObjectives');
            vm.defaultJumpPage = '4 - ' + gettextCatalog.getString('CapitalObjectives');
            $q.all([paramHelper.getParams([], ['AnnualSeasonPlanReport.Id']), authenticationService.getRight('EDZO'), authenticationService.getRight('ADMIN'), authenticationService.getRight('SZAKOSZTVEZ'), authenticationService.getRight('TECHIGAZGATO'), authenticationService.getRight('EVVERSTALAIR1'), authenticationService.getRight('EVVERSTALAIR2'), authenticationService.getRight('UJEVVERST'), authenticationService.getRight('MEGTSPORTLIST'), authenticationService.getRight('MEGTSPORTLIST'), authenticationService.getRight('MEGTEVVERST')])
                .then(function (results) {
                    vm.EDZO = results[1];
                    vm.ADMIN = results[2];
                    vm.SZAKOSZTVEZ = results[3];
                    vm.TECHIGAZGATO = results[4];
                    vm.EVVERSTALAIR1 = results[5];
                    vm.EVVERSTALAIR2 = results[6];
                    vm.UJEVVERST = results[7];
                    vm.MEGTSPORTLIST = results[8];
                    vm.MEGTSPORTLIST = results[9];
                    vm.MEGTEVVERST = results[10];
                    if (results[0]['AnnualSeasonPlanReport.Id'] && results[0]['AnnualSeasonPlanReport.Id'] !== -1) {
                        vm.annualSeasonPlanReportId = results[0]['AnnualSeasonPlanReport.Id'];
                        paramHelper.removeParam('AnnualSeasonPlanReport.Id');
                        dataservice.annualSeasonPlanReportSelectById(vm.annualSeasonPlanReportId)
                            .then(function (data) {
                                vm.head = data;
                                vm.egyeni = data.Egyeni;
                                if (data.EdzoLezarta) {
                                    vm.editable = false;
                                    vm.disable = true;
                                } else {
                                    vm.editable = true;
                                    vm.disable = false;
                                }
                                initUploadBox();
                                dataservice.annualSeasonPlanReportObjectivesSelect(vm.annualSeasonPlanReportId)
                                    .then(function (data) {
                                        vm.data = data;
                                        if (vm.data.IdVersenyeztetesiTervFej == null) {
                                            vm.data.IdVersenyeztetesiTervFej = vm.annualSeasonPlanReportId;
                                        }
                                    });
                            });
                    } else {
                        $rootScope.back();
                    }
                });
        }

        function initUploadBox() {
            angular.element('#uploadbox1').ejUploadbox({
                saveUrl: DOMAIN.baseURL + DOMAIN.webService + 'FileUploadEvesVersTerv',
                autoUpload: true,
                uploadName: 'Uploadbox',
                allowDragAndDrop: false,
                buttonText: {
                    browse: gettextCatalog.getString('UploadFile'),
                    upload: gettextCatalog.getString('UploadFile'),
                    cancel: gettextCatalog.getString('CancelUpload')
                },
                dialogAction: {modal: true, closeOnComplete: true, resize: false, drag: false},
                dialogText: {
                    title: gettextCatalog.getString('UploadedFilesTitle'),
                    name: gettextCatalog.getString('FileName'),
                    size: gettextCatalog.getString('FileSize'),
                    status: gettextCatalog.getString('FileStatus')
                },
                dropAreaText: gettextCatalog.getString('DropFileToUpload'),
                extensionsAllow: '.doc,.docx,.pdf',
                enabled: vm.editable,
                complete: function (args) {
                    if (args.responseText && args.responseText.length > 0) {
                        if (args.responseText.indexOf('>') !== -1) {
                            args.responseText = args.responseText.slice(args.responseText.indexOf('>') + 1, args.responseText.indexOf('</'));
                        }
                        log.successMsg(gettextCatalog.getString('UploadSuccessed'));
                        vm.data.TechKepzesFeladatokFile = args.responseText;
                        dataservice.saveAnnualSeasonPlanCapitalObjectives(vm.data)
                            .then(function (args) {
                                vm.Id = args.res;
                                log.successMsg(gettextCatalog.getString('SaveSuccessed'));
                            });
                    } else {
                        log.htmlError(gettextCatalog.getString('UploadError') + '<br>' + args.error);
                    }
                },
                error: function (args) {
                    var err = '';
                    if (args.error === 'Only files with .doc,.docx,.pdf extensions are allowed.') {
                        err = gettextCatalog.getString('OnlyDocPdf');
                    } else if (args.error === '500 Internal Server Error') {
                        err = gettextCatalog.getString('ToLongFile');
                    }
                    log.htmlError(gettextCatalog.getString('UploadError') + '<br>' + err);
                },
                begin: function (args) {
                    args.data = {tipus: 'EvesVersTerv|techKepzes', tablaId: vm.annualSeasonPlanReportId};
                }
            });
            angular.element('#uploadbox2').ejUploadbox({
                saveUrl: DOMAIN.baseURL + DOMAIN.webService + 'FileUploadEvesVersTerv',
                autoUpload: true,
                uploadName: 'Uploadbox',
                allowDragAndDrop: false,
                buttonText: {
                    browse: gettextCatalog.getString('UploadFile'),
                    upload: gettextCatalog.getString('UploadFile'),
                    cancel: gettextCatalog.getString('CancelUpload')
                },
                dialogAction: {modal: true, closeOnComplete: true, resize: false, drag: false},
                dialogText: {
                    title: gettextCatalog.getString('UploadedFilesTitle'),
                    name: gettextCatalog.getString('FileName'),
                    size: gettextCatalog.getString('FileSize'),
                    status: gettextCatalog.getString('FileStatus')
                },
                dropAreaText: gettextCatalog.getString('DropFileToUpload'),
                extensionsAllow: '.doc,.docx,.pdf',
                enabled: vm.editable,
                complete: function (args) {
                    if (args.responseText && args.responseText.length > 0) {
                        if (args.responseText.indexOf('>') !== -1) {
                            args.responseText = args.responseText.slice(args.responseText.indexOf('>') + 1, args.responseText.indexOf('</'));
                        }
                        log.successMsg(gettextCatalog.getString('UploadSuccessed'));
                        vm.data.TaktikaiKepzesFeladatokFile = args.responseText;
                        dataservice.saveAnnualSeasonPlanCapitalObjectives(vm.data)
                            .then(function (args) {
                                vm.Id = args.res;
                                log.successMsg(gettextCatalog.getString('SaveSuccessed'));
                            });
                    } else {
                        log.htmlError(gettextCatalog.getString('UploadError') + '<br>' + args.error);
                    }
                },
                error: function (args) {
                    var err = '';
                    if (args.error === 'Only files with .doc,.docx,.pdf extensions are allowed.') {
                        err = gettextCatalog.getString('OnlyDocPdf');
                    } else if (args.error === '500 Internal Server Error') {
                        err = gettextCatalog.getString('ToLongFile');
                    }
                    log.htmlError(gettextCatalog.getString('UploadError') + '<br>' + err);
                },
                begin: function (args) {
                    args.data = {tipus: 'EvesVersTerv|taktKepzes', tablaId: vm.annualSeasonPlanReportId};
                }
            });
            angular.element('#uploadbox3').ejUploadbox({
                saveUrl: DOMAIN.baseURL + DOMAIN.webService + 'FileUploadEvesVersTerv',
                autoUpload: true,
                uploadName: 'Uploadbox',
                allowDragAndDrop: false,
                buttonText: {
                    browse: gettextCatalog.getString('UploadFile'),
                    upload: gettextCatalog.getString('UploadFile'),
                    cancel: gettextCatalog.getString('CancelUpload')
                },
                dialogAction: {modal: true, closeOnComplete: true, resize: false, drag: false},
                dialogText: {
                    title: gettextCatalog.getString('UploadedFilesTitle'),
                    name: gettextCatalog.getString('FileName'),
                    size: gettextCatalog.getString('FileSize'),
                    status: gettextCatalog.getString('FileStatus')
                },
                dropAreaText: gettextCatalog.getString('DropFileToUpload'),
                extensionsAllow: '.doc,.docx,.pdf',
                enabled: vm.editable,
                complete: function (args) {
                    if (args.responseText && args.responseText.length > 0) {
                        if (args.responseText.indexOf('>') !== -1) {
                            args.responseText = args.responseText.slice(args.responseText.indexOf('>') + 1, args.responseText.indexOf('</'));
                        }
                        log.successMsg(gettextCatalog.getString('UploadSuccessed'));
                        vm.data.KepessegFejlFeladatokFile = args.responseText;
                        dataservice.saveAnnualSeasonPlanCapitalObjectives(vm.data)
                            .then(function (args) {
                                vm.Id = args.res;
                                log.successMsg(gettextCatalog.getString('SaveSuccessed'));
                            });
                    } else {
                        log.htmlError(gettextCatalog.getString('UploadError') + '<br>' + args.error);
                    }
                },
                error: function (args) {
                    var err = '';
                    if (args.error === 'Only files with .doc,.docx,.pdf extensions are allowed.') {
                        err = gettextCatalog.getString('OnlyDocPdf');
                    } else if (args.error === '500 Internal Server Error') {
                        err = gettextCatalog.getString('ToLongFile');
                    }
                    log.htmlError(gettextCatalog.getString('UploadError') + '<br>' + err);
                },
                begin: function (args) {
                    args.data = {tipus: 'EvesVersTerv|kepessegFejl', tablaId: vm.annualSeasonPlanReportId};
                }
            });
            angular.element('#uploadbox4').ejUploadbox({
                saveUrl: DOMAIN.baseURL + DOMAIN.webService + 'FileUploadEvesVersTerv',
                autoUpload: true,
                uploadName: 'Uploadbox',
                allowDragAndDrop: false,
                buttonText: {
                    browse: gettextCatalog.getString('UploadFile'),
                    upload: gettextCatalog.getString('UploadFile'),
                    cancel: gettextCatalog.getString('CancelUpload')
                },
                dialogAction: {modal: true, closeOnComplete: true, resize: false, drag: false},
                dialogText: {
                    title: gettextCatalog.getString('UploadedFilesTitle'),
                    name: gettextCatalog.getString('FileName'),
                    size: gettextCatalog.getString('FileSize'),
                    status: gettextCatalog.getString('FileStatus')
                },
                dropAreaText: gettextCatalog.getString('DropFileToUpload'),
                extensionsAllow: '.doc,.docx,.pdf',
                enabled: vm.editable,
                complete: function (args) {
                    if (args.responseText && args.responseText.length > 0) {
                        if (args.responseText.indexOf('>') !== -1) {
                            args.responseText = args.responseText.slice(args.responseText.indexOf('>') + 1, args.responseText.indexOf('</'));
                        }
                        log.successMsg(gettextCatalog.getString('UploadSuccessed'));
                        vm.data.NevelesiFeladatokFile = args.responseText;
                        dataservice.saveAnnualSeasonPlanCapitalObjectives(vm.data)
                            .then(function (args) {
                                vm.Id = args.res;
                                log.successMsg(gettextCatalog.getString('SaveSuccessed'));
                            });
                    } else {
                        log.htmlError(gettextCatalog.getString('UploadError') + '<br>' + args.error);
                    }
                },
                error: function (args) {
                    var err = '';
                    if (args.error === 'Only files with .doc,.docx,.pdf extensions are allowed.') {
                        err = gettextCatalog.getString('OnlyDocPdf');
                    } else if (args.error === '500 Internal Server Error') {
                        err = gettextCatalog.getString('ToLongFile');
                    }
                    log.htmlError(gettextCatalog.getString('UploadError') + '<br>' + err);
                },
                begin: function (args) {
                    args.data = {tipus: 'EvesVersTerv|nevFelad', tablaId: vm.annualSeasonPlanReportId};
                }
            });
            angular.element('#uploadbox5').ejUploadbox({
                saveUrl: DOMAIN.baseURL + DOMAIN.webService + 'FileUploadEvesVersTerv',
                autoUpload: true,
                uploadName: 'Uploadbox',
                allowDragAndDrop: false,
                buttonText: {
                    browse: gettextCatalog.getString('UploadFile'),
                    upload: gettextCatalog.getString('UploadFile'),
                    cancel: gettextCatalog.getString('CancelUpload')
                },
                dialogAction: {modal: true, closeOnComplete: true, resize: false, drag: false},
                dialogText: {
                    title: gettextCatalog.getString('UploadedFilesTitle'),
                    name: gettextCatalog.getString('FileName'),
                    size: gettextCatalog.getString('FileSize'),
                    status: gettextCatalog.getString('FileStatus')
                },
                dropAreaText: gettextCatalog.getString('DropFileToUpload'),
                extensionsAllow: '.doc,.docx,.pdf',
                enabled: vm.editable,
                complete: function (args) {
                    if (args.responseText && args.responseText.length > 0) {
                        if (args.responseText.indexOf('>') !== -1) {
                            args.responseText = args.responseText.slice(args.responseText.indexOf('>') + 1, args.responseText.indexOf('</'));
                        }
                        log.successMsg(gettextCatalog.getString('UploadSuccessed'));
                        vm.data.EredmenyessegiFeladatokFile = args.responseText;
                        dataservice.saveAnnualSeasonPlanCapitalObjectives(vm.data)
                            .then(function (args) {
                                vm.Id = args.res;
                                log.successMsg(gettextCatalog.getString('SaveSuccessed'));
                            });
                    } else {
                        log.htmlError(gettextCatalog.getString('UploadError') + '<br>' + args.error);
                    }
                },
                error: function (args) {
                    var err = '';
                    if (args.error === 'Only files with .doc,.docx,.pdf extensions are allowed.') {
                        err = gettextCatalog.getString('OnlyDocPdf');
                    } else if (args.error === '500 Internal Server Error') {
                        err = gettextCatalog.getString('ToLongFile');
                    }
                    log.htmlError(gettextCatalog.getString('UploadError') + '<br>' + err);
                },
                begin: function (args) {
                    args.data = {tipus: 'EvesVersTerv|eredmFelad', tablaId: vm.annualSeasonPlanReportId};
                }
            });

            angular.element('#uploadbox6').ejUploadbox({
                saveUrl: DOMAIN.baseURL + DOMAIN.webService + 'FileUploadEvesVersTerv',
                autoUpload: true,
                uploadName: 'Uploadbox',
                allowDragAndDrop: false,
                buttonText: {
                    browse: gettextCatalog.getString('UploadFile'),
                    upload: gettextCatalog.getString('UploadFile'),
                    cancel: gettextCatalog.getString('CancelUpload')
                },
                dialogAction: {modal: true, closeOnComplete: true, resize: false, drag: false},
                dialogText: {
                    title: gettextCatalog.getString('UploadedFilesTitle'),
                    name: gettextCatalog.getString('FileName'),
                    size: gettextCatalog.getString('FileSize'),
                    status: gettextCatalog.getString('FileStatus')
                },
                dropAreaText: gettextCatalog.getString('DropFileToUpload'),
                extensionsAllow: '.doc,.docx,.pdf',
                enabled: vm.editable,
                complete: function (args) {
                    if (args.responseText && args.responseText.length > 0) {
                        if (args.responseText.indexOf('>') !== -1) {
                            args.responseText = args.responseText.slice(args.responseText.indexOf('>') + 1, args.responseText.indexOf('</'));
                        }
                        log.successMsg(gettextCatalog.getString('UploadSuccessed'));
                        vm.data.SportagSpecFeladatokFile = args.responseText;
                        dataservice.saveAnnualSeasonPlanCapitalObjectives(vm.data)
                            .then(function (args) {
                                vm.Id = args.res;
                                log.successMsg(gettextCatalog.getString('SaveSuccessed'));
                            });
                    } else {
                        log.htmlError(gettextCatalog.getString('UploadError') + '<br>' + args.error);
                    }
                },
                error: function (args) {
                    var err = '';
                    if (args.error === 'Only files with .doc,.docx,.pdf extensions are allowed.') {
                        err = gettextCatalog.getString('OnlyDocPdf');
                    } else if (args.error === '500 Internal Server Error') {
                        err = gettextCatalog.getString('ToLongFile');
                    }
                    log.htmlError(gettextCatalog.getString('UploadError') + '<br>' + err);
                },
                begin: function (args) {
                    args.data = {tipus: 'EvesVersTerv|spSpec', tablaId: vm.annualSeasonPlanReportId};
                }
            });
        }

        function jumpPageDataBound() {
            angular.element('#maKorabbi').ejDropDownList('selectItemByValue', vm.defaultJumpPage);
            vm.selectedJumpPage = vm.defaultJumpPage;
        }

        function jumpPageSelect(args) {
            vm.selectedJumpPage = args.value;
            var sorszam = vm.selectedJumpPage.split('-')[0];
            if (sorszam === '4 ') {
                return;
            }

            if (!vm.hasSignedAnnualSeasonPlan) {
                dataservice.saveAnnualSeasonPlanCapitalObjectives(vm.data)
                    .then(function (args) {
                        vm.Id = args.res;
                        log.successMsg('SaveCompleted');
                    });
            }
            $timeout(function () {
                paramHelper.setParams([{'AnnualSeasonPlanReport.Id': vm.annualSeasonPlanReportId}]);
                switch (sorszam) {
                    case '1 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage1');
                        break;
                    case '2 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage2');
                        break;
                    case '3 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage3');
                        break;
                    case '4 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage4');
                        break;
                    case '5 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage5');
                        break;
                    case '6 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage6');
                        break;
                }
            }, 100);
        }

        function goToNextPage() {
            if (vm.editable) {
                dataservice.saveAnnualSeasonPlanCapitalObjectives(vm.data)
                    .then(function (args) {
                        vm.Id = args.res;
                        log.successMsg('SaveCompleted');
                    });
            }

            $timeout(function () {
                paramHelper.setParams([{
                    'AnnualSeasonPlanReport.Id': vm.annualSeasonPlanReportId
                }]);
                $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage5');
            }, 100);
        }

        function goToPreviousPage() {
            if (vm.editable) {
                dataservice.saveAnnualSeasonPlanCapitalObjectives(vm.data)
                    .then(function (args) {
                        vm.Id = args.res;
                        log.successMsg('SaveCompleted');
                    });
            }
            $timeout(function () {
                paramHelper.setParams([{
                    'AnnualSeasonPlanReport.Id': vm.annualSeasonPlanReportId
                }]);
                $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage3');
            }, 100);
        }

        function closeOutstandingResultsWindow() {
            var dialogObj = angular.element('#searchWindow').data('ejDialog');
            dialogObj.close();
        }

        function selectOutstandingResults() {
            if (vm.selectedRowIndexPopup > -1) {
                var vmi = vm.dataPopup[vm.selectedRowIndexPopup];
                if (vmi) {
                    var sorszam = 0;
                    for (var i = 0; i < vm.data.length; i++) {
                        if (vm.data[i].Sorszam > sorszam) {
                            sorszam = vm.data[i].Sorszam;
                        }
                    }
                    vm.data.push(
                        {
                            Sorszam: sorszam + 1,
                            IdVersenyeztetesiTervFej: vm.annualSeasonPlanReportId,
                            ElertHelyezes: vmi.Helyezes,
                            EsemenyHelye: vmi.Helyszin,
                            EsemenyIdopontja: (vmi.DatumTol ? vmi.DatumTol : '') + (vm.Datumig ? '-' + vm.DatumIg : ''),
                            EsemenyNeve: vmi.EsemenyNeve,
                            VersenyzoNeve: vmi.CsapatSportolo
                        });
                }
            }
            var dialogObj = angular.element('#searchWindow').data('ejDialog');
            dialogObj.close();
        }

        function numberChange() {
            var tx1 = angular.element('#teSpSpec').data('ejNumericTextbox');
            var tx2 = angular.element('#teAddTraining').data('ejNumericTextbox');
            vm.data.HetiEdzesOsszesen = Number(tx1.model.value) + Number(tx2.model.value);
        }

        function openTechnicalTrainingObjectives() {
            if (vm.data.TechKepzesFeladatokFile) {
                openLink(vm.data.TechKepzesFeladatokFile.replace(/\"/g, ''));
            }
        }

        function removeTechnicalTrainingObjectives() {
            var host = DOMAIN.baseURL + DOMAIN.webService;
            dataservice.deleteFileAnnualSeasonPlan(vm.data.TechKepzesFeladatokFile, host)
                .then(function () {
                    vm.data.TechKepzesFeladatokFile = undefined;
                    dataservice.saveAnnualSeasonPlanCapitalObjectives(vm.data)
                        .then(function () {
                            log.successMsg('SaveDone.');
                        });
                });
        }

        function openTacticalTrainingObjectives() {
            if (vm.data.TaktikaiKepzesFeladatokFile) {
                openLink(vm.data.TaktikaiKepzesFeladatokFile.replace(/\"/g, ''));
            }
        }

        function removeTacticalTrainingObjectives() {
            var host = DOMAIN.baseURL + DOMAIN.webService;
            dataservice.deleteFileAnnualSeasonPlan(vm.data.TaktikaiKepzesFeladatokFile, host)
                .then(function () {
                    vm.data.TaktikaiKepzesFeladatokFile = undefined;
                    dataservice.saveAnnualSeasonPlanCapitalObjectives(vm.data)
                        .then(function () {
                            log.successMsg('SaveDone.');
                        });
                });
        }

        function openAbilitydevelopmentObjectives() {
            if (vm.data.KepessegFejlFeladatokFile) {
                openLink(vm.data.KepessegFejlFeladatokFile.replace(/\"/g, ''));
            }
        }

        function removeAbilitydevelopmentObjectives() {
            var host = DOMAIN.baseURL + DOMAIN.webService;
            dataservice.deleteFileAnnualSeasonPlan(vm.data.KepessegFejlFeladatokFile, host)
                .then(function () {
                    vm.data.KepessegFejlFeladatokFile = undefined;
                    dataservice.saveAnnualSeasonPlanCapitalObjectives(vm.data)
                        .then(function () {
                            log.successMsg('SaveDone.');
                        });
                });
        }

        function openEducationalObjectives() {
            if (vm.data.NevelesiFeladatokFile) {
                openLink(vm.data.NevelesiFeladatokFile.replace(/\"/g, ''));
            }
        }

        function removeEducationalObjectives() {
            var host = DOMAIN.baseURL + DOMAIN.webService;
            dataservice.deleteFileAnnualSeasonPlan(vm.data.NevelesiFeladatokFile, host)
                .then(function () {
                    vm.data.NevelesiFeladatokFile = undefined;
                    dataservice.saveAnnualSeasonPlanCapitalObjectives(vm.data)
                        .then(function () {
                            log.successMsg('SaveDone.');
                        });
                });
        }

        function openEfficiencyObjectives() {
            if (vm.data.EredmenyessegiFeladatokFile) {
                openLink(vm.data.EredmenyessegiFeladatokFile.replace(/\"/g, ''));
            }
        }

        function removeEfficiencyObjectives() {
            var host = DOMAIN.baseURL + DOMAIN.webService;
            dataservice.deleteFileAnnualSeasonPlan(vm.data.EredmenyessegiFeladatokFile, host)
                .then(function () {
                    vm.data.EredmenyessegiFeladatokFile = undefined;
                    dataservice.saveAnnualSeasonPlanCapitalObjectives(vm.data)
                        .then(function () {
                            log.successMsg('SaveDone.');
                        });
                });
        }

        function openSportSpecificTrainingObjectives() {
            if (vm.data.SportagSpecFeladatokFile) {
                openLink(vm.data.SportagSpecFeladatokFile.replace(/\"/g, ''));
            }
        }

        function removeSportSpecificTrainingObjectives() {
            var host = DOMAIN.baseURL + DOMAIN.webService;
            dataservice.deleteFileAnnualSeasonPlan(vm.data.SportagSpecFeladatokFile, host)
                .then(function () {
                    vm.data.SportagSpecFeladatokFile = undefined;
                    dataservice.saveAnnualSeasonPlanCapitalObjectives(vm.data)
                        .then(function () {
                            log.successMsg('SaveDone.');
                        });
                });
        }

        function openLink(url) {
            $window.open(url, '_blank');
        }

        function search() {
            vm.selectedRowIndexPopup = -1;
            var dialogObj = angular.element('#searchWindow').data('ejDialog');
            dialogObj.open();
            dataservice.getAnnualSeasonPlanListByTrainer(vm.head.IdEdzo, vm.annualSeasonPlanReportId)
                .then(function (args) {
                    vm.dataPopup = args.itemsList;
                    grid.dataSource = args.itemsList;
                });
        }

        function closeEarlierAnnualSeasonPlanWindow() {
            var dialogObj = angular.element('#searchWindow').data('ejDialog');
            dialogObj.close();
        }

        function selectEarlierAnnualSeasonPlan() {
            vm.selectedRowIndexPopup = grid.selectedRowIndex;
            if (vm.selectedRowIndexPopup > -1) {
                var vmi = vm.dataPopup[vm.selectedRowIndexPopup];
                if (vmi) {
                    dataservice.annualSeasonPlanReportObjectivesSelect(vmi.Id)
                        .then(function (data) {
                            vm.data.HetiEdzesSportag = data.HetiEdzesSportag;
                            vm.data.HetiEdzesKiegeszito = data.HetiEdzesKiegeszito;
                            vm.data.HetiEdzesOsszesen = data.HetiEdzesOsszesen;
                            vm.data.SportagSpecFeladatok = data.SportagSpecFeladatok;
                            vm.data.SportagSpecFeladatokFile = data.SportagSpecFeladatokFile;
                            vm.data.KepessegFejlFeladatok = data.KepessegFejlFeladatok;
                            vm.data.KepessegFejlFeladatokFile = data.KepessegFejlFeladatokFile;
                            vm.data.NevelesiFeladatok = data.NevelesiFeladatok;
                            vm.data.NevelesiFeladatokFile = data.NevelesiFeladatokFile;
                            vm.data.TechKepzesFeladatok = data.TechKepzesFeladatok;
                            vm.data.TechKepzesFeladatokFile = data.TechKepzesFeladatokFile;
                            vm.data.TaktikaiKepzesFeladatok = data.TaktikaiKepzesFeladatok;
                            vm.data.TaktikaiKepzesFeladatokFile = data.TaktikaiKepzesFeladatokFile;
                            vm.data.EredmenyessegiFeladatok = data.EredmenyessegiFeladatok;
                            vm.data.EredmenyessegiFeladatokFile = data.EredmenyessegiFeladatokFile;
                        });
                    var dialogObj = angular.element('#searchWindow').data('ejDialog');
                    dialogObj.close();
                }
            }
        }
    }
})();
