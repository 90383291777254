(function () {
    'use strict';

    angular
        .module('app.administration.monthlyAttendance.monthlyAttendanceList')
        .controller('MonthlyAttendanceListController', MonthlyAttendanceListController);

    MonthlyAttendanceListController.$inject = ['dataservice', 'log', '$q', '$state', 'gettextCatalog', 'paramHelper', '$scope', '$compile', 'authenticationService', '$rootScope', '$timeout'];

    /* @ngInject */
    function MonthlyAttendanceListController(dataservice, log, $q, $state, gettextCatalog, paramHelper, $scope, $compile, authenticationService, $rootScope, $timeout) {
        var vm = this; $rootScope.vm = vm;

        // Page title
        $rootScope.title = gettextCatalog.getString('MonthlyAttendanceList');

        vm.columns = [{
            field: 'Id',
            visible: false,
            isPrimaryKey: true,
            isIdentity: true
        }, {
            field: 'Szakosztaly',
            headerText: gettextCatalog.getString('Section'),
            textAlign: 'center',
            width: 120,
            allowEditing: false
        }, {
            field: 'Korosztaly',
            headerText: gettextCatalog.getString('AgeGroup'),
            textAlign: 'left',
            width: 120,
            allowEditing: false,
            template: '#moatlitemplateDataColumn1'
        }, {
            field: 'Edzo',
            headerText: gettextCatalog.getString('Coach'),
            textAlign: 'center',
            width: 150,
            allowEditing: false,
            template: '#moatlitemplateDataColumn2'

        }, {
            field: 'Datum',
            headerText: gettextCatalog.getString('Date'),
            textAlign: 'center',
            width: 120,
            allowEditing: false
        }, {
            field: 'CsoportLetszam',
            headerText: gettextCatalog.getString('CsoportLetszam'),
            textAlign: 'center',
            width: 120,
            cssClass: 'rotate90degreegridheader2',
            allowEditing: false
        }, {
            field: 'Erkezett',
            headerText: gettextCatalog.getString('Erkezett'),
            textAlign: 'center',
            width: 100,
            cssClass: 'rotate90degreegridheader2',
            allowEditing: false
        }, {
            field: 'Tavozott',
            headerText: gettextCatalog.getString('Tavozott'),
            textAlign: 'center',
            width: 100,
            cssClass: 'rotate90degreegridheader2',
            allowEditing: false
        },
            {
            field: 'EdzoLezarta',
            headerText: gettextCatalog.getString('EdzoLezarta'),
            textAlign: 'center',
            width: 120,
            cssClass: 'rotate90degreegridheader2',
            allowEditing: false,
            type: 'boolean',
            template: '#moatlitemplateDataColumn3'
        }, {
            field: 'SzakvezJovahagyta',
            headerText: gettextCatalog.getString('SzakvezJovahagyta'),
            textAlign: 'center',
            width: 150,
            cssClass: 'rotate90degreegridheader2',
            allowEditing: false,
            type: 'boolean',
            template: '#moatlitemplateDataColumn4'
        },
        {
            field: 'Link',
            headerText: '',
            textAlign: 'center',
            width: 120,
            allowEditing: false,
            template: '#moatlitemplateDataColumn5'
        }];

        var grid = new ej2.grids.Grid({
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            allowTextWrap: true,
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            },
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        function setInitVals() {
            //grid adatai
            vm.coachList = [];
            vm.selectedCoach = 0;
            //Lekérés típusa
            vm.modeList = [
                gettextCatalog.getString('MindenHaviJelenleti'),
                gettextCatalog.getString('CsakLezartHaviJelenletik'),
                gettextCatalog.getString('CsakNemLezartHaviJelenletik')
            ];
            //Kiválasztott lekérés típusa
            vm.selectedMode = gettextCatalog.getString('MindenHaviJelenleti');
            //Évek (2010-től az aktuális évig)
            vm.years = [];
            vm.years.push(gettextCatalog.getString('AllYear'));
            for (var ev = 2010; ev <= new Date().getFullYear(); ev++) {
                vm.years.push(ev);
            }
            //Kiválasztott év (alapból az aktuális)
            vm.selectedYear = new Date().getFullYear();
            //Hónapok
            vm.months = [
                {
                    text: gettextCatalog.getString('AllMonth'),
                    value: -1
                },
                {
                    text: gettextCatalog.getString('January'),
                    value: 0
                }, {
                    text: gettextCatalog.getString('February'),
                    value: 1
                }, {
                    text: gettextCatalog.getString('March'),
                    value: 2
                }, {
                    text: gettextCatalog.getString('April'),
                    value: 3
                }, {
                    text: gettextCatalog.getString('May'),
                    value: 4
                }, {
                    text: gettextCatalog.getString('June'),
                    value: 5
                }, {
                    text: gettextCatalog.getString('July'),
                    value: 6
                }, {
                    text: gettextCatalog.getString('August'),
                    value: 7
                }, {
                    text: gettextCatalog.getString('September'),
                    value: 8
                }, {
                    text: gettextCatalog.getString('October'),
                    value: 9
                }, {
                    text: gettextCatalog.getString('November'),
                    value: 10
                }, {
                    text: gettextCatalog.getString('December'),
                    value: 11
                }];
            //Kiválasztott hónap
            vm.selectedMonth = -1; //new Date().getMonth();
            //A gridben megjelenő adatok
        }

        setInitVals();

        vm.coachChanged = coachChanged;
        vm.createNewAttendance = createNewAttendance;
        vm.refreshData = refreshData;
        vm.compile = compile;
        vm.goToNewUser = goToNewUser;
        vm.goToNewAgeGroup = goToNewAgeGroup;
        vm.goToMonthlyAttendance = goToMonthlyAttendance;
        vm.yearChanged = yearChanged;

        activate();

        //inicializálás
        function activate() {
            authenticationService.getRight('MEGTHAVIJEL').then(function (res) {
                if (!res) {
                    log.permissionError(true);
                } else {
                    var promises = [
                        getCoaches(),
                        authenticationService.getRight('HJFELVIHET'),
                        authenticationService.getRight('MEGTFELH'),
                        authenticationService.getRight('MEGTKOROKEZ'),
                        authenticationService.getRight('MEGTHAVIJEL'),
                        paramHelper.getParams([], ['monthlyAttendance.coachId', 'monthlyAttendance.year', 'monthlyAttendance.month', 'monthlyAttendance.selectedMode'])
                    ];
                    return $q.all(promises).then(function (results) {
                        vm.HJFELVIHET = results[1];
                        vm.MEGTFELH = results[2];
                        vm.MEGTKOROKEZ = results[3];
                        vm.MEGTHAVIJEL = results[4];
                        $timeout(function () {
                            vm.selectedCoach = results[5]['monthlyAttendance.coachId'];
                            vm.selectedMode = results[5]['monthlyAttendance.selectedMode'];
                            vm.selectedYear = results[5]['monthlyAttendance.year'];
                            vm.selectedMonth = results[5]['monthlyAttendance.month'];
                        }, 100);
                        if (vm.coachList.length === 1) {
                            vm.selectedCoach = vm.coachList[0].value;
                        }
                        //refreshData();
                    });
                }
            });
        }

        //edzők lekérése
        function getCoaches() {
            return dataservice.coachSelect().then(
                function (data) {
                    vm.coachList = data.itemsList;
                    /*
                                        var listObj = new ej.dropdowns.DropDownList({
                                            dataSource: vm.coachList,
                                            // set the index value to select an item based on index at initial rendering
                                            index: vm.selectedCoach,
                                            // set the placeholder to DropDownList input element
                                            placeholder: 'Select a couach',
                                            // bind the change event
                                            change: function (args) { coachChanged(args); }
                                        });
                                        listObj.appendTo('#maEdzo');
                                        */
                    return vm.coachList;
                }
            );
        }

        //edző dropdownlist selectionchanged
        function coachChanged(args) {
            console.log(args);
            /*
            vm.selectedCoach = args.value;
            var item = ej.DataManager(vm.coachList).executeLocal(new ej.Query().where('value', 'equal', vm.selectedCoach))[0];
            vm.allAgeGroupfill = item.HaviJelenletiKorosztalyonkent;
            vm.sectionList = [];
            vm.ageGroupList = [];
            vm.selectedAgeGroup = 0;
            vm.selectedSection = 0;
            */
        }

        function createNewAttendance() {
            goToMonthlyAttendance(-1);
        }

        //Melyik az adott év/hónap utolsó napja?
        function daysInMonth(anyDateInMonth) {
            return new Date(anyDateInMonth.getFullYear(), anyDateInMonth.getMonth() + 1, 0).getDate();
        }

        function refreshData() {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }

            saveParameters();
            var tol = new Date();
            var day = 0;
            var ig = 0;
            var year = isNaN(vm.selectedYear) ? 1754 : vm.selectedYear;
            var month = isNaN(vm.selectedMonth) ? 1 : vm.selectedMonth;

            if (vm.selectedMonth == -1) {
                tol = new Date(year, 0, 1, 0, 0, 0);
                day = daysInMonth(new Date(year === 1754 ? 9999 : year, 12, 1));
                ig = new Date(year === 1754 ? 9999 : year, 11, day, 23, 59, 59);
            } else {
                tol = new Date(year, month, 1, 0, 0, 0);
                day = daysInMonth(new Date(year, month, 1));
                ig = new Date(year === 1754 ? 9999 : year, month, day, 23, 59, 59);
            }

            var coach = 0;
            if (vm.selectedCoach) {
                coach = vm.selectedCoach;
            }

            var lezart = 0;
            if (vm.selectedMode === gettextCatalog.getString('CsakLezartHaviJelenletik')) {
                lezart = 1;
            } else if (vm.selectedMode === gettextCatalog.getString('CsakNemLezartHaviJelenletik')) {
                lezart = 2;
            }

            dataservice.getMonthlyAttendanceList(coach, tol, ig, lezart).then(
                function (data) {
                    grid.dataSource = data.itemsList;
                }
            );
        }

        // Compile links in grid
        function compile(args) {
            $compile(args.row)($scope);
        }

        function compileAll(args) {
            $compile(args)($scope);
        }

        function goToNewUser(edzoId) {
            if (!vm.MEGTFELH) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{
                'newUser.userId': edzoId
            }]);
            saveParameters();
            $state.go('settings.userManagement.modifyUser');
        }

        function goToNewAgeGroup(ageGroupId) {
            if (!vm.MEGTKOROKEZ) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{
                'ageGroups.ageGroup': ageGroupId
            }]);
            $state.go('athletes.ageGroups.modifyAgeGroup');
        }

        function saveParameters() {
            paramHelper.setParams([{
                'monthlyAttendance.month': vm.selectedMonth,
                'monthlyAttendance.year': vm.selectedYear,
                'monthlyAttendance.coachId': vm.selectedCoach,
                'monthlyAttendance.selectedMode': vm.selectedMode

            }]);
        }

        function goToMonthlyAttendance(attendanceId) {
            if (!vm.MEGTHAVIJEL) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{
                'monthlyAttendance.id': attendanceId

            }]);
            saveParameters();
            $state.go('administration.monthlyAttendance.monthlyAttendanceReport');
        }

        function yearChanged(args) {
            if (args.itemId === 0) {
                vm.selectedMonth = -1;
            }
        }
    }
})();
