(function () {
    'use strict';

    angular
        .module('app.core')
        .factory('paramHelper', paramHelper);

    paramHelper.$inject = ['$q', 'localStorageService', 'log', 'gettextCatalog', 'authenticationService'];

    /* @ngInject */
    function paramHelper($q, localStorageService, log, gettextCatalog, authenticationService) {
        return {
            setParams: setParams,
            getParams: getParams,
            removeParam: removeParam,
            removeAll: removeAll,
            setUserNameParams: setUserNameParams,
            getUserNameParams: getUserNameParams
        };

        // [{key: value}, {key: value}, ...]
        function setParams(params) {
            return $q(function (resolve) {
                angular.forEach(params, function (value) {
                    angular.forEach(value, function (value, key) {
                        //console.log(key + ': ' + value);
                        localStorageService.set(authenticationService.getAuthData().id + '.' + key, value);
                    });
                });
                resolve(true);
            });
        }

        // [{key: value}, {key: value}, ...]
        function setUserNameParams(userName, params) {
            return $q(function (resolve) {
                angular.forEach(params, function (value) {
                    angular.forEach(value, function (value, key) {
                        localStorageService.set(userName + '.' + key, value);
                    });
                });
                resolve(true);
            });
        }

        // [key, key, ...]
        function getUserNameParams(userName, params, optionalParams) {
            return $q(function (resolve, reject) {
                var result = [];
                angular.forEach(params, function (value) {
                    var temp = localStorageService.get(userName + '.' + value);
                    if (!temp) {
                        var noParam = gettextCatalog.getString('NoParam');
                        log.htmlError(noParam + ': ' + value);
                        reject();
                    } else {
                        result[value] = temp;
                    }
                });
                angular.forEach(optionalParams, function (value) {
                    result[value] = localStorageService.get(userName + '.' + value);
                });
                resolve(result);
            });
        }

        // [key, key, ...]
        function getParams(params, optionalParams) {
            return $q(function (resolve, reject) {
                var result = [];
                if (authenticationService.getAuthData() && authenticationService.getAuthData().id) {
                    angular.forEach(params, function (value) {
                        var temp = localStorageService.get(authenticationService.getAuthData().id + '.' + value);
                        if (!temp) {
                            var noParam = gettextCatalog.getString('NoParam');
                            log.htmlError(noParam + ': ' + value);
                            reject();
                        } else {
                            result[value] = temp;
                            //removeParam(value)
                        }
                    });
                    angular.forEach(optionalParams, function (value) {
                        result[value] = localStorageService.get(authenticationService.getAuthData().id + '.' + value);
                        //removeParam(value);
                    });
                }
                resolve(result);
            });
        }

        function removeParam(param) {
            localStorageService.remove(authenticationService.getAuthData().id + '.' + param);
        }

        function removeAll() {
            return localStorageService.clearAll();
        }
    }
})();
