/* global toastr:false, moment:false */
(function () {
    'use strict';

    angular
        .module('app.core')
        .constant('toastr', toastr)
        .constant('moment', moment)
        .constant('DOMAIN', {
                baseURL: clientConfig.url,
                //baseURL: 'http://deac.sikerszoftver.hu/',
                //baseURL: 'http://egrivizilabda.sikerszoftver.hu/',
                imgBaseUrl: 'api/Photos/',
                webService: 'api/',
                noImage: 'api/Photos/noImg.jpg',
                userImage: 'api/Photos/avatar_2x.png',
                profileImgBaseUrl: 'api/Photos/Profile/',
                psychologyDocUrl: 'api/PsychologyDocuments/',
                resultReportImgBaseUrl: 'api/Photos/Versenyeredmeny/',
                resultReportTeamImgBaseUrl: 'api/Photos/VersenyeredmenyCsapat/',
                anthropometryPhotosUrl: 'api/Photos/Antropometria/',
                youtubeIcon: 'api/Photos/youtube.png',
                teamPhotosUrl: 'api/Photos/Csapat/',
                exerciseBaseUrl: 'api/Photos/Gyakorlatok/',
                justPsycho: 0
            }
            /*
             .constant('DOMAIN', {
             webService: 'http://pszicho.unideb.cts.hu/app/api/',
             noImage: 'http://pszicho.unideb.cts.hu/Photos/noImage.jpg',
             userImage: 'http://pszicho.unideb.cts.hu/Photos/avatar_2x.png',
             imgBaseUrl: 'http://pszicho.unideb.cts.hu/app/api/Photos/',
             profileImgBaseUrl: 'http://pszicho.unideb.cts.hu/app/api/Photos/Profile/',
             psychologyDocUrl: 'http://pszicho.unideb.cts.hu/app/api/PsychologyDocuments/',
             justPsycho: 1
             }*/
        )
        .constant('timeout', 20000) // Http request timeout 20 seconds
        .constant('keepalive', 7200000) // Session time keepalive 2 hours
        .constant('AUTH_EVENTS', {
            login: 'auth-login',
            logout: 'auth-logout',
            sessionTimeout: 'auth-session-timeout',
            notAuthorized: 'auth-not-authorized',
            refreshProfilePic: 'refresh-profile-picture'
        })
        .constant('LANG_EVENTS', {
            changeLanguage: 'change-language'
        })
        .constant('PSYCHOLOGY', {
            Wartegg: 1,
            PerformanceMotivation: 2,
            Pieron: 3,
            Tenessee: 4
        })
        .constant('VIEW_DATA', {
            dropDownList: {
                weight: {
                    downThreshold: 20,
                    upThreshold: 150
                },
                centimetre: {
                    downThreshold: 100,
                    upThreshold: 250
                },
                sex: ['Nő', 'Férfi'],
                hand: ['Jobb', 'Bal'],
                foot: ['Jobb', 'Bal'],
                feeOfEducation: ['Oktatási díjas', 'Sportszolgáltatási díjas'],
                counties: ['Bács-Kiskun', 'Baranya', 'Békés', 'Borsod-Abaúj-Zemplén', 'Csongrád', 'Fejér', 'Győr-Moson-Sopron', 'Hajdú-Bihar', 'Heves', 'Jász-Nagykun-Szolnok', 'Komárom-Esztergom', 'Nógrád', 'Pest', 'Somogy', 'Szabolcs-Szatmár-Bereg', 'Tolna', 'Vas', 'Veszprém', 'Zala', 'Budapest'],
                course: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],
                class: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'Z']

            }
        })
        .constant('LAYOUT_EVENTS', {
            passwordChange: 'layout-password-change'
        });
})();
