(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.health.healthList')
        .controller('HealthListController', HealthListController);

    HealthListController.$inject = ['log', '$state', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'paramHelper', 'authenticationService', '$filter', '$scope', '$timeout'];

    /* @ngInject */
    function HealthListController(log, $state, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $filter, $scope, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('HealthList');

        // Create variables
        vm.Id = null;
        vm.selectedGridRow = {};
        vm.dateFrom = new Date('1900-01-01');
        vm.dateTo = new Date();
        vm.beginedit = false;
        vm.dataTmp = '';
        vm.athleteName = '';
        vm.athleteId = '';
        vm.athleteSocialSecurity = '';
        vm.athleteBirthDate = '';
        vm.gridToolbar = ['printGrid', 'excelExport'];
        vm.pageSize = 12;
        vm.gridHealthDatas = [];

        vm.columns = [
            {
                field: 'Id',
                isPrimaryKey: true,
                isIdentity: true,
                visible: false
            },
            {
                field: 'Mettol',
                headerText: gettextCatalog.getString('From'),
                width: 90
            },
            {
                field: 'Meddig',
                headerText: gettextCatalog.getString('To'),
                width: 90
            },
            {
                field: 'Tipus',
                headerText: gettextCatalog.getString('Type'),
                //databound: typeDataBound,
                width: 100
            },
            {
                field: 'AlTipus',
                headerText: gettextCatalog.getString('SubType'),
                width: 100
            },
            {
                field: 'Leiras',
                headerText: gettextCatalog.getString('Description'),
                width: 125
            },
            {
                field: 'Kezeles',
                headerText: gettextCatalog.getString('Therapy')
            },
            {
                field: 'Letrehozas',
                headerText: gettextCatalog.getString('CreatedDate'),
                width: 90
            },
            {
                field: 'Modositas',
                headerText: gettextCatalog.getString('ModifiedDate'),
                width: 90
            },
            {
                field: 'Szerkesztes',
                headerText: gettextCatalog.getString('Edit'),
                template: '#healteditcolumnTemplate'
            }
        ];
        vm.editText = gettextCatalog.getString('Edit');
        vm.EGESZSMEGT = false;
        vm.EGESZSMOD = false;

        // Public functions
        vm.getHealthDatas = getHealthDatas;
        vm.goToNewHealthEvent = goToNewHealthEvent;
        vm.goToModifyHealthEvent = goToModifyHealthEvent;
        vm.dateFromChange = dateFromChange;
        vm.dateToChange = dateToChange;
        vm.backToDatasheet = backToDatasheet;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Activate page
        activate();

        function activate() {
            $q.all([paramHelper.getParams([], ['athleteModify.sportoloNev', 'athleteModify.sportoloId', 'athleteModify.sportoloTaj', 'athleteModify.sportoloSzuletesiIdo']),
                    authenticationService.getRight('EGESZSMEGT'),
                    authenticationService.getRight('EGESZSMOD')])
                .then(function (results) {
                    vm.EGESZSMEGT = results[1];
                    vm.EGESZSMOD = results[2];

                    if (!vm.EGESZSMEGT) {
                        log.permissionError();
                        $rootScope.back();
                    }

                    $timeout(function () {
                        vm.athleteId = results[0]['athleteModify.sportoloId'];
                        vm.athleteName = results[0]['athleteModify.sportoloNev'];
                        vm.athleteSocialSecurity = results[0]['athleteModify.sportoloTaj'];
                        vm.athleteBirthDate = results[0]['athleteModify.sportoloSzuletesiIdo'];
                        vm.athleteBirthDate = $filter('dateStringFilter')(vm.athleteBirthDate);
                        getHealthDatas();
                    }, 0);
                });
        }

        function getHealthDatas(from, to) {
            if (from) {
                vm.dateFrom = from;
            }
            if (to) {
                vm.dateTo = to;
            }
            if (vm.dateFrom === null) {
                vm.dateFrom = Date();
            }
            if (vm.dateTo === null) {
                vm.dateTo = Date();
            }

            dataservice.GetHealth(vm.athleteId, vm.dateFrom, vm.dateTo).then(function (data) {
                vm.dataTmp = data.itemsList;
                vm.dataTmp = $filter('dateStringFilter')(vm.dataTmp, 'Letrehozas');
                vm.dataTmp = $filter('dateStringFilter')(vm.dataTmp, 'Meddig');
                vm.dataTmp = $filter('dateStringFilter')(vm.dataTmp, 'Mettol');
                vm.dataTmp = $filter('dateStringFilter')(vm.dataTmp, 'Modositas');

                vm.dataTmp.forEach(function (element) {
                    element.Tipus = element.Tipus;
                });

                vm.gridHealthDatas = vm.dataTmp;
                grid.dataSource = vm.dataTmp;
            });
        }

        function dateToChange(args) {
            getHealthDatas(vm.dateFrom, args.value);
        }

        function dateFromChange(args) {
            getHealthDatas(args.value, vm.dateTo);
        }

        function goToNewHealthEvent() {
            paramHelper.setParams([{
                'newHealthAthleteId': vm.athleteId,
                'newHealthAthleteName': vm.athleteName,
                'newHealthAthleteSocialSecurity': vm.athleteSocialSecurity,
                'newHealthAthleteBirthDate': vm.athleteBirthDate,
            }]);
            $state.go('athletes.athletesManagement.athleteModify.health.newHealthEvent');
        }

        function goToModifyHealthEvent(id) {
            vm.selectedGridRow = ej.DataManager(vm.gridHealthDatas).executeLocal(new ej.Query().where('Id', 'equal', id))[0];
            paramHelper.setParams([{
                'mofifyHealthAthleteId': vm.athleteId,
                'mofifyHealthAthleteName': vm.athleteName,
                'mofifyHealthAthleteSocialSecurity': vm.athleteSocialSecurity,
                'mofifyHealthAthleteBirthDate': vm.athleteBirthDate,
                'mofifyHealthId': vm.selectedGridRow.Id,
                'mofifyHealthKezeles': vm.selectedGridRow.Kezeles,
                'mofifyHealthLeiras': vm.selectedGridRow.Leiras,
                'mofifyHealthLetrehozas': vm.selectedGridRow.Letrehozas,
                'mofifyHealthMettol': vm.selectedGridRow.Mettol,
                'mofifyHealthMeddig': vm.selectedGridRow.Meddig,
                'mofifyHealthTipus': vm.selectedGridRow.Tipus,
                'mofifyHealthAlTipus': vm.selectedGridRow.AlTipus,
                'fromState': 'healthList',
            }]);
            $state.go('athletes.athletesManagement.athleteModify.health.modifyHealthEvent');
        }

        function backToDatasheet() {
            paramHelper.setParams([{'athleteModify.sportoloId': vm.athleteId}]);
            $state.go('athletes.athletesManagement.athleteModify.dataSheet');
        }
    }
})();
