(function () {
    'use strict';

    angular
        .module('app.schedule')
        .controller('ScheduleController', ScheduleController);

    ScheduleController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'paramHelper', 'authenticationService', '$filter', 'DOMAIN', '$timeout', '$scope'];
    /* @ngInject */
    function ScheduleController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $filter, DOMAIN, $timeout, $scope) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('Schedule');

        vm.unnamedEvent = gettextCatalog.getString('unnamedEvent');
        // Create variables
        vm.eventColor = '#000000';
        vm.trainingColor = '#169c5f';
        vm.sectionResources = [];
        vm.ageGroupResources = [];
        vm.categoryResources = [
            {text: gettextCatalog.getString('Event'), id: 1, color: vm.eventColor, fontColor: '#000000'},
            {text: gettextCatalog.getString('Training'), id: 2, color: vm.trainingColor, fontColor: '#ffffff'}
        ];
        vm.trainingText = gettextCatalog.getString('Training');
        vm.appointments = [];

        vm.SPORTOLO = null;
        vm.SZULO = null;
        vm.sectionDropDownVisible = true;
        vm.training = false;
        vm.saveThis = {};

        // Public functions
        vm.getSections = getSections;
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.print = print;
        vm.exportAllToIcs = exportAllToIcs;
        vm.trainingCheckboxChanged = trainingCheckboxChanged;

        // Felhasználó kiválasztás
        vm.RecipientsIds = [];
        vm.RecipientsNames = '';
        vm.RecipientsNamesArr = [];
        vm.recipientsText = gettextCatalog.getString('recipients');
        vm.clearText = gettextCatalog.getString('clear');
        vm.showRecipientsText = gettextCatalog.getString('showRecipients');

        vm.searchUser = searchUser;
        vm.searchAthlete = searchAthlete;
        vm.searchParent = searchParent;
        vm.searchUserGroup = searchUserGroup;
        vm.searchSection = searchSection;
        vm.searchTeamsAthletes = searchTeamsAthletes;
        vm.searchTeamsParents = searchTeamsParents;
        vm.searchAgeGroupAthletes = searchAgeGroupAthletes;
        vm.searchAgeGroupParents = searchAgeGroupParents;

        vm.parentsCheckboxChanged = parentsCheckboxChanged;
        vm.athleteCheckboxChanged = athleteCheckboxChanged;
        vm.userCheckboxChanged = userCheckboxChanged;
        vm.usersGroupCheckboxChanged = usersGroupCheckboxChanged;
        vm.sectionsCheckboxChanged = sectionsCheckboxChanged;
        vm.teamsCheckboxChanged = teamsCheckboxChanged;
        vm.teamParentsCheckboxChanged = teamParentsCheckboxChanged;
        vm.ageGroupsCheckboxChanged = ageGroupsCheckboxChanged;
        vm.ageGroupsParentsCheckboxChanged = ageGroupsParentsCheckboxChanged;

        vm.usersList = [];
        vm.athletesList = [];
        vm.parentsList = [];
        vm.usersGroupList = [];
        vm.sectionsList = [];
        vm.teamsAthletesList = [];
        vm.teamParentsList = [];
        vm.ageGroupsList = [];
        vm.ageGroupsParentsList = [];

        vm.usersListAll = [];
        vm.athletesListAll = [];
        vm.parentsListAll = [];
        vm.usersGroupListAll = [];
        vm.sectionsListAll = [];
        vm.teamsAthletesListAll = [];
        vm.teamParentsListAll = [];
        vm.ageGroupsListAll = [];
        vm.ageGroupsParentsListAll = [];

        vm.alertSettings = {};
        initAlertSettings();

        vm.processedTabs = 0;

        vm.showDirectory = showDirectory;
        vm.toolbarPopupClick = toolbarPopupClick;

        vm.toolbarPopupItems = [
            {empid: 1, tooltiptext: gettextCatalog.getString('Ok'), spriteCss: 'msgtools Accept'},
            {empid: 2, tooltiptext: gettextCatalog.getString('Cancel'), spriteCss: 'msgtools Back'}
        ];

        function initAlertSettings() {
            vm.alertSettings = {};
            vm.alertSettings.users = [];
            vm.alertSettings.athletes = [];
            vm.alertSettings.parents = [];
            vm.alertSettings.usergroups = [];
            vm.alertSettings.sections = [];
            vm.alertSettings.teamsathletes = [];
            vm.alertSettings.teamsparents = [];
            vm.alertSettings.agegroupsathletes = [];
            vm.alertSettings.agegroupsparents = [];
        }

        function getUsers() {
            dataservice.Cimjegyzek().then(function (data) {
                data.data.lista.forEach(function (element) {
                    switch (element.usersof){
                        case 'users':
                            vm.usersList = element.itemsList;
                            vm.usersListAll = element.itemsList;
                            break;
                        case 'athletes':
                            vm.athletesList = element.itemsList;
                            vm.athletesListAll = element.itemsList;
                            break;
                        case 'parents':
                            vm.parentsList = element.itemsList;
                            vm.parentsListAll = element.itemsList;
                            break;
                        case 'usergroups':
                            vm.usersGroupList = element.itemsList;
                            vm.usersGroupListAll = element.itemsList;
                            break;
                        case 'sections':
                            vm.sectionsList = element.itemsList;
                            vm.sectionsListAll = element.itemsList;
                            break;
                        case 'teamsathletes':
                            vm.teamsAthletesList = element.itemsList;
                            vm.teamsAthletesListAll = element.itemsList;
                            break;
                        case 'teamsparents':
                            vm.teamParentsList = element.itemsList;
                            vm.teamParentsListAll = element.itemsList;
                            break;
                        case 'agegroupsathletes':
                            vm.ageGroupsList = element.itemsList;
                            vm.ageGroupsListAll = element.itemsList;
                            break;
                        case 'agegroupsparents':
                            vm.ageGroupsParentsList = element.itemsList;
                            vm.ageGroupsParentsListAll = element.itemsList;
                            break;
                    }
                });
            });
        }

        function showRecipients() {
            var obj = {};
            obj.Ids = vm.RecipientsIds;
            obj.Message = '-';
            dataservice.getNamesByIds(obj).then(function (data) {
                var txt = '';
                for (var i = 0; i < data.data.length; i++) {
                    txt += data.data[i];
                    if (i < data.data.length - 1 && data.data.length > 1) {
                        txt += ', ';
                    }
                }
                swal({
                    title: gettextCatalog.getString('recipients'),
                    text: txt,
                    type: 'info',
                    showCancelButton: false,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('Ok')
                });
            });
        }

        function clearRecipients() {
            initAlertSettings();
            vm.RecipientsIds = [];
            vm.RecipientsNames = '';
            vm.RecipientsNamesArr = [];
            vm.emptyGroupList = '';
            $timeout(function () {
                $scope.$apply();
            }, 10);
        }

        function recipientsSettings(tab) {
            var itemstemp = [];
            switch (tab) {
                case 0:
                    itemstemp = angular.element('#usersListBox').ejListBox('getCheckedItems');
                    if (itemstemp.length > 0) {
                        addRecipientNames(itemstemp, 'users');
                    }
                    break;
                case 1:
                    itemstemp = angular.element('#athletesListBox').ejListBox('getCheckedItems');
                    if (itemstemp.length > 0) {
                        addRecipientNames(itemstemp, 'athletes');
                    }
                    break;
                case 2:
                    itemstemp = angular.element('#parentsListBox').ejListBox('getCheckedItems');
                    if (itemstemp.length > 0) {
                        addRecipientNames(itemstemp, 'parents');
                    }
                    break;
                case 3:
                    itemstemp = angular.element('#usersGroupListBox').ejListBox('getCheckedItems');
                    if (itemstemp.length > 0) {
                        addRecipientsFromGroup('usergroupmember', itemstemp);
                    }
                    break;
                case 4:
                    itemstemp = angular.element('#sectionsListBox').ejListBox('getCheckedItems');
                    if (itemstemp.length > 0) {
                        addRecipientsFromGroup('sectionmembers', itemstemp);
                    }
                    break;
                case 5:
                    itemstemp = angular.element('#teamsAthletesListBox').ejListBox('getCheckedItems');
                    if (itemstemp.length > 0) {
                        addRecipientsFromGroup('teammembers', itemstemp);
                    }
                    break;
                case 6:
                    itemstemp = angular.element('#teamParentsListBox').ejListBox('getCheckedItems');
                    if (itemstemp.length > 0) {
                        addRecipientsFromGroup('teammembersparents', itemstemp);
                    }
                    break;
                case 7:
                    itemstemp = angular.element('#ageGroupsListBox').ejListBox('getCheckedItems');
                    if (itemstemp.length > 0) {
                        addRecipientsFromGroup('agegroupsmembers', itemstemp);
                    }
                    break;
                case 8:
                    itemstemp = angular.element('#ageGroupsParentsListBox').ejListBox('getCheckedItems');
                    if (itemstemp.length > 0) {
                        addRecipientsFromGroup('agegroupsmembersparents', itemstemp);
                    }
                    break;
            }
            $scope.$apply();
        }

        function getUserIdsOfGroup(usersof, id, name) {
            var idlist = [];
            idlist.push(id);
            return dataservice.getUserIdsOfGroup(usersof, idlist).then(function (data) {
                if (data.data.length == 0) {
                    vm.emptyGroupList += name + '<br>';
                    return false;
                }
                for (var i = 0; i < data.data.length; i++) {
                    vm.RecipientsIds.push(data.data[i]);
                }
                switch (usersof){
                    case 'usergroupmember':
                        vm.alertSettings.usergroups.push(id);
                        break;
                    case 'sectionmembers':
                        vm.alertSettings.sections.push(id);
                        break;
                    case 'teammembers':
                        vm.alertSettings.teamsathletes.push(id);
                        break;
                    case 'teammembersparents':
                        vm.alertSettings.teamsparents.push(id);
                        break;
                    case 'agegroupsmembers':
                        vm.alertSettings.agegroupsathletes.push(id);
                        break;
                    case 'agegroupsmembersparents':
                        vm.alertSettings.agegroupsparents.push(id);
                        break;
                }
                return name;
            });
        }

        function addRecipientsFromGroup(group, temp) {
            for (var i = 0; i < temp.length; i++) {
                var t = temp[i].data;
                $q.all([getUserIdsOfGroup(group, temp[i].data.value, temp[i].data.text)]).then(function (results) {
                    if (results != false && noDuplicates(results)) {
                        if (vm.RecipientsNames.length > 0) {
                            vm.RecipientsNames += '; ';
                        }
                        vm.RecipientsNames += results;
                        vm.RecipientsNamesArr.push(results);
                    }
                    vm.processedTabs++;
                });
            }
        }

        function addRecipientNames(items, tab) {
            angular.forEach(items, function(value) {
                switch (tab){
                    case 'users':
                        vm.alertSettings.users.push(value.data.value);
                        break;
                    case 'athletes':
                        vm.alertSettings.athletes.push(value.data.value);
                        break;
                    case 'parents':
                        vm.alertSettings.parents.push(value.data.value);
                        break;
                }
                vm.RecipientsIds.push(value.data.value);
                if (noDuplicates(value.text)) {
                    if (vm.RecipientsNames.length > 0) {
                        vm.RecipientsNames += '; ';
                    }
                    vm.RecipientsNames += value.text;
                    vm.RecipientsNamesArr.push(value.text);
                }
            });
            vm.processedTabs++;
        }

        function noDuplicates(newName) {
            for (var i = 0; i < vm.RecipientsNamesArr.length; i++) {
                if (vm.RecipientsNamesArr[i] == newName) {
                    return false;
                }
            }
            return true;
        }

        function getNumberOfCheckedTabs() {
            var itemstemp = [];
            var cnt = 0;
            if (angular.element('#usersListBox').ejListBox('getCheckedItems').length > 0) {
                cnt++;
            }
            if (angular.element('#athletesListBox').ejListBox('getCheckedItems').length > 0) {
                cnt++;
            }
            if (angular.element('#parentsListBox').ejListBox('getCheckedItems').length > 0) {
                cnt++;
            }
            if (angular.element('#usersGroupListBox').ejListBox('getCheckedItems').length > 0) {
                cnt++;
            }
            if (angular.element('#sectionsListBox').ejListBox('getCheckedItems').length > 0) {
                cnt++;
            }
            if (angular.element('#teamsAthletesListBox').ejListBox('getCheckedItems').length > 0) {
                cnt++;
            }
            if (angular.element('#teamParentsListBox').ejListBox('getCheckedItems').length > 0) {
                cnt++;
            }
            if (angular.element('#ageGroupsListBox').ejListBox('getCheckedItems').length > 0) {
                cnt++;
            }
            if (angular.element('#ageGroupsParentsListBox').ejListBox('getCheckedItems').length > 0) {
                cnt++;
            }
            return cnt;
        }

        function toolbarPopupClick(params) {
            vm.processedTabs = 0;
            clearRecipients();
            angular.element('#directory').data('ejDialog').close();
            if (params.currentTarget.id == '1') {
                var tabs = getNumberOfCheckedTabs();
                for (var t = 0; t <= 9; t++) {
                    recipientsSettings(t);
                }
                WaitForRecipientsIds(tabs);
            }
        }

        function WaitForRecipientsIds(tabs) {
            if (vm.processedTabs >= tabs) {
                showRecipients();
                vm.saveThis.alertUsers = vm.RecipientsIds;
                vm.saveThis.alertSettings = vm.alertSettings;
                vm.saveThis.alertSettingsJSON = JSON.stringify(vm.alertSettings);
                switch (vm.saveOrModify){
                    case 'modify':modifyEvent();break;
                    case 'save':
                    default: saveEvent();break;
                }
            } else {
                setTimeout(function() {
                    WaitForRecipientsIds(tabs);
                }, 10);
            }
        }

        function showDirectory(arg) {
            vm.saveOrModify = arg;
            angular.element('#directory').data('ejDialog').open();
        }

        function searchByName(arr, name) {
            if (!name || name.length == 0) {
                return arr;
            }
            var result = [];
            arr.forEach(function (user) {
                if (user.text.toUpperCase().indexOf(name.toUpperCase()) !== -1) {
                    result.push(user);
                }
            });
            return result;
        }

        function searchUser() {
            angular.element('#chkSelectAllUsers').ejCheckBox({checked: false});
            vm.usersList = searchByName(vm.usersListAll, vm.searchUserParam);
        }

        function searchAthlete() {
            angular.element('#chkSelectAllAthletes').ejCheckBox({checked: false});
            vm.athletesList = searchByName(vm.athletesListAll, vm.searchAthleteParam);
        }

        function searchParent() {
            angular.element('#chkSelectAllParents').ejCheckBox({checked: false});
            vm.parentsList = searchByName(vm.parentsListAll, vm.searchParentParam);
        }

        function searchUserGroup() {
            angular.element('#chkSelectAllUserGroup').ejCheckBox({checked: false});
            vm.usersGroupList = searchByName(vm.usersGroupListAll, vm.searchUserGroupParam);
        }

        function searchSection() {
            angular.element('#chkSelectAllSections').ejCheckBox({checked: false});
            vm.sectionsList = searchByName(vm.sectionsListAll, vm.searchSectionParam);
        }

        function searchTeamsAthletes() {
            angular.element('#chkSelectAllTeams').ejCheckBox({checked: false});
            vm.teamsAthletesList = searchByName(vm.teamsAthletesListAll, vm.searchTeamsAthletesParam);
        }

        function searchTeamsParents() {
            angular.element('#chkSelectAllTeamParents').ejCheckBox({checked: false});
            vm.teamParentsList = searchByName(vm.teamParentsListAll, vm.searchTeamsParentParam);
        }

        function searchAgeGroupAthletes() {
            angular.element('#chkSelectAllAgeGroups').ejCheckBox({checked: false});
            vm.ageGroupsList = searchByName(vm.ageGroupsListAll, vm.searchAgeGroupsAthletesParam);
        }

        function searchAgeGroupParents() {
            angular.element('#chkSelectAllAgeGroupsParents').ejCheckBox({checked: false});
            vm.ageGroupsParentsList = searchByName(vm.ageGroupsParentsListAll, vm.searchAgeGroupsParentParam);
        }

        function userCheckboxChanged(args) {
            for (var i = 0; i < vm.usersList.length; i++) {
                if (args.value) {
                    angular.element('#usersListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#usersListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }

        function athleteCheckboxChanged(args) {
            for (var i = 0; i < vm.athletesList.length; i++) {
                if (args.value) {
                    angular.element('#athletesListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#athletesListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }

        function parentsCheckboxChanged(args) {
            for (var i = 0; i < vm.parentsList.length; i++) {
                if (args.value) {
                    angular.element('#parentsListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#parentsListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }

        function usersGroupCheckboxChanged(args) {
            for (var i = 0; i < vm.usersGroupList.length; i++) {
                if (args.value) {
                    angular.element('#usersGroupListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#usersGroupListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }

        function sectionsCheckboxChanged(args) {
            for (var i = 0; i < vm.sectionsList.length; i++) {
                if (args.value) {
                    angular.element('#sectionsListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#sectionsListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }

        function teamsCheckboxChanged(args) {
            for (var i = 0; i < vm.teamsAthletesList.length; i++) {
                if (args.value) {
                    angular.element('#teamsAthletesListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#teamsAthletesListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }

        function teamParentsCheckboxChanged(args) {
            for (var i = 0; i < vm.teamParentsList.length; i++) {
                if (args.value) {
                    angular.element('#teamParentsListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#teamParentsListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }

        function ageGroupsCheckboxChanged(args) {
            for (var i = 0; i < vm.ageGroupsList.length; i++) {
                if (args.value) {
                    angular.element('#ageGroupsListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#ageGroupsListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }

        function ageGroupsParentsCheckboxChanged(args) {
            for (var i = 0; i < vm.ageGroupsParentsList.length; i++) {
                if (args.value) {
                    angular.element('#ageGroupsParentsListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#ageGroupsParentsListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }

        activate();

        function activate() {
            var accessGranted = false;
            authenticationService.getRight('MEGTESEMNAPT').then(function(results) {
                if (results !== true) {
                    log.permissionError(true);
                    return;
                }

                $q.all([
                    paramHelper.getParams([], ['eventsCalendar.sectionId']),
                    getSections(),
                    authenticationService.getLanguage(),
                    authenticationService.getRight('MEGTESEMNAPT'),
                    authenticationService.getRight('MODESEMNAPT'),
                    authenticationService.getRight('UJESEMNAPT'),
                    getSectionResources(),
                    getAgeGroupResources(),
                    authenticationService.getRight('SPORTOLO'),
                    authenticationService.getRight('SZULO')])
                    .then(function (results) {
                        if (results[3] !== true) {
                            log.permissionError(true);
                            return;
                        }
                        if (results[0]['eventsCalendar.sectionId']) {
                            vm.sectionId = results[0]['eventsCalendar.sectionId'];
                        } else {
                            vm.sectionId = 0;
                        }

                        vm.language = results[2];
                        vm.MODESEMNAPT = results[4];
                        vm.UJESEMNAPT = results[5];

                        createSchedule();

                        vm.SPORTOLO = results[8];
                        vm.SZULO = results[9];

                        setSectionVisible();

                        getUsers();
                    });
            });
        }

        function setSectionVisible() {
            vm.sectionDropDownVisible = !(vm.SZULO || vm.SPORTOLO);
        }

        function getSectionResources() {
            return dataservice.getSectionResources().then(function (data) {
                vm.sections = data.itemsList;
                vm.sectionResources = data.itemsList;
            });
        }

        function getAgeGroupResources() {
            return dataservice.getAgeGroupResources().then(function (data) {
                vm.ageGroupResources = data.itemsList;
            });
        }

        function getSections() {
            return dataservice.sectionDropDownList().then(function (data) {
                vm.selectedRowData = {};
                if (data.itemsList.length > 1) {
                    data.itemsList.unshift({value: '0', text: gettextCatalog.getString('All')});
                }
                vm.sectionList = data.itemsList;
            });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                vm.sectionId = vm.sectionList[0].value;
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        function sectionSelect(args) {
            vm.sectionId = args.value;
            vm.thereIsSelectedSection = true;
            getEvents();
        }

        function getEvents() {
            dataservice.getEvents(vm.date, vm.view, vm.sectionId, vm.training).then(function (data) {
                vm.appointments = [];
                vm.appointments = data.itemsList;
                vm.appointments = $filter('dateEventFilter')(vm.appointments, vm.view);
                showSchedule();
                clearRecipients();
            });
        }

        function showSchedule() {
            angular.element('#Schedule1').ejSchedule({
                appointmentSettings: {
                    dataSource: vm.appointments
                }
            });
        }

        function createSchedule() {
            angular.element('#Schedule1').ejSchedule({
                appointmentTemplateId: '#apptemplate',
                width: '100%',
                height: '600px',
                views: ['Day', 'Week', 'Month', 'Agenda'],
                currentView: 'Month',
                timeZone: 'UTC +00:00',
                allowDragDrop: false,
                readOnly: !(vm.MODESEMNAPT || vm.UJESEMNAPT),
                timeMode: ej.Schedule.TimeMode.Hour24,
                enableAppointmentResize: false,
                showAppointmentNavigator: false,
                showQuickWindow: false,
                showLocationField: true,
                showTimeZoneFields: false,
                firstDayOfWeek: 'Monday',
                timeZoneCollection: {
                    dataSource: [{
                        text: 'UTC +00:00',
                        id: '1',
                        value: 'UTC +00:00'
                    }]
                },
                categorizeSettings: {
                    enable: true,
                    allowMultiple: false,
                    dataSource: vm.categoryResources,
                    text: 'text', id: 'id', color: 'color', fontColor: 'fontColor'
                },
                highlightBusinessHours: false,
                tooltipSettings: {
                    enable: true
                },
                resources: [
                    {
                        field: 'SectionId',
                        title: gettextCatalog.getString('Section'),
                        name: 'SectionId',
                        allowMultiple: false,
                        resourceSettings: {
                            dataSource: vm.sectionResources,
                            text: 'text',
                            id: 'id',
                            color: 'color',
                            groupId: 'groupId'
                        }
                    },
                    {
                        field: 'AgeGroupId',
                        title: gettextCatalog.getString('AgeGroup'),
                        name: 'AgeGroupId',
                        allowMultiple: false,
                        resourceSettings: {
                            dataSource: vm.ageGroupResources,
                            text: 'text',
                            id: 'id',
                            color: 'color',
                            groupId: 'groupId'
                        }
                    }
                ],
                contextMenuSettings: {
                    enable: true,
                    menuItems: {
                        appointment: [
                            {id: 'open', text: gettextCatalog.getString('OpenAppointment')},
                            {id: 'delete', text: gettextCatalog.getString('DeleteAppointment')},
                            {id: 'export', text: gettextCatalog.getString('ExportToICS')}
                        ],
                        cells: [
                            {id: 'new', text: gettextCatalog.getString('NewAppointment')},
                            {id: 'recurrence', text: gettextCatalog.getString('NewRecurringAppointment')},
                            {id: 'today', text: gettextCatalog.getString('Today')},
                            {id: 'gotodate', text: gettextCatalog.getString('GoToDate')},
                            {id: 'settings', text: gettextCatalog.getString('Settings')},
                            {id: 'view', text: gettextCatalog.getString('View'), parentId: 'settings'},
                            {id: 'timemode', text: gettextCatalog.getString('TimeMode'), parentId: 'settings'},
                            {id: 'view_Day', text: gettextCatalog.getString('Daily'), parentId: 'view'},
                            {id: 'view_Week', text: gettextCatalog.getString('Weekly'), parentId: 'view'},
                            {id: 'view_Month', text: gettextCatalog.getString('Monthly'), parentId: 'view'},
                            {id: 'view_Agenda', text: gettextCatalog.getString('Agenda'), parentId: 'view'},
                            {id: 'timemode_Hour12', text: gettextCatalog.getString('12Hours'), parentId: 'timemode'},
                            {id: 'timemode_Hour24', text: gettextCatalog.getString('24Hours'), parentId: 'timemode'},
                            {id: 'businesshours', text: gettextCatalog.getString('BusinessHours'), parentId: 'settings'}
                        ]
                    }
                },
                prioritySettings: {
                    enable: true,
                    dataSource: [
                       {text: gettextCatalog.getString('No'), value: 0},
                       {text: gettextCatalog.getString('Yes'), value: 1}],
                    text: 'text', value: 'value',
                    template: '<div class=\'${value}\'></div>'
                },
                appointmentSettings: {
                    applyTimeOffset: false,
                    dataSource: vm.appointments,
                    id: 'Id',
                    location: 'Location',
                    subject: 'Subject',
                    startTime: 'StartTime',
                    startTimeZone: 'StartTimeZone',
                    endTime: 'EndTime',
                    endTimeZone: 'EndTimeZone',
                    description: 'Description',
                    allDay: 'AllDay',
                    recurrence: 'Recurrence',
                    recurrenceId: 'RecurrenceId',
                    recurrenceRule: 'RecurrenceRule',
                    resourceFields: 'SectionId,AgeGroupId',
                    categorize: 'Categorize',
                    priority: 'priority'
                },
                menuItemClick: exportToIcs,
                appointmentWindowOpen: appointmentWindowOpen,
                beforeAppointmentCreate: saveAppointment,
                beforeAppointmentChange: changeAppointment,
                beforeAppointmentRemove: removeAppointment,
                navigation: navigation,
                create: onCreate,
                locale: vm.language,
            });
        }

        function setAlertUsers(alertSettings, tab) {
            var array = alertSettings[tab];
            if (!array || array.length == 0) {
                return;
            }
            var index = -1;
            array.forEach(function (id) {
                switch (tab){
                    case 'users':
                        index = $filter('getIndex')(vm.usersListAll, 'value', id);
                        angular.element('#usersListBox').ejListBox('checkItemByIndex', index);
                        break;
                    case 'athletes':
                        index = $filter('getIndex')(vm.athletesListAll, 'value', id);
                        angular.element('#athletesListBox').ejListBox('checkItemByIndex', index);
                        break;
                    case 'parents':
                        index = $filter('getIndex')(vm.parentsListAll, 'value', id);
                        angular.element('#parentsListBox').ejListBox('checkItemByIndex', index);
                        break;
                    case 'usergroups':
                        index = $filter('getIndex')(vm.usersGroupListAll, 'value', id);
                        angular.element('#usersGroupListBox').ejListBox('checkItemByIndex', index);
                        break;
                    case 'sections':
                        index = $filter('getIndex')(vm.sectionsListAll, 'value', id);
                        angular.element('#sectionsListBox').ejListBox('checkItemByIndex', index);
                        break;
                    case 'teamsathletes':
                        index = $filter('getIndex')(vm.teamsAthletesListAll, 'value', id);
                        angular.element('#teamsAthletesListBox').ejListBox('checkItemByIndex', index);
                        break;
                    case 'teamsparents':
                        index = $filter('getIndex')(vm.teamParentsListAll, 'value', id);
                        angular.element('#teamParentsListBox').ejListBox('checkItemByIndex', index);
                        break;
                    case 'agegroupsathletes':
                        index = $filter('getIndex')(vm.ageGroupsListAll, 'value', id);
                        angular.element('#ageGroupsListBox').ejListBox('checkItemByIndex', index);
                        break;
                    case 'agegroupsparents':
                        index = $filter('getIndex')(vm.ageGroupsParentsListAll, 'value', id);
                        angular.element('#ageGroupsParentsListBox').ejListBox('checkItemByIndex', index);
                        break;
                }
            });
        }

        function appointmentWindowOpen(arg) {
            clearRecipients();
            if (arg.hasOwnProperty('appointment')) {
                if (arg.appointment.hasOwnProperty('alertSettings')) {
                    Object.getOwnPropertyNames(arg.appointment.alertSettings).forEach(function (property) {
                        setAlertUsers(arg.appointment.alertSettings, property);
                    });
                    $timeout(function () {
                        if (arg.appointment.alertUsers && arg.appointment.alertUsers.length > 0) {
                            angular.element('#Schedule1priority').ejDropDownList('selectItemByIndex', 1);
                        } else {
                            angular.element('#Schedule1priority').ejDropDownList('selectItemByIndex', 0);
                        }
                        $scope.$apply();
                    }, 100);
                }
            }
            var resData = this.model.resources[0].resourceSettings.dataSource;
            this['_appointmentAddWindow'].find('.owner_' + 0).ejAutocomplete({
                focusOut: function (argument) {
                    var flag = false;
                    for (var i = 0; i < resData.length; i++) {
                        if (argument.value === resData[i].text) {
                            flag = true;
                        }
                    }
                    if (!flag) {
                        log.errorMsg('SelectedSectionDoesnExist');
                    }
                }
            });
            var resData2 = this.model.resources[1].resourceSettings.dataSource;
            this['_appointmentAddWindow'].find('.owner_' + 1).ejAutocomplete({
                focusOut: function (argument) {
                    var flag = false;
                    for (var i = 0; i < resData2.length; i++) {
                        if (argument.value === resData2[i].text) {
                            flag = true;
                        }
                    }
                    if (!flag) {
                        log.errorMsg('SelectedAgeGroupDoesnExist');
                    }
                }
            });
        }

        function onCreate() {
        }

        function exportToIcs(args) {
            if (args.events.ID == 'export') {
                var obj = $('#Schedule1').data('ejSchedule');
                // exportSchedule() method will send a post to the server-side to call a specified action.
                obj.exportSchedule(DOMAIN.baseURL + DOMAIN.webService + 'ExportToICS', null, args.targetInfo.Id);
            }
        }

        function exportAllToIcs(e) {
            var obj = $('#Schedule1').data('ejSchedule');
            // Calls the server-side action ExportToICS
            obj.exportSchedule(DOMAIN.baseURL + DOMAIN.webService + 'ExportToICS', null, null);
            e.cancel = true;
        }

        function navigation(args) {
            vm.date = args.currentDate;
            vm.view = args.currentView;
            getEvents();
        }

        function saveAppointment(args) {
            if (vm.UJESEMNAPT) {
                vm.saveThis = {};
                if (args.appointment[0] === undefined) {
                    vm.saveThis = jQuery.extend({}, args.appointment);
                    vm.saveThis.StartTime = moment(vm.saveThis.StartTime).format('YYYY-MM-DD HH:mm');
                    vm.saveThis.EndTime = moment(vm.saveThis.EndTime).format('YYYY-MM-DD HH:mm');
                    if (vm.saveThis.Subject === '') {
                        vm.saveThis.Subject = vm.unnamedEvent;
                    }
                    var obj = ej.DataManager(vm.categoryResources).executeLocal(new ej.Query().where('id', 'equal', vm.saveThis.Categorize))[0];
                    vm.saveThis.Categorize = obj !== undefined ? obj.id : 1;
                } else {
                    vm.saveThis = jQuery.extend({}, args.appointment[0]);
                    var resData = this.model.resources[0].resourceSettings.dataSource;
                    var resData2 = this.model.resources[1].resourceSettings.dataSource;
                    var value = vm.saveThis.Schedule1_ownerfield_0 === '' ? (vm.saveThis.SectionId ? vm.saveThis.SectionId : 0) : vm.saveThis.Schedule1_ownerfield_0;
                    var value2 = vm.saveThis.Schedule1_ownerfield_1 === '' ? (vm.saveThis.AgeGroupId ? vm.saveThis.AgeGroupId : 0) : vm.saveThis.Schedule1_ownerfield_1;
                    var flag = false;
                    var flag2 = false;
                    for (var i = 0; i < resData.length; i++) {
                        if (value == resData[i].text || value === resData[i].value) {
                            flag = true;
                        }
                    }
                    for (var i = 0; i < resData2.length; i++) {
                        if (value2 == resData2[i].text || value2 === resData2[i].value) {
                            flag2 = true;
                        }
                    }
                    if (!flag) {
                        log.errorMsg('SelectedSectionDoesnExist');
                        args.cancel = true;
                    } else if (!flag2) {
                        log.errorMsg('SelectedAgeGroupDoesnExist');
                        args.cancel = true;
                    } else {
                        vm.saveThis.StartTime = moment(vm.saveThis.StartTime).format('YYYY-MM-DD HH:mm');
                        vm.saveThis.EndTime = moment(vm.saveThis.EndTime).format('YYYY-MM-DD HH:mm');
                        if (vm.saveThis.Subject === '') {
                            vm.saveThis.Subject = vm.unnamedEvent;
                        }
                        var obj = ej.DataManager(vm.categoryResources).executeLocal(new ej.Query().where('id', 'equal', vm.saveThis.Categorize))[0];
                        vm.saveThis.Categorize = obj !== undefined ? obj.id : 1;
                    }
                    // Felhasználók értesítése
                    if (vm.saveThis.priority == '1') {
                        showDirectory('save');
                    } else {
                        saveEvent();
                    }
                }
            } else {
                args.cancel = true;
                log.permissionError();
            }
        }

        function modifyEvent() {
            if (vm.saveThis.Id < 0) {
                log.errorMsg(gettextCatalog.getString('WeeklyTrainingEventCannotBeModified'));
                return;
            }
            dataservice.modifyEvent(vm.saveThis).then(function () {
                log.successMsg('Saved');
                getEvents();
            });
        }

        function saveEvent() {
            dataservice.saveEvent(vm.saveThis).then(function () {
                log.successMsg('Saved');
                getEvents();
            });
        }

        function changeAppointment(args) {
            if (vm.MODESEMNAPT) {
                vm.saveThis = jQuery.extend({}, args.appointment.changed[0]);
                vm.saveThis.StartTime = moment(vm.saveThis.StartTime).format('YYYY-MM-DD HH:mm');
                vm.saveThis.EndTime = moment(vm.saveThis.EndTime).format('YYYY-MM-DD HH:mm');
                var obj = ej.DataManager(vm.categoryResources).executeLocal(new ej.Query().where('id', 'equal', vm.saveThis.Categorize))[0];
                vm.saveThis.Categorize = obj !== undefined ? obj.id : 1;
                // Felhasználók értesítése
                if (vm.saveThis.priority == '1') {
                    showDirectory('modify');
                } else {
                    modifyEvent();
                }
            } else {
                args.cancel = true;
                log.permissionError();
            }
        }

        function removeAppointment(args) {
            if (args.appointment.Id < 0) {
                log.errorMsg(gettextCatalog.getString('WeeklyTrainingEventCannotBeModified'));
                args.cancel = true;
                return;
            }
            var removeDate = null;
            var year = -1;
            var month = -1;
            var day = -1;
            if (args.currentAction == 'deleteOccurrence') {
                removeDate = args.appointment.StartTime;
                year = removeDate.getFullYear();
                month = removeDate.getMonth();
                day = removeDate.getDate();
            }
            dataservice.removeEvent(args.appointment.Id, year, month, day).then(function () {
                getEvents();
            });
        }

        function print() {
            var schObj = angular.element('#Schedule1').data('ejSchedule');
            schObj.print();
        }

        function trainingCheckboxChanged(args) {
            vm.training = args.value;
            getEvents();
        }
    }
})();
