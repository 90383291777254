(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.dataSheet')
        .controller('AthleteModifyDataSheetController', AthleteModifyDataSheetController);
    AthleteModifyDataSheetController.$inject = ['log', 'logger', 'dataservice', '$q', 'DOMAIN', '$rootScope', 'gettextCatalog', 'paramHelper', 'authenticationService', '$filter', '$scope', '$compile', 'VIEW_DATA', '$state', '$timeout'];

    function AthleteModifyDataSheetController(log, logger, dataservice, $q, DOMAIN, $rootScope, gettextCatalog, paramHelper, authenticationService, $filter, $scope, $compile, VIEW_DATA, $state, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        vm.display = false;

        vm.noImgUrl = DOMAIN.baseURL + DOMAIN.userImage;
        vm.photoUrl = vm.noImgUrl;
        vm.selectedItems = [0, 1];
        vm.sexNum = 0;
        vm.sexList = [
            {text: gettextCatalog.getString('Man'), value: 1},
            {text: gettextCatalog.getString('Woman'), value: -1}
        ];
        vm.betterHandList = [
            {text: gettextCatalog.getString('Left'), value: 1},
            {text: gettextCatalog.getString('Right'), value: 2},
            {text: gettextCatalog.getString('Both'), value: 3}
        ];
        vm.betterFootList = [
            {text: gettextCatalog.getString('Left'), value: 1},
            {text: gettextCatalog.getString('Right'), value: 2},
            {text: gettextCatalog.getString('Both'), value: 3}
        ];
        vm.feeOfEducationList = [
            {text: gettextCatalog.getString('EducationFee'), value: -1},
            {text: gettextCatalog.getString('SportServiceFee'), value: 1}
        ];

        vm.parentList = [];

        vm.FatherMotherList = [
            {value: 'mother', text: gettextCatalog.getString('Mother')},
            {value: 'father', text: gettextCatalog.getString('Father')},
        ];

        // Oktatási díj = 0 - Sportszolgáltatási díj = Tagdíj = 1;
        vm.feeOfEducationValue = gettextCatalog.getString('SportServiceFee'); // Sportszolgáltatási díjas
        vm.countyList = VIEW_DATA.dropDownList.counties;
        vm.courseList = VIEW_DATA.dropDownList.course;
        vm.classList = VIEW_DATA.dropDownList.class;
        vm.emptyResultText = gettextCatalog.getString('emptyResultText');
        vm.fatherNames = [];
        vm.motherNames = [];
        vm.feeOfEducationDataStart = undefined; // String
        vm.feeOfEducationDateOk = false;
        vm.sectionList = []; // recived section list
        vm.ageGroupList = []; // recived age group list
        vm.coachList = []; // for reentering
        vm.sectionListSwal = []; // recived section list
        vm.ageGroupListSwal = []; // recived age group list
        vm.coachListSwal = []; // for reentering
        vm.athleteDataSwalSzakosztaly = undefined;
        vm.athleteDataSwalKorcsoport = undefined;
        vm.athleteDataSwalEdzo = undefined;
        vm.secondAthleteDataSwalSzakosztaly = undefined;
        vm.secondAthleteDataSwalKorcsoport = undefined;
        vm.secondAthleteDataSwalEdzo = undefined;
        vm.allowEdit = true;

        vm.isSecondCoach = false;
        vm.isSecondCoachChange = false;
        vm.secondAgeGroupList = [];
        vm.secondSectionList = [];
        vm.secondCoachList = [];
        vm.secondAgeGroupListSwal = [];
        vm.secondSectionListSwal = [];
        vm.secondCoachListSwal = [];
        vm.selectedExitDate = undefined;
        vm.exitAthleteDateOk = false;
        // Athlete parameters
        vm.selectedAgeGroupText = undefined; // selected age group name
        vm.selectedAthlete = [];
        vm.athleteData = {
            Id: undefined,
            AdoSzam: undefined, // String
            Aktiv: undefined, // Boolean
            AktTestmagassag: undefined, // Int32?
            AktTestsuly: undefined, // Int32?
            Allampolgarsag: undefined, // String
            AnyjaLeanykoriNeve: undefined, // String
            AnyjaNeve: undefined, // String
            AnyjaTestmagassaga: undefined, // Int32?
            ApjaTestmagassaga: undefined, // Int32
            BefOktdij: undefined, // Decimal
            BefTagdij: undefined, // Decimal
            DSITagsagKezdete: undefined, // DateTime
            EddigiEdzoi: undefined, // String
            EddigiSpEgyesuletei: undefined, // String
            EdzesreJarasKezdete: undefined, // DateTime
            Egyenleg: undefined, // Decimal
            Elerhetoseg: undefined, // String
            EmailCim: undefined, // String
            EvzarasOsszege: undefined, // Decimal?
            FajiEredet: undefined, // String
            Fenykep: undefined, // Byte[]
            FenykepUt: undefined, // String
            fileData: null,
            FurdokopenyDb: undefined, // String
            FurdokopenyErtek: undefined, // String
            FurdokopenyMerete: undefined, // String
            GyermekkoriSport: undefined, // String
            IdSzakosztaly: undefined, // Int32
            IskolaEvfolyam: undefined, // String
            IskolaHazszam: undefined, // String
            IskolaIgazgatoNeve: undefined, // String
            IskolaIrSzam: undefined, // String
            IskolaNeve: undefined, // String
            IskolaOsztaly: undefined, // String
            IskolaUtca: undefined, // String
            IskolaVaros: undefined, // String
            JelenlegiEdzo: undefined, // int
            KabatDb: undefined, // String
            KabatErtek: undefined, // String
            KabatMerete: undefined, // String
            KilepesDatuma: undefined, // DateTime
            KilepesIndoka: undefined, // String
            Korcsoport: undefined, // Int32
            LakcimHazszam: undefined, // String
            LakcimIrSzam: undefined, // String
            LakcimMegye: undefined, // String
            LakcimUtca: undefined, // String
            LakcimVaros: undefined, // String
            LeanykoriNev: undefined, // String
            MasodlagosSzakosztaly: undefined, // Int32?
            MasodlagosSzakosztalyEdzoId: undefined,
            MasodlagosKorcsoport: undefined,
            Megjegyzes: undefined, // String
            MelegitoDb: undefined, // String
            MelegitoErtek: undefined, // String
            MelegitoMerete: undefined, // String
            Neme: undefined, // String
            Nev: undefined, // String
            OsztalyFonokNeve: undefined, // String
            PancelNadragDb: undefined, // String
            PancelNadragErtek: undefined, // String
            PancelNadragMerete: undefined, // StringCipo
            UszoNadragMerete: undefined, // StringCipo
            PapucsDb: undefined, // String
            PapucsErte: undefined, // String
            PapucsMerete: undefined, // String
            CipoDb: undefined, // String
            CipoErtek: undefined, // String
            CipoMerete: undefined, // String
            PoloDb: undefined, // String
            PoloErtek: undefined, // String
            PoloMerete: undefined, // String
            PubertasVege: undefined, // DateTime
            RegisztraciosSzam: undefined, // String
            SortDb: undefined, // String
            SortErtek: undefined, // String
            SortMerete: undefined, // String
            SportoloiMultAnya: undefined, // String
            SportoloiMultApa: undefined, // String
            SportolokID: undefined, // Int32
            SzakosztalyNev: undefined, // String
            SzemIgSzam: undefined, // String
            SzuletesiHelyOrszag: undefined,
            SzuletesiHelyVaros: undefined, // String
            SzuletesiIdo: undefined, // DateTime
            Szulo1Elerhetosege: undefined, // String
            Szulo2Elerhetosege: undefined, // String
            SzulokFoglalkozasa: undefined, // String
            SzuloNeve: undefined, // String
            SzunideiElfoglaltsag: undefined, // String
            Tagdijas: undefined, // Boolean
            TAJSzam: undefined, // String
            Testmagassag: undefined, // Int32?
            TestneveloElerhetosege: undefined, // String
            TestneveloNeve: undefined, // String
            Testsuly: undefined, // Int32?
            UgyesebbKez: undefined, // String
            UgyesebbLabT: undefined, // String
            UtlevelSzam: undefined, // String
            UtolsoFrissites: undefined, // DateTime
            ValasztottSportagak: undefined, // String
            VersSportKezd: undefined, // DateTime
            VisszalepesDatuma: undefined, // DateTime?
        };
        vm.sportoloId = -1; // for sql quetion
        // This value show when start the new Fee of education mode
        vm.feeOfEducationDate = undefined;
        // Current coach name in text by Id
        vm.currentCoachNameInText = undefined;
        // Grid's details
        vm.schoolGridSelectedRowData = {};
        vm.gridToolbar = ['printGrid', 'excelExport'];

        vm.columnsUsersOfAthlete = [
            {field: 'UserName', headerText: gettextCatalog.getString('UserName')},
            {
                field: 'UserTeljesNev',
                headerText: gettextCatalog.getString('UserFullName'),
                textAlign: 'left',
                template: true,
                templateID: '#atmotemplateDataColumn4'
            },
            {field: 'szerep', headerText: gettextCatalog.getString('Role')},
            {
                headerText: gettextCatalog.getString('unlinkUserFromAthlete'),
                textAlign: 'left',
                template: true,
                templateID: '#atmotemplateDataColumn5'
            }
        ];

        vm.columnsSchool = [
            {field: 'IskolaNeve', headerText: gettextCatalog.getString('SchoolName')},
            {field: 'IskolaIrSzam', headerText: gettextCatalog.getString('SchoolZipCode')},
            {field: 'IskolaVaros', headerText: gettextCatalog.getString('SchoolCity')},
            {field: 'IskolaUtca', headerText: gettextCatalog.getString('SchoolStreet')},
            {field: 'IskolaHazszam', headerText: gettextCatalog.getString('SchoolNumber')},
            {field: 'IskolaIgazgatoNeve', headerText: gettextCatalog.getString('SchoolDirectorName')}
        ];
        // Grid columnsAthleteMoveStory

        vm.columnsAthleteMoveStory = [
            {
                field: 'Edzo',
                headerText: gettextCatalog.getString('CoachName'),
                width: 100,
                template: '#atmotemplateDataColumn1'
            },
            {field: 'Szakosztaly', headerText: gettextCatalog.getString('Section'), width: 100},
            {field: 'Korcsoport', headerText: gettextCatalog.getString('AgeGroup'), width: 100},
            {field: 'Felvitel', headerText: gettextCatalog.getString('StartTime'), width: 100},
            {field: 'IdoszakVege', headerText: gettextCatalog.getString('EndTime'), width: 100},
            {
                field: 'Belepes',
                headerText: gettextCatalog.getString('Enter'),
                textAlign: 'center',
                width: 70,
                type: 'boolean',
                displayAsCheckBox: true
            },
            {
                field: 'Kilepes',
                headerText: gettextCatalog.getString('GoOut'),
                textAlign: 'center',
                width: 70,
                type: 'boolean',
                displayAsCheckBox: true
            }
        ];
        // Public functions
        // AthleteModify functions
        // Drop Down List function
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.getSections = getSections;

        // Second
        vm.secondSectionSelect = secondSectionSelect;
        vm.secondAgeGroupSelect = secondAgeGroupSelect;
        vm.secondAgeGroupDataBound = secondAgeGroupDataBound;
        vm.changeSecondAgeGroup = changeSecondAgeGroup;
        vm.secondCoachDropDownListDataBound = secondCoachDropDownListDataBound;
        vm.selectSecondCoachBtn = selectSecondCoachBtn;
        vm.exitingSecondSectionBtn = exitingSecondSectionBtn;
        vm.coachDropDownListDataBound = coachDropDownListDataBound;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        // Top menu buttons functions
        vm.anthropometry = anthropometry; // Public
        //vm.enteringAthlete = enteringAthlete; // public
        vm.generateRegNum = generateRegNum;

        // Image cropper variables
        vm.deleteImg = deleteImg; // Public, delete the photo from user profile 999 Hofi

        vm.save = save;
        vm.cropper = {}; // Ricsi
        vm.cropper.sourceImage = null; // Ricsi
        vm.cropper.croppedImage = null; // Ricsi
        vm.bounds = {}; // Ricsi
        vm.bounds.left = 0; // Ricsi
        vm.bounds.right = 0; // Ricsi
        vm.bounds.top = 0; // Ricsi
        vm.bounds.bottom = 0; // Ricsi
        vm.cropDialogTitle = gettextCatalog.getString('ChooseImageTitle'); // Ricsi
        vm.unlinkUserFromAthleteText = gettextCatalog.getString('unlinkUserFromAthlete');

        vm.openCropDialog = openCropDialog; // Ricsi
        vm.doneCropDialog = doneCropDialog; // Ricsi
        vm.closeCropDialog = closeCropDialog; // Ricsi
        // Change Age Group
        vm.changeAgeGroup = changeAgeGroup;
        // sex (gende) dropdown public function
        vm.sexSelect = sexSelect;
        // Height dropdown public function
        vm.changeHeight = changeHeight;
        // Weight dropdown public function
        vm.changeWeight = changeWeight;
        // better Hand dropdown public function
        vm.betterHandSelect = betterHandSelect;
        // better Foot dropdown public function
        vm.betterFootSelect = betterFootSelect;
        // Social security number required
        vm.socialSecurityNumberRequiredValue = false;
        vm.athleteRegistrationNumberRequiredValue = false;
        //
        vm.changeFeeOfEducationBtn = changeFeeOfEducationBtn;
        // select coach
        vm.selectCoachBtn = selectCoachBtn;
        // Select Schools
        vm.selectSchools = selectSchools;
        // Public functions for grid databound
        vm.compile = compile;
        // Grid selcted row
        vm.schoolGridSelectedRow = schoolGridSelectedRow;
        vm.coachList = [];
        vm.clearSecondSection = clearSecondSection;
        vm.clearSecondAgeGroup = clearSecondAgeGroup;
        vm.clearSecondCoach = clearSecondCoach;
        vm.openCoach = openCoach;
        vm.createLoginForAthlete = createLoginForAthlete;
        vm.createLoginForParents = createLoginForParents;
        vm.createLoginForExistingParent = createLoginForExistingParent;

        vm.photoUrl = vm.noImgUrl;
        vm.unlinkUserFromAthlete = unlinkUserFromAthlete;

        // School Grid datasource initial
        vm.displayButtons = true;
        vm.coachListGridData = [];
        vm.tajkeres = true;
        vm.ADMIN = false;
        vm.UJEDZVALT = false;
        vm.MODFELH = false;
        vm.SPORTKILEP = false;
        vm.SPORTKILEPTET = false;
        vm.SPORTBELEPTET = false;
        vm.ModFizetesMod = false;
        vm.SPORTOLO = false;
        vm.SZULO = false;
        vm.EGESZSMEGT = false;
        vm.aktiv = true;
        vm.RUHA = {
            CIPO: false,
            PAPUCS: false,
            MELEGITO: false,
            FURDOKOPENY: false,
            KABAT: false,
            PANCELNADRAG: false,
            POLO: false,
            SORT: false,
            USZONADRAG: false
        };

        vm.reportParametersSchoolRequesting = {};
        vm.SportoloCsoportok = [];
        vm.SzuloCsoportok = [];

        vm.gymTeacherDatasource = [];
        vm.classMasterDatasource = [];
        vm.schoolNameChanged = schoolNameChanged;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsSchool,
            rowSelected: schoolGridSelectedRow,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');

        var gridUsersOfAthlete = new ej2.grids.Grid({
            dataSource: [],
            allowTextWrap: true,
            locale: 'hu-HU',
            gridLines: 'both',
            columns: vm.columnsUsersOfAthlete,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            }
        });
        gridUsersOfAthlete.appendTo('#GridUsersOfAthlete');

        var grid123 = new ej2.grids.Grid({
            dataSource: [],
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsAthleteMoveStory,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid123.appendTo('#Grid123');

        // Activate page
        activate();
        $scope.$on('$viewContentLoaded', function () {
            // Set checkbox
            if (vm.sportoloId <= 0) {
                angular.element('#active').ejCheckBox({checked: true});
            }
        });

        function generateRegNum() {
            dataservice.GenerateRegNum().then(function (data) {
                vm.athleteData.RegisztraciosSzam = data.data;
            });
        }

        function getParentUserGroups() {
            return dataservice.SzuloCsoportokDropDownList().then(function (data) {
                vm.SzuloCsoportok = data.itemsList;
            });
        }

        function getAthleteUserGroups() {
            return dataservice.SportoloCsoportokDropDownList().then(function (data) {
                vm.SportoloCsoportok = data.itemsList;
            });
        }

        function activate() {
            $q.all([paramHelper.getParams(['athleteModify.sportoloId'], ['athleteModify.sportoloNev', 'selectedSectionId', 'selectedAgeGroupId', 'selectedActiveOrInactive', 'selectedPage', 'selectedRow']),
                authenticationService.getRight('ADMIN'),
                authenticationService.getRight('UJEDZVALT'),
                authenticationService.getRight('MODFELH'),
                authenticationService.getRight('SPORTKILEP'),
                authenticationService.getRight('ModFizetesMod'),
                authenticationService.getRight('MODSPORTOLO'),
                authenticationService.getRight('SPORTOLO'),
                authenticationService.getRight('SZULO'),
                authenticationService.getRight('EGESZSMEGT'),
                authenticationService.getRight('KMVERSEREDM'),
                authenticationService.getRight('MEGTHAVIJEL'),
                authenticationService.getRight('MODTAGDBEALL'),
                authenticationService.getRight('MEGTANTFELM'),
                authenticationService.getRight('MEGTKPFELM'),
                authenticationService.getRight('MEGTSPFELM'),
                authenticationService.getRight('PSYCHO'),
                authenticationService.getRight('KIKERONYOMT'),
                authenticationService.getRight('TESTMERET'),
                authenticationService.getRight('MEGTSPORTBEF'),
                authenticationService.getRight('MODKOROKEZ'),
                authenticationService.getRight('LOG'),
                authenticationService.getRight('DOKLIST'),
                getSystemSettings('NINCSOKTDIJ')
            ]).then(function (results) {
                vm.ADMIN = results[1];
                vm.UJEDZVALT = results[2];
                vm.MODFELH = results[3];
                vm.SPORTKILEP = results[4];
                vm.ModFizetesMod = results[5];
                vm.MODSPORTOLO = results[6];
                vm.SPORTOLO = results[7];
                vm.SZULO = results[8];
                vm.EGESZSMEGT = results[9];
                vm.KMVERSEREDM = results[10];
                vm.MEGTHAVIJEL = results[11];
                vm.MODTAGDBEALL = results[12];
                vm.MEGTANTFELM = results[13];
                vm.MEGTKPFELM = results[14];
                vm.MEGTSPFELM = results[15];
                vm.PSYCHO = results[16];
                vm.KIKERONYOMT = results[17];
                vm.TESTMERET = results[18];
                vm.MEGTSPORTBEF = results[19];
                vm.MODKOROKEZ = results[20];
                vm.LOG = results[21];
                vm.DOKLIST = results[22];
                vm.NINCSOKTDIJ = results[23];
                vm.photoUrl = vm.noImgUrl;

                vm.sportoloId = results[0]['athleteModify.sportoloId'];

                var promises = [getSystemSettings('TAJKOTELEZO'), getSystemSettings('SNYSZKOTELEZO'), getSystemSettings('SPORTMINKOTELEZO')];
                $q.all(promises).then(function (results) {
                    vm.socialSecurityNumberRequiredValue = results[0];
                    vm.athleteRegistrationNumberRequiredValue = results[1];
                    vm.athleteRequiredMinimumDatas = results[2];
                });

                dataservice.getAthleteData(vm.sportoloId).then(function (data) {
                    vm.athleteData = data;
                    RefreshClothes();
                    vm.sportoloNev = vm.athleteData.Nev;
                    $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('DataSheet');
                    init();
                });
            });
        }

        function RefreshClothes() {
            $timeout(function () {
                dataservice.SzakosztalyRuhai(vm.athleteData.IdSzakosztaly, vm.athleteData.MasodlagosSzakosztaly).then(function (ruhaResponse) {
                    vm.RUHA = ruhaResponse.data;
                });
            }, 100);
        }

        function init() {
            initAthleteDatas();
            getParentUserGroups();
            getAthleteUserGroups();
            getParentsList();
            getUsersBelongingToAthlete(vm.athleteData.SportolokID);
            getParentNames();
            getAthleteMoveStory(vm.athleteData.SportolokID);
        }

        function unlinkUserFromAthlete(userid, sportoloid, szerep) {
            var promises = [authenticationService.getRight('MODFELH')];
            $q.all(promises).then(function (res) {
                if (!res[0]) {
                    log.permissionError();
                } else {
                    if (szerep == 0) {
                        swal({
                            title: gettextCatalog.getString('Are you sure you want to unlink and delete the selected user?'),
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#DD6B55',
                            confirmButtonText: gettextCatalog.getString('Yes'),
                            cancelButtonText: gettextCatalog.getString('Cancel')
                        }).then(function (isConfirm) {
                            if (isConfirm.value) {
                                return dataservice.unlinkUserFromAthlete(userid, sportoloid).then(function () {
                                    log.successMsg(gettextCatalog.getString('UserUnlinkedAndDeleted'));
                                    activate();
                                });
                            }
                        });
                    } else {
                        swal({
                            title: gettextCatalog.getString('Are you sure you want to unlink the selected user?'),
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#DD6B55',
                            confirmButtonText: gettextCatalog.getString('Yes'),
                            cancelButtonText: gettextCatalog.getString('Cancel'),
                        }).then(function (isConfirm) {
                            if (isConfirm.value) {
                                return dataservice.unlinkUserFromAthlete(userid, sportoloid).then(function () {
                                    log.successMsg(gettextCatalog.getString('UserUnlinked'));
                                    activate();
                                });
                            }
                        });
                    }
                }
            })
                .catch(function () {
                    log.permissionError();
                });
        }

        function getParentsList() {
            dataservice.athleteAndParentDropDownList('parent')
                .then(function (data) {
                    vm.parentList = data.itemsList;
                });
        }

        vm.parentDataSwal = undefined;

        function parentSwalDatabound() {
            if (vm.parentList.length === 1) {
                angular.element('#parentDropDownSwal').ejDropDownList('selectItemByValue', vm.parentList[0].value);
            } else {
                angular.element('#parentDropDownSwal').ejDropDownList('selectItemByValue', vm.parentDataSwal);
            }
        }

        function selectParentDropDownSwal(arg) {
            vm.parentDataSwal = arg.value;
        }

        function createLoginForExistingParent() {
            var promises = [authenticationService.getRight('UJFELH')];
            $q.all(promises).then(function (res) {
                if (!res[0]) {
                    log.permissionError();
                } else {
                    var HTMLtext = '<div style="margin-top: 10px;" >' +
                        '<label class="col-lg-6" style="text-align: left; margin-top:5px;">' + gettextCatalog.getString('SelectParentForAthlete') + '</label>' +
                        '<input class="col-lg-6 col-lg-offset-6" id="parentDropDownSwal" ej-dropdownlist></div><br><br>';
                    swal({
                        title: gettextCatalog.getString('CreateLoginForExistingParent'),
                        html: HTMLtext,
                        type: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: gettextCatalog.getString('Ok'),
                        cancelButtonText: gettextCatalog.getString('Cancel'),
                    }).then(function (isConfirm) {
                        if (isConfirm.value) {
                            dataservice.assignAthleteToParentUser(vm.parentDataSwal, vm.athleteData.SportolokID, vm.selectedSzuloCsoport).then(function (arg) {
                                if (arg.data > 0) {
                                    log.successMsg(gettextCatalog.getString('AthleteAssignedToUserSuccessfully'));
                                    activate();
                                }
                            });
                        }
                    });
                    angular.element('#parentDropDownSwal').ejDropDownList({
                        dataSource: vm.parentList,
                        select: selectParentDropDownSwal,
                        value: vm.parentDataSwal,
                        databound: parentSwalDatabound,
                        width: '100%'
                    });
                }
            })
                .catch(function () {
                    log.permissionError();
                });
        }

        vm.fathermotherDataSwal = undefined;

        function fatherMotherSwalDatabound() {
            if (vm.FatherMotherList.length === 1) {
                angular.element('#fatherMotherDropDownSwal').ejDropDownList('selectItemByValue', vm.FatherMotherList[0].value);
            } else {
                angular.element('#fatherMotherDropDownSwal').ejDropDownList('selectItemByValue', vm.fathermotherDataSwal);
            }
        }

        function selectFatherMotherDropDownSwal(arg) {
            vm.fathermotherDataSwal = arg.value;
        }

        vm.selectedSzuloCsoport = undefined;

        function selectParentGroupDropDownSwal(arg) {
            vm.selectedSzuloCsoport = arg.value;
        }

        function createLoginForParents() {
            var promises = [authenticationService.getRight('UJFELH')];
            $q.all(promises).then(function (res) {
                if (!res[0]) {
                    log.permissionError();
                } else {
                    var HTMLtext = '<div class="row" style="margin-top: 10px;" ><div class="col-lg-6" style="text-align: left; margin-top:5px;"><label>' + gettextCatalog.getString('WhichParent') +
                        '</label></div><div class="col-lg-6"><input id="fatherMotherDropDownSwal" ej-dropdownlist></div></div><br><br>' +
                        '<div class="row" style="margin-top: 10px;"><div class="col-lg-6" style="text-align: left; margin-top:5px;"><label>' + gettextCatalog.getString('SelectParentGroup') + '</label></div>' +
                        '<div class="col-lg-6"><input id="parentGroupsDropDownSwal" ej-dropdownlist></div></div><br><br>' +
                        '<div class="row" style="margin-top: 10px;"><div class="col-lg-6" style="text-align: left; margin-top:5px;"><label>' + gettextCatalog.getString('Username') +
                        '</label></div><div class="col-lg-6"><input id="parentUserName" name="parentUserName" maxlength="10"></div></div>' +
                        '<div class="row" style="margin-top: 10px;"><div class="col-lg-6" style="text-align: left; margin-top:5px;"><label>' + gettextCatalog.getString('Password') +
                        '</label></div><div class="col-lg-6"><input type="password" id="parentPassword" name="parentPassword" maxlength="10"></div></div>' +
                        '<div class="row" style="margin-top: 10px;"><div class="col-lg-6" style="text-align: left; margin-top:5px;"><label>' + gettextCatalog.getString('PassordAgain') +
                        '</label></div><div class="col-lg-6"><input type="password" id="parentPasswordAgain" name="parentPasswordAgain" maxlength="10"></div></div>';
                    swal({
                        title: gettextCatalog.getString('CreateLoginForParents'),
                        html: HTMLtext,
                        type: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: gettextCatalog.getString('Ok'),
                        cancelButtonText: gettextCatalog.getString('Cancel'),
                        preConfirm: function () {
                            return new Promise(function (resolve) {
                                var parentUserName = document.getElementById('parentUserName').value;
                                var parentPassword = document.getElementById('parentPassword').value;
                                var parentPasswordAgain = document.getElementById('parentPasswordAgain').value;
                                if (!(vm.fathermotherDataSwal == 'mother' || vm.fathermotherDataSwal == 'father')) {
                                    swal.showValidationError(gettextCatalog.getString('ChooseWhichParentRegisters'));
                                }
                                if (!parentPassword || parentPassword.length < 5) {
                                    swal.showValidationError(gettextCatalog.getString('PasswordMustBeAtLeast5CharactersLong'));
                                }
                                if (parentPassword !== parentPasswordAgain) {
                                    swal.showValidationError(gettextCatalog.getString('PasswordFieldsMustMatch'));
                                }
                                if (!parentUserName || parentUserName.length < 5) {
                                    swal.showValidationError(gettextCatalog.getString('UsernameMustBeAtLeast5CharactersLong'));
                                }
                                if (parentUserName.length > 10) {
                                    swal.showValidationError(gettextCatalog.getString('UsernameMustBeAtMost10CharactersLong'));
                                }
                                if (!(vm.selectedSzuloCsoport > 0)) {
                                    swal.showValidationError(gettextCatalog.getString('SelectParentUserGroup'));
                                }
                                resolve();
                            });
                        }
                    }).then(function (confirmed) {
                        if (confirmed.value) {
                            var parentUserName = document.getElementById('parentUserName').value;
                            var parentPassword = document.getElementById('parentPassword').value;
                            dataservice.createLoginForParent(parentUserName, parentPassword, vm.fathermotherDataSwal, vm.athleteData.SportolokID, vm.selectedSzuloCsoport).then(function (arg) {
                                if (arg.data > 0) {
                                    log.successMsg(gettextCatalog.getString('UserCreatedSuccessfully'));
                                    activate();
                                }
                            });
                        }
                    });
                    angular.element('#parentGroupsDropDownSwal').ejDropDownList({
                        dataSource: vm.SzuloCsoportok,
                        value: vm.selectedSzuloCsoport,
                        select: selectParentGroupDropDownSwal,
                        width: '100%'
                    });
                    angular.element('#fatherMotherDropDownSwal').ejDropDownList({
                        dataSource: vm.FatherMotherList,
                        select: selectFatherMotherDropDownSwal,
                        value: vm.fathermotherDataSwal,
                        databound: fatherMotherSwalDatabound,
                        width: '100%'
                    });
                }
            })
                .catch(function () {
                    log.permissionError();
                });
        }

        vm.selectedSportoloCsoport = undefined;

        function selectAthleteGroupDropDownSwal(arg) {
            vm.selectedSportoloCsoport = arg.value;
        }

        function createLoginForAthlete() {
            var promises = [authenticationService.getRight('UJFELH')];
            $q.all(promises).then(function (res) {
                if (!res[0]) {
                    log.permissionError();
                } else {
                    var HTMLtext =
                        '<div class="row" style="margin-top: 10px;"><div class="col-lg-6"><label>' + gettextCatalog.getString('Username') +
                        '</label></div><div class="col-lg-6"><input id="athleteUserName" name="athleteUserName" minlength="2" maxlength="30" style="width: 100%"></div></div>' +
                        '<div class="row" style="margin-top: 10px;"><div class="col-lg-6"><label>' + gettextCatalog.getString('Password') +
                        '</label></div><div class="col-lg-6"><input type="password" id="athletePassword" name="athletePassword" maxlength="10" style="width: 100%"></div></div>' +
                        '<div class="row" style="margin-top: 10px;"><div class="col-lg-6"><label>' + gettextCatalog.getString('PassordAgain') +
                        '</label></div><div class="col-lg-6"><input type="password" id="athletePasswordAgain" name="athletePasswordAgain" minlength="5" maxlength="10" style="width: 100%"></div></div>' +
                        '<div class="row" style="margin-top: 10px;"><div class="col-lg-6"><label>' + gettextCatalog.getString('SelectAthleteGroup') + '</label></div>' +
                        '<div class="col-lg-6"><input id="athleteGroupsDropDownSwal" ej-dropdownlist></div></div>';
                    swal({
                        title: gettextCatalog.getString('CreateLoginForAthlete'),
                        html: HTMLtext,
                        type: 'question',
                        showCancelButton: true,
                        allowOutsideClick: false,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: gettextCatalog.getString('Ok'),
                        cancelButtonText: gettextCatalog.getString('Cancel'),
                        preConfirm: function () {
                            var athleteUserName = document.getElementById('athleteUserName').value;
                            var athletePassword = document.getElementById('athletePassword').value;
                            var athletePasswordAgain = document.getElementById('athletePasswordAgain').value;
                            var preConfOk = true;
                            return new Promise(function (resolve) {
                                if (!athletePassword || athletePassword.length < 5) {
                                    swal.showValidationError(gettextCatalog.getString('PasswordMustBeAtLeast5CharactersLong'));
                                    preConfOk = false;
                                }
                                if (athletePassword != athletePasswordAgain) {
                                    swal.showValidationError(gettextCatalog.getString('PasswordFieldsMustMatch'));
                                    preConfOk = false;
                                }
                                if (!athleteUserName || athleteUserName.length < 5) {
                                    swal.showValidationError(gettextCatalog.getString('UsernameMustBeAtLeast5CharactersLong'));
                                    preConfOk = false;
                                }
                                if (athleteUserName.length > 10) {
                                    swal.showValidationError(gettextCatalog.getString('UsernameMustBeAtMost10CharactersLong'));
                                    preConfOk = false;
                                }
                                if (vm.selectedSportoloCsoport <= 0) {
                                    swal.showValidationError(gettextCatalog.getString('SelectAthleteUserGroup'));
                                    preConfOk = false;
                                }
                                resolve();
                            });
                        }
                    }).then(function (isConfirmed) {
                        if (isConfirmed.value) {
                            var athleteUserName = document.getElementById('athleteUserName').value;
                            var athletePassword = document.getElementById('athletePassword').value;
                            if (confirmed.value) {
                                dataservice.createLoginForAthlete(athleteUserName, athletePassword, vm.athleteData.SportolokID, vm.selectedSportoloCsoport).then(function (arg) {
                                    if (arg.data > 0) {
                                        log.successMsg(gettextCatalog.getString('UserCreatedSuccessfully'));
                                        activate();
                                    }
                                });
                            }
                        }
                    });
                    angular.element('#athleteGroupsDropDownSwal').ejDropDownList({
                        dataSource: vm.SportoloCsoportok,
                        value: vm.selectedSportoloCsoport,
                        select: selectAthleteGroupDropDownSwal,
                        width: '100%'
                    });
                }
            })
                .catch(function () {
                    log.permissionError();
                });
        }

        vm.sexDatabound = function () {
            $timeout(function () {
                $('#gender').ejDropDownList('selectItemByValue', vm.sexNum);
            }, 100);
        };

        function initAthleteDatas() {
            if (!vm.athleteData.currentUserCanModifyAthlete) {
                vm.allowEdit = false;
                log.successMsg(gettextCatalog.getString('AthleteDatasheetIsReadyOnly'));
            } else {
                vm.allowEdit = true;
            }
            if (vm.athleteData.Aktiv) {
                vm.SPORTBELEPTET = false;
                vm.SPORTKILEPTET = true;
                vm.displayButtons = true;
            } else {
                vm.SPORTBELEPTET = true;
                vm.SPORTKILEPTET = false;
                vm.displayButtons = false;
            }

            // Oktatási díj = 0 - Sportszolgáltatási díj = 1;
            if (vm.athleteData.Tagdijas) {
                vm.feeOfEducationValue = gettextCatalog.getString('SportServiceFee');
            } else {
                vm.feeOfEducationValue = gettextCatalog.getString('EducationFee');
            }

            if (vm.athleteData.FerfiB) {
                vm.sexNum = 1;
            } else {
                vm.sexNum = -1;
            }
            //angular.element('#feeOfEducation').ejDropDownList('selectItemByValue', vm.feeOfEducationValue);
            $timeout(function () {
                if (vm.athleteData.MasodlagosSzakosztalyEdzoId > 0) {
                    vm.isSecondCoach = true;
                    if (vm.UJEDZVALT) {
                        vm.isSecondCoachChange = true;
                    }
                } else {
                    vm.isSecondCoach = false;
                    vm.isSecondCoachChange = false;
                    getSecondSections(); // show dropDown and get datas
                }
                if (vm.athleteData.FenykepUt === 'avatar_2x.png') {
                    vm.photoUrl = vm.noImgUrl;
                } else {
                    vm.photoUrl = DOMAIN.baseURL + DOMAIN.profileImgBaseUrl + vm.athleteData.FenykepUt;
                }
                $scope.$apply();
            }, 0);
        }

        function save() {
            if (!(vm.MODSPORTOLO || vm.SPORTOLO || vm.SZULO)) {
                log.permissionError();
                return;
            }
            vm.athleteData.UgyesebbLab = vm.athleteData.UgyesebbLabT;
            vm.athleteData.UgyesebbKez = vm.athleteData.UgyesebbKezT;
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
            } else {
                vm.athleteData.TAJSzam = angular.element('#socialSecurityNumber').ejMaskEdit('get_StrippedValue');
                modifyAthlete();
            }
        }

        function modifyAthlete() {
            var parameters = {
                naptol: vm.feeOfEducationDataStart,
                kivSportolo: vm.athleteData.SportolokID,
                csakUjak: 0,
                fizModValtas: 1
            };

            if (!(vm.MODSPORTOLO || vm.SPORTOLO || vm.SZULO)) {
                log.permissionError();
                return;
            }
            if (angular.element('#fileName').val() !== '' && vm.cropper.croppedImage !== null) {
                vm.athleteData.FenykepUt = angular.element('#fileName').val();
                vm.athleteData.Fenykep = vm.cropper.croppedImage;
            } else if (vm.photoUrl === vm.noImg) {
                vm.athleteData.fileName = 'avatar_2x.png';
                vm.athleteData.Fenykep = null;
            } else {
                vm.fileName = vm.athleteData.FenykepUt;
            }

            var dsiTime = new Date(vm.athleteData.DSITagsagKezdete);

            if (dsiTime.getTime() > new Date()) {
                swal({
                    title: gettextCatalog.getString('DateError'),
                    text: gettextCatalog.getString('TheMemberOfDSIDateLateThenNow'),
                    type: 'warning',
                    confirmButtonText: gettextCatalog.getString('Ok')
                });
            } else {
                if (vm.athleteData.MasodlagosSzakosztaly > 0) {
                    if (vm.athleteData.MasodlagosKorcsoport > 0) {
                        if (!(vm.athleteData.MasodlagosSzakosztalyEdzoId > 0)) {
                            log.errorMsg(gettextCatalog.getString('SecondSectionDetailsAreNotComplete'));
                            return;
                        }
                    } else {
                        log.errorMsg(gettextCatalog.getString('SecondSectionDetailsAreNotComplete'));
                        return;
                    }
                }
                dataservice.modifyAthlete(vm.athleteData)
                    .then(function (retval) {
                        if (retval.retValue) {
                            if (parameters.naptol) {
                                dataservice.meteOutFee(parameters).then(function () {
                                    log.successMsg('MeteOutFeeOK');
                                });
                            }
                            swal({
                                title: gettextCatalog.getString('SaveAthlete'),
                                text: gettextCatalog.getString('SaveAthleteSuccess'),
                                type: 'success',
                                confirmButtonColor: '#DD6B55',
                                confirmButtonText: gettextCatalog.getString('OK'),
                                closeOnConfirm: true
                            }).then(
                                function (isConfirm) {
                                    if (isConfirm.value) {
                                        if (!(vm.SPORTOLO || vm.SZULO)) {
                                            paramHelper.setParams([
                                                {'athleteList.selectedSectionId': vm.athleteData.IdSzakosztaly},
                                                {'athleteList.selectedAgeGroupId': vm.athleteData.Korcsoport}
                                            ]);
                                            $state.go('athletes.athletesManagement.athletesList');
                                        }
                                    }
                                });
                        } else {
                            var message = gettextCatalog.getString(retval.message) + ' ' + gettextCatalog.getString(retval.error);
                            swal({
                                title: gettextCatalog.getString('SaveAthlete'),
                                text: message,
                                type: 'error',
                                confirmButtonColor: '#DD6B55',
                                confirmButtonText: gettextCatalog.getString('OK'),
                                closeOnConfirm: true
                            }).then(
                                function (isConfirm) {
                                    if (isConfirm.value) {
                                        paramHelper.setParams([
                                            {'athleteList.selectedSectionId': vm.athleteData.IdSzakosztaly},
                                            {'athleteList.selectedAgeGroupId': vm.athleteData.Korcsoport}
                                        ]);
                                        $state.go('athletes.athletesManagement.athletesList');
                                    }
                                });
                        }
                    });
            }
        }

        function openCropDialog() {
            if (!(vm.MODSPORTOLO || vm.SPORTOLO)) {
                log.permissionError();
                return;
            }
            angular.element('#dialogContainer').css('visibility', 'visible');
            angular.element('#cropDialog').ejDialog('open');
        }

        function closeCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'hidden');
            angular.element('#cropDialog').ejDialog('close');
        }

        function doneCropDialog() {
            vm.photoUrl = vm.cropper.croppedImage;
            angular.element('#cropDialog').ejDialog('close');
        }

        function deleteImg() {
            if (!(vm.MODSPORTOLO || vm.SPORTOLO)) {
                log.permissionError();
                return;
            }

            swal({
                title: gettextCatalog.getString('DeleteProfileImg'),
                text: gettextCatalog.getString('AreYouSureYouWantToDeleteTheProfileImg'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: gettextCatalog.getString('Yes'),
                cancelButtonText: gettextCatalog.getString('No')
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    angular.element('#fileName').val('');
                    vm.photoUrl = vm.noImgUrl;
                    vm.athleteData.fileName = 'avatar_2x.png';
                    $scope.$apply();
                }
            });
        }

        function getSections() {
            dataservice.sectionDropDownList(vm.athleteData.JelenlegiEdzo)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.athleteData.IdSzakosztaly);
            }
        }

        function sectionSelect() {
            getAgeGroups();
        }

        function getSecondSections() {
            dataservice.sectionDropDownList(0)
                .then(function (data) {
                    // for (var i = 0; i < data.itemsList.length; i++) {
                    //     if (data.itemsList[i].value === vm.athleteData.IdSzakosztaly) {
                    //         data.itemsList.splice(i, 1);
                    //     }
                    //
                    // }
                    vm.secondSectionList = data.itemsList;
                });
        }

        function secondSectionSelect(selectedSection) {
            vm.athleteData.MasodlagosSzakosztaly = selectedSection.value;
            getSecondAgeGroups();
            RefreshClothes();
        }

        function getSecondCoacheList() {
            return dataservice.coachDropDownList(vm.athleteData.MasodlagosSzakosztaly, vm.athleteData.MasodlagosKorcsoport) // Kell a section id (szakosztály)
                .then(function (data) {
                    vm.secondCoachList = data.itemsList;
                });
        }

        function secondCoachDropDownListDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#secondCoachName').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                vm.athleteData.MasodlagosSzakosztalyEdzoId = vm.coachList[0].value;
            } else {
                angular.element('#secondCoachName').ejDropDownList('selectItemByValue', vm.athleteData.MasodlagosSzakosztalyEdzoId);
            }
        }

        function selectSecondCoachBtn() {
            vm.athleteData.ModifyCoach = 2;
            paramHelper.setParams([
                {'athleteTransfer.athleteData': vm.athleteData},
                {'previousState': 'athletes.athletesManagement.athleteModify.dataSheet'}
            ]);
            $state.go('settings.athleteTransfer');
        }

        function changeSecondAgeGroup() {
            paramHelper.setParams([{
                'ageGroupChange.athleteData': vm.athleteData,
                'ageGroupChange.which': 2
            }]);
            $state.go('settings.ageGroupChange');
        }

        function getSecondAgeGroups() {
            dataservice.ageGroupDropDownList(vm.athleteData.MasodlagosSzakosztaly)
                .then(function (data) {
                    vm.secondAgeGroupList = data.itemsList;
                });
        }

        function secondAgeGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#secondAgeGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#secondAgeGroupDropDown').ejDropDownList('selectItemByValue', vm.athleteData.MasodlagosKorcsoport);
            }
        }

        function secondAgeGroupSelect(args) {
            vm.athleteData.MasodlagosKorcsoport = args.value;
            getSecondCoacheList();
        }

        function changeAgeGroup() {
            paramHelper.setParams([{
                'ageGroupChange.athleteData': vm.athleteData,
                'ageGroupChange.which': 1,
                'ageGroupChange.backPath': 'athletesManagement.athleteModify'
            }]);
            $state.go('settings.ageGroupChange');
        }

        function getAgeGroups() {
            dataservice.ageGroupDropDownList(vm.athleteData.IdSzakosztaly)
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.athleteData.Korcsoport);
            }
        }

        function ageGroupSelect(args) {
            vm.selectedAgeGroupId = args.value;
        }

        function getActualCoachesList() {
            return dataservice.coachDropDownList(vm.athleteData.IdSzakosztaly) // Kell a section id (szakosztály)
                .then(function (data) {
                    vm.coachList = data.itemsList;
                });
        }

        function coachDropDownListDataBound() {
            angular.element('#coachName').ejDropDownList('selectItemByValue', vm.athleteData.JelenlegiEdzo);
        }

        function selectCoachBtn() {
            vm.athleteData.ModifyCoach = 1;
            paramHelper.setParams([
                {'athleteTransfer.athleteData': vm.athleteData},
                {'previousState': 'athletes.athletesManagement.athleteModify.dataSheet'}
            ]);
            $state.go('settings.athleteTransfer');
        }

        function sexSelect(arg) {
            vm.athleteData.FerfiB = arg.value === 1;
        }

        function changeHeight(args) {
            vm.athleteData.Testmagassag = args.value;
        }

        function changeWeight(args) {
            vm.athleteData.Testsuly = args.value;
        }

        function betterHandSelect(arg) {
            vm.athleteData.UgyesebbKezT = arg.value;
            vm.athleteData.UgyesebbKez = arg.selectedText;
        }

        function betterFootSelect(arg) {
            vm.athleteData.UgyesebbLabT = arg.value;
            vm.athleteData.UgyesebbLab = arg.selectedText;
        }

        function changeFeeOfEducationBtn() {
            if (!vm.ModFizetesMod) {
                log.permissionError();
                return;
            }
            if (vm.NINCSOKTDIJ && vm.athleteData.Tagdijas) {
                log.errorMsg(gettextCatalog.getString('CannotChangeFromSportServiceFeeToEducationFee'));
                return;
            }
            vm.visible = false; // Not need use this for angularJs, because the swa enable appear Error message
            var HTMLarr = [];
            HTMLarr[1] = '<p id="datepickValidText" style="color: #a94442;" class="ng-hide" ng-show="vm.visible"><label>'; // It is working with Angular
            HTMLarr[2] = gettextCatalog.getString('PleasGiveTheStartMonth'); // Kérlek a sportszolgáltatási díj kezdő hónapját add meg!
            HTMLarr[3] = '</label><br></p><div class="control"><label>';
            HTMLarr[4] = gettextCatalog.getString('SelectDate'); // Válaszd ki a szolgáltatás kezdő dátumát!
            HTMLarr[5] = ' ';
            HTMLarr[6] = gettextCatalog.getString('ThechangeWillStartInTheNextMonth'); // A változtatás csak a jövő hónaptól lesz érvényes;
            HTMLarr[7] = '<br></label></div><div  style="padding-left:30%"><input class="form-group cts-form-group" id="datepick" type="text" ></div>';
            var HTMLtext = HTMLarr.join('');
            swal({
                title: gettextCatalog.getString('ReallyDoYouchangeThefeeMode'),
                html: HTMLtext,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Change'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                closeOnConfirm: false
                //closeOnCancel: false
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    vm.feeOfEducationDataStart = angular.element('#datepick').data('ejDatePicker').getValue();
                    if (vm.feeOfEducationDateOk) {
                        // Oktatási díj = 0 - Sportszolgáltatási díj = 1;
                        if (vm.athleteData.Tagdijas) { // Ha Tagdijas másnéven Sportszolg dijas
                            vm.athleteData.Tagdijas = false;
                            vm.feeOfEducationValue = gettextCatalog.getString('EducationFee'); // Csere Okt díjasra
                        } else { // sportoszolg díjas
                            vm.athleteData.Tagdijas = true;
                            vm.feeOfEducationValue = gettextCatalog.getString('SportServiceFee'); // csere Tagdijasra, Sportszolg dijasasra
                        }
                        $scope.$apply();
                        // The date value modified to valid value, soh info about it
                        return true; // bind to swal 'Modified'
                    } else {
                        // Must show the error message!
                        reject(gettextCatalog.getString('SelectDate'));
                        return false; // bind to
                    }
                } else {
                    swal(gettextCatalog.getString('notChangedPayMode') + ' ' + getTagDijText());
                }
            }, function () {
            });
            // Set DatePicker
            var now = new Date();
            var current;
            if (now.getMonth() == 11) {
                current = new Date(now.getFullYear() + 1, 0, 1);
            } else {
                current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
            }
            vm.feeOfEducationDateOk = true;
            angular.element('#datepick').ejDatePicker({
                // set date format
                dateFormat: 'yyyy-MM-dd',
                change: function (args) {
                    // Get current date
                    var currentDate = new Date();
                    var selectedDate = new Date(args.value);
                    if (selectedDate.getFullYear() < currentDate.getFullYear()) {
                        // Error, no correct date
                        vm.feeOfEducationDateOk = false;
                    } else if (selectedDate.getMonth() <= currentDate.getMonth()) {
                        // Error, no correct date
                        vm.feeOfEducationDateOk = false;
                    } else if (vm.selectedDate.getDate() > currentDate.getDate()) {
                        // Error, no correct date
                        vm.feeOfEducationDateOk = false;
                    } else {
                        // Ok, the date is correct
                        vm.feeOfEducationDateOk = true;
                        vm.feeOfEducationDate = new Date(args.value);
                        vm.feeOfEducationDate = vm.feeOfEducationDate.toLocaleDateString().replace(/\s/g, '').replace(/\./g, '-').slice(0, -1);
                    }
                },
                value: current
            });

            // Get the current selected text
            function getTagDijText() {
                // Oktatási díj = 0 - Sportszolgáltatási díj = 1;
                // Oktatási díj = 0 - Sportszolgáltatási díj = 1;
                if (vm.athleteData.Tagdijas) {
                    return gettextCatalog.getString('SportServiceFee');
                } else {
                    return gettextCatalog.getString('EducationFee');
                }
            }
        }

        function selectSchools() {
            if (vm.visibleSchooSelectlGrid) {
                angular.element('#hidedContent').css('height', '0px');
            } else {
                dataservice.selectSchools()
                    .then(function (data) {
                        // swal dropdownList select shool and fill fields with datas
                        angular.element('#hidedContent').css('height', '100%');
                        grid.dataSource = data.itemsList;
                    });
            }
            vm.visibleSchooSelectlGrid = !vm.visibleSchooSelectlGrid;
        }

        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }

        function schoolGridSelectedRow(args) {
            vm.schoolGridSelectedRowData = args.data;
            vm.athleteData.IskolaEvfolyam = vm.schoolGridSelectedRowData.IskolaEvfolyam; // String
            vm.athleteData.IskolaNeve = vm.schoolGridSelectedRowData.IskolaNeve; // String
            vm.athleteData.IskolaIrSzam = vm.schoolGridSelectedRowData.IskolaIrSzam; // String
            vm.athleteData.IskolaIrSzam = vm.schoolGridSelectedRowData.IskolaIrSzam; // String
            vm.athleteData.IskolaVaros = vm.schoolGridSelectedRowData.IskolaVaros; // String
            vm.athleteData.IskolaUtca = vm.schoolGridSelectedRowData.IskolaUtca; // String
            vm.athleteData.IskolaHazszam = vm.schoolGridSelectedRowData.IskolaHazszam; // String
            vm.athleteData.IskolaOsztaly = vm.schoolGridSelectedRowData.IskolaOsztaly; // String
            vm.athleteData.IskolaIgazgatoNeve = vm.schoolGridSelectedRowData.IskolaIgazgatoNeve; // String
            vm.athleteData.TestneveloNeve = '';
            vm.athleteData.TestneveloElerhetosege = '';
            vm.athleteData.TestneveloEmail = '';
            vm.athleteData.OsztalyFonokNeve = '';
            vm.athleteData.OfTel = '';
            vm.athleteData.OfEmail = '';
            $scope.$apply();
            // swal dropdownList select shool and fill fields with datas
            angular.element('#hidedContent').css('height', '0px');
            vm.visibleSchooSelectlGrid = !vm.visibleSchooSelectlGrid;
        }

        function anthropometry() {
            if (!vm.MEGTANTFELM) {
                log.permissionError();
            } else {
                paramHelper.setParams([{'anthropometry.sportoloId': vm.athleteData.SportolokID}]);
                $state.go('athletes.athletesManagement.anthropometry');
            }
        }

        function saveExitAthletes(firstSection) {
            vm.datas = {};
            vm.athletesId = [];
            vm.athletesId.push(vm.athleteData.SportolokID);
            vm.datas.athletesId = vm.athletesId;
            vm.datas.selectedExitDate = vm.selectedExitDate;
            if (angular.isUndefined(vm.noteExitText)) {
                vm.noteExitText = '----';
            }
            vm.datas.noteExitText = vm.noteExitText;
            vm.datas.firstSection = firstSection;
            dataservice.saveExitAthletes(vm.datas)
                .then(function (answer) {
                    // swal() kiírni az eredményt
                    if (answer.value) {
                        swal(gettextCatalog.getString('SuccesfulExitAthleteResult'), gettextCatalog.getString('SuccesfulExitAthlete'), 'success');
                        paramHelper.setParams([
                            {'athleteList.selectedSectionId': vm.athleteData.IdSzakosztaly},
                            {'athleteList.selectedAgeGroupId': vm.athleteData.Korcsoport}
                        ]);
                        $state.go('athletes.athletesManagement.athletesList');
                    } else {
                        swal({
                            title: gettextCatalog.getString('SuccesfulExitAthleteResult'),
                            text: gettextCatalog.getString('FailedExitAthleteResult'),
                            type: 'warning',
                            confirmButtonClass: 'btn-danger',
                            confirmButtonText: 'Ok'
                        });
                    }
                });
        }

        function getSystemSettings(arg) {
            return dataservice.getSystemSettings(arg).then(function (response) {
                return response.retValue;
            });
        }

        function getUsersBelongingToAthlete(sportoloid) {
            return dataservice.getUsersBelongingToAthlete(sportoloid).then(function (response) {
                gridUsersOfAthlete.dataSource = response.itemsList;
            });
        }

        function getAthleteMoveStory(id) {
            return dataservice.getAthleteMoveStory(id).then(function (response) {
                vm.coachListGridData = response.itemsList;
                vm.coachListGridData = $filter('dateStringFilter')(vm.coachListGridData, 'Felvitel');
                vm.coachListGridData = $filter('dateStringFilter')(vm.coachListGridData, 'IdoszakVege');
                grid123.dataSource = vm.coachListGridData;
            });
        }

        function clearSecondSection() {
            vm.athleteData.MasodlagosSzakosztaly = undefined;
            vm.athleteData.MasodlagosKorcsoport = undefined;
            vm.secondAgeGroupList = [];
            vm.athleteData.MasodlagosSzakosztalyEdzoNev = undefined;
            vm.secondCoachList = [];
            $('#secondSectionDropDown').ejDropDownList('clearText');
        }

        function clearSecondAgeGroup() {
            vm.athleteData.MasodlagosKorcsoport = undefined;
            vm.athleteData.MasodlagosSzakosztalyEdzoNev = undefined;
            vm.secondCoachList = [];
            $('#secondAgeGroupDropDown').ejDropDownList('clearText');
        }

        function clearSecondCoach() {
            vm.athleteData.MasodlagosSzakosztalyEdzoNev = undefined;
            $('#secondCoachNameDropDown').ejDropDownList('clearText');
        }

        function exitingSecondSectionBtn() {
            if (!vm.allowEdit) {
                swal({
                    title: gettextCatalog.getString('AthleteDatasheetIsReadyOnly'),
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('Ok'),
                    closeOnConfirm: false,
                    closeOnCancel: false
                });
                return;
            }
            vm.visible = false; // Not need use this for angularJs, because the swa enable appear Error message
            var HTMLarr = [];

            HTMLarr[0] = '<p id="datepickValidText" style="color: #a94442;" class="ng-hide" ng-show="vm.visible"><label>'; // It is working with Angular
            HTMLarr[1] = gettextCatalog.getString('PleasGiveTheStartMonthgoneAthlete'); // Kérlek a sportszolgáltatási díj kezdő hónapját add meg!
            HTMLarr[2] = '</label><br></p><div style="margin-bottom: 10px; width: 95%;" ><label>';
            HTMLarr[3] = gettextCatalog.getString('SelectDategoneAthlete'); // Válaszd ki a kiléptetés dátumát!
            HTMLarr[4] = '<br></label></div><div style="margin-left: 30%;"><input id="datepick" type="text"></div>';
            var HTMLtext = HTMLarr.join('');

            swal({
                title: gettextCatalog.getString(vm.athleteData.Nev + ' ' + gettextCatalog.getString('ExitAthleteSecondSection')),
                html: HTMLtext,
                type: 'warning',
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Exiting'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                closeOnConfirm: false,
                preConfirm: function (Exiting) {
                    if (Exiting.value) {
                        var preConfOk = false;
                        vm.selectedExitDate = angular.element('#datepick').data('ejDatePicker').getValue(); // get value
                        vm.noteExitText = angular.element('#noteTextArea').val();
                        checkExitDate(vm.selectedExitDate).then(function () {
                            return new Promise(function (resolve) {
                                if (vm.selectedExitDate === null || !vm.exitAthleteDateOk) {
                                    // Must show the error message!
                                    preConfOk = false;
                                    swal.showValidationError(gettextCatalog.getString('SelectExitDate'));
                                } else {
                                    preConfOk = true;
                                }
                                if (vm.noteExitText === null || vm.noteExitText === '') {
                                    // Must show the error message!
                                    preConfOk = false;
                                    swal.showValidationError(gettextCatalog.getString('SetNoteText'));
                                } else {
                                    preConfOk = true;
                                }
                                resolve();
                            });
                        });
                    }
                },
                closeOnCancel: false
            }).then(function (confirmed) {
                if (confirmed.value) {
                    saveExitAthletes(false);
                    swal.close();
                    return true;
                } else {
                    swal(gettextCatalog.getString('Cancelled'), gettextCatalog.getString('notExitAthlete'), 'error');
                }
            });
            // Set DatePicker
            angular.element('#datepick').ejDatePicker({
                // set date format
                dateFormat: 'yyyy-MM-dd',
                change: function (args) {
                    checkDade(args.value);
                }
            });
        }

        function checkExitDate(args) {
            return $q(function () {
                // Get current date
                var currentDate = new Date();
                vm.selectedExitDate = new Date(args);
                if (vm.selectedExitDate.getFullYear() > currentDate.getFullYear()) {
                    // Error, no correct date
                    vm.exitAthleteDateOk = false;
                } else if (vm.selectedExitDate.getMonth() > currentDate.getMonth()) {
                    // Error, no correct date
                    vm.exitAthleteDateOk = false;
                } else if (vm.selectedExitDate.getDate() > currentDate.getDate()) {
                    // Error, no correct date
                    vm.exitAthleteDateOk = false;
                } else {
                    // Ok, the date is correct
                    vm.exitAthleteDateOk = true;
                }
            });
        }

        function checkDade(date) {
            // Get current date
            var currentDate = new Date();
            vm.selectedExitDate = new Date(date);
            if (vm.selectedExitDate.getFullYear() > currentDate.getFullYear()) {
                // Error, no correct date
                vm.exitAthleteDateOk = false;
            } else if (vm.selectedExitDate.getMonth() > currentDate.getMonth()) {
                // Error, no correct date
                vm.exitAthleteDateOk = false;
            } else if (vm.selectedExitDate.getDate() > currentDate.getDate()) {
                // Error, no correct date
                vm.exitAthleteDateOk = false;
            } else {
                // Ok, the date is correct
                vm.exitAthleteDateOk = true;
            }
        }

        function openCoach(coachId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': coachId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function schoolNameChanged() {
            if (vm.athleteData.IskolaNeve && vm.athleteData.IskolaNeve.length > 0) {
                dataservice.getGymTeachers(vm.athleteData.IskolaNeve).then(function (data) {
                    vm.gymTeacherDatasource = data.itemsList;
                });
                dataservice.getClassMasters(vm.athleteData.IskolaNeve).then(function (data) {
                    vm.classMasterDatasource = data.itemsList;
                });
            } else {
                vm.gymTeacherDatasource = [];
                vm.classMasterDatasource = [];
            }
        }

        vm.selectOf = selectOf;

        function selectOf(arg) {
            for (var i = 0; i < vm.classMasterDatasource.length; i++) {
                if (arg.value == vm.classMasterDatasource[i].value) {
                    dataservice.getClassMasterContact(arg.value).then(function (data) {
                        if (data.data.tel) {
                            vm.athleteData.OfTel = data.data.tel;
                        }
                        if (data.data.email) {
                            vm.athleteData.OfEmail = data.data.email;
                        }
                    });
                }
            }
        }

        vm.selectTesiTanar = selectTesiTanar;

        function selectTesiTanar(arg) {
            for (var i = 0; i < vm.gymTeacherDatasource.length; i++) {
                if (arg.value == vm.gymTeacherDatasource[i].value) {
                    dataservice.getGymTeacherContact(arg.value).then(function (data) {
                        if (data.data.tel) {
                            vm.athleteData.TestneveloElerhetosege = data.data.tel;
                        }
                        if (data.data.email) {
                            vm.athleteData.TestneveloEmail = data.data.email;
                        }
                    });
                }
            }
        }

        function getParentNames() {
            dataservice.getParentNames().then(function (data) {
                for (var i = 0; i < data.data.father.length; i++) {
                    vm.fatherNames.push({value: data.data.father[i], text: data.data.father[i]});
                }
                for (var i = 0; i < data.data.mother.length; i++) {
                    vm.motherNames.push({value: data.data.mother[i], text: data.data.mother[i]});
                }
            });
        }

        vm.selectMother = selectMother;

        function selectMother(arg) {
            if (arg && arg.value.length > 0) {
                var closeidx = arg.value.indexOf(')');
                // if (arg.value.length-1 > closeidx){return;}
                var idx = arg.value.indexOf('(');
                if (idx != -1 && !(arg.value.length - 1 > closeidx)) {
                    getParentData('mother', arg.value, arg.value.substring(0, idx - 1));
                    // vm.athleteData.AnyjaNeve = arg.value.substring(0, idx - 1);
                }
            }
        }

        vm.selectFather = selectFather;

        function selectFather(arg) {
            if (arg && arg.value.length > 0) {
                var closeidx = arg.value.indexOf(')');
                // if (arg.value.length-1 > closeidx){return;}
                var idx = arg.value.indexOf('(');
                if (idx != -1 && !(arg.value.length - 1 > closeidx)) {
                    getParentData('father', arg.value, arg.value.substring(0, idx - 1));
                    // vm.athleteData.SzuloNeve = arg.value.substring(0, idx - 1);
                }
            }
        }

        function getParentData(parent, arg, szuloNeve) {
            dataservice.getParentDataPOST(parent, arg).then(function (data) {
                // Szülő kiválasztásakor felajánljuk hogy csatolhatja már meglévő szülő userhez a sportolót
                if (data.data.userid > 0) {
                    swal({
                        title: gettextCatalog.getString('Do you want to assign the athlete to the selected parent user?'),
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: gettextCatalog.getString('Yes'),
                        cancelButtonText: gettextCatalog.getString('Cancel'),
                    }).then(function (isConfirm) {
                        if (isConfirm.value) {
                            dataservice.assignAthleteToParentUser(data.data.userid, vm.athleteData.SportolokID).then(function (arg) {
                                if (arg.data > 0) {
                                    log.successMsg(gettextCatalog.getString('AthleteAssignedToUserSuccessfully'));
                                }
                            });
                        }
                    });
                }
                if (parent == 'father') {
                    $('#fatherName').ejAutocomplete('clearText');
                    $('#fatherName').ejAutocomplete({multiSelectMode: ej.MultiSelectMode.None, value: szuloNeve});
                    if (data.data) {
                        vm.athleteData.Szulo2Elerhetosege = '';
                        vm.athleteData.ApaEmail = '';
                        vm.athleteData.ApjaTestmagassaga = '';
                        vm.athleteData.ApjaFoglalkozasa = '';
                        vm.athleteData.ApaMunkahely = '';
                        vm.athleteData.SportoloiMultApa = '';
                        if (data.data.tel && data.data.tel.length > 0) {
                            vm.athleteData.Szulo2Elerhetosege = data.data.tel;
                        }
                        if (data.data.email && data.data.email.length > 0) {
                            vm.athleteData.ApaEmail = data.data.email;
                        }
                        if (data.data.height && data.data.height.length > 0) {
                            vm.athleteData.ApjaTestmagassaga = data.data.height;
                        }
                        if (data.data.job && data.data.job.length > 0) {
                            vm.athleteData.ApjaFoglalkozasa = data.data.job;
                        }
                        if (data.data.workplace && data.data.workplace.length > 0) {
                            vm.athleteData.ApaMunkahely = data.data.workplace;
                        }
                        if (data.data.sportpast && data.data.sportpast.length > 0) {
                            vm.athleteData.SportoloiMultApa = data.data.sportpast;
                        }
                    }
                } else if (parent == 'mother') {
                    $('#motherName').ejAutocomplete('clearText');
                    $('#motherName').ejAutocomplete({multiSelectMode: ej.MultiSelectMode.None, value: szuloNeve});
                    if (data.data) {
                        vm.athleteData.Szulo1Elerhetosege = '';
                        vm.athleteData.AnyaEmail = '';
                        vm.athleteData.AnyjaTestmagassaga = '';
                        vm.athleteData.AnyjaFoglalkozasa = '';
                        vm.athleteData.AnyaMunkahely = '';
                        vm.athleteData.SportoloiMultAnya = '';
                        if (data.data.tel && data.data.tel.length > 0) {
                            vm.athleteData.Szulo1Elerhetosege = data.data.tel;
                        }
                        if (data.data.email && data.data.email.length > 0) {
                            vm.athleteData.AnyaEmail = data.data.email;
                        }
                        if (data.data.height && data.data.height.length > 0) {
                            vm.athleteData.AnyjaTestmagassaga = data.data.height;
                        }
                        if (data.data.job && data.data.job.length > 0) {
                            vm.athleteData.AnyjaFoglalkozasa = data.data.job;
                        }
                        if (data.data.workplace && data.data.workplace.length > 0) {
                            vm.athleteData.AnyaMunkahely = data.data.workplace;
                        }
                        if (data.data.sportpast && data.data.sportpast.length > 0) {
                            vm.athleteData.SportoloiMultAnya = data.data.sportpast;
                        }
                    }
                }
            });
        }
    }
})();
