/* global Aes */

(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.psychology.wartegg')
        .controller('WarteggController', WarteggController);
    WarteggController.$inject = ['$q', 'gettextCatalog', 'dataservice', 'paramHelper', '$state', '$rootScope', 'authenticationService', 'log'];
    /* @ngInject */
    function WarteggController($q, gettextCatalog, dataservice, paramHelper, $state, $rootScope, authenticationService, log) {
        var vm = this; $rootScope.vm = vm;

        vm.sportoloId = 0;
        vm.psychologyId = 0;
        vm.kiertekelt = false;

        // Public functions
        vm.upload = upload;

        activate();

        function activate() {
            var promises = [paramHelper.getParams(['athleteModify.sportoloId', 'psychology.psychologyId', 'athleteModify.sportoloNev'], ['psychology.surveyPassword']), authenticationService.getRight('PSYCHO')];
            return $q.all(promises).then(function (data) {
                if (data[1] !== true) {
                    log.permissionError(true);
                } else {
                    vm.psychologyId = data[0]['psychology.psychologyId'];
                    vm.password = data[0]['psychology.surveyPassword'];
                    vm.sportoloId = data[0]['athleteModify.sportoloId'];
                    vm.sportoloNev = data[0]['athleteModify.sportoloNev'];
                    $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('Psychology') + ' - ' + gettextCatalog.getString('WarteggTest');
                    swal({
                        title: gettextCatalog.getString('Password'),
                        text: gettextCatalog.getString('SelectPasswordForTheTest'),
                        //type: 'input',
                        input: 'password',
                        animation: 'slide-from-top',
                        inputValue: vm.password,
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(
                        function (inputValue) {
                            if (!inputValue || !inputValue.value || inputValue.dismiss === 'cancel' || inputValue.dismiss === 'esc') {
                                return false;
                            } else {
                                vm.password = inputValue.value;
                                dataservice.getWarteggTestById(vm.psychologyId)
                                    .then(function (response) {
                                        vm.kiertekelt = response.kiertekelesDatuma !== '';
                                        vm.kepfajl = response.kitoltottKerdoiv;
                                        vm.kiertekelesSzoveg = response.kiertekelesSzoveg;
                                        if (vm.kiertekelesSzoveg !== '') {
                                            vm.kiertekelesSzoveg = Aes.Ctr.decrypt(vm.kiertekelesSzoveg, inputValue.value, 256);
                                            angular.element('#rteSample').ejRTE({value: vm.kiertekelesSzoveg});
                                        }
                                        swal.close();
                                    });
                            }
                        });
                }
            });
        }

        function upload() {
            swal({
                title: gettextCatalog.getString('Password'),
                text: gettextCatalog.getString('SelectPasswordForTheTest'),
                //type: 'input',
                input: 'password',
                animation: 'slide-from-top',
                inputValue: vm.password,
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(
                function (inputValue) {
                    if (!inputValue || !inputValue.value || inputValue.dismiss === 'cancel' || inputValue.dismiss === 'esc') {
                        return false;
                    } else {
                        vm.password = inputValue.value;
                        var file = document.getElementById('file').files[0];
                        var szoveg = angular.element('#rteSample').ejRTE('getHtml');
                        if (file) {
                            var r = new FileReader();
                            if (szoveg !== '') {
                                szoveg = Aes.Ctr.encrypt(szoveg, inputValue.value, 256);
                            }

                            r.onloadend = function (e) {
                                var data = {
                                    psychologyId: vm.psychologyId,
                                    fileName: file.name,
                                    fileData: e.target.result,
                                    sportoloId: vm.sportoloId,
                                    psychologySurvey: szoveg
                                };
                                dataservice.uploadWartegg(data)
                                    .then(function () {
                                        swal.close();
                                        $state.go('athletes.athletesManagement.athleteModify.psychology.summary');
                                    });
                            };
                            r.readAsDataURL(file);
                        } else {
                            if (szoveg !== '') {
                                szoveg = Aes.Ctr.encrypt(szoveg, inputValue.value, 256);
                            }
                            var data = {
                                psychologyId: vm.psychologyId,
                                fileName: '',
                                fileData: null,
                                sportoloId: vm.sportoloId,
                                psychologySurvey: szoveg
                            };
                            dataservice.uploadWartegg(data)
                                .then(function () {
                                    swal.close();
                                    $state.go('athletes.athletesManagement.athleteModify.psychology.summary');
                                });
                        }
                    }
                });
        }
    }
})();
