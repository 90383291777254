(function () {
    'use strict';

    angular
        .module('app.reports.statisticalReports.listAthletes')
        .controller('ListAthletesController', ListAthletesController);

    ListAthletesController.$inject = ['log', '$state', 'dataservice', 'authenticationService', '$q', 'gettextCatalog', '$rootScope', 'paramHelper', '$scope', '$filter', '$compile'];
    /* @ngInject */
    function ListAthletesController(log, $state, dataservice, authenticationService, $q, gettextCatalog, $rootScope, paramHelper, $scope, $filter, $compile) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('listAthletes');
        vm.params = [];
        vm.openAthlete = openAthlete;
        vm.openSportServiceFee = openSportServiceFee;
        vm.openCoach = openCoach;
        vm.compile = compile;
        vm.athleteList = [];
        vm.gridToolbar = ['printGrid', 'excelExport'];
        vm.columns = [];

        setColumns();

        function setColumns() {
            vm.columns = [];
            vm.columns = [{
                field: 'Nev',
                headerText: gettextCatalog.getString('Name'),
                width: 150,
                textAlign: 'left',
                template: true,
                templateID: '#laopenath'
            }, {
                field: 'SzuletesiIdo',
                headerText: gettextCatalog.getString('BirthDate'),
                width: 100,
                textAlign: 'center'
            }, {
                field: 'Aktiv',
                headerText: gettextCatalog.getString('Active'),
                width: 50,
                textAlign: 'left'
            }, {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                width: 150,
                textAlign: 'left',
                template: true,
                templateID: '#laopencoach'
            }, {
                field: 'SzakosztalyNeve',
                headerText: gettextCatalog.getString('Section'),
                width: 150,
                textAlign: 'left'
            }, {
                field: 'KorcsoportNeve',
                headerText: gettextCatalog.getString('AgeGroup'),
                width: 150,
                textAlign: 'left'
            }, {
                field: 'Megjegyzes',
                headerText: gettextCatalog.getString('Comment'),
                width: 150,
                textAlign: 'left'
            }];
        }

        //0: mindkettő, 1: belépés dátuma, 2: kilépés dátuma
        function addColumns(args) {
            setTimeout(function() {
                    if (args === 0 || args === 1) {
                        vm.columns.push({field: 'edzosportoloiIdoszakTol', headerText: gettextCatalog.getString('EnteringDate'), width: 100, textAlign: 'center'});
                    }
                    if (args === 0 || args === 2) {
                        vm.columns.push({field: 'edzosportoloiIdoszakIg', headerText: gettextCatalog.getString('ExitingDate'), width: 100, textAlign: 'center'});
                    }
                    vm.columns.push({field: 'Tagdij', headerText: gettextCatalog.getString('PayedSportServiceFee'), width: 240, textAlign: 'left'});
                    vm.columns.push({field: 'Oktatasidij', headerText: gettextCatalog.getString('PayedEducationalFee'), width: 240, textAlign: 'left'});
                    vm.columns.push({field: 'Egyenleg', headerText: gettextCatalog.getString('Balance'), width: 150, textAlign: 'left', template: true, templateID: '#laopenfee'});
                    $scope.$apply();
                    angular.element('#Grid').ejGrid('refreshContent');
                }, 10);
        }

        vm.gridExcelExport = dataservice.getURL() + 'SportolokListaSzakosztalyonkent/ExcelExport/';
        vm.toolbarHandler = toolbarHandler;

        activate();

        function activate() {
            $q.all([paramHelper.getParams([], ['state', 'sectionId', 'sectionName', 'dateFrom', 'dateTo', 'gender', 'coachId', 'coachName', 'year'])]).then(function (results) {
                if (results[0].state == null) {
                    $state.go('dashboard');
                }
                vm.params = results[0];
                paramHelper.removeParam('state');
                paramHelper.removeParam('sectionId');
                paramHelper.removeParam('sectionName');
                paramHelper.removeParam('dateFrom');
                paramHelper.removeParam('dateTo');
                paramHelper.removeParam('gender');
                paramHelper.removeParam('coachId');
                paramHelper.removeParam('coachName');
                paramHelper.removeParam('year');
                setTitle(vm.params.state, vm.params.sectionName, vm.params.coachName, vm.params.gender, vm.params.dateFrom, vm.params.dateTo, vm.params.year);
                if (vm.params.state == 'leavingAthletes') {
                    checkRight('KMKILSZAK');
                    addColumns(2);
                    getList(vm.params.gender);
                } else if (vm.params.state == 'joinedAthletes') {
                    checkRight('KMBELSZAK');
                    addColumns(1);
                    getList(vm.params.gender);
                } else if (vm.params.state == 'activeAthletes') {
                    checkRight('KMSPORTSZAK');
                    getList(vm.params.gender);
                } else if (vm.params.state == 'monthlyStatJoined') {
                    checkRight('SPSZAMALAKRESZL');
                    addColumns(0);
                    getMonthlyListJoined();
                } else if (vm.params.state == 'monthlyStatLeaving') {
                    checkRight('SPSZAMALAKRESZL');
                    addColumns(2);
                    getMonthlyListLeaving();
                } else if (vm.params.state == 'athletesByCoach') {
                    checkRight('KMSPORTEDZO');
                    getActiveAthleteListByCoach(vm.params.coachId, vm.params.sectionId, vm.params.dateFrom, vm.params.gender);
                } else if (vm.params.state == 'leavingAthletesFromCoach') {
                    checkRight('KMKILEPEDZO');
                    addColumns(2);
                    getLeavingAthletesFromCoach(vm.params.gender);
                } else if (vm.params.state == 'joinedAthletesToCoach') {
                    checkRight('KMBELEPEDZO');
                    addColumns(1);
                    getJoinedAthletesToCoach(vm.params.gender);
                } else if (vm.params.state == 'monthlyStat') {
                    checkRight('KMSPORTALAK');
                    addColumns(0);
                    vm.params.dateFrom = vm.params.dateFrom.slice(0, 10);
                    getMonthlyListActive(vm.params.gender);
                    vm.params.dateFrom = $filter('dateYearMonthLongStrFilter')(vm.params.dateFrom);
                    $rootScope.title = gettextCatalog.getString('Sportolok Szamanak Alakulasa') + ' (' + gettextCatalog.getString(vm.params.sectionName) + ', ' + gettextCatalog.getString(vm.params.gender) + ', ' + vm.params.dateFrom + ')';
                } else if (vm.params.state == 'activeAthletesByBirthYear') {
                    checkRight('KMSPORTELETKOR');
                    getActiveAthletesByBirthYear(vm.params.sectionId, vm.params.year, vm.params.gender);
                    $rootScope.title = gettextCatalog.getString('activeAthletesByBirthYear') + ' (' + gettextCatalog.getString(vm.params.sectionName) + ', ' + gettextCatalog.getString(vm.params.gender) + ', ' + vm.params.year + ')';
                }
            });
        }

        function checkRight(right) {
            authenticationService.getRight(right).then(function(results) {
                if (results !== true) {
                    log.permissionError(true);
                }
            });
        }

        function setTitle(state, sectionname, coachname, gender, datefrom, dateto, year) {
            var titleText = ' (';
            if (sectionname && sectionname.length > 0) {
                titleText += sectionname + ', ';
            }
            if (coachname && coachname.length > 0) {
                titleText += coachname + ', ';
            }
            if (gender) {
                switch (gender){
                    case 'allgender': titleText += gettextCatalog.getString('allgender'); break;
                    case 'male': titleText += gettextCatalog.getString('male'); break;
                    case 'female': titleText += gettextCatalog.getString('female'); break;
                }
            }
            if (datefrom == dateto) {
                dateto = undefined;
            }
            if (datefrom) {
                titleText += ', ' + datefrom;
            }
            if (dateto) {
                titleText += ' - ' + dateto;
            }
            if (year) {
                titleText + year;
            }
            titleText += ')';
            switch (state){
                case 'activeAthletes': $rootScope.title = gettextCatalog.getString('activeAthletesBySection') + titleText;break;
                case 'leavingAthletes': $rootScope.title = gettextCatalog.getString('leavingAthletesBySection') + titleText;break;
                case 'joinedAthletes': $rootScope.title = gettextCatalog.getString('joinedAthletesBySection') + titleText;break;
                case 'monthlyStatJoined': $rootScope.title = gettextCatalog.getString('joinedAthletesInMonth') + ' (' + $filter('dateYearMonthLongStrFilter')(vm.params.dateFrom) + ')';break;
                case 'monthlyStatLeaving': $rootScope.title = gettextCatalog.getString('leavingAthletesInMonth') + ' (' + $filter('dateYearMonthLongStrFilter')(vm.params.dateFrom) + ')';break;
                case 'athletesByCoach': $rootScope.title = gettextCatalog.getString('AthletesByCoach') + titleText;break;
                case 'leavingAthletesFromCoach': $rootScope.title = gettextCatalog.getString('leavingAthletesFromCoach') + titleText;break;
                case 'joinedAthletesToCoach': $rootScope.title = gettextCatalog.getString('joinedAthletesToCoach') + titleText;break;
                case 'monthlyStat': $rootScope.title = gettextCatalog.getString('monthlyStat') + ' (' + sectionname + ', ' + gender + ', ' + $filter('dateYearMonthLongStrFilter')(vm.params.dateFrom) + ')'; break;
                case 'activeAthletesByBirthYear': $rootScope.title = gettextCatalog.getString('activeAthletesByBirthYear') + titleText;break;
            }
        }

        function getList(gender) {
            dataservice.listAthletes(vm.params.dateFrom, vm.params.dateTo, vm.params.state, vm.params.sectionId, gender)
                .then(function(data) {
                    vm.athleteList = data.itemsList;
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'SzuletesiIdo');
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'edzosportoloiIdoszakTol');
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'edzosportoloiIdoszakIg');
                });
        }

        function getActiveAthletesByBirthYear(sectionId, year, gender) {
            dataservice.activeAthletesByBirthYear(sectionId, year, gender)
                .then(function(data) {
                    vm.athleteList = data.itemsList;
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'SzuletesiIdo');
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'edzosportoloiIdoszakTol');
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'edzosportoloiIdoszakIg');
                });
        }

        function getActiveAthleteListByCoach(coachId, sectionId, dateFrom, gender) {
            dataservice.activeAthletesByCoach(coachId, sectionId, dateFrom, gender)
                .then(function(data) {
                    vm.athleteList = data.itemsList;
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'SzuletesiIdo');
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'edzosportoloiIdoszakTol');
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'edzosportoloiIdoszakIg');
                });
        }

        function getMonthlyListActive(gender) {
            dataservice.ActiveAthletesInASpecificMonth(vm.params.dateFrom, vm.params.dateFrom, vm.params.sectionId, gender)
                .then(function(data) {
                    vm.athleteList = data.itemsList;
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'SzuletesiIdo');
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'edzosportoloiIdoszakTol');
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'edzosportoloiIdoszakIg');
                });
        }

        function getMonthlyListJoined() {
            dataservice.JoinedAthletesInASpecificMonth(vm.params.dateFrom)
                .then(function(data) {
                    vm.athleteList = data.itemsList;
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'SzuletesiIdo');
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'edzosportoloiIdoszakTol');
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'edzosportoloiIdoszakIg');
                });
        }

        function getMonthlyListLeaving() {
            dataservice.LeavingAthletesInASpecificMonth(vm.params.dateFrom)
                .then(function(data) {
                    vm.athleteList = data.itemsList;
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'SzuletesiIdo');
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'edzosportoloiIdoszakTol');
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'edzosportoloiIdoszakIg');
                });
        }

        function getLeavingAthletesFromCoach(gender) {
            dataservice.leavingAthletesFromASpecificCoach(vm.params.coachId, vm.params.dateFrom, vm.params.dateTo, gender)
                .then(function(data) {
                    vm.athleteList = data.itemsList;
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'SzuletesiIdo');
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'edzosportoloiIdoszakTol');
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'edzosportoloiIdoszakIg');
                });
        }

        function getJoinedAthletesToCoach(gender) {
            dataservice.joinedAthletesToASpecificCoach(vm.params.coachId, vm.params.dateFrom, vm.params.dateTo, gender)
                .then(function(data) {
                    vm.athleteList = data.itemsList;
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'SzuletesiIdo');
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'edzosportoloiIdoszakTol');
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'edzosportoloiIdoszakIg');
                });
        }

        function openSportServiceFee(SportolokID) {
            var promises = [authenticationService.getRight('MEGTTAGDNYILV'), authenticationService.getRight('MEGTOKTDNYILV')];
            $q.all(promises).then(function (results) {
                if (results[0] !== true || results[1] !== true) {
                    log.permissionError();
                } else {
                    paramHelper.setParams([
                        {'sportServiceFeeRegister.athleteID': SportolokID}
                    ]);
                    $state.go('business.sportServiceFeeRegister');
                }
            });
        }

        function openAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                var grid = angular.element('#Grid').ejGrid('instance');
                grid.ignoreOnExport.splice(grid.ignoreOnExport.indexOf('dataSource'), 1);
                this.export(dataservice.getURL() + 'SportolokListaSzakosztalyonkent/ExcelExport/');
            }
        }
    }
})();
