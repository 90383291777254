(function () {
    'use strict';

    angular
        .module('app.athleteDashboard')
        .controller('AthleteDashboardController', AthleteDashboardController);

    AthleteDashboardController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog'];
    /* @ngInject */
    function AthleteDashboardController(log, dataservice, $q, $rootScope, gettextCatalog) {
        $rootScope.vm = this;
        $rootScope.title = gettextCatalog.getString('AthleteDashboard');

        // Create variables

        // Public functions

        // Activate page
        activate();

        function activate() {
            var promises = [];
            $q.all(promises).then(function () {})
                .catch(function () {
                    log.permissionError();
                });
        }
    }
})();
