(function() {
    'use strict';

    angular
        .module('app.administration.sportSpecificTest.newSportSpecificTest')
        .controller('NewSportSpecificTestController', NewSportSpecificTestController);

    NewSportSpecificTestController.$inject = ['log', 'authenticationService', 'dataservice', '$q', '$state', '$rootScope', 'gettextCatalog', 'paramHelper', '$scope', '$compile'];
    function NewSportSpecificTestController(log, authenticationService, dataservice, $q, $state, $rootScope, gettextCatalog, paramHelper, $scope, $compile) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('NewSportSpecificTest');

        vm.gridToolbar = ['printGrid', 'excelExport'];
        vm.columns = [
            {field: 'SportoloID', headerText: 'Id', textAlign: 'left', isPrimaryKey: true, visible: false},
            {field: 'SportoloNeve', headerText: gettextCatalog.getString('AthleteName'), textAlign: 'left', width: 110, allowEditing: false}
        ];
        vm.testdata = [];
        vm.sectionList = [];
        vm.ageGroupList = [];
        vm.coachList = [];
        vm.sectionId = 0;
        vm.ageGroupId = 0;
        vm.coachId = 0;
        vm.felmeroFejId = -1;
        vm.felmeroElemFejId = -1;
        vm.felmeroElemFejIdValue = undefined;
        vm.newTest = true;
        vm.createButtonEnabled = true;
        vm.editTestTypeButtonEnabled = true;
        vm.edzoertekeles = '';
        vm.querycellinfo = querycellinfo;
        vm.sectionDataBound = sectionDataBound;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.coachDataBound = coachDataBound;
        vm.felmeroDataBound = felmeroDataBound;
        vm.actionBegin = actionBegin;
        vm.toolbarHandler = toolbarHandler;
        vm.DeleteTest = DeleteTest;
        vm.SaveTest = SaveTest;
        vm.CreateTest = CreateTest;
        vm.CreateTestType = CreateTestType;
        vm.EditTestType = EditTestType;
        vm.params = [];
        vm.test = [];
        vm.dataFelmeroElem = [];
        vm.dateValue = new Date();
        vm.cellEdit = cellEdit;
        vm.hints = [];
        vm.cellHint = '';
        vm.openAthlete = openAthlete;
        vm.compile = compile;
        vm.colorCheckBoxChange = colorCheckBoxChange;
        vm.UJSPFELM = false;
        vm.Highlight = -1;
        vm.colors = true;
        vm.showColors = false;

        activate();

        function activate() {
            authenticationService.getRight('MEGTSPFELM').then(function (results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    var promises = [
                        paramHelper.getParams(['sportSpecificTest.sectionId', 'sportSpecificTest.ageGroupId', 'sportSpecificTest.coachId', 'sportSpecificTest.felmeroFejId', 'sportSpecificTest.felmeroElemFejId', 'sportSpecificTest.highlight']),
                        authenticationService.getRight('UJSPFELM')
                    ];
                    $q.all(promises).then(function (results) {
                        vm.params = results[0];
                        vm.UJSPFELM = results[1];
                        vm.sectionId = vm.params['sportSpecificTest.sectionId'];
                        vm.ageGroupId = vm.params['sportSpecificTest.ageGroupId'];
                        vm.coachId = vm.params['sportSpecificTest.coachId'];
                        vm.felmeroFejId = vm.params['sportSpecificTest.felmeroFejId'];
                        if (vm.params['sportSpecificTest.felmeroElemFejId']) {
                            vm.felmeroElemFejId = vm.params['sportSpecificTest.felmeroElemFejId'];
                            vm.felmeroElemFejIdValue = vm.params['sportSpecificTest.felmeroElemFejId'];
                        }
                        if (vm.params['sportSpecificTest.highlight']) {
                            vm.Highlight = vm.params['sportSpecificTest.highlight'];
                        }
                        vm.sectionIdValue = vm.sectionId;
                        vm.ageGroupIdValue = vm.ageGroupId;
                        vm.coachIdValue = vm.coachId;
                        getSections();
                        getAgeGroups(vm.sectionId);
                        getCoaches();
                        getFelmeroTipusok(vm.sectionId);
                        if (vm.felmeroFejId > 0) {
                            vm.newTest = false;
                            vm.showColors = true;
                            vm.createButtonEnabled = false;
                            LoadTest(vm.felmeroFejId);
                        } else {
                            vm.createButtonEnabled = true;
                            vm.newTest = true;
                        }
                    });
                }
            });
        }

        function get1DArrayBelongsToUserInRow(row) {
            var retArray1D = [];
            var SportoloNeve = vm.test[row].SportoloNeve;
            var SportoloID = vm.test[row].SportoloID.toString();
            retArray1D.push(SportoloID);
            retArray1D.push(SportoloNeve);
            var strArr = [];
            for (var i = 0; i < vm.dataFelmeroElem.length; i++) {
                var fieldName = vm.dataFelmeroElem[i].fieldName;
                strArr.push(fieldName);
                retArray1D.push(vm.test[row][strArr[i]]);
                if (vm.test[row][strArr[i]] && vm.test[row][strArr[i]].length > 200) {
                    log.errorMsg(gettextCatalog.getString('Max200CharactersInFields'));
                    return false;
                }
            }
            return retArray1D;
        }

        function resetColumns() {
            vm.columns = [
                {field: 'SportoloID', headerText: 'Id', textAlign: 'left', isPrimaryKey: true, visible: false},
                {field: 'SportoloNeve', headerText: gettextCatalog.getString('AthleteName'), textAlign: 'left', width: 110, allowEditing: false, template: true, templateID: '#sstname'}
            ];
        }

        function setColumns(FEF_ID, newTest, itemsList) {
            if (!FEF_ID) {
                return;
            }
            dataservice.getFelmeroElemList(FEF_ID).
                then(function (arg) {
                    vm.dataFelmeroElem = arg.itemsList;
                    for (var i = 0; i < vm.dataFelmeroElem.length; i++) {
                        switch (vm.dataFelmeroElem[i].Tipus){
                            case 1: vm.dataFelmeroElem[i].TipusStr = gettextCatalog.getString('NumberInteger'); break;
                            case 2: vm.dataFelmeroElem[i].TipusStr = gettextCatalog.getString('NumberFraction'); break;
                            case 3: vm.dataFelmeroElem[i].TipusStr = gettextCatalog.getString('Text'); break;
                            case 4: vm.dataFelmeroElem[i].TipusStr = gettextCatalog.getString('TimeHourMinuteSecHundredthOfASec'); break;
                            case 5: vm.dataFelmeroElem[i].TipusStr = gettextCatalog.getString('TimeMinuteSecHundredthOfASec'); break;
                            case 6: vm.dataFelmeroElem[i].TipusStr = gettextCatalog.getString('TimeSecHundredthOfASec'); break;
                            default: vm.dataFelmeroElem[i].TipusStr = 'x';
                        }
                        vm.hints.push(vm.dataFelmeroElem[i].TipusStr);
                    }
                    if (newTest) {
                        dataservice.FelmeroLetrehozasa(vm.coachId, vm.ageGroupId, parseInt(vm.felmeroElemFejIdValue), vm.dateValue).then(function(data) {
                            vm.test = data.itemsList;
                        }); //  dataservice.FelmeroLetrehozasa
                    } else {
                        for (var i = 0; i < itemsList.length; i++) {
                            var line = {};
                            line.SportoloID = itemsList[i].SportoloID;
                            line.SportoloNeve = itemsList[i].SportoloNeve;
                            for (var c = 0; c < vm.dataFelmeroElem.length; c++) {
                                var fieldName = 'c' + c;
                                line[fieldName] = '';
                            }
                            vm.test.push(line);
                        }
                    }
                    var strArr = [];
                    resetColumns();
                    for (var i = 0; i < vm.dataFelmeroElem.length; i++) {
                        if (vm.dataFelmeroElem[i].ElvartEredmeny) {
                            vm.dataFelmeroElem[i].Nev += '<br>' + gettextCatalog.getString('ExpectedResult') + ': ' + vm.dataFelmeroElem[i].ElvartEredmeny;
                        }
                        var fieldName = vm.dataFelmeroElem[i].fieldName;
                        strArr.push(fieldName);
                        switch (vm.dataFelmeroElem[i].Tipus){
                            case 1:
                                vm.columns.push({field: vm.dataFelmeroElem[i].fieldName, headerText: vm.dataFelmeroElem[i].Nev, textAlign: 'center', width: 120, isEnabled: true, allowEditing: true, editTemplate: vm.tableNumericIntEditorTemplate});
                                break;
                            case 2:
                                vm.columns.push({field: vm.dataFelmeroElem[i].fieldName, headerText: vm.dataFelmeroElem[i].Nev, textAlign: 'center', width: 120, editType: ej.Grid.EditingType.Numeric, editParams: {decimalPlaces: 2, format: '{0:C2}'}});
                                break;
                            case 4:
                                vm.columns.push({field: vm.dataFelmeroElem[i].fieldName, headerText: vm.dataFelmeroElem[i].Nev, textAlign: 'center', width: 120, isEnabled: true, allowEditing: true, editTemplate: vm.tableHHMMSS});
                                break;
                            case 5:
                                vm.columns.push({field: vm.dataFelmeroElem[i].fieldName, headerText: vm.dataFelmeroElem[i].Nev, textAlign: 'center', width: 120, isEnabled: true, allowEditing: true, editTemplate: vm.tableMMSS});
                                break;
                            case 6:
                                vm.columns.push({field: vm.dataFelmeroElem[i].fieldName, headerText: vm.dataFelmeroElem[i].Nev, textAlign: 'center', width: 120, isEnabled: true, allowEditing: true, editTemplate: vm.tableSS});
                                break;
                            default: // 3: text
                                vm.columns.push({field: vm.dataFelmeroElem[i].fieldName, headerText: vm.dataFelmeroElem[i].Nev, textAlign: 'center', width: 400, isEnabled: true, allowEditing: true, editTemplate: vm.tableTEXT});
                        }
                        for (var j = 0; !newTest && j < vm.test.length; j++) {
                            vm.test[j][strArr[i]] = itemsList[j].Adatok1D[i];
                        }
                    }
                }); // dataservice.getFelmeroElemList
        }

        function EditTestType() {
            var felmeroTipusIdx = angular.element('#felmeroTipusDropDown').data('ejDropDownList').selectedIndexValue;
            if (felmeroTipusIdx == -1) {
                swal({title: gettextCatalog.getString('ChooseTestTypeFirst'),
                    type: 'warning',
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('Ok'),
                });
                return;
            }
            var felmeroTipus = vm.felmeroElemFejList[felmeroTipusIdx].value;
            paramHelper.setParams([
                {'sectionId': vm.sectionIdValue},
                {'FelmeroElemFej': felmeroTipus}
            ]);
            $state.go('settings.createSportSpecificTest');
        }

        function CreateTest() {
            var felmeroTipusIdx = angular.element('#felmeroTipusDropDown').data('ejDropDownList').selectedIndexValue;
            if (felmeroTipusIdx == -1) {
                swal({title: gettextCatalog.getString('ChooseTestTypeFirst'),
                    type: 'warning',
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('Ok'),
                });
                return;
            }
            var felmeroTipus = vm.felmeroElemFejList[felmeroTipusIdx].value;
            setColumns(felmeroTipus, true, null);
        }

        function LoadTest(ffid) {
            vm.felmeroFejId = ffid;
            resetColumns();
            return dataservice.getFelmeroById(ffid).then(function(data) {
                vm.dateValue = data.date;
                vm.testdata = data.itemsList;
                vm.felmeroElemFejIdValue = data.FEFID;
                vm.felmeroElemFejId = data.FEFID;
                setColumns(vm.felmeroElemFejIdValue, false, vm.testdata);
                vm.edzoertekeles = data.EdzoErtekeles;
            });
        }

        function SaveTest() {
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            vm.createButtonEnabled = false;
            angular.element('#Grid').ejGrid('batchSave');
            vm.testSaveTest = {};
            var felmeroTipusIdx = angular.element('#felmeroTipusDropDown').data('ejDropDownList').selectedIndexValue;
            vm.felmeroElemFejIdValue = vm.felmeroElemFejList[felmeroTipusIdx].value;
            vm.testSaveTest.FEF_ID = vm.felmeroElemFejIdValue;
            vm.testSaveTest.data = vm.test;

            var arr2D = [];
            for (var i = 0; i < vm.test.length; i++) {
                var arr1D = get1DArrayBelongsToUserInRow(i);
                if (arr1D === false) {
                    return false;
                }
                arr2D.push(arr1D);
            }
            return dataservice.FelmeroMentes(arr2D, vm.felmeroFejId, vm.felmeroElemFejIdValue, vm.sectionId, vm.ageGroupId, vm.coachId, vm.edzoertekeles, vm.dateValue).then(function(data) {
                log.successMsg(gettextCatalog.getString('SaveCompleted'));
                var lastSaveTestdFFID = data.data;
                vm.test = [];
                vm.dataFelmeroElem = [];
                LoadTest(lastSaveTestdFFID);
            });
        }

        function DeleteTest() {
            swal({title: gettextCatalog.getString('Are you sure you want to delete the selected test?'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes, delete this test'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
            }).then(function(isConfirm) {
                if (isConfirm.value) {
                    dataservice.deleteFelmero(vm.felmeroFejId).
                        then(function () {
                            $state.go('administration.sportSpecificTest.newSportSpecificTestList');
                        });
                }
            });
        }

        function getFelmeroTipusok(sectionId) {
            return dataservice.felmeroElemFejDropDownList(sectionId).then(function(data) {
                vm.newTest = false;
                vm.felmeroElemFejList = data.itemsList;
            });
        }

        function actionBegin(args) {
            if (args.requestType && (args.requestType === 'paging' || args.requestType === 'sorting')) {
                args.cancel = true;
                angular.element('#Grid').ejGrid('batchSave');
            }
        }

        function getSections() {
            return dataservice.sectionDropDownList().then(function(data) {
                vm.sectionList = data.itemsList;
            });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                vm.sectionId = vm.sectionList[0].value;
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        function getAgeGroups(sectionId) {
            return dataservice.ageGroupDropDownList(sectionId).then(function(data) {
                vm.ageGroupList = data.itemsList;
            });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupId);
            }
        }

        function getCoaches() {
            return dataservice.coachDropDownList(vm.sectionId, vm.ageGroupId, vm.dateValue).then(function(data) {
                vm.coachList = data.itemsList;
            });
        }

        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
            }
        }

        function felmeroDataBound() {
            if (vm.felmeroElemFejList.length === 1) {
                angular.element('#felmeroTipusDropDown').ejDropDownList('selectItemByValue', vm.felmeroElemFejList[0].value);
            } else {
                angular.element('#felmeroTipusDropDown').ejDropDownList('selectItemByValue', vm.felmeroElemFejId);
            }
        }

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                var grid = angular.element('#Grid').ejGrid('instance');
                grid.ignoreOnExport.splice(grid.ignoreOnExport.indexOf('dataSource'), 1);
                this.export(dataservice.getURL() + 'FelmeroExcelExport/ExcelExport/');
            }
        }

        function cellEdit(arg) {
            if (!vm.UJSPFELM) {
                arg.cancel = true;
                return;
            }
            var strnum = '';
            var num = undefined;
            for (var i = 1; i < arg.columnName.length; i++) {
                strnum += arg.columnName.charAt(i);
            }
            num = parseInt(strnum);
            vm.cellHint = gettextCatalog.getString('TheSelectedCellIs') + ' ' + vm.hints[num];
            $scope.$apply();
        }

        function CreateTestType() {
            paramHelper.setParams([
                {'sectionId': vm.sectionIdValue},
                {'FelmeroElemFej': 0}
            ]);
            $state.go('settings.createSportSpecificTest');
        }

        function openAthlete(Id) {
            swal({
                title: gettextCatalog.getString('AreYouSureGo'),
                text: gettextCatalog.getString('AreYouSureYouWantToGoToAthlete'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: gettextCatalog.getString('GoToAthlete'),
                cancelButtonText: gettextCatalog.getString('Cancel')
            }).then(function (isConfirmed) {
                if (isConfirmed.value) {
                    paramHelper.setParams([
                        {'athleteModify.sportoloId': Id}
                    ]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                }
            });
        }

        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }

        vm.tableNumericIntEditorTemplate = {
            create: function () {
                return '<input>';
            },
            read: function (args) {
                return args.ejMaskEdit('get_StrippedValue');
            },
            write: function (args) {
                var s = args.element[0].id.toString().replace('Grid', '');
                args.element.ejMaskEdit({
                    width: '100%',
                    maskFormat: '999999',
                    inputMode: ej.InputMode.Numeric,
                    customCharacter: '-',
                    value: args.rowdata !== undefined ? args.rowdata[s] : '',
                });
            }
        };

        vm.tableNumericFloatEditorTemplate = {
            create: function () {
                return '<input>';
            },
            read: function (args) {
                return args.ejNumericTextbox('getValue');
            },
            write: function (args) {
                var s = args.element[0].id.toString().replace('Grid', '');
                args.element.ejNumericTextBox({
                    width: '100%',
                    decimalPlaces: 2,
                    value: args.rowdata !== undefined ? args.rowdata[s] : ''
                });
            }
        };

        vm.tableHHMMSS = {
            create: function () {
                return '<input>';
            },
            read: function (args) {
                return args.ejMaskEdit('get_UnstrippedValue');
            },
            write: function (args) {
                var s = args.element[0].id.toString().replace('Grid', '');
                args.element.ejMaskEdit({
                    width: '100%',
                    maskFormat: '99:99:99',
                    inputMode: ej.InputMode.Numeric,
                    //customCharacter: ':',
                    value: args.rowdata !== undefined ? args.rowdata[s] : '',
                });
            }
        };

        vm.tableMMSS = {
            create: function () {
                return '<input>';
            },
            read: function (args) {
                return args.ejMaskEdit('get_UnstrippedValue');
            },
            write: function (args) {
                var s = args.element[0].id.toString().replace('Grid', '');
                args.element.ejMaskEdit({
                    width: '100%',
                    maskFormat: '99:99',
                    inputMode: ej.InputMode.Numeric,
                    value: args.rowdata !== undefined ? args.rowdata[s] : '',
                });
            }
        };

        vm.tableSS = {
            create: function () {
                return '<input>';
            },
            read: function (args) {
                return args.ejMaskEdit('get_StrippedValue');
            },
            write: function (args) {
                var s = args.element[0].id.toString().replace('Grid', '');
                args.element.ejMaskEdit({
                    width: '100%',
                    maskFormat: 'NNNNNN',
                    inputMode: ej.InputMode.Numeric,
                    customCharacter: '-.,',
                    value: args.rowdata !== undefined ? args.rowdata[s] : '',
                });
            }
        };

        vm.tableTEXT = {
            create: function () {
                return '<input>';
            },
            read: function (args) {
                return args.ejMaskEdit('get_UnstrippedValue');
            },
            write: function (args) {
                var s = args.element[0].id.toString().replace('Grid', '');
                args.element.ejMaskEdit({
                    width: '100%',
                    //maskFormat: '99',
                    inputMode: ej.InputMode.Text,
                    //customCharacter: ':',
                    value: args.rowdata !== undefined ? args.rowdata[s] : '',
                });
            }
        };

        function querycellinfo(arg) {
            var $element = angular.element(arg.cell);
            if (vm.Highlight && arg.data.SportoloID == vm.Highlight && arg.column.field == 'SportoloNeve') {
                $element.css('background-color', '#cdeecd');
            } else if (vm.colors && vm.testdata && vm.testdata.length > 0 && arg.cell.cellIndex >= 2) {
                var rowIndex = getRowIndex(arg.data.SportoloID);
                if (vm.testdata[rowIndex].Colors[arg.cell.cellIndex - 2] && vm.testdata[rowIndex].Colors[arg.cell.cellIndex - 2].length > 0) {
                    $element.css('background-color', vm.testdata[rowIndex].Colors[arg.cell.cellIndex - 2]);
                }
            }
        }

        function getRowIndex(SportoloID) {
            for (var i = 0; i < vm.testdata.length; i++) {
                if (vm.testdata[i].SportoloID == SportoloID) {
                    return i;
                }
            }
            return -1;
        }

        function colorCheckBoxChange(arg) {
            vm.colors = arg.value;
            vm.test = [];
            vm.testdata = [];
            LoadTest(vm.felmeroFejId);
        }
    }
})();

