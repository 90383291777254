(function () {
    'use strict';

    angular
        .module('app.reports.statisticalReports.leavingAthletesBySection')
        .controller('LeavingAthletesBySection', LeavingAthletesBySection);

    LeavingAthletesBySection.$inject = ['log', 'dataservice', '$rootScope', 'gettextCatalog', 'authenticationService', '$scope', '$compile', '$state', 'paramHelper'];

    function LeavingAthletesBySection(log, dataservice, $rootScope, gettextCatalog, authenticationService, $scope, $compile, $state, paramHelper) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('LeavingAthletesBySection');
        vm.sectionList = [];
        vm.athleteList = [];
        vm.accessibleSections = [];
        vm.gridToolbar = ['printGrid', 'excelExport'];
        vm.columns = [
            {
                field: 'SzakosztalyNeve',
                headerText: gettextCatalog.getString('Section')
            }, {
                field: 'SzamKilepett',
                headerText: gettextCatalog.getString('NumberOfAthletes'), template: '#leatbysetemplateDataColumn1'
            }, {
                field: 'SzamFiu',
                headerText: gettextCatalog.getString('NumberOfBoys'), template: '#leatbysetemplateDataColumn2'
            }, {
                field: 'SzamLany',
                headerText: gettextCatalog.getString('NumberOfGirls'), template: '#leatbysetemplateDataColumn3'
            }];
        vm.queryDate = new Date();
        vm.queryDate.setMonth(vm.queryDate.getMonth() - 1);
        vm.queryDate2 = new Date();
        vm.dateChange = dateChange;
        vm.dateChange2 = dateChange2;
        vm.openSection = openSection;
        vm.athleteListFull = [];
        vm.showEachSections = false;
        vm.accessibleSections = [];
        vm.checkboxChanged = checkboxChanged;
        vm.checkboxEnabled = true;
        vm.checkboxchangedonce = false;
        vm.checked = false;
        vm.isAdmin = false;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function openSection(SectionId, gender2) {
            new ej2.data.DataManager(grid.dataSource).executeQuery(new ej2.data.Query().where('SzakosztalyId', 'equal', SectionId)).then(function (data) {
                var SectionName = data.result[0].SzakosztalyNeve;
                var gender = gender2 === 0 ? 'allgender' : (gender2 === 1 ? 'male' : 'female');

                var sectionBelongsToUser = false;
                for (var i = 0; i < vm.accessibleSections.length; i++) {
                    if (vm.accessibleSections[i] == SectionId) {
                        sectionBelongsToUser = true;
                        break;
                    }
                }
                if (sectionBelongsToUser || vm.isAdmin) {
                    paramHelper.setParams([
                        {'state': 'leavingAthletes'},
                        {'sectionId': SectionId},
                        {'sectionName': SectionName},
                        {'dateFrom': vm.queryDate},
                        {'dateTo': vm.queryDate2},
                        {'gender': gender}
                    ]);
                    $state.go('reports.statisticalReports.listAthletes');
                } else {
                    log.htmlWarning(gettextCatalog.getString('You do not have permission to access the selected section details!') + '<br><br>' + gettextCatalog.getString(SectionName));
                }
            });
        }

        function activate() {
            authenticationService.getRight('KMKILSZAK').then(function (results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    getSections();
                    getNumbers(vm.queryDate, vm.queryDate2);
                }
            });
        }

        function getNumbers(date, date2) {
            dataservice.leavingAthletesBySection(date, date2)
                .then(function (data) {
                    vm.athleteListFull = data.itemsList;
                    vm.accessibleSections = data.felhasznaloSzakosztalyai;
                    vm.isAdmin = data.isAdmin;
                    if (data.isAdmin == true) {
                        vm.checked = true;
                    }
                    filterSections();
                });
        }

        function dateChange(data) {
            getNumbers(data.value, vm.queryDate2);
        }

        function dateChange2(data) {
            getNumbers(vm.queryDate, data.value);
        }

        function checkboxChanged(args) {
            vm.showEachSections = args.value;
            filterSections();
        }

        function filterSections() {
            vm.athleteList = [];
            if (!vm.showEachSections) {
                for (var i = 0; i < vm.athleteListFull.length; i++) {
                    for (var s = 0; s < vm.accessibleSections.length; s++) {
                        if (vm.athleteListFull[i].SzakosztalyId == vm.accessibleSections[s]) {
                            vm.athleteList.push(vm.athleteListFull[i]);
                        }
                    }
                }
            } else {
                vm.athleteList = vm.athleteListFull;
                grid.dataSource = vm.athleteList;
            }
        }

        function getSections() {
            dataservice.sectionDropDownList(0)
                .then(function (data) {
                    data.itemsList.unshift({
                        value: '0',
                        text: gettextCatalog.getString('All')
                    });
                    vm.sectionList = data.itemsList;
                });
        }
    }
})();
