(function () {
    'use strict';

    angular
        .module('app.business.annualSportServiceFeeByAthletes')
        .controller('AnnualSportServiceFeeByAthletesController', AnnualSportServiceFeeByAthletesController);

    AnnualSportServiceFeeByAthletesController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'authenticationService', '$scope', '$compile', '$state', 'paramHelper'];
    /* @ngInject */
    function AnnualSportServiceFeeByAthletesController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $compile, $state, paramHelper) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AnnualSportServiceFeeByAthletes');

        // Create variables
        vm.yearList = dataservice.range(2000, new Date().getFullYear());
        vm.year = new Date().getFullYear();

        vm.gridToolbar = ['printGrid', 'excelExport'];
        vm.summaryRows = [{
            columns: [
                {
                    type: 'sum',
                    field: 'Elvart',
                    footerTemplate: '${sum}',
                    format: 'N0'
                },
                {
                    type: 'sum',
                    field: 'Tenyleges',
                    footerTemplate: '${sum}',
                    format: 'N0'
                }
            ]
        }];
        vm.columns = [
            {field: 'Id', isPrimaryKey: true, visible: false},
            {field: 'SportoloNeve', headerText: gettextCatalog.getString('Athlete'), width: 100,
            template: '#annualsportservicefeebyathletecolumnTemplate1'},
            {field: 'EdzoNeve', headerText: gettextCatalog.getString('Coach'), width: 100,
            template: '#annualsportservicefeebyathletecolumnTemplate2'},
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), width: 100},
            {field: 'Elvart', headerText: gettextCatalog.getString('Elvart'), textAlign: 'left', width: 100, format: 'N0'},
            {field: 'Tenyleges', headerText: gettextCatalog.getString('Tenyleges'), textAlign: 'left', width: 100, format: 'N0'}
        ];

        // Public functions
        vm.getDatas = getDatas;
        vm.compile = compile;
        vm.goToAthlete = goToAthlete;
        vm.openCoach = openCoach;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            editSettings: {allowEditing: true, mode: 'batch', showConfirmDialog: false},
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            allowDeleting: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            aggregates: vm.summaryRows,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Activate page
        activate();

        function activate() {
            var promises = [authenticationService.getRight('MEGTTAGDOSP')];
            $q.all(promises).then(function (result) {
                if (result[0] !== true) {
                    log.permissionError(true);
                } else {
                    getDatas();
                }
            });
        }

        function getDatas() {
            dataservice.getAnnualSportServiceFeeByAthletes(vm.year).then(function(data) {
                grid.dataSource = data.itemsList;
            });
        }

        // Compile links in grid
        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                var grid = angular.element('#Grid').ejGrid('instance');
                grid.ignoreOnExport.splice(grid.ignoreOnExport.indexOf('dataSource'), 1);
                this.export(dataservice.getURL() + 'TagdijBySportoloSelect/ExcelExport/');
            }
        }
    }
})();
