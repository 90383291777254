(function () {
    'use strict';

    angular
        .module('app.athletes.ageGroups.ageGroupsList')
        .controller('AgeGroupsListController', TeamsCtrl);

    TeamsCtrl.$inject = ['log', 'dataservice', 'authenticationService', '$q', '$state', '$scope', 'gettextCatalog', 'paramHelper', '$compile', '$rootScope'];
    /* @ngInject */
    function TeamsCtrl(log, dataservice, authenticationService, $q, $state, $scope, gettextCatalog, paramHelper, $compile, $rootScope) {
        var vm = this; $rootScope.vm = vm;

        // Page title
        $rootScope.title = gettextCatalog.getString('AgeGroups');

        // Dropdownlist's sources
        vm.sectionList = [];
        vm.ageGroupList = [];
        // checkbox
        vm.inactiveAgeGroups = false;
        vm.checkboxEnabled = false;

        vm.thereIsSelectedSection = false;
        vm.columns = [
            {type: 'checkbox', textAlign: 'center', width: 35},
            {
                field: 'nev',
                headerText: gettextCatalog.getString('Name'),
                textAlign: 'left',
                width: 150,
                template: '#templateData'
            },
            {
                field: 'megjegyzes',
                headerText: gettextCatalog.getString('Comment'),
                textAlign: 'left',
                width: 150
            }
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            },
            rowSelecting: function () {
                grid.clearSelection();
            },
            checkBoxChange: function (args) {
                $rootScope.checkBoxChangeOnlyOne(args, grid);
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Publikus funkciók
        vm.sectionSelect = sectionSelect;
        vm.sectionDestinationDropDownChanged = sectionDestinationDropDownChanged;
        vm.copyAllAgeGroup = copyAllAgeGroup;
        vm.goToAddAgeGroup = goToAddAgeGroup;
        vm.goToModifyAgeGroup = goToModifyAgeGroup;
        vm.goToModifyAgeGroupButton = goToModifyAgeGroupButton;
        vm.goToAgeGroupMembersList = goToAgeGroupMembersList;
        vm.sectionDataBound = sectionDataBound;
        vm.inactiveChange = inactiveChange;

        activate();

        function activate() {
            $q.all([authenticationService.getRight('MEGTKOROKEZ')]).then(function (result) {
                if (result[0] !== true) {
                    log.permissionError(true);
                } else {
                    vm.MEGTKOROKEZ = result[0];
                    $q.all([authenticationService.getRight('UJKOROKEZ'), authenticationService.getRight('MODKOROKEZ'), paramHelper.getParams([], ['ageGroups.section', 'ageGroups.inactive'])]).then(function (results) {
                        vm.UJKOROKEZ = results[0];
                        vm.MODKOROKEZ = results[1];
                        vm.sectionId = results[2]['ageGroups.section'];
                        vm.inactiveAgeGroups = results[2]['ageGroups.inactive'];
                        angular.element('#active').ejCheckBox({checked: vm.inactiveAgeGroups});
                        getSections();
                    });
                }
            });
        }

        function getSections() {
            return dataservice.sectionDropDownList(0).then(function (data) {
                vm.sectionList = data.itemsList;
            });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                vm.sectionId = vm.sectionList[0].value;
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        function sectionSelect(args) {
            vm.checkboxEnabled = true;
            vm.sectionId = args.value;
            if (!vm.inactiveAgeGroups) {
                vm.thereIsSelectedSection = true;
            }
            getAgeGroups();
            paramHelper.setParams([{'ageGroups.section': vm.sectionId}]);
        }

        // in modal window
        function sectionDestinationDropDownChanged(args) {
            vm.selectedDestinationSection = args.value;
            angular.element('.sa-error-container').removeClass('show');
            angular.element('.sa-input-error').removeClass('show');
        }

        function getAgeGroups(sectionId, inactives) {
            if (!sectionId) {
                sectionId = vm.sectionId;
            }
            if (inactives !== true) {
                inactives = vm.inactiveAgeGroups;
            }
            return dataservice.ageGroupList(sectionId, inactives).then(function (data) {
                grid.dataSource = data.itemsList;
            });
        }

        function copyAllAgeGroup() {
            if (vm.UJKOROKEZ && vm.MODKOROKEZ) {
                if (angular.isDefined(vm.sectionId)) {
                    swal({
                        title: gettextCatalog.getString('CopyAllAgeGroup'),
                        html: '<label>' + gettextCatalog.getString('DestinationSection') + '</label><input id="sectionDropDown3" ej-dropdownlist placeholder="' + gettextCatalog.getString('PleaseChoose') + '" style="margin-bottom: 10px;">',
                        showCancelButton: true,
                        confirmButtonText: gettextCatalog.getString('Yes'),
                        cancelButtonText: gettextCatalog.getString('No'),
                        closeOnConfirm: false,
                        preConfirm: function () {
                            return new Promise(function (resolve, reject) {
                                if (!vm.selectedDestinationSection) {
                                    swal.showValidationError(gettextCatalog.getString('PleaseChooseSection'));
                                    reject(gettextCatalog.getString('PleaseChooseSection'));
                                } else {
                                    resolve();
                                }
                            });
                        }
                    }).then(function (isConfirmed) {
                        if (isConfirmed.value) {
                            dataservice.copyAllAgeGroup(vm.sectionId, vm.selectedDestinationSection).then(function () {
                                getAgeGroups();
                                log.successMsg('AllAgeGroupCopied');
                                swal.close();
                            }, function () {
                            });
                        }
                    });
                    angular.element('#sectionDropDown3').ejDropDownList({
                        dataSource: vm.sectionList,
                        change: sectionDestinationDropDownChanged,
                        value: vm.selectedDestinationSection,
                        width: '100%'
                    });
                    getAgeGroups();
                } else {
                    log.errorMsg('YouHaveToSelectTeam');
                }
            } else {
                log.permissionError();
            }
        }

        function goToAddAgeGroup() {
            if (vm.UJKOROKEZ) {
                paramHelper.setParams([{'ageGroups.section': vm.sectionId}]);
                $state.go('athletes.ageGroups.addAgeGroup');
            } else {
                log.permissionError();
            }
        }

        function goToModifyAgeGroupButton() {
            if (vm.MODKOROKEZ || vm.MEGTKOROKEZ) {
                if ($rootScope.checkRowSelection(grid, true)) {
                    var selectedRecords = $rootScope.getSelectedItems(grid);
                    paramHelper.setParams([{'ageGroups.ageGroup': selectedRecords[0].id}, {'ageGroups.section': vm.sectionId}]);
                    $state.go('athletes.ageGroups.modifyAgeGroup');
                }
            } else {
                log.permissionError();
            }
        }

        function goToModifyAgeGroup(id) {
            if (vm.MODKOROKEZ || vm.MEGTKOROKEZ) {
                if (id) {
                    console.log(id);
                    paramHelper.setParams([{'ageGroups.ageGroup': id}]);
                    $state.go('athletes.ageGroups.modifyAgeGroup');
                }
            } else {
                log.permissionError();
            }
        }

        function goToAgeGroupMembersList() {
            if (vm.MEGTKOROKEZ) {
                if ($rootScope.checkRowSelection(grid, true)) {
                    var selectedRecords = $rootScope.getSelectedItems(grid);
                    paramHelper.setParams([{'ageGroups.ageGroup': selectedRecords[0].id}, {'ageGroups.section': vm.sectionId}]);
                    $state.go('athletes.ageGroups.ageGroupMembersList');
                }
            } else {
                log.permissionError();
            }
        }

        // when click inactives checkbox
        function inactiveChange(args) {
            vm.inactiveAgeGroups = args.value;
            vm.thereIsSelectedSection = false;
            if (vm.sectionId) {
                getAgeGroups(vm.sectionId, args.value);
            }
            paramHelper.setParams([{'ageGroups.inactive': vm.inactiveAgeGroups}]);
        }
    }
})();
