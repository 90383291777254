(function () {
    'use strict';

    angular
        .module('app.business.checkPayment')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'business.checkPayment',
                config: {
                    url: '/checkPayment',
                    templateUrl: 'app/business/checkPayment/checkPayment.html',
                    controller: 'CheckPaymentController',
                    controllerAs: 'vm',
                    title: 'CheckPayment',
                    settings: {
                        navId: 2193,
                        level: 2,
                        order: 19,
                        orderTitle_hu: 'Csekkes befizetés',
                        orderTitle_en: 'Check Payment',
                        parentId: 3,
                        content: 'CheckPayment',
                        activatorPermission: '"CSEKKEXPORT"'
                    }
                }
            }
        ];
    }
})();
