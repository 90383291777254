(function () {
    'use strict';

    angular
        .module('app.administration.trainingDiary')
        .controller('TrainingDiaryController', TrainingDiaryController);

    TrainingDiaryController.$inject = ['log', 'logger', 'DOMAIN', 'paramHelper', '$state', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'authenticationService', '$filter', '$scope'];
    /* @ngInject */
    function TrainingDiaryController(log, logger, DOMAIN, paramHelper, $state, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $filter, $scope) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('trainingDiary');
        vm.gridToolbar = ['printGrid', 'excelExport'];
        vm.coachList = [];
        vm.sectionList = [];
        vm.ageGroupList = [];
        vm.years = [];
        vm.month = [];
        vm.selectedCoach = undefined;
        vm.selectedSection = undefined;
        vm.selectedAgeGroup = undefined;
        vm.yearValue = undefined;
        vm.monthValue = undefined;
        vm.Save = Save;
        vm.addRow = addRow;
        vm.removeRow = removeRow;
        vm.data = [];
        vm.HaviJelenletiFejId = undefined;
        vm.lezart = false;
        vm.addEnabled = false;
        vm.removeEnabled = false;
        vm.selectedRow = selectedRow;
        vm.selectedId = undefined;
        vm.newId = -1;
        vm.querycellinfo = querycellinfo;
        vm.beginEdit = beginEdit;
        vm.toolbarHandler = toolbarHandler;
        vm.print = print;
        vm.keszult = new Date();
        var printOpened = false;

        vm.columns = [
            {field: 'Id', visible: false, isPrimaryKey: true, isIdentity: true},
            {field: 'AktivNap', visible: false, headerText: 'AktivNap', textAlign: 'left', width: 150, allowEditing: false},
            {field: 'Nap', headerText: gettextCatalog.getString('Day'), textAlign: 'left', width: 150, allowEditing: false},
            {field: 'EdzesIdopontja', headerText: gettextCatalog.getString('TrainingTime'), textAlign: 'left', width: 120, allowEditing: true},
            {field: 'TevekenysegHelye', headerText: gettextCatalog.getString('TrainingLocation'), textAlign: 'left', width: 120, allowEditing: true},
            {field: 'EdzesmunkaLeirasa', headerText: gettextCatalog.getString('TrainingDescription'), textAlign: 'left', width: 120, allowEditing: true}
        ];

        vm.months = [
            {text: gettextCatalog.getString('January'), value: 0},
            {text: gettextCatalog.getString('February'), value: 1},
            {text: gettextCatalog.getString('March'), value: 2},
            {text: gettextCatalog.getString('April'), value: 3},
            {text: gettextCatalog.getString('May'), value: 4},
            {text: gettextCatalog.getString('June'), value: 5},
            {text: gettextCatalog.getString('July'), value: 6},
            {text: gettextCatalog.getString('August'), value: 7},
            {text: gettextCatalog.getString('September'), value: 8},
            {text: gettextCatalog.getString('October'), value: 9},
            {text: gettextCatalog.getString('November'), value: 10},
            {text: gettextCatalog.getString('December'), value: 11}
        ];

        activate();

        function activate() {
            var promises = [authenticationService.getRight('HJFELVIHET'), paramHelper.getParams([], ['HaviJelenletiFejId'])];
            $q.all(promises).then(function (results) {
                if (!results[0]) {
                    log.permissionError(true);
                } else if (results[1].HaviJelenletiFejId) {
                    vm.HaviJelenletiFejId = results[1].HaviJelenletiFejId;
                    loadTrainingDiary(results[1].HaviJelenletiFejId);
                }
            });
        }

        function print() {
            vm.reportParameters = {
                FejId: vm.HaviJelenletiFejId,
                'EdzoNeve': angular.element('#edzo').data('ejDropDownList').getSelectedItem()[0].innerText,
                'keszult': vm.keszult
            };
            //     Name : 'name',
            //     TajSzam : 'TajSzam',
            //     SzuletesiIdo : '2012-01-01',
            //     SportoloId : 3422,
            //     Type : 'Type',
            //     Fenykeput : 'Fenykeput'
            // };
            // console.log('print',vm.reportParameters);

            $scope.$apply();

            if (printOpened) {
                angular.element('#printDialog').ejDialog('open');
            } else {
                printOpened = true;
                angular.element('#printDialog').ejDialog({height: '99%', width: '99%', enablemodal: 'true', 'position-x': '0', 'position-y': '0', target: '#mainContent', enableresize: 'false'});
            }
        }

        function querycellinfo(args) {
            angular.element('Grid').ejGrid({columns: vm.columns});
            var $element = angular.element(args.cell);
            if (!args.data.AktivNap) {
                $element.css('background-color', '#efdcdc');
            }
        }

        function beginEdit(arg) {
            if (!arg.rowData.AktivNap) {
                arg.cancel = true;
            }
        }

        function loadTrainingDiary(HaviJelenletiFejId) {
            dataservice.loadTrainingDiaryByMonthlyAttendanceId(HaviJelenletiFejId).then(function (data) {
                vm.sectionList.push({text: data.szakosztaly, value: data.szakosztalyid});
                vm.ageGroupList.push({text: data.korcsoport, value: data.korcsoportid});
                vm.coachList.push({text: data.edzo, value: data.edzoid});
                vm.selectedCoach = data.edzoid;
                vm.selectedSection = data.szakosztalyid;
                if (data.keszult) {
                    vm.keszult = data.keszult;
                }
                var datum = new Date (data.datum);
                var year = (datum).getFullYear();
                var month = (datum).getMonth();
                vm.yearValue = year;
                vm.monthValue = month;
                vm.years.push({text: year, value: year});
                if (data.lezartJelenleti) {
                    vm.lezart = true;
                    angular.element('#Grid').ejGrid({
                        allowSelection: false,
                        editSettings: {allowEditing: false}
                    });
                }
                vm.data = data.edzesmunka;
                vm.data = $filter('dateStringFilter')(vm.data, 'Nap');
                setTimeout(function() {
                    vm.selectedAgeGroup = data.korcsoportid;
                    angular.element('#ev').ejDropDownList('selectItemByValue', vm.years[0].value);
                    angular.element('#edzo').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                    angular.element('#szakosztaly').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                    if (data.korcsoportid > 0) {
                        angular.element('#korosztaly').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
                    }
                    $scope.$apply();
                }, 10);
            });
        }

        function getIndex(id) {
            for (var i = 0; i < vm.data.length; i++) {
                if (vm.data[i].Id == id) {
                    return i;
                }
            }
            return null;
        }

        function addRow() {
            angular.element('#Grid').ejGrid('saveCell');
            angular.element('#Grid').ejGrid('endEdit');
            angular.element('#Grid').ejGrid('batchSave');
            var idx = getIndex(vm.selectedId);
            var newLine = {};
            newLine.Id = vm.newId--;
            newLine.AktivNap = vm.data[idx].AktivNap;
            newLine.Nap = vm.data[idx].Nap;
            newLine.EdzesIdopontja = '';
            newLine.TevekenysegHelye = '';
            newLine.EdzesmunkaLeirasa = '';
            vm.data.splice(idx + 1, 0, newLine);
            vm.addEnabled = false;
            vm.removeEnabled = false;
            $scope.$apply();
        }

        function removeRow() {
            vm.addEnabled = false;
            vm.removeEnabled = false;
            var idx = getIndex(vm.selectedId);
            var entries = entriesForThisDay(idx);
            if (entries > 1) {
                vm.data.splice(idx, 1);
                $scope.$apply();
            }
        }

        function entriesForThisDay(index) {
            var datum = vm.data[index].Nap;
            var counter = 0;
            for (var i = 0; i < vm.data.length; i++) {
                if (vm.data[i].Nap == datum) {
                    counter++;
                }
            }
            return counter;
        }

        function selectedRow(arg) {
            vm.addEnabled = true;
            vm.selectedId = arg.data.Id;
            var idx = getIndex(arg.data.Id);
            var entries = entriesForThisDay(idx);
            vm.removeEnabled = entries > 1;
            $scope.$apply();
        }

        function Save() {
            angular.element('#Grid').ejGrid('saveCell');
            angular.element('#Grid').ejGrid('endEdit');
            angular.element('#Grid').ejGrid('batchSave');
            console.log('vm.data', vm.data);
            dataservice.saveTrainingDiary(vm.data, vm.HaviJelenletiFejId, vm.keszult).then(function (response) {
                loadTrainingDiary(response.data);
            });
        }

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                var grid = angular.element('#Grid').ejGrid('instance');
                grid.ignoreOnExport.splice(grid.ignoreOnExport.indexOf('dataSource'), 1);
                this.export(dataservice.getURL() + 'KepzesiNaploBetoltesIdAlapjan/ExcelExport/');
            }
        }
    }
})();
