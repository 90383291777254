(function () {
    'use strict';

    angular
        .module('app.reports.absenceReport')
        .controller('AbsenceReportController', AbsenceReportController);

    AbsenceReportController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'paramHelper', 'authenticationService', '$scope', '$compile', '$state', '$timeout'];
    /* @ngInject */
    function AbsenceReportController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $scope, $compile, $state, $timeout) {
        var vm = this; $rootScope.vm = vm;
        vm.params = [];
        $rootScope.title = gettextCatalog.getString('AbsenceReport');
        vm.searchSPC = true;
        vm.searchPP = true;
        vm.sectionList = [];
        vm.coachList = [];
        vm.athletesLists = [];
        vm.gridExcelExport = dataservice.getURL() + 'Hianyzasok/ExcelExport/';
        vm.toolbarHandler = toolbarHandler;
        vm.search = search;
        vm.openCoach = openCoach;
        vm.openAthlete = openAthlete;
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.coachDropDownListDataBound = coachDropDownListDataBound;
        vm.coachSelect = coachSelect;
        vm.selectdateFrom = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate());
        vm.selectdateTo = new Date();
        vm.fromDateSelect = fromDateSelect;
        vm.toDateSelect = toDateSelect;
        vm.SelectedSectionID = -1;
        vm.SelectedCoachID = -1;
        vm.compile = compile;
        vm.gridToolbar = ['printGrid', 'excelExport'];

        vm.columns = [
            {field: 'SportoloNeve', headerText: gettextCatalog.getString('Athlete'), headerTextAlign: 'center', textAlign: 'left', width: '120', template: true, templateID: '#abretemplateDataColumn1'},
            {field: 'EdzoNeve', headerText: gettextCatalog.getString('Coach'), textAlign: 'left', width: '120', template: true, templateID: '#abretemplateDataColumn2'},
            {field: 'OsszEdzesekSzama', headerText: '*' + gettextCatalog.getString('SumNumberOfTrainings'), textAlign: 'left', width: '150'},
            {field: 'JelenletSzazalek', headerText: '*' + gettextCatalog.getString('AttendancePercent'), headerTextAlign: 'center', width: '140'},
            {field: 'EdzesekSzama', headerText: gettextCatalog.getString('NumberOfTrainings'), textAlign: 'left', width: '115'},
            {field: 'HianyzasokSzama', headerText: gettextCatalog.getString('NumberOfAbsence'), headerTextAlign: 'center', width: '130'},
            {field: 'IgazoltHianyzasokSzama', headerText: gettextCatalog.getString('ProvenNumberOfAbsence'), headerTextAlign: 'center', width: '130'},
            {field: 'BetegsegekSzama', headerText: gettextCatalog.getString('NumberOfSickness'), headerTextAlign: 'center', width: '140'},
            {field: 'SerulesekSzama', headerText: gettextCatalog.getString('NumberOfInjuries'), headerTextAlign: 'center', width: '120'},
            {field: 'VersenyekSzama', headerText: gettextCatalog.getString('NumberOfCompetition'), headerTextAlign: 'center', width: '125'}
        ];

        angular.element(document).ready(function () {
            activate();
        });

        function activate() {
            $timeout(function() {
                vm.selectdateFrom = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate());
                vm.selectdateTo = new Date();
                $scope.$apply();
            });
            var promises = [authenticationService.getRight('HIANYZASMEGT')];
            $q.all(promises).then(function (res) {
                if (!res[0]) {
                    log.permissionError(true);
                } else {
                    getSections();
                    setDropDowns();
                }
            })
            .catch(function () {
                log.permissionError(true);
            });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'absenceReport.sectionId',
                'absenceReport.coachId',
                'absenceReport.selectdateFrom',
                'absenceReport.selectdateTo',
            ])
            ];
            $q.all(promises).then(function (results) {
                vm.SelectedSectionID = results[0]['absenceReport.sectionId'];
                vm.SelectedCoachID = results[0]['absenceReport.coachId'];
                vm.selectdateFrom = results[0]['absenceReport.selectdateFrom'];
                vm.selectdateTo = results[0]['absenceReport.selectdateTo'];
            });
        }

        function search() {
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg('FillTheRequiredFields');
                return;
            }
            paramHelper.setParams([
                {'absenceReport.sectionId': vm.SelectedSectionID},
                {'absenceReport.coachId': vm.SelectedCoachID},
                {'absenceReport.selectdateFrom': vm.selectdateFrom},
                {'absenceReport.selectdateTo': vm.selectdateTo},
            ]);

            dataservice.getAbsenceReport(vm.SelectedSectionID, 0, vm.SelectedCoachID, vm.selectdateFrom, vm.selectdateTo)
                .then(function (data) {
                    vm.athletesLists = data.itemsList;
                });
        }

        function sectionSelect(args) {
            getActualCoachesList(args.value);
            vm.showDelButton = false;
        }

        function coachSelect(args) {
            vm.SelectedCoachID = args.value;
            if (args.value == -1) {
                vm.SelectedCoachID = 0;
            }
            vm.showDelButton = false;
        }

        function getSections() {
            dataservice.sectionDropDownList(0)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: -1, text: gettextCatalog.getString('All')};
                        vm.sectionList.splice(0, 0, all);
                    }
                });
        }
        function getActualCoachesList(SelectedSectionID) {
            return dataservice.coachDropDownList(SelectedSectionID, 0, vm.selectdateFrom, vm.selectdateTo)
                .then(function (data) {
                    vm.coachList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: -1, text: gettextCatalog.getString('All')};
                        vm.coachList.splice(0, 0, all);
                    }
                });
        }

        function openAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST'), authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }

        function coachDropDownListDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coach').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coach').ejDropDownList('selectItemByValue', vm.SelectedCoachID);
            }
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#section').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#section').ejDropDownList('selectItemByValue', vm.SelectedSectionID);
            }
        }

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                var grid = angular.element('#Grid').ejGrid('instance');
                grid.ignoreOnExport.splice(grid.ignoreOnExport.indexOf('dataSource'), 1);
                this.export(dataservice.getURL() + 'Hianyzasok/ExcelExport/');
            }
        }

        function fromDateSelect() {
            getActualCoachesList(vm.SelectedSectionID);
        }

        function toDateSelect() {
            getActualCoachesList(vm.SelectedSectionID);
        }
    }
})();

