(function () {
    'use strict';

    angular
        .module('app.administration.trainingPlan.trainingPlanTemplate')
        .controller('TrainingPlanTemplateController', TrainingPlanTemplateController);

    TrainingPlanTemplateController.$inject = ['log', 'paramHelper', 'dataservice', '$timeout', '$q', '$rootScope', '$filter', 'DOMAIN', 'gettextCatalog', 'authenticationService', '$compile', '$scope', '$state', '$filter', '$scope', '$state'];
    /* @ngInject */
    function TrainingPlanTemplateController(log, paramHelper, dataservice, $timeout, $q, $rootScope, $filter, DOMAIN, gettextCatalog, authenticationService, $compile, $scope, $state) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('TrainingPlanTemplate');
        vm.youtubeIcon = DOMAIN.baseURL + DOMAIN.imgBaseUrl + 'youtube.png';
        // main datas
        vm.sorIndex = 0; // a gyakorlatok torléséhez kell egy elsödlegeskulcs sorIndex)
        vm.params = null;
        vm.loadPlan = false;
        vm.saveParams = null;
        vm.trainingSectionId = 0;
        vm.trainingSectionList = [];
        vm.trainingName = null;
        vm.trainingDescription = null;
        vm.trainingAgeGroupId = 0;
        vm.trainingAgeGroupList = [];

        vm.columnsMain = [
            {field: 'SorIndex', isPrimaryKey: true, visible: false},
            {field: 'EdzesFejId', visible: false},
            {field: 'GyakorlatId', visible: false},
            {field: 'Nev', headerText: gettextCatalog.getString('Name'), width: 200, textAlign: 'center', template: '#wtpropentemp'},
            {field: 'Mintakep', headerText: gettextCatalog.getString('Pattern'), width: 180, textAlign: 'center', template: '#imagetemplateGrid'},
            {field: 'Video', headerText: gettextCatalog.getString('Video'), width: 115, type: 'boolean', displayAsCheckBox: true, textAlign: 'center'},
            {field: 'GyakorlatKategoria', headerText: gettextCatalog.getString('GyakorlatKategoria'), width: 150, textAlign: 'center'},
            {field: 'IsmSzam', headerText: gettextCatalog.getString('IsmSzam'), width: 150, textAlign: 'center'},
            {field: 'GyakorlatTipus', headerText: gettextCatalog.getString('GyakorlatTipus'), width: 150, textAlign: 'center'},
            {field: 'Jellege', headerText: gettextCatalog.getString('individualTeam'), width: 150, textAlign: 'center'},
            {field: 'Testresz', headerText: gettextCatalog.getString('BodyParts'), width: 150, textAlign: 'center'},
        ];

        var MainGrid = new ej2.grids.Grid({
            dataSource: [],
            allowPaging: true,
            allowSorting: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsMain,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#GridTraining'), $scope);
                }
            },
            rowSelecting: function () {
                MainGrid.clearSelection();
            },
            checkBoxChange: function (args) {
                $rootScope.checkBoxChangeOnlyOne(args, MainGrid);
            }
        });
        MainGrid.appendTo('#GridTraining');

        // filter top
        vm.trainingSectionSelect = trainingSectionSelect;
        vm.trainingSectionDataBound = trainingSectionDataBound;
        vm.trainingAgeGroupSelect = trainingAgeGroupSelect;
        vm.trainingAgeGroupDataBound = trainingAgeGroupDataBound;

        // grid
        vm.compile = compile;
        vm.openTemplate = openTemplate;

        activate();
        function activate() {
            $q.all([authenticationService.getRight('UJEDZESTERV'), authenticationService.getRight('MODEDZESTERV'), authenticationService.getRight('MEGTEDZESTERV'), paramHelper.getParams([], ['trainingPlanCreate.trainingId', 'trainingPlanCreate.sectionId', 'trainingPlanCreate.ageGroupId'])])
                .then(function (results) {
                        var UJEDZESTERV = results[0];
                        var MODEDZESTERV = results[1];
                        var MEGTEDZESTERV = results[2];
                        vm.params = results[3];
                        if (MEGTEDZESTERV) {
                            if (vm.params && vm.params['trainingPlanCreate.trainingId'] > 0) {
                                LoadPlan(results[3]['trainingPlanCreate.trainingId']);
                                $timeout(function () {
                                    vm.modify = MODEDZESTERV;
                                    $scope.$apply();
                                });
                            } else if (vm.params && vm.params['trainingPlanCreate.trainingId'] === 0) {
                                $timeout(function () {
                                    vm.modify = UJEDZESTERV;
                                    $scope.$apply();
                                }, 0);
                            }
                            $timeout(function () {
                                getTrainingSections();
                            }, 0);
                        } else {
                            log.permissionError(true);
                        }
                    }
                );
        }

        function LoadPlan(trainingId) {
            vm.loadPlan = true;
            dataservice.GetTrainingPlanById(trainingId).then(function (data) {
                if (data.itemsList[0].SzakosztalyId) {
                    vm.trainingSectionId = data.itemsList[0].SzakosztalyId;
                }
                if (data.itemsList[0].KorosztalyId) {
                    vm.trainingAgeGroupId = data.itemsList[0].KorosztalyId;
                }
                vm.trainingName = data.itemsList[0].Nev;
                console.log('data.itemsList[0]', data.itemsList[0]);
                MainGrid.dataSource = $filter('addImagePathToObject')(data.itemsList[0].Gyakorlatok, 'Mintakep', DOMAIN.baseURL + DOMAIN.exerciseBaseUrl);
                vm.trainingDescription = data.itemsList[0].Leiras;
                vm.public = data.itemsList[0].Publikus;
                $timeout(function () {
                    $('.thumbnail-item').magnificPopup({
                        type: 'image'
                    });
                }, 1);
            });
        }

        // function Top
        function getTrainingSections() {
            dataservice.sectionDropDownList(0, false).then(function (data) {
                if (data.itemsList && data.itemsList.length > 1) {
                    data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                }
                vm.sectionList = data.itemsList;
                vm.trainingSectionList = data.itemsList;
            });
        }

        function trainingSectionDataBound() {
            if (!vm.trainingSectionList) {
                return;
            }
            if (vm.params != null && vm.params['trainingPlanCreate.sectionId'] != null) {
                angular.element('#trainingSectionDropDown').ejDropDownList('selectItemByValue', vm.params['trainingPlanCreate.sectionId']);
                vm.params['trainingPlanCreate.sectionId'] = null;
            } else if (vm.loadPlan === true) {
                angular.element('#trainingSectionDropDown').ejDropDownList('selectItemByValue', vm.trainingSectionId);
            } else {
                vm.trainingSectionId = vm.trainingSectionList[0].value;
                angular.element('#trainingSectionDropDown').ejDropDownList('selectItemByValue', vm.trainingSectionId);
            }
        }

        function trainingSectionSelect(args) {
            vm.trainingSectionId = args.value;
            getTrainingAgeGroups(args.value);
        }

        function getTrainingAgeGroups(trainingSectionId) {
            return dataservice.ageGroupDropDownList(trainingSectionId).then(function (data) {
                if (data.itemsList && data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                }
                vm.trainingAgeGroupList = data.itemsList;
            });
        }

        function trainingAgeGroupDataBound() {
            if (!vm.trainingAgeGroupList) {
                return;
            }
            if (vm.params && vm.params['trainingPlanCreate.ageGroupId'] != null) {
                angular.element('#trainingAgeGroupDropDown').ejDropDownList('selectItemByValue', vm.params['trainingPlanCreate.ageGroupId']);
                vm.params['trainingPlanCreate.ageGroupId'] = null;
            } else if (vm.loadPlan === true) {
                angular.element('#trainingAgeGroupDropDown').ejDropDownList('selectItemByValue', vm.trainingAgeGroupId);
                vm.loadPlan = false;
            } else {
                vm.trainingAgeGroupId = vm.trainingAgeGroupList[0].value;
                angular.element('#trainingAgeGroupDropDown').ejDropDownList('selectItemByValue', vm.trainingAgeGroupId);
            }
        }

        function trainingAgeGroupSelect(args) {
            vm.trainingAgeGroupId = args.value;
        }

        // grid events
        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }

        function openTemplate(exerciseId) {
            paramHelper.setParams([{'exercises.exerciseId': exerciseId}]);
            $state.go('administration.exercises.exercisesCreate');
        }
    }
})();
