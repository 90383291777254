(function () {
    'use strict';

    angular
        .module('app.other')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'other',
                config: {
                    title: 'Other',
                    url: '/other',
                    templateUrl: 'app/other/other.html'
                }
            }
        ];
    }
})();
