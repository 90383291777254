(function () {
    'use strict';

    angular
        .module('app.settings.userGroupsManagement')
        .controller('UserGroupsManagementController', UserGroupsManagementController);

    UserGroupsManagementController.$inject = ['$q', 'paramHelper', 'gettextCatalog', '$state', 'dataservice', 'logger', 'authenticationService', '$rootScope', '$scope', 'log', '$timeout'];
    /* @ngInject */
    function UserGroupsManagementController($q, paramHelper, gettextCatalog, $state, dataservice, logger, authenticationService, $rootScope, $scope, log, $timeout) {
        var vm = this; $rootScope.vm = vm;

        // Page title
        $rootScope.title = gettextCatalog.getString('UserGroups');

        vm.data = [];
        vm.typeList = [];
        vm.newgrouptitle = gettextCatalog.getString('AddNewUserGroup');
        vm.editgrouptitle = gettextCatalog.getString('EditUserGroup');

        vm.columns = [
            {field: 'Id', visible: false, isPrimaryKey: true},
            {field: 'Nev', headerText: gettextCatalog.getString('Name'), textAlign: 'left', validationRules: {required: true}, validationMessage: {required: gettextCatalog.getString('GroupNameRequrired')}},
            {field: 'Leiras', headerText: gettextCatalog.getString('Description'), textAlign: 'left'}
        ];

        vm.gridToolbar = ['printGrid', 'excelExport']; // 'add', 'edit', 'update', 'cancel', 'delete',
        vm.gridExcelExport = dataservice.getURL() + 'FelhasznaloCsoportokSelect/ExcelExport/';
        vm.deleteGroupEnabled = false;
        vm.setGroupRightsEnabled = false;
        vm.selectedGroupId = -1;
        vm.EndAdd = EndAdd;
        vm.EndEdit = EndEdit;
        vm.selectedRow = selectedRow;
        vm.DeleteGroup = DeleteGroup;
        vm.ManageRights = ManageRights;
        vm.EditGroup = EditGroup;
        vm.AddGroup = AddGroup;
        vm.newgroupCancel = newgroupCancel;
        vm.newgroupSave = newgroupSave;
        vm.editgroupCancel = editgroupCancel;
        vm.editgroupSave = editgroupSave;

        vm.MEGTFELHCSOP = false;
        vm.MODFELHCSOP = false;
        vm.UJFELHCSOP = false;
        vm.MEGTFELHJOG = false;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            allowDeleting: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowSelected: vm.selectedRow,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            $q.all([authenticationService.getRight('MEGTFELHCSOP'), authenticationService.getRight('MODFELHCSOP'), authenticationService.getRight('UJFELHCSOP'), authenticationService.getRight('MEGTFELHJOG')]).then(function (data) {
                $timeout(function () {
                    vm.MEGTFELHCSOP = data[0];
                    vm.MODFELHCSOP = data[1];
                    vm.UJFELHCSOP = data[2];
                    vm.MEGTFELHJOG = data[3];
                }, 10);
                if (data[0] || data[1] || data[2]) {
                    getUserGroupTypes().then(function () {
                        getUserGroupList();
                    });
                } else {
                    log.permissionError(true);
                }
            });
        }

        function AddGroup() {
            vm.newgroup = {};
            var dialogObj = angular.element('#newgroup').data('ejDialog');
            dialogObj.open();
        }

        function newgroupCancel() {
            var dialogObj = angular.element('#newgroup').data('ejDialog');
            dialogObj.close();
        }

        function newgroupSave() {
            var dialogObj = angular.element('#newgroup').data('ejDialog');
            if (vm.newgroup && vm.newgroup.Nev) {
                vm.newgroup.Id = -1;
                dialogObj.close();
                dataservice.addUserGroup(vm.newgroup).then(function () {
                    log.successMsg(gettextCatalog.getString('saveSuccess'));
                    vm.deleteGroupEnabled = false;
                    activate();
                });
            } else {
                dialogObj.close();
                log.errorMsg(gettextCatalog.getString('NewUserGroupMustHaveNameAndType'));
            }
        }

        function editgroupCancel() {
            var dialogObj = angular.element('#editgroup').data('ejDialog');
            dialogObj.close();
        }

        function editgroupSave() {
            var dialogObj = angular.element('#editgroup').data('ejDialog');
            dialogObj.close();
            dataservice.editUserGroup(vm.editgroup).then(function () {
                log.successMsg(gettextCatalog.getString('saveSuccess'));
                vm.deleteGroupEnabled = false;
                activate();
            });
        }

        function DeleteGroup() {
            swal({
                title: gettextCatalog.getString('Are you sure you want to delete the selected user group?'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes, delete this user group'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                closeOnConfirm: false,
                closeOnCancel: false
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    return dataservice.deleteUserGroup(vm.selectedGroupId).then(function () {
                        activate();
                    });
                }
            });
        }

        function ManageRights() {
            paramHelper.setParams([{'usergroupid': vm.selectedGroupId}]);
            $state.go('settings.userRights');
        }

        function EditGroup() {
            var dialogObj = angular.element('#editgroup').data('ejDialog');
            dialogObj.open();
        }

        function selectedRow(args) {
            vm.editgroup = args.data;
            vm.selectedGroupId = args.data.Id;
            vm.deleteGroupEnabled = args != null && vm.MODFELHCSOP;
            vm.setGroupRightsEnabled = args != null && vm.MEGTFELHJOG;
            $scope.$apply();
        }

        function getUserGroupTypes() {
            return dataservice.getUserGroupTypes().then(function (data) {
                vm.typeList = data.itemsList;
                angular.element('Grid').ejGrid({columns: vm.columns});
            });
        }

        function getUserGroupList() {
            dataservice.getUserGroupList().then(function (data) {
                vm.data = data.itemsList;
                grid.dataSource = vm.data;
            });
        }

        function EndEdit(args) {
            dataservice.editUserGroup(args.data).then(function () {
                getUserGroupList();
                log.successMsg(gettextCatalog.getString('saveSuccess'));
            });
        }

        function EndAdd(args) {
            args.data.Id = -1;
            dataservice.addUserGroup(args.data).then(function () {
                log.successMsg(gettextCatalog.getString('saveSuccess'));
            });
        }
    }
})();
