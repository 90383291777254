(function () {
    'use strict';

    angular
        .module('app.parentMonthlyAttendance')
        .controller('ParentMonthlyAttendanceController', ParentMonthlyAttendanceController);

    ParentMonthlyAttendanceController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', '$scope', '$filter'];
    /* @ngInject */
    function ParentMonthlyAttendanceController(log, dataservice, $q, $rootScope, gettextCatalog, $scope, $filter) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('parentMonthlyAttendance');

        // Create variables
        vm.dateValue = new Date();
        vm.athleteList = [];
        vm.athleteId = '';
        vm.data = [];
        vm.gridToolbar = ['printGrid'];
        vm.columns = [
            {
                field: 'SportoloNeve',
                headerText: gettextCatalog.getString('Athlete'),
                textAlign: 'left'
                //template: true,
                //templateID: '#teamlistcolumnTemplate1'
            },
            {
                field: 'Nap',
                headerText: gettextCatalog.getString('Day'),
                textAlign: 'left'
            },
            {
                field: 'Igazolt',
                headerText: gettextCatalog.getString('Igazolt'),
                textAlign: 'center',
                type: 'boolean'
            }
        ];

        // Public functions
        vm.saveDate = saveDate;
        vm.athleteDataBound = athleteDataBound;

        // Activate page
        activate();

        function activate() {
            var promises = [];
            $q.all(promises)
                .then(function () {
                    getAthletes();
                    parentsAthleteAttendanceList();
                })
                .catch(function () {
                    log.permissionError();
                });
        }

        // Get sections' list
        function getAthletes() {
            return dataservice.parentsAthletes().then(function (data) {
                vm.athleteList = data.itemsList;
            });
        }

        function athleteDataBound() {
            if (vm.athleteList.length === 1) {
                angular.element('#sectionId').ejDropDownList('selectItemByValue', vm.athleteList[0].value);
                vm.athleteId = vm.athleteList[0].value;
            }
        }

        function parentsAthleteAttendanceList() {
            return dataservice.parentsAthleteAttendanceList().then(function (data) {
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'Nap');
                vm.data = data.itemsList;
            });
        }

        function saveDate() {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            return dataservice.parentsAthleteAttendance(vm.athleteId, vm.dateValue).then(function () {
                log.successMsg('HianyzasBejelentve');
                parentsAthleteAttendanceList();
            });
        }
    }
})();
