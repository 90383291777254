(function () {
    'use strict';

    angular
        .module('app.core')
        .factory('authenticationService', authenticationService);

    authenticationService.$inject = ['localStorageService', 'moment', 'dataservice', '$rootScope', 'AUTH_EVENTS', '$q'];

    /* @ngInject */
    function authenticationService(localStorageService, moment, dataservice, $rootScope, AUTH_EVENTS, $q) {
        return {
            isAuthenticated: isAuthenticated,
            getPermission: getPermission,
            noEducationalFee: noEducationalFee,
            getAuthData: getAuthData,
            logout: logout,
            login: login,
            setSessionTimeOut: setSessionTimeOut,
            isSessionTimeOut: isSessionTimeOut,
            getSessionData: getSessionData,
            getLanguage: getLanguage,
            getRights: getRights,
            getRight: getRight,
            getAccessToken: getAccessToken
        };

        function isAuthenticated() {
            var authData = localStorageService.get('_authData');
            return !authData ? false : true;
        }

        function getAccessToken() {
            return localStorageService.get('_authData').access_token;
        }

        function getPermission() {
            if (localStorageService.get('_authData') && localStorageService.get('_authData').permission) {
                return localStorageService.get('_authData').permission;
            } else {
                return -1;
            }
        }

        function getRights(data) {
            return $q(function (resolve) {
                var accessRights = [];
                for (var i = 0; i < data.length; i++) {
                    accessRights.push({RightName: data[i], Allowed: false});
                }
                if (isAuthenticated()) {
                    for (var r = 0; r < accessRights.length; r++) {
                        for (var j = 0; j < getAuthData().accessRights.length; j++) {
                            if (data[r].indexOf(getAuthData().accessRights[j]) >= 0 && isAuthenticated()) {
                                accessRights[r].Allowed = true;
                            }
                        }
                    }
                }
                resolve(accessRights);
            });
        }

        function noEducationalFee() {
            dataservice.noEducationalFee().then(function (result) {
                localStorageService.set('noEducationalFee', result.retValue);
            });
        }

        function getRight(data) {
            return $q(function (resolve) {
                if (!isAuthenticated()) {
                    resolve(false);
                } else {
                    data = '"' + data + '"';
                    if (getAuthData().accessRights && getAuthData().accessRights.indexOf(data) >= 0) {
                        resolve(true);
                    }
                    resolve(false);
                }
            });
        }

        function getLanguage() {
            if (isAuthenticated()) {
                return localStorageService.get('_authData').language;
            } else if (isSessionTimeOut()) {
                return localStorageService.get('_sessionData').language;
            } else {
                return navigator.language || navigator.userLanguage;
            }
        }

        function pSetAuthData(user) {
            if (!user) {
                return false;
            }
            // át kell írni a szervert, hogy adja vissza ezeket is
            // valamint az access_token-t is menteni kell
            return localStorageService.set('_authData', {
                id: user.id,
                loginName: user.loginName,
                userFullName: user.userFullName,
                picture: user.picture,
                language: user.language,
                gridRecordPerPage: user.gridRecordPerPage,
                pwdExpiration: moment.utc(user.expiration).format(),
                accessRights: user.accessRights,
                permission: user.permission,
                access_token: user.access_token
            });
        }

        function login(loginName, password, language) {
            return dataservice.login(loginName, password, language)
                .then(function (data) {
                    if (angular.isDefined(data.data.access_token)) {
                        data.data.loginName = loginName;
                        data.data.language = language;
                        localStorageService.set(data.data.id + '.' + 'gridRecordPerPage', data.data.gridRecordPerPage);
                        if (pSetAuthData(data.data)) {
                            if (isSessionTimeOut() && loginName === getSessionData().loginName) {
                                $rootScope.$broadcast(AUTH_EVENTS.login, getSessionData().url, data.data.permission);
                            } else {
                                $rootScope.$broadcast(AUTH_EVENTS.login, '', data.data.permission);
                            }
                            pClearSessionData();
                            return true;
                        }
                    }
                    return false;
                }).catch(function () {
                    return false;
                });
        }

        function logout() {
            return dataservice.logout().then(function (data) {
                    if (angular.isDefined(data) && data.retValue) {
                        pClearAuthData();
                        $rootScope.$broadcast(AUTH_EVENTS.logout);
                        return true;
                    }
                    return false;
                }
            ).catch(function () {
                return false;
            });
        }

        function setSessionTimeOut(url) {
            if (isAuthenticated()) {
                var userInfo = getAuthData();
                localStorageService.set('_sessionData', {
                    loginName: userInfo.loginName,
                    picture: userInfo.picture,
                    language: userInfo.language,
                    url: url
                });
                logout();
            }
        }

        function isSessionTimeOut() {
            var sessionData = localStorageService.get('_sessionData');
            return !sessionData ? false : true;
        }

        function getSessionData() {
            return localStorageService.get('_sessionData');
        }

        function getAuthData() {
            return localStorageService.get('_authData');
        }

        function pClearSessionData() {
            return localStorageService.remove('_sessionData');
        }

        function pClearAuthData() {
            return localStorageService.remove('_authData', 'marMegnezte');
        }
    }
})();
