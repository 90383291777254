(function () {
    'use strict';

    angular
        .module('app.administration.monthlyAttendance.monthlyAttendanceReport')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.monthlyAttendance.monthlyAttendanceReport',
                config: {
                    url: '/monthlyAttendanceReport',
                    templateUrl: 'app/administration/monthlyAttendance/monthlyAttendanceReport/monthlyAttendanceReport.html',
                    controller: 'MonthlyAttendanceReportController',
                    controllerAs: 'vm',
                    title: 'MonthlyAttendanceReport',
                    settings: {
                        navId: 222,
                        level: 2,
                        order: 2,
                        orderTitle_hu: 'Havi jelenléti összesítő',
                        orderTitle_en: 'Monthly Attendance Sheet Report',
                        parentId: 2,
                        content: 'MonthlyAttendanceReport',
                        activatorPermission: '"MEGTHAVIJEL"'
                    }
                }
            }
        ];
    }
})();
