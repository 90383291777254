(function () {
    'use strict';

    angular
        .module('app.settings.schoolManagement.editSchool')
        .controller('editSchoolController', editSchoolController);

    editSchoolController.$inject = ['log', '$state', 'dataservice', 'authenticationService', '$q', 'gettextCatalog', '$rootScope', 'paramHelper', '$scope', 'logger'];
    /* @ngInject */
    function editSchoolController(log, $state, dataservice, authenticationService, $q, gettextCatalog, $rootScope, paramHelper, $scope) {
        var vm = this; $rootScope.vm = vm;
        vm.params = [];
        vm.ModifySchool = ModifySchool;

        // Page title
        $rootScope.title = gettextCatalog.getString('editSchool');

        activate();

        function activate() {
            authenticationService.getRight('UJISKOLA').then(function (results) {
                if (results !== true) {
                    log.permissionError();
                } else {
                    $q.all([paramHelper.getParams([], ['schoolManagement.editSchool'])]).then(function (results) {
                        vm.params = results[0]['schoolManagement.editSchool'];
                    });
                }
            });
        }

        function ModifySchool() {
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            return dataservice.editSchool(vm.params).then(function () {
                log.successMsg(gettextCatalog.getString('SchoolModified'));
                $state.go('settings.schoolManagement.schoolList');
            });
        }
    }
})();
