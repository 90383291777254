(function () {
    'use strict';

    angular
        .module('app.business.monthlyAttendanceArrears')
        .controller('MonthlyAttendanceArrearsController', MonthlyAttendanceArrearsController);

    MonthlyAttendanceArrearsController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'authenticationService', '$filter', '$compile', '$state', '$scope', 'paramHelper'];
    /* @ngInject */
    function MonthlyAttendanceArrearsController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $filter, $compile, $state, $scope, paramHelper) {
        var vm = this; $rootScope.vm = vm;

        $rootScope.title = gettextCatalog.getString('MonthlyAttendanceReportArrears');

        // Grid's variables
        vm.columns = [
            {field: 'Id', isPrimaryKey: true, visible: false},
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), width: 100},
            {field: 'EdzoNeve', headerText: gettextCatalog.getString('CoachName'), textAlign: 'center', width: 150, template: '#monthlyattendancearrearscolumnTemplate'},
            {field: 'Datum2', headerText: gettextCatalog.getString('Date'), textAlign: 'center', width: 130},
            {field: 'CsoportLetszam', headerText: gettextCatalog.getString('groupStaffNumbers'), textAlign: 'center', width: 120},
            {field: 'Erkezett', headerText: gettextCatalog.getString('Arrived'), textAlign: 'center', width: 60},
            {field: 'Tavozott', headerText: gettextCatalog.getString('Departed'), textAlign: 'center', width: 60},
            {field: 'Megjegyzes', headerText: gettextCatalog.getString('Comment'), textAlign: 'left', width: 200},
            {field: 'Edzo_Alairta', headerText: gettextCatalog.getString('CoachSigned'), textAlign: 'center', width: 100, type: 'boolean', displayAsCheckBox: true},
            {field: 'Szakosztalyvezeto_Alairta', headerText: gettextCatalog.getString('SectionLeaderSigned'), textAlign: 'center', width: 130, type: 'boolean', displayAsCheckBox: true},
            {field: 'utolsoKitoltottDatum', headerText: gettextCatalog.getString('ModifiedDate'), textAlign: 'center', width: 100}
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        vm.openCoach = openCoach;
        // Activate page
        activate();

        function activate() {
            var promises = [authenticationService.getRight('MEGTHAVIJELLEM')];
            $q.all(promises).then(function (results) {
                if (results[0] !== true) {
                    log.permissionError(true);
                } else {
                    getDatas();
                }
            });
        }

        function getDatas() {
            dataservice.getAnnualSeasonPlanArrears().then(function(data) {
                data.itemsList = $filter('dateYearMonthStringFilter')(data.itemsList, 'Datum2');
                grid.dataSource = data.itemsList;
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }
    }
})();
