(function () {
    'use strict';

    angular
        .module('app.messageCenter.newMessage')
        .controller('NewMessageController', NewMessageController);

    NewMessageController.$inject = ['$q', 'logger', '$rootScope', 'gettextCatalog', 'log', '$state', 'dataservice', '$scope', 'paramHelper', '$timeout', 'authenticationService'];
    /* @ngInject */
    function NewMessageController($q, logger, $rootScope, gettextCatalog, log, $state, dataservice, $scope, paramHelper, $timeout, authenticationService) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('NewMessage');

        vm.toolbarClick = toolbarClick;
        vm.toolbarPopupClick = toolbarPopupClick;
        vm.Message = '';
        vm.LIMITEDUSER = false;
        vm.SPORTOLOUSER = false;
        vm.SZULOUSER = false;

        vm.showRecipients = showRecipients;
        vm.showDirectory = showDirectory;
        vm.clearRecipients = clearRecipients;
        vm.getUsersUserGroup = getUsersUserGroup;
        vm.uncheckAll = uncheckAll;

        vm.RecipientsIds = [];
        vm.RecipientsNames = '';
        vm.RecipientsNamesArr = [];
        vm.recipientsText = gettextCatalog.getString('recipients');
        vm.clearText = gettextCatalog.getString('clear');
        vm.showRecipientsText = gettextCatalog.getString('showRecipients');

        vm.parentsCheckboxChanged = parentsCheckboxChanged;
        vm.athleteCheckboxChanged = athleteCheckboxChanged;
        vm.userCheckboxChanged = userCheckboxChanged;
        vm.usersGroupCheckboxChanged = usersGroupCheckboxChanged;
        vm.sectionsCheckboxChanged = sectionsCheckboxChanged;
        vm.teamsCheckboxChanged = teamsCheckboxChanged;
        vm.teamParentsCheckboxChanged = teamParentsCheckboxChanged;
        vm.ageGroupsCheckboxChanged = ageGroupsCheckboxChanged;
        vm.ageGroupsParentsCheckboxChanged = ageGroupsParentsCheckboxChanged;

        vm.emptyGroupList = '';

        vm.searchUser = searchUser;
        vm.searchAthlete = searchAthlete;
        vm.searchParent = searchParent;
        vm.searchUserGroup = searchUserGroup;
        vm.searchSection = searchSection;
        vm.searchTeamsAthletes = searchTeamsAthletes;
        vm.searchTeamsParents = searchTeamsParents;
        vm.searchAgeGroupAthletes = searchAgeGroupAthletes;
        vm.searchAgeGroupParents = searchAgeGroupParents;

        vm.usersList = [];
        vm.athletesList = [];
        vm.parentsList = [];
        vm.usersGroupList = [];
        vm.sectionsList = [];
        vm.teamsAthletesList = [];
        vm.teamParentsList = [];
        vm.ageGroupsList = [];
        vm.ageGroupsParentsList = [];

        vm.usersListAll = [];
        vm.athletesListAll = [];
        vm.parentsListAll = [];
        vm.usersGroupListAll = [];
        vm.sectionsListAll = [];
        vm.teamsAthletesListAll = [];
        vm.teamParentsListAll = [];
        vm.ageGroupsListAll = [];
        vm.ageGroupsParentsListAll = [];

        activate();

        function searchByName(arr, name) {
            if (!name || name.length == 0) {
                return arr;
            }
            var result = [];
            arr.forEach(function (user) {
                if (user.text.toUpperCase().indexOf(name.toUpperCase()) !== -1) {
                    result.push(user);
                }
            });
            return result;
        }

        function searchUser() {
            angular.element('#chkSelectAllUsers').ejCheckBox({checked: false});
            vm.usersList = searchByName(vm.usersListAll, vm.searchUserParam);
        }

        function searchAthlete() {
            angular.element('#chkSelectAllAthletes').ejCheckBox({checked: false});
            vm.athletesList = searchByName(vm.athletesListAll, vm.searchAthleteParam);
        }

        function searchParent() {
            angular.element('#chkSelectAllParents').ejCheckBox({checked: false});
            vm.parentsList = searchByName(vm.parentsListAll, vm.searchParentParam);
        }

        function searchUserGroup() {
            angular.element('#chkSelectAllUserGroup').ejCheckBox({checked: false});
            vm.usersGroupList = searchByName(vm.usersGroupListAll, vm.searchUserGroupParam);
        }

        function searchSection() {
            angular.element('#chkSelectAllSections').ejCheckBox({checked: false});
            vm.sectionsList = searchByName(vm.sectionsListAll, vm.searchSectionParam);
        }

        function searchTeamsAthletes() {
            angular.element('#chkSelectAllTeams').ejCheckBox({checked: false});
            vm.teamsAthletesList = searchByName(vm.teamsAthletesListAll, vm.searchTeamsAthletesParam);
        }

        function searchTeamsParents() {
            angular.element('#chkSelectAllTeamParents').ejCheckBox({checked: false});
            vm.teamParentsList = searchByName(vm.teamParentsListAll, vm.searchTeamsParentParam);
        }

        function searchAgeGroupAthletes() {
            angular.element('#chkSelectAllAgeGroups').ejCheckBox({checked: false});
            vm.ageGroupsList = searchByName(vm.ageGroupsListAll, vm.searchAgeGroupsAthletesParam);
        }

        function searchAgeGroupParents() {
            angular.element('#chkSelectAllAgeGroupsParents').ejCheckBox({checked: false});
            vm.ageGroupsParentsList = searchByName(vm.ageGroupsParentsListAll, vm.searchAgeGroupsParentParam);
        }

        function toolbarClick(params) {
            switch (params.currentTarget.id) {
                case '1':
                    SendMessage();
                    break;
                case '2':
                    $state.go('messageCenter.messageCenter');
                    break;
            }
        }

        function toolbarPopupClick(params) {
            clearRecipients();
            switch (params.currentTarget.id) {
                case '1':
                    //var tab = angular.element('#defaultTab').ejTab('selectedItemIndex');
                    for (var t = 0; t <= 8; t++) {
                        recipientsSettings(t);
                    }
                    //uncheckAll();
                    break;
                case '2':
                    var dialogObj = angular.element('#directory').data('ejDialog');
                    dialogObj.close();
                    break;
            }
        }

        function SendMessage() {
            showEmptyGroupAlert();
            var hiba = '';
            if (vm.RecipientsIds.length === 0) {
                hiba += 'Legalább egy címzettet adjon meg!';
            }
            if (vm.Message.length === 0) {
                hiba += '<br>Adjon meg üzenetet!';
            }
            if (hiba.length > 0) {
                log.errorMsg(hiba);
                return;
            }
            vm.Obj = {};
            vm.Obj.Ids = vm.RecipientsIds;
            vm.Obj.Message = vm.Message;
            dataservice.sendMessage(vm.Obj).then(function () {
                log.successMsg('SendSuccessful');
                $state.go('messageCenter.messageCenter');
            });
        }

        function getUserIdsOfGroup(usersof, id, name) {
            var idlist = [];
            idlist.push(id);
            return dataservice.getUserIdsOfGroup(usersof, idlist).then(function (data) {
                if (data.data.length == 0) {
                    vm.emptyGroupList += name + '<br>';
                    return false;
                }
                for (var i = 0; i < data.data.length; i++) {
                    vm.RecipientsIds.push(data.data[i]);
                }
                return name;
            });
        }

        function addRecipientsFromGroup(group, temp) {
            for (var i = 0; i < temp.length; i++) {
                var t = temp[i].data;
                $q.all([getUserIdsOfGroup(group, temp[i].data.value, temp[i].data.text)]).then(function (results) {
                    if (results != false && noDuplicates(results)) {
                        if (vm.RecipientsNames.length > 0) {
                            vm.RecipientsNames += '; ';
                        }
                        vm.RecipientsNames += results;
                        vm.RecipientsNamesArr.push(results);
                    }
                });
            }
        }

        function addRecipientNames(items) {
            angular.forEach(items, function(value) {
                vm.RecipientsIds.push(value.value);
                if (noDuplicates(value.text)) {
                    if (vm.RecipientsNames.length > 0) {
                        vm.RecipientsNames += '; ';
                    }
                    vm.RecipientsNames += value.text;
                    vm.RecipientsNamesArr.push(value.text);
                }
            });
        }

        function recipientsSettings(tab) {
            var itemstemp = [];
            switch (tab) {
                case 0:
                    itemstemp = angular.element('#usersListBox').ejListBox('getCheckedItems');
                    addRecipientNames(itemstemp);
                    break;
                case 1:
                    itemstemp = angular.element('#athletesListBox').ejListBox('getCheckedItems');
                    addRecipientNames(itemstemp);
                    break;
                case 2:
                    itemstemp = angular.element('#parentsListBox').ejListBox('getCheckedItems');
                    addRecipientNames(itemstemp);
                    break;
                case 3:
                    itemstemp = angular.element('#usersGroupListBox').ejListBox('getCheckedItems');
                    if (itemstemp.length > 0) {
                        addRecipientsFromGroup('usergroupmember', itemstemp);
                    }
                    break;
                case 4:
                    itemstemp = angular.element('#sectionsListBox').ejListBox('getCheckedItems');
                    if (itemstemp.length > 0) {
                        addRecipientsFromGroup('sectionmembers', itemstemp);
                    }
                    break;
                case 5:
                    itemstemp = angular.element('#teamsAthletesListBox').ejListBox('getCheckedItems');
                    if (itemstemp.length > 0) {
                        addRecipientsFromGroup('teammembers', itemstemp);
                    }
                    break;
                case 6:
                    itemstemp = angular.element('#teamParentsListBox').ejListBox('getCheckedItems');
                    if (itemstemp.length > 0) {
                        addRecipientsFromGroup('teammembersparents', itemstemp);
                    }
                    break;
                case 7:
                    itemstemp = angular.element('#ageGroupsListBox').ejListBox('getCheckedItems');
                    if (itemstemp.length > 0) {
                        addRecipientsFromGroup('agegroupsmembers', itemstemp);
                    }
                    break;
                case 8:
                    itemstemp = angular.element('#ageGroupsParentsListBox').ejListBox('getCheckedItems');
                    if (itemstemp.length > 0) {
                        addRecipientsFromGroup('agegroupsmembersparents', itemstemp);
                    }
                    break;
            }
            $scope.$apply();
            var dialogObj = angular.element('#directory').data('ejDialog');
            dialogObj.close();
        }

        function clearRecipients() {
            vm.RecipientsIds = [];
            vm.RecipientsNames = '';
            vm.RecipientsNamesArr = [];
            vm.emptyGroupList = '';
            $timeout(function () {
                $scope.$apply();
            }, 10);
        }

        function noDuplicates(newName) {
            for (var i = 0; i < vm.RecipientsNamesArr.length; i++) {
                if (vm.RecipientsNamesArr[i] == newName) {
                    return false;
                }
            }
            return true;
        }

        function showRecipients() {
            var obj = {};
            obj.Ids = vm.RecipientsIds;
            obj.Message = '-';
            dataservice.getNamesByIds(obj)
                .then(function (data) {
                    var txt = '';
                    for (var i = 0; i < data.data.length; i++) {
                        txt += data.data[i];
                        if (i < data.data.length - 1 && data.data.length > 1) {
                            txt += ', ';
                        }
                    }
                    swal({
                        title: gettextCatalog.getString('recipients'),
                        text: txt,
                        type: 'info',
                        showCancelButton: false,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: gettextCatalog.getString('Ok')
                    });
                });
        }

        function showEmptyGroupAlert() {
            if (vm.emptyGroupList.length > 0) {
                log.errorMsg(gettextCatalog.getString('ThereAreNoUsersInTheSelectedGroup') + '<br>' + vm.emptyGroupList);
            }
        }

        function uncheckAll() {
            clearRecipients();
            angular.element('#usersListBox').ejListBox('uncheckAll');
            angular.element('#athletesListBox').ejListBox('uncheckAll');
            angular.element('#parentsListBox').ejListBox('uncheckAll');
            angular.element('#usersGroupListBox').ejListBox('uncheckAll');
            angular.element('#sectionsListBox').ejListBox('uncheckAll');
            angular.element('#teamsAthletesListBox').ejListBox('uncheckAll');
            angular.element('#teamParentsListBox').ejListBox('uncheckAll');
            angular.element('#ageGroupsListBox').ejListBox('uncheckAll');
            angular.element('#ageGroupsParentsListBox').ejListBox('uncheckAll');
        }

        function showDirectory() {
            var dialogObj = angular.element('#directory').data('ejDialog');
            dialogObj.open();
        }

        function getUserId() {
            getUsersUserGroup(authenticationService.getAuthData().id);
        }

        function getUsersUserGroup(userid) {
            dataservice.UsersUserGroup(userid)
                .then(function (data) {
                    vm.LIMITEDUSER = false;
                    for (var i = 0; i < data.data.length; i++) {
                        switch (data.data[i]) {
                            case 'SZULO':
                                vm.SZULOUSER = true;
                                vm.LIMITEDUSER = true;
                                break;
                            case 'SPORTOLO':
                                vm.SPORTOLOUSER = true;
                                vm.LIMITEDUSER = true;
                                break;
                        }
                    }
                    return data.data;
                });
        }

        function activate() {
            $q.all([authenticationService.getRight('UZENETIR')]).then(function (results) {
                vm.UZENETIR = results[0];
                if (!vm.UZENETIR) {
                    log.permissionError(true);
                }
            });

            getUserId();
            vm.toolbarItems = [
                {empid: 1, tooltiptext: gettextCatalog.getString('msgSend'), spriteCss: 'msgtools newSend'},
                {empid: 2, tooltiptext: gettextCatalog.getString('msgRecieved'), spriteCss: 'msgtools msgrcvd'},
            ];

            vm.toolbarPopupItems = [
                {empid: 1, tooltiptext: gettextCatalog.getString('msgOk'), spriteCss: 'msgtools Accept'},
                {empid: 2, tooltiptext: gettextCatalog.getString('msgCancel'), spriteCss: 'msgtools Back'}
            ];
            // ÖSSZES
            dataservice.Cimjegyzek().then(function (data) {
                data.data.lista.forEach(function (element) {
                    switch (element.usersof){
                        case 'users':
                            vm.usersList = element.itemsList;
                            vm.usersListAll = element.itemsList;
                            break;
                        case 'athletes':
                            vm.athletesList = element.itemsList;
                            vm.athletesListAll = element.itemsList;
                            break;
                        case 'parents':
                            vm.parentsList = element.itemsList;
                            vm.parentsListAll = element.itemsList;
                            break;
                        case 'usergroups':
                            vm.usersGroupList = element.itemsList;
                            vm.usersGroupListAll = element.itemsList;
                            break;
                        case 'sections':
                            vm.sectionsList = element.itemsList;
                            vm.sectionsListAll = element.itemsList;
                            break;
                        case 'teamsathletes':
                            vm.teamsAthletesList = element.itemsList;
                            vm.teamsAthletesListAll = element.itemsList;
                            break;
                        case 'teamsparents':
                            vm.teamParentsList = element.itemsList;
                            vm.teamParentsListAll = element.itemsList;
                            break;
                        case 'agegroupsathletes':
                            vm.ageGroupsList = element.itemsList;
                            vm.ageGroupsListAll = element.itemsList;
                            break;
                        case 'agegroupsparents':
                            vm.ageGroupsParentsList = element.itemsList;
                            vm.ageGroupsParentsListAll = element.itemsList;
                            break;
                    }
                });
            });
            $q.all([paramHelper.getParams([], ['newMessage.Message', 'newMessage.OriginalSenderId', 'newMessage.OriginalSenderName'])]).then(function(result) {
                vm.Message = result[0]['newMessage.Message'];
                vm.RecipientsIds = [];
                vm.RecipientsNames = '';
                vm.RecipientsNamesArr = [];
                if (result[0]['newMessage.OriginalSenderId'] > 0) {
                    vm.RecipientsIds.push(result[0]['newMessage.OriginalSenderId']);
                }
                vm.RecipientsNames = result[0]['newMessage.OriginalSenderName'];
            });
        }

        function userCheckboxChanged(args) {
            for (var i = 0; i < vm.usersList.length; i++) {
                if (args.value) {
                    angular.element('#usersListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#usersListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }

        function athleteCheckboxChanged(args) {
            for (var i = 0; i < vm.athletesList.length; i++) {
                if (args.value) {
                    angular.element('#athletesListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#athletesListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }

        function parentsCheckboxChanged(args) {
            for (var i = 0; i < vm.parentsList.length; i++) {
                if (args.value) {
                    angular.element('#parentsListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#parentsListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }

        function usersGroupCheckboxChanged(args) {
            for (var i = 0; i < vm.usersGroupList.length; i++) {
                if (args.value) {
                    angular.element('#usersGroupListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#usersGroupListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }

        function sectionsCheckboxChanged(args) {
            for (var i = 0; i < vm.sectionsList.length; i++) {
                if (args.value) {
                    angular.element('#sectionsListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#sectionsListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }

        function teamsCheckboxChanged(args) {
            for (var i = 0; i < vm.teamsAthletesList.length; i++) {
                if (args.value) {
                    angular.element('#teamsAthletesListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#teamsAthletesListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }

        function teamParentsCheckboxChanged(args) {
            for (var i = 0; i < vm.teamParentsList.length; i++) {
                if (args.value) {
                    angular.element('#teamParentsListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#teamParentsListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }

        function ageGroupsCheckboxChanged(args) {
            for (var i = 0; i < vm.ageGroupsList.length; i++) {
                if (args.value) {
                    angular.element('#ageGroupsListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#ageGroupsListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }

        function ageGroupsParentsCheckboxChanged(args) {
            for (var i = 0; i < vm.ageGroupsParentsList.length; i++) {
                if (args.value) {
                    angular.element('#ageGroupsParentsListBox').ejListBox('checkItemByIndex', i);
                } else {
                    angular.element('#ageGroupsParentsListBox').ejListBox('uncheckItemByIndex', i);
                }
            }
        }
    }
})();
