(function () {
    'use strict';

    angular .module('app.administration.annualSeasonPlan.annualSeasonPlanReportPage5')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.annualSeasonPlan.annualSeasonPlanReportPage5',
                config: {
                    url: '/annualSeasonPlanReportPage5',
                    templateUrl: 'app/administration/annualSeasonPlan/annualSeasonPlanReportPage5/annualSeasonPlanReportPage5.html',
                    controller: 'AnnualSeasonPlanReportPage5Controller',
                    controllerAs: 'vm',
                    title: 'AnnualSeasonPlan'
                }
            }
        ];
    }
})();
