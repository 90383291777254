(function () {
    'use strict';

    angular
        .module('app.reports.annualPresenceReports.annualPresenceReportsBySections')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.annualPresenceReports.annualPresenceReportsBySections',
                config: {
                    url: '/annualPresenceReportsBySections',
                    templateUrl: 'app/reports/annualPresenceReports/annualPresenceReportsBySections/annualPresenceReportsBySections.html',
                    controller: 'AnnualPresenceReportsBySectionsController',
                    controllerAs: 'vm',
                    title: 'AnnualPresenceReportsBySections',
                    settings: {
                        navId: 33234,
                        level: 3,
                        order: 3,
                        orderTitle_hu: 'Éves jelenléti kimutatás szakosztályonként',
                        orderTitle_en: 'Annual Attendance Reports by Sections',
                        parentId: 224,
                        content: 'AnnualPresenceReportsBySections',
                        activatorPermission: '"KMEVESJELEDZO"'
                    }
                }
            }
        ];
    }
})();
