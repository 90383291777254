(function () {
    'use strict';

    angular
        .module('app.athletes.ageGroups.ageGroupMembersList')
        .controller('AgeGroupMembersListController', AgeGroupMembersList);
    AgeGroupMembersList.$inject = ['log', '$state', 'dataservice', 'authenticationService', '$q', '$window', '$filter', '$scope', 'gettextCatalog', 'paramHelper', '$compile', '$rootScope', '$cookies'];
    /* @ngInject */
    function AgeGroupMembersList(log, $state, dataservice, authenticationService, $q, $window, $filter, $scope, gettextCatalog, paramHelper, $compile, $rootScope) {
        var vm = this; $rootScope.vm = vm;

        // Page title
        $rootScope.title = gettextCatalog.getString('AgeGroupMembersList');

        // Dropdownlist's sources
        vm.ageGroupList = [];
        vm.ageGroupMembersList = [];

        vm.columns = [
            {field: 'Nev', headerText: gettextCatalog.getString('Name'), textAlign: 'left', template: '#aggrmelitemplateDataColumn1', width: 150},
            {field: 'SzuletesiIdo', headerText: gettextCatalog.getString('BirthDate'), textAlign: 'left', width: 100},
            {field: 'EdzoNeve', headerText: gettextCatalog.getString('CoachsName'), textAlign: 'left', template: '#aggrmelitemplateDataColumn2', width: 150},
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), textAlign: 'left', width: 100},
            {field: 'Tagdij', headerText: gettextCatalog.getString('PayedSportServiceFee'), textAlign: 'center', width: 75},
            {field: 'Oktatasidij', headerText: gettextCatalog.getString('PayedEducationalFee'), textAlign: 'center', width: 75},
            {field: 'Megjegyzes', headerText: gettextCatalog.getString('Comment'), textAlign: 'center', width: 75}
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowSelection: false,
            allowExcelExport: true,
            allowTextWrap: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            },
            rowSelecting: function () {
                grid.clearSelection();
            },
            checkBoxChange: function (args) {
                $rootScope.checkBoxChangeOnlyOne(args, grid);
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Publikus funkciók
        vm.goToAgeGroupChange = goToAgeGroupChange;
        vm.goToNewAthlete = goToNewAthlete;
        vm.goToNewUser = goToNewUser;

        activate();

        function activate() {
            $q.all([authenticationService.getRight('MEGTKOROKEZ'), authenticationService.getRight('MODKOROKEZ')]).then(function (results) {
                if (results[0] !== true) {
                    log.permissionError(true);
                } else {
                    vm.MODKOROKEZ = results[1];
                    $q.all([paramHelper.getParams(['ageGroups.section', 'ageGroups.ageGroup']), authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                        vm.korosztaly = results[0]['ageGroups.ageGroup'];
                        vm.szakosztaly = results[0]['ageGroups.section'];
                        vm.MEGTSPORTLIST = results[1];
                        getAgeGroupMembersList();
                    });
                }
            });
        }

        function getAgeGroupMembersList() {
            return dataservice.getAgeGroupMembersList(vm.szakosztaly, vm.korosztaly)
                .then(function (data) {
                    grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'SzuletesiIdo');
                });
        }

        function goToAgeGroupChange() {
            authenticationService.getRight('MEGTKORVALT').then(function (results) {
                if (results) {
                    $state.go('settings.ageGroupChange');
                } else {
                    log.permissionError();
                }
            });
        }

        function goToNewUser(edzoId) {
            paramHelper.setParams([{'newUser.userId': edzoId}]);
            $state.go('settings.userManagement.modifyUser');
        }

        function goToNewAthlete(Id) {
            if (vm.MEGTSPORTLIST) {
                paramHelper.setParams([{'athleteModify.sportoloId': Id}, {'athleteNew.selectedSectionId': vm.szakosztaly}, {'athleteNew.selectedAgeGroupId': vm.korosztaly}]);
                $state.go('athletes.athletesManagement.athleteModify.dataSheet');
            } else {
                log.permissionError();
            }
        }
    }
})();
