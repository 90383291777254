(function () {
    'use strict';

    angular
        .module('app.settings.usersBelongingToAthletes')
        .controller('UsersBelongingToAthletesController', UsersBelongingToAthletesController);

    UsersBelongingToAthletesController.$inject = ['logger', 'log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'authenticationService', '$compile', '$scope', 'paramHelper', '$state'];

    function UsersBelongingToAthletesController(logger, log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $compile, $scope, paramHelper, $state) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('UsersBelongingToAthletes');

        activate();

        vm.columns = [
            {field: 'UserName', headerText: gettextCatalog.getString('UserName'), textAlign: 'left', template: '#usbetoattemplateDataColumn1'},
            {field: 'UserTeljesNev', headerText: gettextCatalog.getString('UserFullName'), textAlign: 'left'},
            {field: 'szerep', headerText: gettextCatalog.getString('szerep'), textAlign: 'left'},
            {field: 'csoport', headerText: gettextCatalog.getString('User group'), textAlign: 'left'},
            {field: 'szakosztaly', headerText: gettextCatalog.getString('Section'), textAlign: 'left'},
            {field: 'korosztaly', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'left'},
            {field: 'SportoloNeve', headerText: gettextCatalog.getString('SportoloNeve'), textAlign: 'left', template: '#usbetoattemplateDataColumn2'},
            {field: 'unlinkUserFromAthlete', headerText: gettextCatalog.getString('unlinkUserFromAthlete'), textAlign: 'left', template: '#usbetoattemplateDataColumn3'}
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowTextWrap: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            },
            rowSelecting: function () {
                grid.clearSelection();
            },
            checkBoxChange: function (args) {
                $rootScope.checkBoxChangeOnlyOne(args, grid);
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };
        vm.sectionId = 0;
        vm.sectionIdValue = undefined;
        vm.ageGroupId = 0;
        vm.ageGroupIdValue = undefined;
        vm.sectionList = [];
        vm.ageGroupList = [];
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.unlinkUserFromAthlete = unlinkUserFromAthlete;
        vm.openUser = openUser;
        vm.openAthlete = openAthlete;
        vm.getParentAndAthleteUsers = getParentAndAthleteUsers;

        function activate() {
            $q.all([authenticationService.getRight('SPTARTMEGT'), paramHelper.getParams([], ['usersBelongingToAthletes.SzakosztalyId', 'usersBelongingToAthletes.KorcsoportId'])]).then(function (result) {
                if (result[0] !== true) {
                    log.permissionError(true);
                } else {
                    getSections();
                }
                if (result[1]['usersBelongingToAthletes.SzakosztalyId']) {
                    vm.sectionId = result[1]['usersBelongingToAthletes.SzakosztalyId'];
                }
                if (result[1]['usersBelongingToAthletes.KorcsoportId']) {
                    vm.ageGroupId = result[1]['usersBelongingToAthletes.KorcsoportId'];
                }
            });
        }

        function getSections() {
            dataservice.sectionDropDownList().then(function (data) {
                vm.sectionList = data.itemsList;
            });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                vm.sectionId = vm.sectionList[0].value;
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        function sectionSelect(args) {
            vm.sectionId = args.value;
            getAgeGroups();
        }

        function getAgeGroups() {
            dataservice.ageGroupDropDownList(vm.sectionId).then(function (data) {
                vm.ageGroupList = data.itemsList;
                if (data.itemsList.length > 1) {
                    vm.ageGroupList.unshift({value: -1, text: gettextCatalog.getString('All')});
                }
            });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupId);
            }
        }

        function ageGroupSelect(args) {
            vm.ageGroupId = args.value;
        }

        function getParentAndAthleteUsers() {
            paramHelper.setParams([
                {'usersBelongingToAthletes.SzakosztalyId': vm.sectionId},
                {'usersBelongingToAthletes.KorosztalyId': vm.ageGroupId},
            ]);
            dataservice.getParentAndAthleteUsers(vm.sectionId, vm.ageGroupId)
                .then(function (data) {
                    grid.dataSource = data.itemsList;
                });
        }

        function unlinkUserFromAthlete(userid, sportoloid, szerep) {
            if (szerep == 0) {
                swal({
                    title: gettextCatalog.getString('Are you sure you want to unlink and delete the selected user?'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('Yes'),
                    cancelButtonText: gettextCatalog.getString('Cancel'),
                }).then(function (isConfirm) {
                    if (isConfirm.value) {
                        return dataservice.unlinkUserFromAthlete(userid, sportoloid).then(function () {
                            log.successMsg(gettextCatalog.getString('UserUnlinkedAndDeleted'));
                            getParentAndAthleteUsers();
                        });
                    }
                });
            } else {
                swal({
                    title: gettextCatalog.getString('Are you sure you want to unlink the selected user?'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('Yes'),
                    cancelButtonText: gettextCatalog.getString('Cancel'),
                }).then(function (isConfirm) {
                    if (isConfirm.value) {
                        return dataservice.unlinkUserFromAthlete(userid, sportoloid).then(function () {
                            log.successMsg(gettextCatalog.getString('UserUnlinked'));
                            getParentAndAthleteUsers();
                        });
                    }
                });
            }
        }

        function openUser(userid) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': userid},
                    {'usersBelongingToAthletes.SzakosztalyId': vm.sectionId},
                    {'usersBelongingToAthletes.KorosztalyId': vm.ageGroupId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function openAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id},
                    {'usersBelongingToAthletes.SzakosztalyId': vm.sectionId},
                    {'usersBelongingToAthletes.KorosztalyId': vm.ageGroupId}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }
    }
})();
