(function () {
    'use strict';

    angular
        .module('app.login')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$q', 'dataservice', 'logger', 'authenticationService',
        '$state', '$rootScope', 'LANG_EVENTS', 'DOMAIN', 'paramHelper'
    ];
    /* @ngInject */
    function LoginController($q, dataservice, logger, authenticationService, $state, $rootScope, LANG_EVENTS, DOMAIN, paramHelper) {
        var vm = this; $rootScope.vm = vm;
        vm.title = 'Bejelentkezés';
        vm.loginName = '';
        vm.password = '';
        vm.defaultLang = '';
        vm.image = DOMAIN.baseURL + DOMAIN.userImage;
        vm.isAuthenticated = authenticationService.isAuthenticated();
        vm.sessionTimeout = false;
        vm.unknownUser = false;
        vm.languageTemplate = '<img src=\"${icon}\" alt=\"lang\" height=\"20px\" width=\"20px\"/>' +
            '<span style=\"padding-left: 10px; vertical-align: middle\">${text}</span>';

        // Events
        vm.changeLanguage = changeLanguage;
        vm.changeLoginName = changeLoginName;
        vm.login = login;
        vm.loginFocusOut = loginFocusOut;

        activate();

        function activate() {
            if (authenticationService.isAuthenticated()) {
                $state.go('dashboard');
                return;
            }
            var promises = [getAvailableLanguages(), loadSessionData()];
            $q.all(promises).then(function () {
                angular.element('#availableLang').ejDropDownList({dataSource: vm.availableLanguages});
                if (!authenticationService.isSessionTimeOut()) {
                    angular.element('#availableLang').ejDropDownList('setSelectedValue', vm.defaultLang);
                } else {
                    angular.element('#availableLang').ejDropDownList('setSelectedValue', authenticationService.getLanguage());
                }
            });
        }

        function getAvailableLanguages() {
            var browserLang = navigator.language || navigator.userLanguage;
            authenticationService.noEducationalFee();
            return dataservice.getAvailableLanguages().then(function (data) {
                data = data.itemsList;
                vm.availableLanguages = data;
                for (var i = 0; i < vm.availableLanguages.length; i++) {
                    if (browserLang.toLowerCase().indexOf(vm.availableLanguages[i].value.toLowerCase()) >= 0) {
                        vm.defaultLang = vm.availableLanguages[i].value;
                    }
                }
                if (vm.defaultLang === '') {
                    vm.defaultLang = data.d.defLanguage;
                }
                return vm.availableLanguages;
            });
        }

        function loadSessionData() {
            if (!authenticationService.isSessionTimeOut()) {
                return;
            }
            var user = authenticationService.getSessionData();
            vm.image = user.picture;
            vm.sessionTimeout = true;
            vm.loginName = user.loginName;
        }

        function login() {
            paramHelper.setUserNameParams(vm.loginName, [{'Login.Language': angular.element('#availableLang').ejDropDownList('getSelectedValue')}]);
            authenticationService.login(vm.loginName, vm.password, angular.element('#availableLang').ejDropDownList('getSelectedValue')).then(function (result) {
                if (!result) {
                    angular.element('#wrongUnameOrPwd').fadeIn('medium');
                    angular.element('#loginName').addClass('error');
                    angular.element('#password').addClass('error');
                    vm.unknownUser = true;
                }
            });
        }

        function changeLanguage(args) {
            paramHelper.setUserNameParams(vm.loginName, [{'Login.Language': angular.element('#availableLang').ejDropDownList('getSelectedValue')}]);
            $rootScope.$broadcast(LANG_EVENTS.changeLanguage, args.value);
            vm.frmLogin.$setPristine();
        }

        function changeLoginName() {
            if (vm.unknownUser) {
                angular.element('#wrongUnameOrPwd').css('display', 'none');
                angular.element('#loginName').removeClass('error');
                angular.element('#password').removeClass('error');
                vm.unknownUser = false;
            }
            if (authenticationService.isSessionTimeOut() && vm.loginName !== authenticationService.getSessionData().loginName) {
                vm.image = DOMAIN.baseURL + DOMAIN.userImage;
                vm.sessionTimeout = false;
            } else if (authenticationService.isSessionTimeOut() && vm.loginName === authenticationService.getSessionData().loginName) {
                vm.image = authenticationService.getSessionData().picture;
                vm.sessionTimeout = true;
            }
        }

        function loginFocusOut() {
            paramHelper.getUserNameParams(vm.loginName, [], ['Login.Language']).then(function(args) {
                if (args['Login.Language']) {
                    vm.defaultLang = args['Login.Language'];
                    angular.element('#availableLang').ejDropDownList('setSelectedValue', vm.defaultLang);
                }
            });
        }
    }
})();
