(function () {
    'use strict';

    angular
        .module('app.athletes.teams.teamMembersList')
        .controller('TeamMembersListController', TeamMembersList);

    TeamMembersList.$inject = ['log', '$state', 'dataservice', '$q', '$filter', 'gettextCatalog', 'paramHelper', 'authenticationService', '$scope', '$compile', '$rootScope'];

    /* @ngInject */
    function TeamMembersList(log, $state, dataservice, $q, $filter, gettextCatalog, paramHelper, authenticationService, $scope, $compile, $rootScope) {
        var vm = this; $rootScope.vm = vm;

        // Page title
        $rootScope.title = gettextCatalog.getString('TeamMembersTitle');

        vm.now = new Date();
        vm.dateValue = vm.now;
        // Grid's variable
        vm.columns = [
            {type: 'checkbox', textAlign: 'center', width: 35},
            {
                field: 'Name',
                headerText: gettextCatalog.getString('Name'),
                template: '#temelitemplateDataColumn1',
                width: 150
            },
            {field: 'BirthDate', headerText: gettextCatalog.getString('BirthDate'), width: 100}
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            width: '100%',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Public functions
        vm.dateChange = dateChange;
        vm.deleteTeamMember = deleteTeamMember;
        vm.goToAddTeamMember = goToAddTeamMember;
        vm.goToNewAthlete = goToNewAthlete;

        activate();

        function activate() {
            authenticationService.getRight('MEGTCSOPORT').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([paramHelper.getParams(['teams.sectionId', 'teams.ageGroupId', 'teams.team'], ['teams.date']),
                        authenticationService.getRight('MEGTSPORTLIST')
                    ]).then(function (results) {
                        vm.csapat = results[0]['teams.team'];
                        vm.korosztaly = results[0]['teams.ageGroupId'];
                        vm.szakosztaly = results[0]['teams.sectionId'];
                        if (results[0]['teams.date'] != null) {
                            vm.dateValue = results[0]['teams.date'];
                        }
                        vm.MEGTSPORTLIST = results[1];
                        getTeamMembersList();
                    });
                }
            });
        }

        function getTeamMembersList() {
            dataservice.getTeamMembersList(vm.csapat, vm.dateValue).then(function (data) {
                grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'BirthDate');
            });
        }

        function dateChange(args) {
            if (args.value !== null) {
                vm.dateValue = args.value;
                getTeamMembersList();
                paramHelper.setParams([{'teams.date': vm.dateValue}]);
            }
        }

        function deleteTeamMember() {
            var selectedRows = $rootScope.getSelectedItems(grid);
            if (selectedRows.length !== 0) {
                var out = [];
                for (var i = 0; i < selectedRows.length; i++) {
                    out.push(selectedRows[i].Id);
                }
                swal({
                    title: gettextCatalog.getString('DeleteTeamMembers'),
                    text: gettextCatalog.getString('AreYouSureYouWantToDeleteTheTeamMembers'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: gettextCatalog.getString('Yes'),
                    cancelButtonText: gettextCatalog.getString('No')
                }).then(function (isConfirm) {
                    if (isConfirm.value) {
                        dataservice.deleteTeamMember(vm.csapat, out)
                            .then(function () {
                                getTeamMembersList();
                                log.successMsg('TeamMemberDeletedSuccess');
                            });
                    }
                });
            } else {
                log.errorMsg('YouHaveToSelectLeastAthlete');
            }
        }

        function goToAddTeamMember() {
            $state.go('athletes.teams.addTeamMember');
        }

        function goToNewAthlete(Id) {
            if (vm.MEGTSPORTLIST) {
                paramHelper.setParams([{'athleteModify.sportoloId': Id}, {'athleteNew.selectedSectionId': vm.szakosztaly}, {'athleteNew.selectedAgeGroupId': vm.korosztaly}]);
                $state.go('athletes.athletesManagement.athleteModify.dataSheet');
            } else {
                log.permissionError();
            }
        }
    }
})
();
