(function () {
    'use strict';

    angular
        .module('app.administration.trainingPlan')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.trainingPlan',
                config: {
                    url: '/trainingPlan',
                    templateUrl: 'app/administration/trainingPlan/trainingPlan.html'
                }
            }
        ];
    }
})();
