/* global Aes */

(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.psychology.tenesseeSelfImageScaleEvaluation')
        .controller('TenesseeSelfImageScaleEvaluationController', TenesseeSelfImageScaleEvaluationController);

    TenesseeSelfImageScaleEvaluationController.$inject = ['$q', 'gettextCatalog', 'dataservice', 'paramHelper', '$rootScope', 'authenticationService', 'log'];
    /* @ngInject */
    function TenesseeSelfImageScaleEvaluationController($q, gettextCatalog, dataservice, paramHelper, $rootScope, authenticationService, log) {
        var vm = this; $rootScope.vm = vm;

        vm.questions = [
            gettextCatalog.getString('TenesseeSelfImageScaleQ1'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ2'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ3'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ4'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ5'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ6'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ7'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ8'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ9'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ10'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ11'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ12'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ13'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ14'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ15'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ16'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ17'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ18'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ19'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ20'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ21'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ22'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ23'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ24'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ25'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ26'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ27'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ28'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ29'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ30'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ31'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ32'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ33'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ34'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ35'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ36'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ37'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ38'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ39'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ40'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ41'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ42'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ43'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ44'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ45'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ46'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ47'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ48'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ49'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ50'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ51'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ52'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ53'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ54'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ55'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ56'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ57'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ58'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ59'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ60'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ61'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ62'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ63'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ64'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ65'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ66'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ67'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ68'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ69'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ70'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ71'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ72'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ73'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ74'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ75'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ76'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ77'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ78'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ79'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ80'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ81'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ82'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ83'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ84'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ85'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ86'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ87'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ88'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ89'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ90'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ91'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ92'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ93'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ94'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ95'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ96'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ97'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ98'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ99'),
            gettextCatalog.getString('TenesseeSelfImageScaleQ100')
        ];
        vm.answers = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        // Public functions
        vm.saveEval = saveEval;

        activate();

        function activate() {
            $q.all([paramHelper.getParams(['psychology.surveyId'], ['psychology.surveyPassword', 'athleteModify.sportoloNev']), authenticationService.getRight('PSYCHO')])
                .then(function (results) {
                    if (results[1] !== true) {
                        log.permissionError(true);
                    } else {
                        vm.surveyId = results[0]['psychology.surveyId'];
                        vm.password = results[0]['psychology.surveyPassword'];
                        vm.sportoloNev = results[0]['athleteModify.sportoloNev'];
                        $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('Psychology') + ' - ' + gettextCatalog.getString('TenesseeSelfImageScaleEvaluation');
                        getTSFIS();
                    }
                });
        }

        function getTSFIS() {
            swal({
                title: gettextCatalog.getString('Password'),
                text: gettextCatalog.getString('SelectPasswordForTheTest'),
                //type: 'input',
                input: 'password',
                animation: 'slide-from-top',
                inputValue: vm.password,
                closeOnConfirm: false,
                allowEscapeKey: false
            }).then(
                function (inputValue) {
                    if (!inputValue || !inputValue.value || inputValue.dismiss === 'cancel' || inputValue.dismiss === 'esc') {
                        return false;
                    } else {
                        vm.password = inputValue.value;
                        dataservice.getTenesseeSelfImageScaleById(vm.surveyId).then(function (data) {
                            if (data.error === '') {
                                vm.answers = data.kitoltottKerdoiv;
                                vm.sportolo = {};
                                vm.sportolo.nev = data.sportoloNeve;
                                vm.sportolo.szuletesiDatum = data.sportoloSzuletesiDatuma;
                                vm.sportolo.anyjaNeve = data.sportoloAnyjaNeve;
                                vm.kitoltesDatuma = data.kitoltesDatuma;
                                vm.kiertekelesDatuma = data.kiertekelesDatuma;
                                vm.kiertekelesSzam = data.kiertekelesSzam;
                                vm.kiertekelesSzoveg = data.kiertekelesSzoveg;
                                vm.kiertekelesSzamMagyarazat = data.kiertekelesSzamMagyarazat;
                                if (vm.kiertekelesDatuma !== '') {
                                    vm.kiertekelesSzoveg = Aes.Ctr.decrypt(vm.kiertekelesSzoveg, inputValue.value, 256);
                                    angular.element('#rteSample').ejRTE({value: vm.kiertekelesSzoveg});
                                }
                                swal.close();
                            }
                        });
                    }
                });
        }

        function saveEval() {
            swal({
                title: gettextCatalog.getString('Password'),
                text: gettextCatalog.getString('SelectPasswordForTheTest'),
                //type: 'input',
                input: 'password',
                animation: 'slide-from-top',
                inputValue: vm.password,
                closeOnConfirm: false,
                allowEscapeKey: false
            }).then(function (inputValue) {
                if (!inputValue || !inputValue.value || inputValue.dismiss === 'cancel' || inputValue.dismiss === 'esc') {
                    return false;
                } else {
                    vm.password = inputValue.value;
                    paramHelper.setParams([{'psychology.surveyPassword': inputValue.value}]);
                    var hiba = false;
                    angular.forEach(vm.answers, function (value, key) {
                        if (value === 0) {
                            vm.answers[key] = -1;
                            hiba = true;
                        }
                    });
                    if (hiba) {
                        log.errorMsg('SurveyNotFilled');
                    } else {
                        vm.encryptedHtml = Aes.Ctr.encrypt(angular.element('#rteSample').ejRTE('getHtml'), inputValue.value, 256);
                        dataservice.saveTenesseeSelfImageScaleEval(vm.surveyId, vm.encryptedHtml)
                            .then(function () {
                                log.successMsg('TestSaved');
                                $rootScope.back();
                            });
                    }
                }
            });
        }
    }
})();
