(function () {
    'use strict';

    angular .module('app.administration.competitiveMonthlyPlan')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.competitiveMonthlyPlan',
                config: {
                    url: '/competitiveMonthlyPlan',
                    templateUrl: 'app/administration/competitiveMonthlyPlan/competitiveMonthlyPlan.html',
                }
            }
        ];
    }
})();
