(function () {
    'use strict';

    angular
        .module('app.administration')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration',
                config: {
                    title: 'Administration',
                    url: '/administration',
                    templateUrl: 'app/administration/administration.html',
                    settings: {
                        navId: 2,
                        level: 1,
                        order: 2,
                        parentId: 0,
                        content: 'Administration'
                    }
                }
            }
        ];
    }
})();
