(function () {
    'use strict';

    angular
        .module('app.reports.sportSpecificReport')
        .controller('SportSpecificReportController', SportSpecificReportController);

    SportSpecificReportController.$inject = ['log', 'dataservice', '$rootScope', 'gettextCatalog', 'authenticationService', '$scope', '$filter', '$compile', '$state', 'paramHelper'];

    function SportSpecificReportController(log, dataservice, $rootScope, gettextCatalog, authenticationService, $scope, $filter, $compile, $state, paramHelper) {
        var vm = this;
        $rootScope.vm = vm;
        vm.gridToolbar = ['printGrid', 'excelExport'];
        $rootScope.title = gettextCatalog.getString('SportSpecificReport');
        vm.dateFromValue = new Date(new Date().getFullYear(), 0, 1);
        vm.dateToValue = new Date(new Date().getFullYear(), 11, 31);
        vm.sectionList = [];
        vm.ageGroupList = [];
        vm.tipusList = [];
        vm.sectionId = 0;
        vm.ageGroupId = 0;
        vm.tipusId = 0;
        vm.userid = undefined;
        vm.allAthlete = false;
        vm.querycellinfo = querycellinfo;
        vm.sectionDataBound = sectionDataBound;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.tipusDataBound = tipusDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupSelect = ageGroupSelect;
        vm.tipusSelect = tipusSelect;
        vm.toolbarHandler = toolbarHandler;
        vm.getData = getData;
        vm.dataList = [];
        vm.dataFelmeroElem = [];
        vm.testdata = [];
        vm.test = [];
        vm.columns = [
            {field: 'SportoloID', isPrimaryKey: true, visible: false},
            {field: 'FelmeroFej', visible: false},
            {field: 'SportoloNeve', headerText: gettextCatalog.getString('AthleteName'), textAlign: 'left', width: 80},
            {field: 'datum', headerText: gettextCatalog.getString('Date'), textAlign: 'left', width: 80}
        ];

        activate();

        function activate() {
            authenticationService.getRight('MEGTSPFELM').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    getSections();
                    authenticationService.getRight('MEGTSPFELM');
                    paramHelper.getParams([], ['sportSpecificReport.SportoloID']);
                }
            });
        }

        function resetColumns() {
            vm.columns = [
                {field: 'SportoloID', headerText: 'Id', textAlign: 'left', isPrimaryKey: true, visible: false},
                {
                    field: 'SportoloNeve',
                    headerText: gettextCatalog.getString('AthleteName'),
                    textAlign: 'left',
                    width: 80
                },
                {field: 'datum', headerText: gettextCatalog.getString('Date'), textAlign: 'left', width: 80}
            ];
        }

        function getData() {
            if (!((vm.ageGroupId > 0 || vm.allAthlete) && vm.tipusId > 0)) {
                return;
            }
            vm.testdata = [];
            vm.test = [];
            vm.dataFelmeroElem = [];
            dataservice.getFelmeroElemList(vm.tipusId).then(function (elemResponse) {
                vm.dataFelmeroElem = elemResponse.itemsList;
                console.log('getFelmeroElemList() vm.dataFelmeroElem', vm.dataFelmeroElem);
                resetColumns();
                vm.userid = authenticationService.getAuthData().id;
                dataservice.getSportagSpecReport(vm.userid, vm.sectionId, vm.tipusId, vm.ageGroupId, vm.dateFromValue, vm.dateToValue, vm.allAthlete).then(function (data) {
                    vm.testdata = data.itemsList;
                    console.log('getSportagSpecReport() vm.testdata', vm.testdata);
                    vm.test = [];
                    for (var i = 0; i < vm.testdata.length; i++) {
                        var line = {};
                        line.SportoloID = vm.testdata[i].SportoloID;
                        line.FelmeroFej = vm.testdata[i].FelmeroFej;
                        line.SportoloNeve = vm.testdata[i].SportoloNeve;
                        line.datum = vm.testdata[i].datum;
                        for (var c = 0; c < vm.dataFelmeroElem.length; c++) {
                            var fieldName = 'c' + c;
                            line[fieldName] = '';
                        }
                        vm.test.push(line);
                    }
                    var strArr = [];
                    resetColumns();
                    for (var i = 0; i < vm.dataFelmeroElem.length; i++) {
                        var fieldName = vm.dataFelmeroElem[i].fieldName;
                        strArr.push(fieldName);
                        vm.columns.push({
                            field: vm.dataFelmeroElem[i].fieldName,
                            headerText: vm.dataFelmeroElem[i].Nev,
                            textAlign: 'center',
                            width: 120
                        });
                        for (var j = 0; j < vm.test.length; j++) {
                            vm.test[j][strArr[i]] = vm.testdata[j].Adatok1D[i];
                        }
                    }
                });
            });
        }

        function querycellinfo(arg) {
            if (vm.testdata && vm.testdata.length > 0 && arg.cell.cellIndex >= 3) {
                var rowIndex = getRowIndex(arg.data.SportoloID, arg.data.FelmeroFej);
                var color = vm.testdata[rowIndex].Colors[arg.cell.cellIndex - 3];
                if (color && color.length > 0) {
                    var cell = angular.element(arg.cell);
                    cell.css('background-color', vm.testdata[rowIndex].Colors[arg.cell.cellIndex - 3]);
                }
            }
        }

        function getRowIndex(SportoloID, FelmeroFej) {
            for (var i = 0; i < vm.testdata.length; i++) {
                if (vm.testdata[i].SportoloID == SportoloID && vm.testdata[i].FelmeroFej == FelmeroFej) {
                    return i;
                }
            }
            return -1;
        }

        function getSections() {
            return dataservice.sectionDropDownList().then(function (data) {
                vm.sectionList = data.itemsList;
            });
        }

        function getAgeGroups() {
            return dataservice.ageGroupDropDownList(vm.sectionId).then(function (data) {
                vm.ageGroupList = data.itemsList;
            });
        }

        function getTipusList() {
            return dataservice.felmeroElemFejDropDownList(vm.sectionId).then(function (data) {
                vm.tipusList = data.itemsList;
            });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                vm.sectionId = vm.sectionList[0].value;
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupId);
            }
        }

        function tipusDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#tipusDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#tipusDropDown').ejDropDownList('selectItemByValue', vm.ageGroupId);
            }
        }

        function sectionSelect(args) {
            vm.sectionId = args.value;
            getAgeGroups();
            getTipusList();
        }

        function ageGroupSelect(args) {
            vm.ageGroupId = args.value;
            getData();
        }

        function tipusSelect(args) {
            vm.tipusId = args.value;
            getData();
        }

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                var grid = angular.element('#Grid').ejGrid('instance');
                grid.ignoreOnExport.splice(grid.ignoreOnExport.indexOf('dataSource'), 1);
                this.export(dataservice.getURL() + 'FelmeroExcelExport/ExcelExport/');
            }
        }
    }
})();
