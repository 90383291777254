(function () {
    'use strict';

    angular
        .module('app.business.annualSportServiceFeeByAthletes')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'business.annualSportServiceFeeByAthletes',
                config: {
                    url: '/annualSportServiceFeeByAthletes',
                    templateUrl: 'app/business/annualSportServiceFeeByAthletes/annualSportServiceFeeByAthletes.html',
                    controller: 'AnnualSportServiceFeeByAthletesController',
                    controllerAs: 'vm',
                    title: 'AnnualSportServiceFeeByAthletes',
                    settings: {
                        navId: 2123,
                        level: 2,
                        order: 12,
                        orderTitle_hu: 'Sportszolgáltatási díj összesítés sportolónként',
                        orderTitle_en: 'Annual Sport Service Fee by Athlets',
                        parentId: 3,
                        content: 'AnnualSportServiceFeeByAthletes',
                        activatorPermission: '"MEGTTAGDOSP"'
                    }
                }
            }
        ];
    }
})();
