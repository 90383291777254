(function () {
    'use strict';

    angular
        .module('app.reports.managementReports.trainingDiaryReports')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.managementReports.trainingDiaryReports',
                config: {
                    url: '/trainingDiaryReports',
                    templateUrl: 'app/reports/managementReports/trainingDiaryReports/trainingDiaryReports.html',
                    controller: 'TrainingDiaryReportsController',
                    controllerAs: 'vm',
                    title: 'TrainingDiaryReports',
                    settings: {
                        navId: 31244,
                        level: 3,
                        order: 1,
                        orderTitle_hu: 'Képzési naplók',
                        orderTitle_en: 'Training Diaries',
                        parentId: 244,
                        content: 'TrainingDiaryReports',
                        activatorPermission: '"MEGTHAVIJEL"'
                    }
                }
            }
        ];
    }
})();
