(function () {
    'use strict';

    angular
        .module('app.settings.schoolManagement.addSchool')
        .controller('AddSchoolController', AddSchoolController);

    AddSchoolController.$inject = ['log', '$state', 'dataservice', 'authenticationService', 'gettextCatalog', '$rootScope', '$scope', 'logger'];
    /* @ngInject */
    function AddSchoolController(log, $state, dataservice, authenticationService, gettextCatalog, $rootScope, $scope) {
        var vm = this; $rootScope.vm = vm;
        vm.params = [];
        vm.newSchool = {};
        vm.saveSchool = saveSchool;

        // Page title
        $rootScope.title = gettextCatalog.getString('AddSchool');

        activate();

        function activate() {
            authenticationService.getRight('UJISKOLA').then(function (results) {
                if (results !== true) {
                    log.permissionError();
                }
            });
        }

        function saveSchool() {
            vm.newSchool.Id = -1;
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            return dataservice.addSchool(vm.newSchool).then(function () {
                    log.successMsg(gettextCatalog.getString('SchoolSaved'));
                    $state.go('settings.schoolManagement.schoolList');
                });
        }
    }
})();
