(function () {
    'use strict';

    angular
        .module('app.reports.healthReport')
        .controller('HealthReportController', HealthReportController);

    HealthReportController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'authenticationService', '$scope', '$filter', '$compile', 'paramHelper', '$state'];

    function HealthReportController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $filter, $compile, paramHelper, $state) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('HealthReport');
        vm.selectdateFrom = new Date(new Date().getFullYear(), new Date().getMonth() - 1);
        vm.selectdateTo = new Date();
        vm.mainType = -1;
        vm.subType = -1;
        vm.sectionList = [];
        vm.ageGroupList = [];
        vm.coachList = [];
        vm.healthMainType = [];
        vm.healthSubType = [];
        vm.filteredSubTypes = [];
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.openAthlete = openAthlete;
        vm.openCoach = openCoach;
        vm.show = show;
        vm.MainTypeSelect = MainTypeSelect;
        vm.mainTypeDataBound = mainTypeDataBound;
        vm.subTypeDataBound = subTypeDataBound;
        vm.goToModifyHealthEvent = goToModifyHealthEvent;
        vm.changeDateFrom = changeDateFrom;
        vm.changeDateTo = changeDateTo;

        vm.columns = [
            {field: 'SportoloNev', headerText: gettextCatalog.getString('Name'), textAlign: 'left', width: 120},
            {field: 'SzuletesiIdo', headerText: gettextCatalog.getString('BirthDate'), textAlign: 'left', width: 120},
            {field: 'KorcsoportNeve', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'left', width: 120},
            {field: 'EdzokNeve', headerText: gettextCatalog.getString('Coaches'), textAlign: 'left', width: 120},
            {field: 'MettolMeddig', headerText: gettextCatalog.getString('TimeInterval'), textAlign: 'left', width: 120},
            {field: 'FoTipus', headerText: gettextCatalog.getString('MainType'), textAlign: 'left', width: 120},
            {field: 'AlTipus', headerText: gettextCatalog.getString('SubType'), textAlign: 'left', width: 120},
            {
                field: 'Open',
                headerText: gettextCatalog.getString('Open'),
                textAlign: 'left',
                width: 120,
                template: '#healthedit'
            }
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowTextWrap: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            authenticationService.getRight('HEALTHREPORT').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    getSections();
                    getHealthMainTypes();
                    getHealthSubTypes();
                    setDropDowns();
                }
            });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'hr.section',
                'hr.agegroup',
                'hr.coach',
                'hr.from',
                'hr.to',
                'hr.main',
                'hr.sub'
            ])
            ];
            $q.all(promises).then(function (results) {
                vm.sectionId = results[0]['hr.section'];
                vm.ageGroupId = results[0]['hr.agegroup'];
                vm.coachId = results[0]['hr.coach'];
                vm.selectdateFrom = results[0]['hr.from'];
                vm.selectdateTo = results[0]['hr.to'];
                if (results[0]['hr.main']) {
                    vm.mainType = results[0]['hr.main'];
                }
                if (results[0]['hr.sub']) {
                    vm.subType = results[0]['hr.sub'];
                }
            });
        }

        function goToModifyHealthEvent(arg) {
            vm.selectedGridRow = ej.DataManager(grid.dataSource).executeLocal(new ej.Query().where('EgeszsegEsetId', 'equal', arg))[0];
            console.log('selectedGridRow', selectedGridRow);
            paramHelper.setParams([{
                'athleteModify.sportoloId': vm.selectedGridRow.SportoloId,
                'mofifyHealthAthleteId': vm.selectedGridRow.SportoloId,
                'mofifyHealthAthleteName': vm.selectedGridRow.SportoloNev,
                'mofifyHealthAthleteSocialSecurity': vm.selectedGridRow.taj,
                'mofifyHealthAthleteBirthDate': vm.selectedGridRow.SzuletesiIdo,
                'mofifyHealthId': vm.selectedGridRow.EgeszsegEsetId,
                'mofifyHealthKezeles': vm.selectedGridRow.kezeles,
                'mofifyHealthLeiras': vm.selectedGridRow.leiras,
                'mofifyHealthLetrehozas': vm.selectedGridRow.Letrehozas,
                'mofifyHealthMettol': vm.selectedGridRow.Mettol,
                'mofifyHealthMeddig': vm.selectedGridRow.Meddig,
                'mofifyHealthTipus': vm.selectedGridRow.FoTipus,
                'mofifyHealthAlTipus': vm.selectedGridRow.AlTipus,
                'fromState': 'report',
            }]);
            $state.go('athletes.athletesManagement.athleteModify.health.modifyHealthEvent');
        }

        function MainTypeSelect(arg) {
            var mainKey = vm.healthMainType.filter(function (MainType) {
                return MainType.text === arg.text;
            });
            if (mainKey[0] != undefined) {
                vm.filteredSubTypes = [];
                for (var i = 0; i < vm.healthSubType.length; i++) {
                    if (vm.healthSubType[i].Fotipus === mainKey[0].value) {
                        vm.filteredSubTypes.push(vm.healthSubType[i]);
                    }
                }
            }
        }

        function getHealthMainTypes() {
            dataservice.healthMainTypesList().then(function (response) {
                vm.healthMainType.push({text: gettextCatalog.getString('All'), value: -1});
                for (var i = 0; i < response.itemsList.length; i++) {
                    vm.healthMainType.push({text: response.itemsList[i].Nev, value: response.itemsList[i].Id});
                }
            });
        }

        function getHealthSubTypes() {
            dataservice.healthSubTypesList().then(function (response) {
                for (var i = 0; i < response.itemsList.length; i++) {
                    vm.healthSubType.push({
                        text: response.itemsList[i].AltipusNev,
                        value: response.itemsList[i].Id,
                        Fotipus: response.itemsList[i].FotipusId
                    });
                }
            });
        }

        function show() {
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            paramHelper.setParams([
                {'hr.section': vm.sectionId},
                {'hr.agegroup': vm.ageGroupId},
                {'hr.coach': vm.coachId},
                {'hr.from': vm.selectdateFrom},
                {'hr.to': vm.selectdateTo},
                {'hr.main': vm.mainType},
                {'hr.sub': vm.subType}
            ]);
            dataservice.HealthReport(vm.selectdateFrom, vm.selectdateTo, vm.sectionId, vm.ageGroupId, vm.coachId, vm.mainType, vm.subType).then(function (data) {
                grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'SzuletesiIdo');
            });
        }

        function openAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function getSections() {
            return dataservice.sectionDropDownList(0, true).then(function (data) {
                vm.sectionList = data.itemsList;
                vm.sectionList.unshift({value: 0, text: gettextCatalog.getString('All')});
            });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                vm.sectionId = vm.sectionList[0].value;
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        function sectionSelect(args) {
            vm.sectionId = args.value;
            vm.coachList = [];
            getAgeGroups();
        }

        function getAgeGroups() {
            return dataservice.ageGroupDropDownList(vm.sectionId).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.ageGroupList = data.itemsList;
            });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupId);
                if (vm.ageGroupId) {
                    getActualCoachesList(-1);
                }
            }
        }

        function mainTypeDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#MainTypeDropDown').ejDropDownList('selectItemByValue', vm.healthMainType[0].value);
            } else {
                angular.element('#MainTypeDropDown').ejDropDownList('selectItemByValue', vm.mainType);
            }
        }

        function subTypeDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#SubTypeDropDown').ejDropDownList('selectItemByValue', vm.filteredSubTypes[0].value);
            } else {
                angular.element('#SubTypeDropDown').ejDropDownList('selectItemByValue', vm.subType);
            }
        }

        function ageGroupSelect(args) {
            vm.ageGroupId = args.value;
            getActualCoachesList(args.value);
        }

        function getActualCoachesList(ageGroupId) {
            return dataservice.coachDropDownList(vm.sectionId, ageGroupId, vm.selectdateFrom, vm.selectdateTo).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.coachList = data.itemsList;
            });
        }

        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
            }
        }

        function coachSelect(args) {
            vm.coachId = args.value;
        }

        function changeDateFrom(args) {
            vm.selectdateFrom = args.value;
            getActualCoachesList(vm.ageGroupId);
        }

        function changeDateTo(args) {
            vm.selectdateTo = args.value;
            getActualCoachesList(vm.ageGroupId);
        }
    }
})();
