(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.athletesBodyStat')
        .controller('AthletesBodyStatController', AthletesBodyStatController);

    AthletesBodyStatController.$inject = ['log', 'logger', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'authenticationService', 'paramHelper', '$filter'];

    /* @ngInject */
    function AthletesBodyStatController(log, logger, dataservice, $q, $rootScope, gettextCatalog, authenticationService, paramHelper, $filter) {
        var vm = this; $rootScope.vm = vm;

        vm.BodyStatText = gettextCatalog.getString('BodyStat');
        vm.cm = gettextCatalog.getString('Cm');
        vm.kg = gettextCatalog.getString('Kg');
        vm.bmi = gettextCatalog.getString('Bmi');
        vm.sportoloNev = '';
        vm.data = [];

        activate();

        function activate() {
            authenticationService.getRight('TESTMERET').then(function (results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([paramHelper.getParams(['athleteModify.sportoloId', 'athleteModify.sportoloNev'], [])]).then(function (results) {
                        vm.sportoloId = results[0]['athleteModify.sportoloId'];
                        vm.sportoloNev = results[0]['athleteModify.sportoloNev'];
                        $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('athletesBodyStat');
                        getAthletesBodyStat(vm.sportoloId);
                    });
                }
            });
        }

        function getAthletesBodyStat(sportoloid) {
            vm.data = [];
            dataservice.getAthletesBodyStat(sportoloid)
                .then(function (data) {
                    if (data.itemsList.length == 0) {
                        log.errorMsg(gettextCatalog.getString('NoBodyStatFoundOnMonthlyAttendanceReports'));
                        $rootScope.back();
                    }
                    vm.data = data.itemsList;
                    vm.data = $filter('dateStringFilter')(vm.data, 'datum');
                });
        }
    }
})();
