(function () {
    'use strict';

    angular
        .module('app.search.athletes.memberList')
        .controller('MemberListController', MemberListController);

    MemberListController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'VIEW_DATA', '$compile', '$scope', '$state', 'authenticationService', 'paramHelper'];

    function MemberListController(log, dataservice, $q, $rootScope, gettextCatalog, VIEW_DATA, $compile, $scope, $state, authenticationService, paramHelper) {
        var vm = this; $rootScope.vm = vm;
        vm.athleteList = [];
        $rootScope.title = gettextCatalog.getString('SearchMemberList');
        vm.gridExcelExport = dataservice.getURL() + 'TaglistaKeresesExcelExport/ExcelExport/';
        vm.toolbarHandler = toolbarHandler;
        vm.countyList = VIEW_DATA.dropDownList.counties;
        vm.courseList = VIEW_DATA.dropDownList.course;
        vm.classList = VIEW_DATA.dropDownList.class;

        // Create variables
        vm.leftrightboth = [
            {text: gettextCatalog.getString(''), value: undefined},
            {text: gettextCatalog.getString('Left'), value: 'Left'},
            {text: gettextCatalog.getString('Right'), value: 'Right'},
            {text: gettextCatalog.getString('Both'), value: 'Both'}
        ];
        vm.genderList = [
            {text: gettextCatalog.getString(''), value: undefined},
            {text: gettextCatalog.getString('Male'), value: 'Male'},
            {text: gettextCatalog.getString('Female'), value: 'Female'}
        ];
        vm.yesno = [
            {text: gettextCatalog.getString(''), value: undefined},
            {text: gettextCatalog.getString('Yes'), value: true},
            {text: gettextCatalog.getString('No'), value: false}
        ];
        vm.muv_EGYEZIK_NEMEGYZIK = [
            {text: gettextCatalog.getString(''), value: undefined},
            {text: gettextCatalog.getString('Egyezik'), value: 'Egyezik'},
            {text: gettextCatalog.getString('Nem egyezik'), value: 'Nem egyezik'}
        ];
        vm.muv_KIJELOLVE_NINCSKIJELOLVE = [
            {text: gettextCatalog.getString(''), value: undefined},
            {text: gettextCatalog.getString('Kijelölve'), value: 'Kijelölve'},
            {text: gettextCatalog.getString('Nincs kijelölve'), value: 'Nincs kijelölve'}
        ];
        vm.muv_E_NEME_TART_NEMT = [
            {text: gettextCatalog.getString(''), value: undefined},
            {text: gettextCatalog.getString('Tartalmazza'), value: 'Tartalmazza'},
            {text: gettextCatalog.getString('Nem tartalmazza'), value: 'Nem tartalmazza'},
            {text: gettextCatalog.getString('Egyezik'), value: 'Egyezik'},
            {text: gettextCatalog.getString('Nem egyezik'), value: 'Nem egyezik'}
        ];
        vm.muv_TAG_NEMTAG = [
            {text: gettextCatalog.getString(''), value: undefined},
            {text: gettextCatalog.getString('Tagja'), value: 'Tagja'},
            {text: gettextCatalog.getString('Nem tagja'), value: 'Nem tagja'}
        ];
        vm.muv_KIJ_NINCSKIJ = [
            {text: gettextCatalog.getString(''), value: undefined},
            {text: gettextCatalog.getString('Kijelölve'), value: 'Kijelölve'}
            /*,{text: gettextCatalog.getString('Nincs kijelölve'), value: 'Nincs kijelölve'}*/
        ];
        vm.muv_DATE = [
            {text: gettextCatalog.getString(''), value: undefined},
            {text: gettextCatalog.getString('Date_Before'), value: '<='},
            {text: gettextCatalog.getString('Date_After'), value: '>='},
            {text: gettextCatalog.getString('Date_At'), value: '='},
            {text: gettextCatalog.getString('Date_NotAt'), value: '≠'},
            {text: gettextCatalog.getString('Date_Between'), value: 'Között'}
        ];
        vm.muv_REL_KOZ = [
            {text: gettextCatalog.getString(''), value: undefined},
            {text: gettextCatalog.getString('Less than'), value: '<='},
            {text: gettextCatalog.getString('Greater than'), value: '>='},
            {text: gettextCatalog.getString('Equals'), value: '='},
            {text: gettextCatalog.getString('Not equal'), value: '≠'},
            {text: gettextCatalog.getString('Between'), value: 'Között'}
        ];

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                var grid = angular.element('#Grid').ejGrid('instance');
                grid.ignoreOnExport.splice(grid.ignoreOnExport.indexOf('dataSource'), 1);
                this.export(dataservice.getURL() + 'TaglistaKeresesExcelExport/ExcelExport/');
            }
        }

        vm.nameChange = nameChange;
        function nameChange () {
            vm.query.muvnev = 'Tartalmazza';
            vm.NameNotMatchingChecked = false;
            vm.NameExactMatchChecked = false;
        }

        vm.NameExactMatch = NameExactMatch;
        function NameExactMatch (arg) {
            if (arg.value == true) {
                vm.NameNotMatchingChecked = false;
                vm.query.muvnev = 'Egyezik';
            }
        }

        vm.NameNotMatching = NameNotMatching;
        function NameNotMatching (arg) {
            if (arg.value == true) {
                vm.NameExactMatchChecked = false;
                vm.query.muvnev = 'Nem egyezik';
            }
        }

        vm.MemberOfSection = MemberOfSection;
        function MemberOfSection (arg) {
            if (arg.value == true) {
                vm.NotMemberOfSectionChecked = false;
                vm.query.muvszakosztaly = 'Tagja';
            }
        }

        vm.NotMemberOfSection = NotMemberOfSection;
        function NotMemberOfSection (arg) {
            if (arg.value == true) {
                vm.MemberOfSectionChecked = false;
                vm.query.muvszakosztaly = 'Nem tagja';
            }
        }

        vm.MemberOfAgeGroup = MemberOfAgeGroup;
        function MemberOfAgeGroup (arg) {
            if (arg.value == true) {
                vm.NotMemberOfAgeGroupChecked = false;
                vm.query.muvkorosztaly = 'Tagja';
            }
        }

        vm.NotMemberOfAgeGroup = NotMemberOfAgeGroup;
        function NotMemberOfAgeGroup (arg) {
            if (arg.value == true) {
                vm.MemberOfAgeGroupChecked = false;
                vm.query.muvkorosztaly = 'Nem tagja';
            }
        }

        vm.BelongsToCoach = BelongsToCoach;
        function BelongsToCoach (arg) {
            if (arg.value == true) {
                vm.NotBelongsToCoachChecked = false;
                vm.query.muvedzo = 'Egyezik';
            }
        }

        vm.NotBelongsToCoach = NotBelongsToCoach;
        function NotBelongsToCoach (arg) {
            if (arg.value == true) {
                vm.BelongsToCoachChecked = false;
                vm.query.muvedzo = 'Nem egyezik';
            }
        }

        vm.ContactNotMatching = ContactNotMatching;
        function ContactNotMatching(arg) {
            var msg = '';
            if (arg.value == true) {
                swal({
                    title: gettextCatalog.getString('Query will exclude athletes with the given contact details'),
                    text: msg,
                    type: 'warning',
                    confirmButtonText: gettextCatalog.getString('Ok')
                });
            }
        }

        vm.SchoolNotMatching = SchoolNotMatching;
        function SchoolNotMatching(arg) {
            var msg = '';
            if (arg.value == true) {
                swal({
                    title: gettextCatalog.getString('Query will exclude athletes with the given school details'),
                    text: msg,
                    type: 'warning',
                    confirmButtonText: gettextCatalog.getString('Ok')
                });
            }
        }

        vm.ParentNotMatching = ParentNotMatching;
        function ParentNotMatching(arg) {
            var msg = '';
            if (arg.value == true) {
                vm.query.muvanyjaMagTol = '=';
                vm.query.muvapaMagTol = '=';
                swal({
                    title: gettextCatalog.getString('Query will exclude athletes with the given parent details'),
                    text: msg,
                    type: 'warning',
                    confirmButtonText: gettextCatalog.getString('Ok')
                });
            }
        }

        vm.BodyShapeNotMatching = BodyShapeNotMatching;
        function BodyShapeNotMatching(arg) {
            var msg = '';
            if (arg.value == true) {
                swal({
                    title: gettextCatalog.getString('Query will exclude athletes with the given bodyshape details'),
                    text: msg,
                    type: 'warning',
                    confirmButtonText: gettextCatalog.getString('Ok')
                });
            }
        }

        vm.IdNumbersNotMatching = IdNumbersNotMatching;
        function IdNumbersNotMatching(arg) {
            var msg = '';
            if (arg.value == true) {
                swal({
                    title: gettextCatalog.getString('Query will exclude athletes with the given id numbers'),
                    text: msg,
                    type: 'warning',
                    confirmButtonText: gettextCatalog.getString('Ok')
                });
            }
        }

        vm.columns = [{field: 'Nev', headerText: gettextCatalog.getString('Name'), textAlign: 'left', width: '150'}];
        vm.gridToolbar = ['printGrid', 'excelExport', 'divider'];

        vm.query = {};
        vm.sectionList = [];
        vm.ageGroupList = [];
        vm.coachList = [];
        vm.SelectedSectionID = 0;
        vm.SelectedAgeGroupID = 0;
        vm.SelectedCoachID = 0;
        vm.SelectedTeamID = 0;

        vm.resetQueryDetails = resetQueryDetails;
        resetQueryDetails();

        activate();

        function activate() {
            var promises = [authenticationService.getRight('SPORTOLOKERESO')];
            $q.all(promises)
                .then(function (results) {
                    if (results[0] !== true) {
                        log.permissionError(true);
                    } else {
                        getSections();
                        getAgeGroups();
                        getCoaches();
                    }
                });
        }

        vm.compile = compile;
        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
            //$rootScope.refreshGridSize();
        }

        vm.goToAthlete = goToAthlete;
        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        vm.sectionSelect = sectionSelect;
        function sectionSelect(args) {
            vm.MemberOfSectionChecked = args.value > 0;
            vm.SelectedCoachID = 0;
            vm.SelectedAgeGroupID = 0;
            vm.SelectedSectionID = args.value;
            if (args.value == -1) {
                vm.SelectedSectionID = 0;
            }
            getActualCoachesList();
            getAgeGroups();
        }

        vm.ageGroupSelect = ageGroupSelect;
        function ageGroupSelect(args) {
            vm.MemberOfAgeGroupChecked = true;
            vm.SelectedCoachID = 0;
            vm.SelectedAgeGroupID = args.value;
            if (args.value == -1) {
                vm.SelectedAgeGroupID = 0;
            }
            getActualCoachesList();
        }

        vm.coachSelect = coachSelect;
        function coachSelect(args) {
            vm.BelongsToCoachChecked = true;
            vm.SelectedCoachID = args.value;
            if (args.value == -1) {
                vm.SelectedCoachID = 0;
            }
        }

        function getSections() {
            dataservice.sectionDropDownList(0)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: -1, text: gettextCatalog.getString('')};
                        vm.sectionList.splice(0, 0, all);
                    }
                });
        }

        function getAgeGroups() {
            dataservice.ageGroupDropDownList(vm.SelectedSectionID)
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                    if (data.itemsList.length > 0) {
                        var all = {value: -1, text: gettextCatalog.getString('')};
                        vm.ageGroupList.splice(0, 0, all);
                    }
                });
        }

        function getActualCoachesList() {
            return dataservice.coachDropDownList(vm.SelectedSectionID, vm.SelectedAgeGroupID) // Need the section id (szakosztály)
                .then(function (data) {
                    vm.coachList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: -1, text: gettextCatalog.getString('')};
                        vm.coachList.splice(0, 0, all);
                    }
                });
        }

        function getCoaches() {
            return dataservice.coachSelect().then(
                function (data) {
                    vm.coachList = data.itemsList;
                    return vm.coachList;
                }
            );
        }

        vm.search = search;
        function search() {
            initGrid();
            initData();
            dataservice.searchMemberList(vm.query).then(function (results) {
                vm.athleteList = results.itemsList;
            });
        }

        function resetQueryDetails () {
            // string, datetime, int?
            vm.query.nev = undefined;
            vm.query.szakosztalyId = undefined;
            vm.query.korosztalyId = undefined;
            vm.query.edzoId = undefined;
            vm.query.tol = undefined;
            vm.query.ig = undefined;
            vm.query.neme = undefined;
            vm.query.allamp = undefined;
            vm.query.vspTol = undefined;
            vm.query.vspIg = undefined;
            vm.query.lakcimMegye = undefined;
            vm.query.lakcimIrSzam = undefined;
            vm.query.lakcimVaros = undefined;
            vm.query.lakcimUtca = undefined;
            vm.query.lakcimHazszam = undefined;
            vm.query.elerh = undefined;
            vm.query.iskNev = undefined;
            vm.query.iskIrSzam = undefined;
            vm.query.iskVaros = undefined;
            vm.query.iskUtca = undefined;
            vm.query.iskHazszam = undefined;
            vm.query.igNeve = undefined;
            vm.query.testnev = undefined;
            vm.query.testnevElerh = undefined;
            vm.query.evfolyam = undefined;
            vm.query.osztaly = undefined;
            vm.query.anyjaNeve = undefined;
            vm.query.anyjaElerh = undefined;
            vm.query.anyjaMagTol = undefined;
            vm.query.anyjaMagIg = undefined;
            vm.query.anyjaSpMult = undefined;
            vm.query.apaNeve = undefined;
            vm.query.apaElerh = undefined;
            vm.query.apaMagTol = undefined;
            vm.query.apaMagIg = undefined;
            vm.query.apaSpMult = undefined;
            vm.query.testMagTol = undefined;
            vm.query.testMagIg = undefined;
            vm.query.testsulyTol = undefined;
            vm.query.testSulyIg = undefined;
            vm.query.bmi = undefined;
            vm.query.bmiig = undefined;
            vm.query.ugyKez = undefined;
            vm.query.ugyLab = undefined;
            vm.query.szemIgSzam = undefined;
            vm.query.tajSzam = undefined;
            vm.query.adoSzam = undefined;
            vm.query.utlevelSzam = undefined;
            vm.query.egyeb = undefined;
            vm.query.aktiv = undefined;
            vm.query.havidijas = undefined;
            vm.query.oktdijas = undefined;
            vm.query.dsiKezdTol = undefined;
            vm.query.dsiKezdIg = undefined;
            vm.query.kiDatTol = undefined;
            vm.query.kiDatIg = undefined;
            vm.query.kilepIndoka = undefined;
            vm.query.eddigiEdzoi = undefined;
            vm.query.eddigiSpEgyes = undefined;
            vm.query.edzesKezdTol = undefined;
            vm.query.edzesKezdIg = undefined;
            vm.query.email = undefined;
            vm.query.regSzam = undefined;
            vm.query.osztFoNeve = undefined;
            vm.query.anyjaLeanyNeve = undefined;
            vm.query.pubVegeTol = undefined;
            vm.query.pubVegeIg = undefined;
            vm.query.szuloFogl = undefined;
            vm.query.gyereksport = undefined;
            vm.query.fajiEredet = undefined;
            vm.query.szunidElfogl = undefined;
            vm.query.valSportagak = undefined;
            vm.query.leanykoriNev = undefined;
            vm.query.papucsMerete = undefined;
            vm.query.melegitoMerete = undefined;
            vm.query.furdokopenyMerete = undefined;
            vm.query.kabatMerete = undefined;
            vm.query.pancelNadragMerete = undefined;
            vm.query.poloMerete = undefined;
            vm.query.sortMerete = undefined;
            vm.query.cipoMerete = undefined;
            vm.query.uszonadragMerete = undefined;

            vm.query.acttestMagTol = undefined;
            vm.query.acttestMagIg = undefined;
            vm.query.acttestSulyTol = undefined;
            vm.query.testactSulyIg = undefined;
            vm.query.actbmi = undefined;
            vm.query.actbmiig = undefined;

            // Müvelet, Egyezik, Nem egyezik, Tartalmazza, Nem tartalmazza stb
            vm.query.muvnev = undefined;
            vm.query.muvszakosztaly = undefined;
            vm.query.muvkorosztaly = undefined;
            vm.query.muvedzo = undefined;
            vm.query.muvtol = undefined;
            vm.query.muvig = undefined;
            //vm.query.muvneme = undefined;
            vm.query.muvneme = 'Egyezik';
            vm.query.muvallamp = 'Tartalmazza';
            vm.query.muvvspTol = undefined;
            vm.query.muvvspIg = undefined;
            vm.query.muvlakcimMegye = undefined;
            vm.query.muvlakcimIrSzam = undefined;
            vm.query.muvlakcimVaros = undefined;
            vm.query.muvlakcimUtca = undefined;
            vm.query.muvlakcimHazszam = undefined;
            vm.query.muvelerh = undefined;
            vm.query.muviskNev = undefined;
            vm.query.muviskIrSzam = undefined;
            vm.query.muviskVaros = undefined;
            vm.query.muviskUtca = undefined;
            vm.query.muviskHazszam = undefined;
            vm.query.muvigNeve = undefined;
            vm.query.muvtestnev = undefined;
            vm.query.muvtestnevElerh = undefined;
            vm.query.muvevfolyam = undefined;
            vm.query.muvosztaly = undefined;
            vm.query.muvanyjaNeve = undefined;
            vm.query.muvanyjaElerh = undefined;
            vm.query.muvanyjaMagTol = undefined;
            vm.query.muvanyjaMagIg = undefined;
            vm.query.muvanyjaSpMult = undefined;
            vm.query.muvapaNeve = undefined;
            vm.query.muvapaElerh = undefined;
            vm.query.muvapaMagTol = undefined;
            vm.query.muvapaMagIg = undefined;
            vm.query.muvapaSpMult = undefined;
            vm.query.muvtestMagTol = undefined;
            vm.query.muvtestMagIg = undefined;
            vm.query.muvtestsulyTol = undefined;
            vm.query.muvtestSulyIg = undefined;
            vm.query.muvugyKez = undefined;
            vm.query.muvugyLab = undefined;
            vm.query.muvszemIgSzam = undefined;
            vm.query.muvtajSzam = undefined;
            vm.query.muvadoSzam = undefined;
            vm.query.muvutlevelSzam = undefined;
            vm.query.muvegyeb = undefined;
            vm.query.muvaktiv = 'Kijelölve';
            vm.query.muvhavidijas = undefined;
            vm.query.muvoktdijas = undefined;
            vm.query.muvdsiKezdTol = undefined;
            vm.query.muvdsiKezdIg = undefined;
            vm.query.muvkiDatTol = undefined;
            vm.query.muvkiDatIg = undefined;
            vm.query.muvkilepIndoka = undefined;
            vm.query.muveddigiEdzoi = undefined;
            vm.query.muveddigiSpEgyes = undefined;
            vm.query.muvedzesKezdTol = undefined;
            vm.query.muvedzesKezdIg = undefined;
            vm.query.muvemail = undefined;
            vm.query.muvregSzam = undefined;
            vm.query.muvosztFoNeve = undefined;
            vm.query.muvanyjaLeanyNeve = undefined;
            vm.query.muvpubVegeTol = undefined;
            vm.query.muvpubVegeIg = undefined;
            vm.query.muvszuloFogl = undefined;
            vm.query.muvgyereksport = undefined;
            vm.query.muvfajiEredet = undefined;
            vm.query.muvszunidElfogl = undefined;
            vm.query.muvvalSportagak = undefined;
            vm.query.muvleanykoriNev = 'Tartalmazza';
            //vm.query.muvleanykoriNev = undefined;
            vm.query.muvbmi = undefined;
            vm.query.muvacttestMagTol = undefined;
            vm.query.muvacttestSulyTol = undefined;
            vm.query.muvactbmi = undefined;

            vm.query.muvpapucsMerete = undefined;
            vm.query.muvmelegitoMerete = undefined;
            vm.query.muvfurdokopenyMerete = undefined;
            vm.query.muvkabatMerete = undefined;
            vm.query.muvpancelNadragMerete = undefined;
            vm.query.muvpoloMerete = undefined;
            vm.query.muvsortMerete = undefined;
            vm.query.muvcipoMerete = undefined;
            vm.query.muvuszonadragMerete = undefined;

            vm.query.anyjaFoglalkozasa = undefined;
            vm.query.muvanyjaFoglalkozasa = undefined;
            vm.query.apaFoglalkozasa = undefined;
            vm.query.muvapaFoglalkozasa = undefined;

            // BOOL
            vm.query.kellnev = undefined;
            vm.query.kelltestiAdottsagok = undefined;
            vm.query.kellAzonositok = undefined;
            vm.query.kelliskCim = undefined;
            vm.query.kelllakcim = undefined;
            vm.query.kellpapucs = undefined;
            vm.query.kellmelegito = undefined;
            vm.query.kellfurdokopeny = undefined;
            vm.query.kellkabat = undefined;
            vm.query.kellpancelNadrag = undefined;
            vm.query.kellpolo = undefined;
            vm.query.kellsort = undefined;
            vm.query.kellcipo = undefined;
            vm.query.kelluszonadrag = undefined;
            vm.query.kellmegjegyzes = undefined;
            vm.query.kellszulhelyorszag = undefined;
            vm.query.kellszulhelyvaros = undefined;
            vm.query.kellaktiv = undefined;
            vm.query.kellszakosztalyId = undefined;
            vm.query.kellkorosztalyId = undefined;
            vm.query.kelledzoId = undefined;
            vm.query.kelltol = undefined;
            vm.query.kellig = undefined;
            vm.query.kellneme = undefined;
            vm.query.kellSzulHely = undefined;
            vm.query.kellallamp = undefined;
            vm.query.kellvspTol = undefined;
            vm.query.kellvspIg = undefined;
            vm.query.kelllakcimMegye = undefined;
            vm.query.kelllakcimIrSzam = undefined;
            vm.query.kelllakcimVaros = undefined;
            vm.query.kelllakcimUtca = undefined;
            vm.query.kelllakcimHazszam = undefined;
            vm.query.kellelerh = undefined;
            vm.query.kelliskNev = undefined;
            vm.query.kelliskIrSzam = undefined;
            vm.query.kelliskVaros = undefined;
            vm.query.kelliskUtca = undefined;
            vm.query.kelliskHazszam = undefined;
            vm.query.kelligNeve = undefined;
            vm.query.kelltestnev = undefined;
            vm.query.kelltestnevElerh = undefined;
            vm.query.kellevfolyam = undefined;
            vm.query.kellosztaly = undefined;
            vm.query.kellanyjaNeve = undefined;
            vm.query.kellanyjaElerh = undefined;
            vm.query.kellanyjaMagTol = undefined;
            vm.query.kellanyjaMagIg = undefined;
            vm.query.kellanyjaSpMult = undefined;
            vm.query.kellapaNeve = undefined;
            vm.query.kellapaElerh = undefined;
            vm.query.kellapaMagTol = undefined;
            vm.query.kellapaMagIg = undefined;
            vm.query.kellapaSpMult = undefined;
            vm.query.kelltestMagTol = undefined;
            vm.query.kelltestMagIg = undefined;
            vm.query.kelltestsulyTol = undefined;
            vm.query.kelltestSulyIg = undefined;
            vm.query.kellugyKez = undefined;
            vm.query.kellugyLab = undefined;
            vm.query.kellszemIgSzam = undefined;
            vm.query.kelltajSzam = undefined;
            vm.query.kelladoSzam = undefined;
            vm.query.kellutlevelSzam = undefined;
            vm.query.kellegyeb = undefined;
            vm.query.kellaktiv = undefined;
            vm.query.kellhavidijas = undefined;
            vm.query.kelloktdijas = undefined;
            vm.query.kellNyilvSzam = undefined;
            vm.query.kellDSIKezd = undefined;
            vm.query.kellEddigiEdzo = undefined;
            vm.query.kellEddigiSp = undefined;
            vm.query.kellTagdijas = undefined;
            vm.query.kellEmail = undefined;
            vm.query.kellEdzJaras = undefined;
            vm.query.kellGyermSp = undefined;
            vm.query.kellFajiEredet = undefined;
            vm.query.kellSzunidei = undefined;
            vm.query.kellLeanykNev = undefined;
            vm.query.kellKilepesDatum = undefined;
            vm.query.kellKilepesIndok = undefined;
            vm.query.kellpapucsMerete = undefined;
            vm.query.kellmelegitoMerete = undefined;
            vm.query.kellfurdokopenyMerete = undefined;
            vm.query.kellkabatMerete = undefined;
            vm.query.kellpancelNadragMerete = undefined;
            vm.query.kellpoloMerete = undefined;
            vm.query.kellsortMerete = undefined;
            vm.query.kellAnyjaLeanykoriNeve = undefined;
            vm.query.kellAnyjaAdatai = undefined;
            vm.query.kellApjaAdatai = undefined;
            vm.query.kellbmi = undefined;
            vm.query.kellof = undefined;

            // CHECKBOX bools
            vm.NameExactMatchChecked = false;
            vm.NameNotMatchingChecked = false;
            vm.MemberOfSectionChecked = false;
            vm.NotMemberOfSectionChecked = false;
            vm.MemberOfAgeGroupChecked = false;
            vm.NotMemberOfAgeGroupChecked = false;
            vm.BelongsToCoachChecked = false;
            vm.NotBelongsToCoachChecked = false;

            // OFFSET
            vm.query.kezdo = undefined;
            vm.query.darab = undefined;
        }

        function initData() {
            // CONTACT DETAILS
            if (vm.ContactNotMatchingChecked == true) {
                if (vm.query.lakcimMegye) {
                    vm.query.muvlakcimMegye = 'Nem tartalmazza';
                }
                if (vm.query.lakcimIrSzam) {
                    vm.query.muvlakcimIrSzam = 'Nem tartalmazza';
                }
                if (vm.query.lakcimVaros) {
                    vm.query.muvlakcimVaros = 'Nem tartalmazza';
                }
                if (vm.query.lakcimUtca) {
                    vm.query.muvlakcimUtca = 'Nem tartalmazza';
                }
                if (vm.query.lakcimHazszam) {
                    vm.query.muvlakcimHazszam = 'Nem tartalmazza';
                }
                if (vm.query.elerh) {
                    vm.query.muvelerh = 'Nem tartalmazza';
                }
                if (vm.query.email) {
                    vm.query.muvemail = 'Nem tartalmazza';
                }
            } else {
                if (vm.query.lakcimMegye) {
                    vm.query.muvlakcimMegye = 'Tartalmazza';
                }
                if (vm.query.lakcimIrSzam) {
                    vm.query.muvlakcimIrSzam = 'Tartalmazza';
                }
                if (vm.query.lakcimVaros) {
                    vm.query.muvlakcimVaros = 'Tartalmazza';
                }
                if (vm.query.lakcimUtca) {
                    vm.query.muvlakcimUtca = 'Tartalmazza';
                }
                if (vm.query.lakcimHazszam) {
                    vm.query.muvlakcimHazszam = 'Tartalmazza';
                }
                if (vm.query.elerh) {
                    vm.query.muvelerh = 'Tartalmazza';
                }
                if (vm.query.email) {
                    vm.query.muvemail = 'Tartalmazza';
                }
            }

            // SCHOOL DETAILS
            if (vm.SchoolNotMatchingChecked == true) {
                if (vm.query.iskNev) {
                    vm.query.muviskNev = 'Nem tartalmazza';
                }
                if (vm.query.iskIrSzam) {
                    vm.query.muviskIrSzam = 'Nem tartalmazza';
                }
                if (vm.query.iskVaros) {
                    vm.query.muviskVaros = 'Nem tartalmazza';
                }
                if (vm.query.iskUtca) {
                    vm.query.muviskUtca = 'Nem tartalmazza';
                }
                if (vm.query.iskHazszam) {
                    vm.query.muviskHazszam = 'Nem tartalmazza';
                }
                if (vm.query.igNeve) {
                    vm.query.muvigNeve = 'Nem tartalmazza';
                }
                if (vm.query.testnev) {
                    vm.query.muvtestnev = 'Nem tartalmazza';
                }
                if (vm.query.testnevElerh) {
                    vm.query.muvtestnevElerh = 'Nem tartalmazza';
                }
                if (vm.query.testnevEmail) {
                    vm.query.muvtestnevEmail = 'Nem tartalmazza';
                }
                if (vm.query.evfolyam) {
                    vm.query.muvevfolyam = 'Nem tartalmazza';
                }
                if (vm.query.osztaly) {
                    vm.query.muvosztaly = 'Nem tartalmazza';
                }
                if (vm.query.regszam) {
                    vm.query.muvregszam = 'Nem tartalmazza';
                }
                if (vm.query.osztFoNeve) {
                    vm.query.muvosztFoNeve = 'Nem tartalmazza';
                }
                if (vm.query.OfTel) {
                    vm.query.muvOfTel = 'Nem tartalmazza';
                }
                if (vm.query.OfEmail) {
                    vm.query.muvOfEmail = 'Nem tartalmazza';
                }
            } else {
                if (vm.query.iskNev) {
                    vm.query.muviskNev = 'Tartalmazza';
                }
                if (vm.query.iskIrSzam) {
                    vm.query.muviskIrSzam = 'Tartalmazza';
                }
                if (vm.query.iskVaros) {
                    vm.query.muviskVaros = 'Tartalmazza';
                }
                if (vm.query.iskUtca) {
                    vm.query.muviskUtca = 'Tartalmazza';
                }
                if (vm.query.iskHazszam) {
                    vm.query.muviskHazszam = 'Tartalmazza';
                }
                if (vm.query.igNeve) {
                    vm.query.muvigNeve = 'Tartalmazza';
                }
                if (vm.query.testnev) {
                    vm.query.muvtestnev = 'Tartalmazza';
                }
                if (vm.query.testnevElerh) {
                    vm.query.muvtestnevElerh = 'Tartalmazza';
                }
                if (vm.query.testnevEmail) {
                    vm.query.muvtestnevEmail = 'Tartalmazza';
                }
                if (vm.query.evfolyam) {
                    vm.query.muvevfolyam = 'Tartalmazza';
                }
                if (vm.query.osztaly) {
                    vm.query.muvosztaly = 'Tartalmazza';
                }
                if (vm.query.regszam) {
                    vm.query.muvregszam = 'Tartalmazza';
                }
                if (vm.query.osztFoNeve) {
                    vm.query.muvosztFoNeve = 'Tartalmazza';
                }
                if (vm.query.kilepIndoka) {
                    vm.query.muvkilepIndoka = 'Tartalmazza';
                }
                if (vm.query.eddigiEdzoi) {
                    vm.query.muveddigiEdzoi = 'Tartalmazza';
                }
                if (vm.query.eddigiSpEgyes) {
                    vm.query.muveddigiSpEgyes = 'Tartalmazza';
                }
                if (vm.query.OfTel) {
                    vm.query.muvOfTel = 'Tartalmazza';
                }
                if (vm.query.OfEmail) {
                    vm.query.muvOfEmail = 'Tartalmazza';
                }
            }

            // PARENT DETAILS
            if (vm.ParentNotMatchingChecked == true) {
                if (vm.query.anyjaNeve) {
                    vm.query.muvanyjaNeve = 'Nem tartalmazza';
                }
                if (vm.query.anyjaElerh) {
                    vm.query.muvanyjaElerh = 'Nem tartalmazza';
                }
                if (vm.query.anyjaSpMult) {
                    vm.query.muvanyjaSpMult = 'Nem tartalmazza';
                }
                if (vm.query.anyjaLeanyNeve) {
                    vm.query.muvanyjaLeanyNeve = 'Nem tartalmazza';
                }
                if (vm.query.anyjaFoglalkozasa) {
                    vm.query.muvanyjaFoglalkozasa = 'Nem tartalmazza';
                }
                if (vm.query.apaNeve) {
                    vm.query.muvapaNeve = 'Nem tartalmazza';
                }
                if (vm.query.apaElerh) {
                    vm.query.muvapaElerh = 'Nem tartalmazza';
                }
                if (vm.query.apaSpMult) {
                    vm.query.muvapaSpMult = 'Nem tartalmazza';
                }
                if (vm.query.apaFoglalkozasa) {
                    vm.query.muvapaFoglalkozasa = 'Nem tartalmazza';
                }
                if (vm.query.anyjaMagTol) {
                    vm.query.muvanyjaMagTol = '≠';
                }
                if (vm.query.apaMagTol) {
                    vm.query.muvapaMagTol = '≠';
                }
                if (vm.query.AnyaMunkahely) {
                    vm.query.muvAnyaMunkahely = 'Nem tartalmazza';
                }
                if (vm.query.ApaMunkahely) {
                    vm.query.muvApaMunkahely = 'Nem tartalmazza';
                }
                if (vm.query.AnyaEmail) {
                    vm.query.muvAnyaEmail = 'Nem tartalmazza';
                }
                if (vm.query.ApaEmail) {
                    vm.query.muvApaEmail = 'Nem tartalmazza';
                }
            } else {
                if (vm.query.anyjaNeve) {
                    vm.query.muvanyjaNeve = 'Tartalmazza';
                }
                if (vm.query.anyjaElerh) {
                    vm.query.muvanyjaElerh = 'Tartalmazza';
                }
                if (vm.query.anyjaSpMult) {
                    vm.query.muvanyjaSpMult = 'Tartalmazza';
                }
                if (vm.query.anyjaLeanyNeve) {
                    vm.query.muvanyjaLeanyNeve = 'Tartalmazza';
                }
                if (vm.query.anyjaFoglalkozasa) {
                    vm.query.muvanyjaFoglalkozasa = 'Tartalmazza';
                }
                if (vm.query.apaNeve) {
                    vm.query.muvapaNeve = 'Tartalmazza';
                }
                if (vm.query.apaElerh) {
                    vm.query.muvapaElerh = 'Tartalmazza';
                }
                if (vm.query.apaSpMult) {
                    vm.query.muvapaSpMult = 'Tartalmazza';
                }
                if (vm.query.apaFoglalkozasa) {
                    vm.query.muvapaFoglalkozasa = 'Tartalmazza';
                }
                if (vm.query.AnyaMunkahely) {
                    vm.query.muvAnyaMunkahely = 'Tartalmazza';
                }
                if (vm.query.ApaMunkahely) {
                    vm.query.muvApaMunkahely = 'Tartalmazza';
                }
                if (vm.query.AnyaEmail) {
                    vm.query.muvAnyaEmail = 'Tartalmazza';
                }
                if (vm.query.ApaEmail) {
                    vm.query.muvApaEmail = 'Tartalmazza';
                }
            }

            // BODYSHAPE
            if (vm.BodyShapeNotMatchingChecked == true) {
                if (vm.query.testMagTol) {
                    vm.query.muvtestMagTol = '≠';
                }
                if (vm.query.testsulyTol) {
                    vm.query.muvtestsulyTol = '≠';
                }
                if (vm.query.bmi) {
                    vm.query.muvbmi = '≠';
                }
                if (vm.query.acttestMagTol) {
                    vm.query.muvacttestMagTol = '≠';
                }
                if (vm.query.acttestSulyTol) {
                    vm.query.muvacttestSulyTol = '≠';
                }
                if (vm.query.actbmi) {
                    vm.query.muvactbmi = '≠';
                }
                if (vm.query.ugyKez) {
                    vm.query.muvugyKez = 'Nem egyezik';
                }
                if (vm.query.ugyLab) {
                    vm.query.muvugyLab = 'Nem egyezik';
                }
            }

            // ID
            if (vm.IdNumbersNotMatchingChecked == true) {
                if (vm.query.szemIgSzam) {
                    vm.query.muvszemIgSzam = 'Nem tartalmazza';
                }
                if (vm.query.tajSzam) {
                    vm.query.muvtajSzam = 'Nem tartalmazza';
                }
                if (vm.query.adoSzam) {
                    vm.query.muvadoSzam = 'Nem tartalmazza';
                }
                if (vm.query.utlevelSzam) {
                    vm.query.muvutlevelSzam = 'Nem tartalmazza';
                }
            } else {
                if (vm.query.szemIgSzam) {
                    vm.query.muvszemIgSzam = 'Tartalmazza';
                }
                if (vm.query.tajSzam) {
                    vm.query.muvtajSzam = 'Tartalmazza';
                }
                if (vm.query.adoSzam) {
                    vm.query.muvadoSzam = 'Tartalmazza';
                }
                if (vm.query.utlevelSzam) {
                    vm.query.muvutlevelSzam = 'Tartalmazza';
                }
            }

            if (vm.query.egyeb) {
                vm.query.muvegyeb = 'Tartalmazza';
            }
            if (vm.query.havidijas) {
                vm.query.muvhavidijas = 'Kijelölve';
            }
            if (vm.query.oktdijas) {
                vm.query.muvoktdijas = 'Kijelölve';
            }
            if (vm.query.gyereksport) {
                vm.query.muvgyereksport = 'Tartalmazza';
            }
            if (vm.query.szunidElfogl) {
                vm.query.muvszunidElfogl = 'Tartalmazza';
            }
            if (vm.query.valSportagak) {
                vm.query.muvvalSportagak = 'Tartalmazza';
            }
        }

        function initGrid() {
            vm.columns = [];
            vm.columns.push({field: 'Nev', headerText: gettextCatalog.getString('Name'), textAlign: 'left', width: '150', template: true, templateID: '#searchmemberlistgotoathlete'});
            vm.columns.push({field: 'SzuletesiIdo', headerText: gettextCatalog.getString('BirthDate'), textAlign: 'left', width: '150', type: 'date', format: '{0:yyyy.MM.dd.}'});
            if (vm.query.kellSzulHely) {
                vm.query.kellszulhelyvaros = true;
                vm.query.kellszulhelyorszag = true;
                vm.columns.push({field: 'SzuletesiHelyOrszag', headerText: gettextCatalog.getString('BirthCountry'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'SzuletesiHelyVaros', headerText: gettextCatalog.getString('BirthCity'), textAlign: 'left', width: '150'});
            }
            // ÁLTALÁNOS
            if (vm.query.kellszakosztalyId) {
                vm.columns.push({field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellkorosztalyId) {
                vm.columns.push({field: 'KorcsoportNeve', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'center', width: '150'});
            }
            if (vm.query.kelledzoId) {
                vm.columns.push({field: 'EdzoNeve', headerText: gettextCatalog.getString('Coach'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellmegjegyzes) {
                vm.columns.push({field: 'Megjegyzes', headerText: gettextCatalog.getString('Comment'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellneme) {
                vm.columns.push({field: 'Neme', headerText: gettextCatalog.getString('Gender'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellaktiv) {
                vm.columns.push({field: 'Aktiv', headerText: gettextCatalog.getString('Active'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellallamp) {
                vm.columns.push({field: 'Allampolgarsag', headerText: gettextCatalog.getString('Citizenship'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellvspTol) {
                vm.columns.push({field: 'VersSportKezd', headerText: gettextCatalog.getString('CompetitiveSportStart'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellLeanykNev) {
                vm.columns.push({field: 'LeanykoriNeve', headerText: gettextCatalog.getString('MaidenName'), textAlign: 'left', width: '150'});
            }
            // KAPCSOLAT
            if (vm.query.kelllakcim) {
                vm.query.kelllakcimMegye = true;
                vm.query.kelllakcimVaros = true;
                vm.query.kelllakcimIrSzam = true;
                vm.query.kelllakcimUtca = true;
                vm.query.kelllakcimHazszam = true;
                vm.columns.push({field: 'lakcim', headerText: gettextCatalog.getString('Address'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellelerh) {
                vm.columns.push({field: 'Elerhetoseg', headerText: gettextCatalog.getString('Contact'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellEmail) {
                vm.columns.push({field: 'Email', headerText: gettextCatalog.getString('Email'), textAlign: 'left', width: '150'});
            }
            // ISKOLA
            if (vm.query.kelliskNev) {
                vm.columns.push({field: 'IskolaNeve', headerText: gettextCatalog.getString('SchoolName'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kelliskCim) {
                vm.query.kelliskVaros = true;
                vm.query.kelliskIrSzam = true;
                vm.query.kelliskUtca = true;
                vm.query.kelliskHazszam = true;
                vm.columns.push({field: 'iskolacim', headerText: gettextCatalog.getString('SchoolAddress'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kelligNeve) {
                vm.columns.push({field: 'IskolaIgazgatoNeve', headerText: gettextCatalog.getString('Director'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellof) {
                vm.columns.push({field: 'Of', headerText: gettextCatalog.getString('ClassMaster'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'OfTel', headerText: gettextCatalog.getString('ClassMasterTel'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'OfEmail', headerText: gettextCatalog.getString('ClassMasterEmail'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kelltestnev) {
                vm.columns.push({field: 'TestneveloNeve', headerText: gettextCatalog.getString('GymTeacher'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kelltestnevElerh) {
                vm.columns.push({field: 'TestneveloElerhetosege', headerText: gettextCatalog.getString('GymTeacherContact'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'TestneveloEmail', headerText: gettextCatalog.getString('GymTeacherEmail'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellevfolyam || vm.query.kellosztaly) {
                vm.query.kellevfolyam = true;
                vm.query.kellosztaly = true;
                vm.columns.push({field: 'evfolyamosztaly', headerText: gettextCatalog.getString('SchoolYearClass'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellDSIKezd) {
                vm.columns.push({field: 'DSITagsagKezd', headerText: gettextCatalog.getString('MembershipStart'), textAlign: 'left', width: '150', type: 'date', format: '{0:yyyy.MM.dd.}'});
            }
            if (vm.query.kellKilepesDatum) {
                vm.query.kellKilepesIndok = true;
                vm.columns.push({field: 'KilepesDatuma', headerText: gettextCatalog.getString('MembershipEnd'), textAlign: 'left', width: '150', type: 'date', format: '{0:yyyy.MM.dd.}'});
                vm.columns.push({field: 'KilepesIndoka', headerText: gettextCatalog.getString('CauseOfLeaving'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellEddigiEdzo) {
                vm.columns.push({field: 'EddigiEdzoi', headerText: gettextCatalog.getString('FormerCoaches'), textAlign: 'center', width: '150'});
            }
            if (vm.query.kellEddigiSp) {
                vm.columns.push({field: 'EddigiSpEgy', headerText: gettextCatalog.getString('FormerSportOrganizationMemberships'), textAlign: 'center', width: '150'});
            }
            if (vm.query.kellNyilvSzam) {
                vm.columns.push({field: 'NyilvantartasiSzam', headerText: gettextCatalog.getString('RegistrationNumber'), textAlign: 'center', width: '150'});
            }
            if (vm.query.kellEdzJaras) {
                vm.columns.push({field: 'EdzesreJarasKezd', headerText: gettextCatalog.getString('TrainingStart'), textAlign: 'left', width: '150', type: 'date', format: '{0:yyyy.MM.dd.}'});
            }
            //  SZÜLŐK ADATAI
            if (vm.query.kellAnyjaAdatai) {
                vm.query.kellanyjaNeve = true;
                vm.query.kellAnyjaLeanykoriNeve = true;
                vm.query.kellanyjaElerh = true;
                vm.query.kellanyjaMagTol = true;
                vm.query.kellanyjaSpMult = true;
                vm.columns.push({field: 'AnyjaNeve', headerText: gettextCatalog.getString('MothersName'), textAlign: 'center', width: '150'});
                vm.columns.push({field: 'AnyjaLeanykoriNeve', headerText: gettextCatalog.getString('MothersMaidenName'), textAlign: 'center', width: '150'});
                vm.columns.push({field: 'Szulo1Elerhetosege', headerText: gettextCatalog.getString('MotherContact'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'AnyaEmail', headerText: gettextCatalog.getString('MotherEmail'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'AnyaMunkahely', headerText: gettextCatalog.getString('MothersWorkplace'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'AnyjaTestmagassaga', headerText: gettextCatalog.getString('MotherHeight'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'SportoloiMultAnya', headerText: gettextCatalog.getString('MotherSportHistory'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'anyjafoglalkozasa', headerText: gettextCatalog.getString('MothersJob'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellApjaAdatai) {
                vm.query.kellapaNeve = true;
                vm.query.kellapaElerh = true;
                vm.query.kellapaMagTol = true;
                vm.query.kellapaSpMult = true;
                vm.columns.push({field: 'SzuloNeve', headerText: gettextCatalog.getString('FathersName'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'Szulo2Elerhetosege', headerText: gettextCatalog.getString('FatherContact'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'ApaEmail', headerText: gettextCatalog.getString('FatherEmail'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'ApaMunkahely', headerText: gettextCatalog.getString('FathersWorkplace'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'ApjaTestmagassaga', headerText: gettextCatalog.getString('FatherHeight'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'SportoloiMultApa', headerText: gettextCatalog.getString('FatherSportHistory'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'apjafoglalkozasa', headerText: gettextCatalog.getString('FathersJob'), textAlign: 'left', width: '150'});
            }
            // TESTI ADOTTSÁGOK
            if (vm.query.kelltestiAdottsagok) {
                vm.query.kelltestMagTol = true;
                vm.query.kelltestsulyTol = true;
                vm.query.kellugyKez = true;
                vm.query.kellugyLab = true;
                vm.query.kellbmi = true;
                vm.columns.push({field: 'Testmagassag', headerText: gettextCatalog.getString('Height'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'TestsulyStr', headerText: gettextCatalog.getString('Weight'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'bmi', headerText: gettextCatalog.getString('BodyMassIndex'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'AktTestmagassag', headerText: gettextCatalog.getString('ActualHeight'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'AktTestsuly', headerText: gettextCatalog.getString('ActualWeight'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'actbmi', headerText: gettextCatalog.getString('ActualBodyMassIndex'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'UgyesebbKez', headerText: gettextCatalog.getString('BetterHand'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'UgyesebbLab', headerText: gettextCatalog.getString('BetterFoot'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'FajiEredet', headerText: gettextCatalog.getString('RacialOrigin'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'PubertasVege', headerText: gettextCatalog.getString('EndOfPuberty'), textAlign: 'left', width: '150'});
            }
            //  ID
            if (vm.query.kellAzonositok) {
                vm.query.kellszemIgSzam = true;
                vm.query.kelltajSzam = true;
                vm.query.kelladoSzam = true;
                vm.query.kellutlevelSzam = true;
                vm.columns.push({field: 'SzemIgSzam', headerText: gettextCatalog.getString('PersonalID'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'TAJSzam', headerText: gettextCatalog.getString('SocialSecurityNumber'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'AdoSzam', headerText: gettextCatalog.getString('TaxNumber'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'UtlevelSzam', headerText: gettextCatalog.getString('PassportNumber'), textAlign: 'left', width: '150'});
            }
            // OTHERS
            if (vm.query.kellTagdijas || vm.query.kellhavidijas || vm.query.kelloktdijas) { // Díjfizetés
                vm.query.kellTagdijas = true;
                vm.query.kelloktdijas = true;
                vm.columns.push({field: 'Tagdijas', headerText: gettextCatalog.getString('DuesOrEducationFee'), textAlign: 'left', width: '150'});
                vm.columns.push({field: 'Tagdij', headerText: gettextCatalog.getString('Dues'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellGyermSp) {
                vm.columns.push({field: 'GyermekkoriSport', headerText: gettextCatalog.getString('ChildhoodSport'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellSzunidei) {
                vm.columns.push({field: 'SzunideiElfogl', headerText: gettextCatalog.getString('VacationActivity'), textAlign: 'left', width: '150'});
            }
            // RUHA
            if (vm.query.kellpapucs) {
                vm.query.kellpapucsMerete = true;
                vm.columns.push({field: 'papucsMerete', headerText: gettextCatalog.getString('SlipperSize'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellmelegito) {
                vm.query.kellmelegitoMerete = true;
                vm.columns.push({field: 'melegitoMerete', headerText: gettextCatalog.getString('JoggingSize'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellfurdokopeny) {
                vm.query.kellfurdokopenyMerete = true;
                vm.columns.push({field: 'furdokopenyMerete', headerText: gettextCatalog.getString('BathrobeSize'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellkabat) {
                vm.query.kellkabatMerete = true;
                vm.columns.push({field: 'kabatMerete', headerText: gettextCatalog.getString('JacketSize'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellpancelNadrag) {
                vm.query.kellpancelNadragMerete = true;
                vm.columns.push({field: 'pancelNadragMerete', headerText: gettextCatalog.getString('ArmorPantsSize'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellpolo) {
                vm.query.kellpoloMerete = true;
                vm.columns.push({field: 'poloMerete', headerText: gettextCatalog.getString('ShirtSize'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellsort) {
                vm.query.kellsortMerete = true;
                vm.columns.push({field: 'sortMerete', headerText: gettextCatalog.getString('ShortsSize'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kellcipo) {
                vm.query.kellcipoMerete = true;
                vm.columns.push({field: 'cipoMerete', headerText: gettextCatalog.getString('ShoeSize'), textAlign: 'left', width: '150'});
            }
            if (vm.query.kelluszonadrag) {
                vm.query.kelluszonadragMerete = true;
                vm.columns.push({field: 'uszonadragMerete', headerText: gettextCatalog.getString('SwimmingPantSize'), textAlign: 'left', width: '150'});
            }
        }
    }
})();
