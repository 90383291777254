(function () {
    'use strict';

    angular.module('app', [
        'angular-img-cropper',
        'app.administration',
        'app.administration.assetManagement',
        'app.administration.annualSeasonPlan',
        'app.administration.annualSeasonPlan.annualSeasonPlanReportPage1',
        'app.administration.annualSeasonPlan.annualSeasonPlanReportPage2',
        'app.administration.annualSeasonPlan.annualSeasonPlanReportPage3',
        'app.administration.annualSeasonPlan.annualSeasonPlanReportPage4',
        'app.administration.annualSeasonPlan.annualSeasonPlanReportPage5',
        'app.administration.annualSeasonPlan.annualSeasonPlanReportPage6',
        'app.administration.annualSeasonPlan.annualSeasonPlanList',
        'app.administration.awayBudgetPlan',
        'app.administration.awayBudgetPlan.awayBudgetPlanCreate',
        'app.administration.awayBudgetPlan.awayBudgetPlanList',
        'app.administration.exercises',
        'app.administration.exercises.exercisesList',
        'app.administration.exercises.exercisesCreate',
        'app.administration.trainingPlan',
        'app.administration.trainingPlan.trainingPlanTemplate',
        'app.administration.trainingPlan.trainingPlanCreate',
        'app.administration.trainingPlan.trainingPlanList',
        'app.administration.competitiveMonthlyPlan',
        'app.administration.resultReportAthletes',
        'app.administration.resultReportTeam',
        'app.administration.monthlyAttendance',
        'app.administration.monthlyAttendance.monthlyAttendanceList',
        'app.administration.monthlyAttendance.monthlyAttendanceReport',
        'app.administration.monthlyTrainingPlan',
        'app.administration.monthlyTrainingPlan.monthlyTrainingPlanList',
        'app.administration.monthlyTrainingPlan.monthlyTrainingPlanReport',
        'app.administration.quickCompetitionResults',
        'app.administration.dailyAttendance',
        'app.administration.trainingDiary',
        'app.administration.sportSpecificTest',
        'app.athletes.athletesManagement.athleteModify.athletesSportSpecificTestList',
        'app.administration.sportSpecificTest.newSportSpecificTestList',
        'app.administration.sportSpecificTest.newSportSpecificTest',
        'app.administration.test',
        'app.administration.test.newTest',
        'app.administration.test.testList',
        'app.administration.test.testResults',
        'app.administration.competitiveMonthlyPlan.competitiveMonthlyPlanList',
        'app.administration.competitiveMonthlyPlan.competitiveMonthlyPlanReport',
        'app.administration.weeklyTrainingPlan',
        'app.administration.weeklyTrainingPlan.weeklyTrainingPlanList',
        'app.administration.weeklyTrainingPlan.weeklyTrainingPlanReport',
        'app.athleteDashboard',
        'app.athletes',
        'app.athletes.ageGroups',
        'app.athletes.ageGroups.addAgeGroup',
        'app.athletes.ageGroups.ageGroupMembersList',
        'app.athletes.ageGroups.ageGroupsList',
        'app.athletes.ageGroups.modifyAgeGroup',
        'app.athletes.athletesManagement',
        'app.athletes.athletesManagement.athleteNew',
        'app.athletes.athletesManagement.athleteModify',
        'app.athletes.athletesManagement.athleteModify.dataSheet',
        'app.athletes.athletesManagement.athleteModify.athleteFees',
        'app.athletes.athletesManagement.athleteModify.raceResultsReport',
        'app.athletes.athletesManagement.athleteModify.anthropometry',
        'app.athletes.athletesManagement.athleteModify.athleteLog',
        'app.athletes.athletesManagement.athletesList',
        'app.athletes.athletesManagement.athleteModify.competitionResultsChart',
        'app.athletes.athletesManagement.athleteModify.athletesAssets',
        'app.athletes.teams',
        'app.athletes.teams.addTeam',
        'app.athletes.teams.addTeamMember',
        'app.athletes.teams.modifyTeam',
        'app.athletes.teams.teamList',
        'app.athletes.teams.teamMembersList',
        'app.athletes.sections',
        'app.business',
        'app.business.annualEducationalFeeByAthletes',
        'app.business.annualEducationalFeeByCoach',
        'app.business.annualEducationalFeeByOneSection',
        'app.business.annualEducationalFeeBySection',
        'app.business.monthlyAttendanceArrears',
        'app.business.annualSportServiceFeeByAthletes',
        'app.business.annualSportServiceFeeByCoach',
        'app.business.annualSportServiceFeeByOneSection',
        'app.business.annualSportServiceFeeBySection',
        'app.business.checkPayment',
        'app.business.csvPayment',
        'app.business.checkPrintingExport',
        'app.business.dailyEducationalFeePayments',
        'app.business.dailySportServiceFeePayments',
        'app.business.educationalFeeRegister',
        'app.business.modifyFees',
        'app.business.sportServiceFeeArrears',
        'app.business.sportServiceFeeRegister',
        'app.business.dailyEducationalFeesPerCoach',
        'app.business.dailySportServiceFeesPerCoach',
        'app.athletes.athletesManagement.athleteModify.health',
        'app.athletes.athletesManagement.athleteModify.health.healthList',
        'app.athletes.athletesManagement.athleteModify.health.newHealthEvent',
        'app.athletes.athletesManagement.athleteModify.health.modifyHealthEvent',
        'app.reports.annualPresenceReports',
        'app.reports.annualPresenceReports.annualPresenceReportsByTrainers',
        'app.reports.annualPresenceReports.annualPresenceReportsBySections',
        'app.reports.annualPresenceReports.annualPresenceReportsBySportSchool',
        'app.reports.annualPresenceReports',
        'app.athletes.athletesManagement.athleteModify.athletesBodyStat',
        'app.reports.awayBudgetPlanSummary',
        'app.reports.assetInventoryReports',
        'app.reports.assetInventoryReportByUsers',
        'app.reports.assetInventoryReportByType',
        'app.reports.weeklyTravelReport',
        'app.reports.weeklyTrainingReport',
        'app.core',
        'app.dashboard',
        'app.layout',
        'app.login',
        'app.messageCenter',
        'app.messageCenter.messageCenter',
        'app.messageCenter.newMessage',
        'app.messageCenter.sentMessages',
        'app.paint',
        'app.parentDashboard',
        'app.parentMonthlyAttendance',
        'app.athletes.athletesManagement.athleteModify.files',
        'app.athletes.athletesManagement.athleteModify.psychology',
        'app.athletes.athletesManagement.athleteModify.psychology.performanceMotivationTest',
        'app.athletes.athletesManagement.athleteModify.psychology.performanceMotivationTestEvaluation',
        'app.athletes.athletesManagement.athleteModify.psychology.pieronTest',
        'app.athletes.athletesManagement.athleteModify.psychology.pieronTestEvaluation',
        'app.athletes.athletesManagement.athleteModify.psychology.summary',
        'app.athletes.athletesManagement.athleteModify.psychology.tenesseeSelfImageScale',
        'app.athletes.athletesManagement.athleteModify.psychology.tenesseeSelfImageScaleEvaluation',
        'app.athletes.athletesManagement.athleteModify.psychology.wartegg',
        'app.reports',
        'app.reports.anthropometryTestPrinting',
        'app.reports.athleteTransfer',
        'app.reports.duplicatedAthletes',
        'app.reports.managementReports',
        'app.reports.managementReports.annualCompetitivePlans',
        'app.reports.managementReports.monthlyAttendanceReports',
        'app.reports.managementReports.monthlyCompetitivePlans',
        'app.reports.managementReports.weeklyTrainingPlans',
        'app.reports.managementReports.trainingDiaryReports',
        'app.reports.monthlyCompetitivePlans',
        'app.reports.playUpAndDown',
        'app.reports.raceResultsReport',
        'app.reports.statisticalReports',
        'app.reports.trainingAttendanceReport',
        'app.reports.sportSpecificReport',
        'app.reports.expiringDocuments',
        'app.reports.absenceReport',
        'app.reports.centralReport',
        'app.reports.centralReport.centralReportAllIn',
        'app.reports.centralReport.centralReportByRating',
        'app.athletes.athletesManagement.athleteModify.centralReportOfAthlete',
        'app.reports.statisticalReports.activeAthletesByCoach',
        'app.reports.statisticalReports.activeAthletesBySection',
        'app.reports.statisticalReports.joinedAthletesBySection',
        'app.reports.statisticalReports.leavingAthletesBySection',
        'app.reports.statisticalReports.listAthletes',
        'app.reports.statisticalReports.leavingAthletesByCoach',
        'app.reports.statisticalReports.joinedAthletesByCoach',
        'app.reports.statisticalReports.activeAthletesByAge',
        'app.reports.statisticalReports.activeAthletesByMonth',
        'app.reports.statisticalReports.athletesByBirthYear',
        'app.reports.statisticalReports.athletesByYear',
        'app.reports.statisticalReports.activeAthletesInSectionByMonth',
        'app.reports.statisticalReports.athletesNotPresentOnMonthlyAttendance',
        'app.athletes.athletesManagement.athleteModify.trainingVisitReport',
        'app.reports.healthReport',
        'app.schedule',
        'app.search',
        'app.search.athletes',
        'app.search.athletes.simple',
        'app.search.athletes.memberList',
        'app.search.user',
        'app.search.user.searchuser',
        'app.settings',
        'app.settings.ageGroupChange',
        'app.settings.athleteTransfer',
        'app.settings.createSportSpecificTest',
        'app.settings.editSection',
        'app.settings.editHealthType',
        'app.settings.schoolManagement',
        'app.settings.schoolManagement.addSchool',
        'app.settings.schoolManagement.editSchool',
        'app.settings.schoolManagement.schoolList',
        'app.settings.systemSettings',
        'app.settings.userGroupsManagement',
        'app.settings.userManagement',
        'app.settings.userManagement.newUser',
        'app.settings.userManagement.modifyUser',
        'app.settings.userManagement.userSettings',
        'app.settings.userManagement.userList',
        'app.settings.userRights',
        'app.settings.usersBelongingToAthletes',
        'ejangular',
        'gettext',
        'LocalStorageModule',
        'app.other',
        'app.other.files'
    ]);
})();
