(function () {
    'use strict';

    angular
        .module('app.reports.awayBudgetPlanSummary')
        .controller('AwayBudgetPlanSummaryController', AwayBudgetPlanSummary);

    AwayBudgetPlanSummary.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'authenticationService', '$scope', '$filter', '$compile', 'paramHelper', '$state', '$timeout'];
    /* @ngInject */
    function AwayBudgetPlanSummary(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $filter, $compile, paramHelper, $state, $timeout) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AwayBudgetPlanSummary');

        vm.sectionList = [];
        vm.sectionId = undefined;
        vm.ageGroupList = [];
        vm.ageGroupId = undefined;
        vm.coachList = [];
        vm.coachId = undefined;
        vm.colIndex = undefined;
        vm.rowindex = undefined;
        vm.toRange = undefined;
        vm.newRange = undefined;
        vm.fromRange = undefined;
        vm.datasource = [];
        vm.onlyStartDate = false;
        vm.g = [];
        vm.selectdateFrom = getMonday(new Date(), 0);
        vm.selectdateTo = getMonday(new Date(), 6);
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.openAthlete = openAthlete;
        vm.openCoach = openCoach;
        vm.show = show;

        vm.gridToolbar = ['printGrid', 'excelExport'];
        vm.gridExcelExport = dataservice.getURL() + 'AtjatszoSportolokSelect/ExcelExport/';
        vm.toolbarHandler = toolbarHandler;

        vm.columns = [
            {field: 'VersenySorszam', headerText: gettextCatalog.getString('TervSorszam'), width: 50, textAlign: 'center'},
            {field: 'Szakosztaly', headerText: gettextCatalog.getString('Section'), width: 120, textAlign: 'center'},
            {field: 'NevHelyCsopRsz', headerText: gettextCatalog.getString('NevHelyCsopRsz'), width: 110, textAlign: 'center'},
            {field: 'VersenyKezdesIdopont', headerText: gettextCatalog.getString('RaceStartTime'), width: 120, textAlign: 'center'},
            {field: 'VersenyBefejezesIdopont', headerText: gettextCatalog.getString('RaceEndTime'), width: 120, textAlign: 'center'},
            {field: 'Megnevezes', headerText: gettextCatalog.getString('Appellation'), width: 100, textAlign: 'center'},
            {field: 'UtazasiKoltseg', headerText: gettextCatalog.getString('TravelCosts'), width: 110, textAlign: 'center', format: '{0:N0}'},
            {field: 'Etkezes', headerText: gettextCatalog.getString('Meal'), width: 100, textAlign: 'center', format: '{0:N0}'},
            {field: 'Szallas', headerText: gettextCatalog.getString('Accommodation'), width: 100, textAlign: 'center', format: '{0:N0}'},
            {field: 'NevezesiDij', headerText: gettextCatalog.getString('EntryFee'), width: 100, textAlign: 'center', format: '{0:N0}'},
            {field: 'KikuldetesiKoltseg', headerText: gettextCatalog.getString('DelegacyCost'), width: 100, textAlign: 'center', format: '{0:N0}'},
            {field: 'Egyeb', headerText: gettextCatalog.getString('Other'), width: 100, textAlign: 'center', format: '{0:N0}'},
            {field: 'Osszesen', headerText: gettextCatalog.getString('Total'), width: 100, textAlign: 'center', format: '{0:N0}'},
            {field: 'ElolegOsszege', headerText: gettextCatalog.getString('AmountOfAdvance'), width: 130, textAlign: 'center', format: '{0:N0}'},
        ];

        function mergeGrid() {
            $('#Grid').ejGrid({
                //The datasource "window.gridData" is referred from 'http://js.syncfusion.com/demos/web/scripts/jsondata.min.js'
                allowPaging: true,
                allowCellMerging: true,
                load: оnLoad,
                mergeCellInfo: cellmerge
            });
        }

        // function ClientSideEvents(eve) {
        //     eve.MergeCellInfo("cellmerge").Load("оnLoad");
        // }
        function оnLoad() {
            //Initialize the mergeCellRanges property in Grid model
            this.model.mergeCellRanges = [];
        }
        function cellmerge(args) {
            if (args.column.field == 'VersenySorszam' && args.rowData.VersenySorszam != null) {
                args.rowMerge(4);
                generateRange(args, {rowRange: 4});
            }
            if (args.column.field == 'Szakosztaly' && args.rowData.Szakosztaly != null) {
                args.rowMerge(4);
                generateRange(args, {rowRange: 4});
            }
            if (args.column.field == 'VersenyKezdesIdopont' && args.rowData.VersenyKezdesIdopont != null) {
                args.rowMerge(4);
                generateRange(args, {rowRange: 4});
            }
            if (args.column.field == 'VersenyBefejezesIdopont' && args.rowData.VersenyBefejezesIdopont != null) {
                args.rowMerge(4);
                generateRange(args, {rowRange: 4});
            }
            if (args.column.field == 'ElolegOsszege' && args.rowData.ElolegOsszege != null) {
                args.rowMerge(3);
                generateRange(args, {rowRange: 3});
            }
            if (args.column.field == 'UtazasiKoltseg' && args.rowData.Megnevezes == 'Megjegyzés') {
                args.colMerge(8);
                generateRange(args, {colRange: 8});
            }
        }

        function generateRange(args, range) {
            //Generate the merged cell ranges like [“C2:D2”,”C4:D6”]
            vm.g = $('.e-grid').ejGrid('instance');
            if (vm.g.model.mergeCellRanges == null) {
                vm.g.model.mergeCellRanges = [];
            }
            vm.colIndex = vm.g.getColumnIndexByField(args.column.field);
            vm.rowIndex = $(args.cell).closest('tr').index() + 2;
            vm.fromRange = String.fromCharCode(65 + vm.colIndex) + vm.rowIndex.toString(), vm.toRange, vm.newRange;

            if (!ej.isNullOrUndefined(range.colRange) && !ej.isNullOrUndefined(range.rowRange)) {
                vm.rowIndex = vm.rowIndex + range.rowRange - 1;
                vm.toRange = String.fromCharCode(65 + (vm.colIndex + range.colRange - 1)) + vm.rowIndex.toString();
                vm.newRange = vm.fromRange + ':' + vm.toRange;
            } else if (!ej.isNullOrUndefined(range.colRange)) {
                vm.toRange = String.fromCharCode(65 + (vm.colIndex + range.colRange - 1)) + vm.rowIndex.toString();
                vm.newRange = vm.fromRange + ':' + vm.toRange;
            } else if (!ej.isNullOrUndefined(range.rowRange)) {
                vm.rowIndex = vm.rowIndex + range.rowRange - 1;
                vm.toRange = String.fromCharCode(65 + vm.colIndex) + vm.rowIndex.toString();
                vm.newRange = vm.fromRange + ':' + vm.toRange;
            }
            vm.g.model.mergeCellRanges.push(vm.newRange); //generated range will be added into the Grid model
        }

        activate();

        function activate() {
            authenticationService.getRight('MEGTIDEGENKOLTSTERV').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    getSections();
                    setDropDowns();
                }
            });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'awayBudgetPlanSummary.sectionId',
                'awayBudgetPlanSummary.ageGroupId',
                'awayBudgetPlanSummary.coachId',
                'awayBudgetPlanSummary.onlyStartDate',
            ])
            ];
            $q.all(promises).then(function (results) {
                vm.sectionId = results[0]['awayBudgetPlanSummary.sectionId'];
                vm.ageGroupId = results[0]['awayBudgetPlanSummary.ageGroupId'];
                vm.coachId = results[0]['awayBudgetPlanSummary.coachId'];

                angular.element('#onlyStartDate').ejCheckBox({checked: results[0]['awayBudgetPlanSummary.onlyStartDate']});
                $timeout(function () {
                    $scope.$apply();
                }, 0);
            });
        }

        function getSections() {
            dataservice.sectionDropDownList(0, true)
                .then(function (data) {
                    if (data.itemsList && data.itemsList.length > 1) {
                        data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    }
                    vm.sectionList = data.itemsList;
                });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.sectionList && vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        // Run after section selected
        function sectionSelect(args) {
            getAgeGroups(args.value);
            getActualCoachesList(0);
        }

        function getAgeGroups(sectionId) {
            return dataservice.ageGroupDropDownList(sectionId).then(function (data) {
                if (data.itemsList && data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                }
                vm.ageGroupList = data.itemsList;
            });
        }

        // Run after data bounded
        function ageGroupDataBound() {
            if (vm.ageGroupList && vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupId);
                if (vm.ageGroupId) {
                    getActualCoachesList(0);
                }
            }
        }

        // Run after age group selected
        function ageGroupSelect(args) {
            getActualCoachesList(args.value);
        }

        function getActualCoachesList(ageGroupId) {
            return dataservice.coachDropDownList(vm.sectionId, ageGroupId).then(function (data) {
                if (data.itemsList && data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                }
                vm.coachList = data.itemsList;
            });
        }

        // Run after data bounded
        function coachDataBound() {
            if (vm.coachList && vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
            }
        }

        // Run after age coach selected
        function coachSelect(args) {
            vm.coachId = args.value;
        }

        function getMonday(d, plusDay) {
            d = new Date(d);
            var day = d.getDay();
            var diff = d.getDate() - day + (day == 0 ? -6 : 1) + plusDay; // adjust when day is sunday
            return new Date(d.setDate(diff));
        }

        function show() {
            vm.onlyStartDate = $('#onlyStartDate').ejCheckBox('isChecked');
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            paramHelper.setParams([
                {'awayBudgetPlanSummary.sectionId': vm.sectionId},
                {'awayBudgetPlanSummary.ageGroupId': vm.ageGroupId},
                {'awayBudgetPlanSummary.coachId': vm.coachId},
                {'awayBudgetPlanSummary.onlyStartDate': vm.onlyStartDate},
            ]);
            dataservice.KoltsegtervOsszesitoKimutatas(vm.sectionId, vm.ageGroupId, vm.coachId, vm.selectdateFrom, vm.selectdateTo, vm.onlyStartDate).then(function (data) {
                vm.datasource = data.itemsList;
                mergeGrid();
            });
        }

        function openAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                dataservice.KoltsegtervOsszesitoExcelExport(vm.sectionId, vm.ageGroupId, vm.coachId, vm.selectdateFrom, vm.selectdateTo, vm.onlyStartDate).then(function (response) {
                    var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
                    if (urlCreator) {
                        var url = response.data;
                        var a = document.createElement('a');
                        document.body.appendChild(a);
                        a.href = url;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                });
            }
        }
    }
})();
