(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.athletesAssets')
        .controller('AthletesAssetsController', AthletesAssetsController);

    AthletesAssetsController.$inject = ['log', 'logger', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'paramHelper', 'authenticationService', '$scope'];

    function AthletesAssetsController(log, logger, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $scope) {
        var vm = this;
        $rootScope.vm = vm;

        vm.assetColumns = [
            {field: 'EszkozId', visible: false, isPrimaryKey: true},
            {field: 'Megnevezes', headerText: gettextCatalog.getString('Megnevezes'), width: 70},
            {field: 'Leiras', headerText: gettextCatalog.getString('Leiras'), width: 50},
            {field: 'Azonosito', headerText: gettextCatalog.getString('Azonosito'), width: 50},
            {field: 'Meret', headerText: gettextCatalog.getString('Meret'), width: 30},
            {field: 'Ar', headerText: gettextCatalog.getString('Ar'), width: 30}
        ];
        vm.types = [];
        vm.newAsset = {};
        vm.selectedAssets = [];
        vm.Issue = Issue;
        vm.IssueAsset = IssueAsset;
        vm.IssueNewAsset = IssueNewAsset;
        vm.TakeBack = TakeBack;
        vm.takebackEnabled = false;
        vm.chooseEnabled = false;
        vm.ESZKMOZG = false;
        vm.TakeBackComment = '';
        vm.IssueComment = '';

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.assetColumns,
            selectionSettings: {type: 'multiple'},
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#AthletesAssetGrid'), $scope);
                }
            }
        });
        grid.appendTo('#AthletesAssetGrid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        var gridPopUp = new ej2.grids.Grid({
            dataSource: [],
            allowTextWrap: true,
            allowSorting: true,
            locale: 'hu-HU',
            gridLines: 'both',
            columns: vm.assetColumns,
            selectionSettings: {type: 'multiple'},
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#AssetGrid'), $scope);
                }
            }
        });
        gridPopUp.appendTo('#AssetGrid');

        activate();

        function activate() {
            $q.all([
                authenticationService.getRight('ESZKNYILVMEGT'),
                authenticationService.getRight('ESZKMOZG'),
                paramHelper.getParams(['athleteModify.sportoloId', 'athleteModify.sportoloNev'], [])
            ]).then(function (results) {
                if (!results[0]) {
                    log.permissionError(true);
                } else {
                    vm.ESZKMOZG = results[1];
                    vm.sportoloId = results[2]['athleteModify.sportoloId'];
                    vm.sportoloNev = results[2]['athleteModify.sportoloNev'];
                    $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('AthletesAssets');
                    IssuedAssets();
                }
            });
        }

        function IssuedAssets() {
            dataservice.IssuedAssets(vm.sportoloId, 0).then(function (response) {
                grid.dataSource = response.data;
            });
        }

        function getSelectedAssetIds(targetGrid) {
            var selectedAssets = targetGrid.getSelectedRecords();
            var assetIds = [];
            selectedAssets.forEach(function (asset) {
                assetIds.push(asset.EszkozId);
            });
            return assetIds;
        }

        function IssueAsset() {
            if (vm.ESZKMOZG) {
                angular.element('#issueDialog').data('ejDialog').open();
                dataservice.AssetsByType(0, 'issuable').then(function (data) {
                    gridPopUp.dataSource = data.data;
                });
                dataservice.assetTypeList().then(function (data) {
                    vm.types = data.itemsList;
                    vm.types.forEach(function (item) {
                        item.value = item.TipusId;
                        item.text = item.TipusNev;
                    });
                });
            } else {
                log.permissionError();
            }
        }

        function Issue() {
            vm.chooseEnabled = false;
            vm.IssueComment = '';
            angular.element('#issueDialog').data('ejDialog').close();
            dataservice.AssetIssue(getSelectedAssetIds(gridPopUp), 0, vm.sportoloId, vm.IssueComment).then(function () {
                log.successMsg('IssuedSuccessfully');
                IssuedAssets();
            });
        }

        function TakeBack() {
            vm.takebackEnabled = false;
            dataservice.AssetTakeBack(getSelectedAssetIds(grid), vm.TakeBackComment).then(function () {
                vm.TakeBackComment = '';
                log.successMsg('TakenBackSuccessfully');
                IssuedAssets();
            });
        }

        function IssueNewAsset() {
            angular.element('#issueDialog').data('ejDialog').close();
            vm.chooseEnabled = false;
            var HTMLtext = createHtmlSwalContent([
                {id: 'Megnevezes', text: gettextCatalog.getString('Megnevezes'), value: ''},
                {id: 'Leiras', text: gettextCatalog.getString('Leiras'), value: ''},
                {id: 'Azonosito', text: gettextCatalog.getString('Azonosito'), value: ''},
                {id: 'Meret', text: gettextCatalog.getString('Meret'), value: ''},
                {id: 'Ar', text: gettextCatalog.getString('Ar'), value: ''},
                {id: 'darab', text: gettextCatalog.getString('NumberMassAdd'), value: ''},
                {id: 'AssetType', text: gettextCatalog.getString('AssetType'), value: '', ac: true}
            ]);
            swal({
                title: gettextCatalog.getString('CreateAsset'),
                html: HTMLtext,
                type: 'info',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Ok'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    vm.newAsset.EszkozId = -1;
                    vm.newAsset.TipusId = 0;
                    vm.newAsset.TipusNev = '';
                    vm.newAsset.Megnevezes = document.getElementById('Megnevezes').value;
                    vm.newAsset.Leiras = document.getElementById('Leiras').value;
                    vm.newAsset.Azonosito = document.getElementById('Azonosito').value;
                    vm.newAsset.Meret = document.getElementById('Meret').value;
                    vm.newAsset.Ar = document.getElementById('Ar').value;
                    vm.newAsset.darab = document.getElementById('darab').value;
                    vm.newAsset.TipusNev = document.getElementById('AutoCompleteSwal').value;
                    console.log('vm.newAsset', vm.newAsset);
                    if (!vm.newAsset.Megnevezes || vm.newAsset.Megnevezes.length == 0) {
                        log.errorMsg(gettextCatalog.getString('AssetNameMustNotBeEmpty'));
                        return;
                    }
                    if (vm.newAsset.TipusId == 0 && (!vm.newAsset.TipusNev || vm.newAsset.TipusNev.length == 0)) {
                        log.errorMsg(gettextCatalog.getString('AssetTypeNameMustNotBeEmpty'));
                        return;
                    }
                    dataservice.AssetManager(vm.newAsset).then(function (response) {
                        dataservice.AssetIssue(response.data.EszkozIdk, 0, vm.sportoloId, vm.IssueComment).then(function () {
                            vm.IssueComment = '';
                            log.successMsg('IssuedSuccessfully');
                            IssuedAssets();
                        });
                    });
                }
            });
            angular.element('#AutoCompleteSwal').ejAutocomplete({
                dataSource: vm.types,
                change: function (arg) {
                    vm.newAsset.TipusId = 0;
                    vm.newAsset.TipusNev = arg.value;
                },
                value: vm.newAsset.TipusId,
                width: '100%'
            });
        }

        function createHtmlSwalContent(arr) {
            var HTMLtext = '<div style="margin-top: 10px;" >';
            arr.forEach(function (item) {
                HTMLtext += '<div class="row">';
                HTMLtext += '<label class="col-lg-6" style="text-align: left; margin-top:5px;">' + item.text + '</label>';
                if (item.hasOwnProperty('ac')) {
                    HTMLtext += '<input class="col-lg-6 col-lg-offset-6" id="AutoCompleteSwal" ej-autocomplete></input><br>';
                    // HTMLtext += '<cts-auto-complete name="ac" label="'+item.text+'" value="" change="vm.AutoCompleteChange" validation-rules="{required: true}" datasource="vm.types" style="width: 100%"></cts-auto-complete>'
                } else if (item.hasOwnProperty('dd')) {
                    HTMLtext += '<input class="col-lg-6 col-lg-offset-6" id="DropDownSwal" ej-dropdownlist></input><br>';
                } else {
                    if (item.value && item.value.length > 0) {
                        HTMLtext += '<input type="text" value="' + item.value + '" id="' + item.id + '"></input><br>';
                    } else {
                        HTMLtext += '<input type="text" value="" id="' + item.id + '"></input><br>';
                    }
                }
                HTMLtext += '</div>';
            });
            HTMLtext += '</div><br>';
            return HTMLtext;
        }
    }
})();

