(function () {
    'use strict';

    angular
        .module('app.reports.expiringDocuments')
        .controller('ExpiringDocumentsController', ExpiringDocumentsController);

    ExpiringDocumentsController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'paramHelper', 'authenticationService', '$scope', '$filter', '$compile', '$state'];

    /* @ngInject */
    function ExpiringDocumentsController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $scope, $filter, $compile, $state) {
        var vm = this;
        $rootScope.vm = vm;
        vm.params = [];
        $rootScope.title = gettextCatalog.getString('ExpiringDocuments');
        vm.searchSPC = true;
        vm.searchPP = true;
        vm.searchPID = true;
        vm.sectionList = [];
        vm.ageGroupList = [];
        vm.coachList = [];
        vm.search = search;
        vm.openCoach = openCoach;
        vm.openAthlete = openAthlete;
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.selectdateTo = new Date(new Date().getFullYear(), new Date().getMonth() + 1);
        vm.SelectedSectionID = 0;
        vm.SelectedAgeGroupID = 0;
        vm.SelectedCoachID = 0;

        vm.columns = [
            {
                field: 'SportoloNeve',
                headerText: gettextCatalog.getString('Athlete'),
                textAlign: 'center',
                width: 120
            },
            {
                field: 'SportorvosiLejar',
                headerText: gettextCatalog.getString('SportPhysicalCertificateExpirationDate'),
                textAlign: 'left',
                width: 250
            },
            {
                field: 'UtlevelLejar',
                headerText: gettextCatalog.getString('PassportExpirationDate'),
                textAlign: 'left',
                width: 180
            },
            {
                field: 'SzemelyiLejar',
                headerText: gettextCatalog.getString('PersonalIDExpirationDate'),
                textAlign: 'left',
                width: 180
            },
            {
                field: 'SzakosztalyNeve',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'center',
                width: 125
            },
            {
                field: 'KorosztalyNeve',
                headerText: gettextCatalog.getString('AgeGroup'),
                textAlign: 'center',
                width: 120
            },
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                textAlign: 'center',
                width: 120
            }
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            var promises = [authenticationService.getRight('LEJAROMEGT')];
            $q.all(promises).then(function (res) {
                if (!res[0]) {
                    log.permissionError(true);
                } else {
                    getSections();
                    getAgeGroups();
                    getCoaches();
                    setDropDowns();
                }
            })
                .catch(function () {
                    log.permissionError(true);
                });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'ed.section',
                'ed.agegroup',
                'ed.coach',
                'ed.to'
            ])
            ];
            $q.all(promises).then(function (results) {
                if (results[0]['ed.to'] != null) {
                    vm.selectdateTo = results[0]['ed.to'];
                    vm.savedSection = results[0]['ed.section'];
                    vm.savedAgegroup = results[0]['ed.agegroup'];
                    vm.savedCoach = results[0]['ed.coach'];
                }
            });
        }

        function search() {
            if (!vm.searchSPC && !vm.searchPP && !vm.searchPID) {
                swal({
                    title: gettextCatalog.getString('AtLeastOneConditionHaveToBeSelected'),
                    type: 'warning',
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('Ok'),
                });
                return;
            }
            paramHelper.setParams([
                {'ed.section': vm.SelectedSectionID},
                {'ed.agegroup': vm.SelectedAgeGroupID},
                {'ed.coach': vm.SelectedCoachID},
                {'ed.to': vm.selectdateTo}
            ]);
            dataservice.getExpiringDocuments(vm.SelectedSectionID, vm.SelectedAgeGroupID, vm.SelectedCoachID, vm.selectdateTo, vm.searchSPC, vm.searchPP, vm.searchPID)
                .then(function (data) {
                    var athleteList = data.itemsList;
                    athleteList = $filter('dateStringFilter')(data.itemsList, 'SportorvosiLejar');
                    grid.dataSource = $filter('dateStringFilter')(athleteList, 'UtlevelLejar');
                });
        }

        function sectionSelect(args) {
            vm.SelectedCoachID = 0;
            vm.SelectedAgeGroupID = 0;
            vm.SelectedSectionID = args.value;
            if (args.value == -1) {
                vm.SelectedSectionID = 0;
            }
            getActualCoachesList();
            getAgeGroups();
            getTeamsList();
            vm.showDelButton = false;
        }

        function ageGroupSelect(args) {
            vm.SelectedCoachID = 0;
            vm.SelectedAgeGroupID = args.value;
            if (args.value == -1) {
                vm.SelectedAgeGroupID = 0;
            }
            getActualCoachesList();
            getTeamsList();
            vm.showDelButton = false;
        }

        function coachSelect(args) {
            vm.SelectedCoachID = args.value;
            if (args.value == -1) {
                vm.SelectedCoachID = 0;
            }
            vm.showDelButton = false;
        }

        function getSections() {
            dataservice.sectionDropDownList(0)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: -1, text: gettextCatalog.getString('All')};
                        vm.sectionList.splice(0, 0, all);
                    }
                });
        }

        function getAgeGroups() {
            dataservice.ageGroupDropDownList(vm.SelectedSectionID)
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                    var all = {value: -1, text: gettextCatalog.getString('All')};
                    vm.ageGroupList.splice(0, 0, all);
                });
        }

        function getActualCoachesList() {
            return dataservice.coachDropDownList(vm.SelectedSectionID, vm.SelectedAgeGroupID, new Date(2000, 1, 1), vm.selectdateTo)
                .then(function (data) {
                    vm.coachList = data.itemsList;
                    var all = {value: -1, text: gettextCatalog.getString('All')};
                    vm.coachList.splice(0, 0, all);
                });
        }

        function getCoaches() {
            return dataservice.coachSelect().then(
                function (data) {
                    vm.coachList = data.itemsList;
                    return vm.coachList;
                }
            );
        }

        function getTeamsList() {
            dataservice.teamsDropDownList(vm.SelectedSectionID, vm.SelectedAgeGroupID, vm.SelectedCoachID)
                .then(function (data) {
                    vm.teamsList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: -1, text: gettextCatalog.getString('All')};
                        vm.teamsList.splice(0, 0, all);
                    }
                });
        }

        function openAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach() {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': 0}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function sectionDataBound() {
            if (vm.savedSection > 0) {
                angular.element('#section').ejDropDownList('selectItemByValue', vm.savedSection);
            } else {
                if (vm.sectionList.length === 1) {
                    angular.element('#section').ejDropDownList('selectItemByValue', vm.sectionList[0]);
                } else if (vm.SelectedSectionID > 0) {
                    angular.element('#section').ejDropDownList('selectItemByValue', vm.SelectedSectionID);
                }
            }
        }

        function ageGroupDataBound() {
            if (vm.savedAgegroup > 0) {
                angular.element('#agegroup').ejDropDownList('selectItemByValue', vm.savedAgegroup);
            } else {
                if (vm.ageGroupList.length === 1) {
                    angular.element('#agegroup').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
                } else if (vm.SelectedAgeGroupID > 0) {
                    angular.element('#agegroup').ejDropDownList('selectItemByValue', vm.SelectedAgeGroupID);
                }
            }
        }

        function coachDataBound() {
            if (vm.savedCoach > 0) {
                angular.element('#coach').ejDropDownList('selectItemByValue', vm.savedCoach);
            } else {
                if (vm.coachList.length === 1) {
                    angular.element('#coach').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                } else if (vm.SelectedCoachID > 0) {
                    angular.element('#coach').ejDropDownList('selectItemByValue', vm.SelectedCoachID);
                }
            }
        }
    }
})();

