(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athletesList')
        .controller('AthletesListController', athletesListController);

    athletesListController.$inject = ['log', 'dataservice', '$q', '$state', 'gettextCatalog', '$scope', '$compile', 'paramHelper', 'authenticationService', '$filter', '$rootScope', '$timeout'];

    function athletesListController(log, dataservice, $q, $state, gettextCatalog, $scope, $compile, paramHelper, authenticationService, $filter, $rootScope, $timeout) {
        var vm = this;
        $rootScope.vm = vm; // jshint ignore: line
        vm.params = []; // for parameters sending

        $rootScope.title = gettextCatalog.getString('AthletesList');

        // Permissions
        vm.MEGTSPORTLIST = false;
        vm.MEGTCSOPORT = false;
        vm.MEGTKOROSZT = false;
        vm.MODSPORTOLO = false;
        vm.UJSPORTOLO = false;
        vm.MEGTSPORTLIST = false;
        vm.SPORTKILEP = false;
        vm.MODFELH = false;
        vm.KMVERSEREDM = false;
        vm.athleteResultsBtnEnabled = false;
        // Dropdownlist's sources
        vm.sectionList = [];
        vm.ageGroupList = [];
        vm.athleteList = [];
        // Dropdown values
        vm.selectedSectionId = '';
        vm.selectedAgeGroupId = '';
        vm.selectedAgeGroupName = '';
        vm.sportoliId = -1;
        // Checkbox variables
        vm.checkboxEnabled = false;
        // Grid's details
        vm.selectedAthleteExitDate = '';
        vm.exitAthleteDateOk = false;
        vm.selectedExitDate = undefined;
        vm.athleteCompetitionResult = false;
        // Grid columns
        vm.columns = [
            {type: 'checkbox', textAlign: 'center', width: 35},
            {
                field: 'Nev',
                headerText: gettextCatalog.getString('Athletes'),
                textAlign: 'left',
                template: '#templateDataRow1',
                width: 150
            },
            {field: 'SzuletesiIdo', headerText: gettextCatalog.getString('BirthDate'), textAlign: 'left', width: 100},
            {field: 'KorcsoportNeve', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'left', width: 100},
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                textAlign: 'left',
                template: '#templateDataRow2',
                width: 150
            },
            {
                field: 'Tagdij',
                headerText: gettextCatalog.getString('PayedSportServiceFee'),
                textAlign: 'left',
                width: 100
            },
            {
                field: 'Oktatasidij',
                headerText: gettextCatalog.getString('PayedEducationalFee'),
                textAlign: 'left',
                width: 100
            },
            {field: 'Egyenleg', headerText: gettextCatalog.getString('Balance'), textAlign: 'left', width: 100}
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Public functions
        vm.compile = compile;
        // sectionDropDown
        vm.sectionDataBound = sectionDataBound; // Public
        vm.sectionSelect = sectionSelect; // Public
        // ageGroupDropDown
        vm.ageGroupDataBound = ageGroupDataBound; // Public
        vm.ageGroupSelect = ageGroupSelect; // Public
        // grid
        vm.goToUser = goToUser; // Mine
        // vm.actionComplete = actionComplete; // 2016. 06. 09. nincs használva
        // grid and button newAthletes
        vm.goToNewAthlete = goToNewAthlete; // Mine
        // checkbox
        vm.inactiveChange = inactiveChange;
        vm.modifyAthletes = modifyAthletes; // Mine
        vm.modifyAthletesButton = modifyAthletesButton; // Mine
        // button goneAthletes
        vm.goneAthletesBtn = goneAthletesBtn; // Mine
        vm.competitionResult = competitionResult;

        // Activate page
        activate();

        function activate() {
            if (!authenticationService.isAuthenticated()) {
                $rootScope.login();
                return;
            }
            $q.all([paramHelper.getParams([],
                [
                    'athleteList.selectedSectionId',
                    'athleteList.selectedAgeGroupId',
                    'athleteList.inactiveAthletes'
                ]),
                authenticationService.getRight('MEGTSPORTLIST'),
                authenticationService.getRight('MEGTCSOPORT'),
                authenticationService.getRight('MEGTKOROSZT'),
                authenticationService.getRight('MODSPORTOLO'),
                authenticationService.getRight('UJSPORTOLO'),
                authenticationService.getRight('SPORTKILEP'),
                authenticationService.getRight('MODFELH'),
                authenticationService.getRight('KMVERSEREDM')
            ]).then(function (results) {
                // Nem épül fel a DOM Three, mire érték adódik
                $timeout(function () {
                    vm.MEGTSPORTLIST = results[1];
                    vm.MEGTCSOPORT = results[2];
                    vm.MEGTKOROSZT = results[3];
                    vm.MODSPORTOLO = results[4];
                    vm.UJSPORTOLO = results[5];
                    vm.SPORTKILEP = results[6];
                    vm.MODFELH = results[7];
                    vm.KMVERSEREDM = results[8];

                    if (!(vm.MEGTSPORTLIST)) {
                        log.permissionError(true);
                        return;
                    }

                    // set selected values for dropDownLists
                    vm.params.sectionId = results[0]['athleteList.selectedSectionId'];
                    vm.params.selectedAgeGroupId = results[0]['athleteList.selectedAgeGroupId'];
                    // set selected values for checkBox
                    vm.params.inactiveAthletes = results[0]['athleteList.inactiveAthletes'];
                    // Permission for delete
                    vm.TORLCSOPORT = results[1];
                    // Permission for modify
                    vm.MODCSOPORT = results[2];
                    // Set params for checkBox
                    // if (!vm.params.inactiveAthletes) {
                    // vm.inactiveAthletes = false;
                    // } else {
                    // vm.inactiveAthletes = true;
                    // }
                    angular.element('#active').ejCheckBox({checked: vm.params.inactiveAthletes});
                    // Get section list
                    getSections();
                }, 0);
            });
        }

        function getSections() {
            dataservice.sectionDropDownList(0)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                vm.selectedSectionId = vm.sectionList[0].value;
            } else {
                vm.selectedSectionId = vm.params.sectionId;
            }
        }

        function sectionSelect(args) {
            vm.selectedSectionId = args.value;
            vm.checkboxEnabled = true;
            if (angular.isUndefined(vm.params.ageGroupId)) {
                //getTeams();
                getAthletes();
            }
            getAgeGroups();
            paramHelper.setParams([{'athleteList.selectedSectionId': vm.selectedSectionId}]);
        }

        function getAgeGroups() {
            dataservice.ageGroupDropDownList(vm.selectedSectionId)
                .then(function (data) {
                    if (data.itemsList.length > 1) {
                        data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    }
                    vm.ageGroupList = data.itemsList;
                    if (vm.ageGroupList.length == 1) {
                        $timeout(function () {
                            vm.ageGroupId = vm.ageGroupList[0].value;
                        }, 0);
                    }
                });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                vm.selectedAgeGroupId = vm.ageGroupList[0].value;
            } else {
                if (vm.params.sectionId && vm.params.selectedAgeGroupId && parseInt(vm.params.sectionId) === parseInt(vm.selectedSectionId)) {
                    vm.selectedAgeGroupId = vm.params.selectedAgeGroupId;
                } else {
                    vm.selectedAgeGroupId = 0;
                }
            }
        }

        function ageGroupSelect(args) {
            vm.selectedAgeGroupId = args.value;
            //getTeams();
            getAthletes();
            paramHelper.setParams([{'athleteList.selectedAgeGroupId': vm.selectedAgeGroupId}]);
        }

        function getAthletes() {
            dataservice.athletesSelect(vm.selectedSectionId, vm.selectedAgeGroupId, vm.inactiveAthletes)
                .then(function (data) {
                    vm.athleteResultsBtnEnabled = false;
                    grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'SzuletesiIdo');
                });
        }

        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }

        function inactiveChange(args) {
            vm.inactiveAthletes = args.value;
            getAthletes();
            paramHelper.setParams([{'athleteList.inactiveAthletes': vm.inactiveAthletes}]);
        }

        function goToUser(EdzoId) {
            if (!vm.MODFELH) {
                log.errorMsg(gettextCatalog.getString('YouHaveNoPermissionToThisFunction'));
                return;
            }
            paramHelper.setParams([{'newUser.userId': EdzoId}]);
            $state.go('settings.userManagement.modifyUser');
        }

        function goToNewAthlete() {
            if (vm.selectedSectionId > 0) {
                // Save data for restore
                vm.selectedSectionName = angular.element('#sectionDropDown').data('ejDropDownList').selectedTextValue;
                vm.selectedAgeGroupName = angular.element('#ageGroupDropDown').data('ejDropDownList').selectedTextValue;

                paramHelper.setParams([
                    {'athleteNew.sportolokId': -1},
                    {'athleteNew.sectionList': vm.sectionList},
                    {'athleteNew.selectedSectionId': vm.selectedSectionId},
                    {'athleteNew.selectedSectionName': vm.selectedSectionName},
                    {'athleteNew.ageGroupList': vm.ageGroupList},
                    {'athleteNew.selectedAgeGroupId': vm.selectedAgeGroupId},
                    {'athleteNew.selectedAgeGroupName': vm.selectedAgeGroupName},
                    {'athleteList.selectedAgeGroupName': vm.selectedAgeGroupName}]);
                $state.go('athletes.athletesManagement.athleteNew');
            } else {
                log.errorMsg(gettextCatalog.getString('RequiredSection'));
            }
        }

        function modifyAthletes(id) {
            if (vm.MODSPORTOLO || vm.MEGTSPORTLIST) {
                vm.selectedAgeGroupName = angular.element('#ageGroupDropDown').data('ejDropDownList').selectedTextValue;
                paramHelper.setParams([
                    {'athleteModify.sportoloId': id},
                    {'athleteList.selectedAgeGroupName': vm.selectedAgeGroupName}]);
                $state.go('athletes.athletesManagement.athleteModify.dataSheet');
            } else {
                log.errorMsg(gettextCatalog.getString('PermissionDenied'));
            }
        }

        function modifyAthletesButton() {
            if (vm.MODSPORTOLO || vm.MEGTSPORTLIST) {
                if ($rootScope.checkRowSelection(grid, true)) {
                    vm.selectedAgeGroupName = angular.element('#ageGroupDropDown').data('ejDropDownList').selectedTextValue;
                    var selectedAthletes = $rootScope.getSelectedItems(grid);
                    paramHelper.setParams([
                        {'athleteModify.sportoloId': selectedAthletes[0].SportolokId},
                        {'athleteModify.sportoloNev': selectedAthletes[0].Nev},
                        {'athleteList.selectedAgeGroupName': vm.selectedAgeGroupName}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                }
            } else {
                log.errorMsg(gettextCatalog.getString('PermissionDenied'));
            }
        }

        function goneAthletesBtn() {
            if ($rootScope.checkRowSelection(grid)) {
                var selectedAthletes = $rootScope.getSelectedItems(grid);
                var exitAthletesNames = '';
                for (var i = 0; i < selectedAthletes.length; i++) {
                    exitAthletesNames += selectedAthletes[i].Nev + '; ';
                }

                vm.visible = false; // Not need use this for angularJs, because the swa enable appear Error message
                var HTMLarr = [];

                HTMLarr[0] = '<p id="datepickValidText" style="color: #a94442;" class="ng-hide" ng-show="vm.visible"><label>'; // It is working with Angular
                HTMLarr[1] = gettextCatalog.getString('PleasGiveTheStartMonthgoneAthlete'); // Kérlek a sportszolgáltatási díj kezdő hónapját add meg!
                HTMLarr[2] = '</label><br></p><div style="margin-bottom: 10px; width: 95%;" ><label>';
                HTMLarr[3] = gettextCatalog.getString('SelectDategoneAthlete'); // Válaszd ki a kiléptetés dátumát!
                HTMLarr[4] = '<br></label></div><div style="margin-left: 30%;"><input id="datepick" type="text"></div>';
                HTMLarr[5] = '<br><label>';
                HTMLarr[6] = gettextCatalog.getString('ExitedAthletesNamesText'); // A kilépő sportolók nevei
                HTMLarr[7] = '</label><br>';
                HTMLarr[8] = '<textarea id="exitAthleteNamesTextArea" rows="2" cols="55" style="width: 95%; resize: none; padding: 5px;">';
                HTMLarr[9] = exitAthletesNames; // set value width athletes names
                HTMLarr[10] = '</textarea><br>';
                HTMLarr[11] = '<br><label>';
                HTMLarr[12] = gettextCatalog.getString('ExitedAthletesText'); // A kiléptetés indoka
                HTMLarr[13] = '</label><br>';
                HTMLarr[14] = '<textarea id="noteTextArea" rows="4" cols="55" style="width: 95%; resize: none; padding: 5px;"></textarea>';

                var HTMLtext = HTMLarr.join('');

                swal({
                    title: gettextCatalog.getString('ExitAthlete'),
                    html: HTMLtext,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('Exiting'),
                    cancelButtonText: gettextCatalog.getString('Cancel'),
                    closeOnConfirm: false,
                    preConfirm: function () {
                        var preConfOk = false;
                        vm.selectedExitDate = angular.element('#datepick').data('ejDatePicker').getValue(); // get value
                        vm.noteExitText = angular.element('#noteTextArea').val();
                        return new Promise(function (resolve, reject) {
                            if (vm.selectedExitDate === null || !vm.exitAthleteDateOk) {
                                // Must show the error message!
                                preConfOk = false;
                                swal.showValidationError(gettextCatalog.getString('SelectExitDate')); // Válaszd ki a kiléptetés dátumát!
                                reject(gettextCatalog.getString('SelectExitDate'));
                            } else {
                                preConfOk = true;
                            }
                            if (vm.noteExitText === null || vm.noteExitText === '') {
                                // Must show the error message!
                                preConfOk = false;
                                swal.showValidationError(gettextCatalog.getString('SetNoteText')); // Írd be a kiléptetés indokát!
                                reject(gettextCatalog.getString('SetNoteText'));
                            } else {
                                preConfOk = true;
                            }
                            if (preConfOk) {
                                resolve();
                            }
                        });
                    }
                    //                closeOnCancel: false
                }).then(function (confirmed) {
                    if (confirmed.value) {
                        saveExitAthletes();
                        swal.close();
                        return true;
                    } else {
                        swal(gettextCatalog.getString('Cancelled'), gettextCatalog.getString('notExitAthlete'), 'error');
                    }
                }).then(function () {
                });
                // Set DatePicker
                angular.element('#datepick').ejDatePicker({
                    // set date format
                    dateFormat: 'yyyy-MM-dd',
                    change: function (args) {
                        // Get current date
                        var currentDate = new Date();
                        vm.selectedExitDate = new Date(args.value);
                        if (vm.selectedExitDate.getFullYear() > currentDate.getFullYear()) {
                            // Error, no correct date
                            vm.exitAthleteDateOk = false;
                        } else if (vm.selectedExitDate.getMonth() > currentDate.getMonth()) {
                            // Error, no correct date
                            vm.exitAthleteDateOk = false;
                        } else if (vm.selectedExitDate.getDate() !== getMonthLastDay(args.value)) {
                            // Error, no correct date
                            vm.exitAthleteDateOk = false;
                        } else {
                            // Ok, the date is correct
                            vm.exitAthleteDateOk = true;
                        }

                        function getMonthLastDay(selectDate) {
                            var date = new Date(selectDate);
                            var y = date.getFullYear();
                            var m = date.getMonth();
                            var lastDay = new Date(y, m + 1, 0).getDate();
                            return date.getDate();
                        }
                    }
                });
            }
        }

        // Locale saveExitAthletes()
        // The selected athletes gone out (kilépteti a kiválasztott sportolókat)
        function saveExitAthletes() {
            var data = {};
            vm.athletesId = [];
            var selectedAthletes = $rootScope.getSelectedItems(grid);
            for (var i = 0; i < selectedAthletes.length; i++) {
                vm.athletesId.push(selectedAthletes[i].SportolokId);
            }
            data.athletesId = vm.athletesId;
            data.selectedExitDate = vm.selectedExitDate;
            data.noteExitText = vm.noteExitText;
            data.firstSection = true;
            dataservice.saveExitAthletes(data)
                .then(function (answer) {
                    // swal() kiírni az eredményt
                    if (answer.retValue) {
                        log.successMsg('SuccesfulExitAthlete');
                        getAthletes();
                    } else {
                        swal({
                            title: gettextCatalog.getString('SuccesfulExitAthleteResult'),
                            text: gettextCatalog.getString('FailedExitAthleteResult'),
                            type: 'warning',
                            confirmButtonClass: 'btn-danger',
                            confirmButtonText: 'Ok'
                        });
                    }
                });
        }

        function competitionResult() {
            if ($rootScope.checkRowSelection(grid, true)) {
                vm.selectedAgeGroupName = angular.element('#ageGroupDropDown').data('ejDropDownList').selectedTextValue;
                var selectedAthletes = $rootScope.getSelectedItems(grid);
                paramHelper.setParams([{'raceResultsReport.athleteData': selectedAthletes[0]}]);
                $state.go('reports.raceResultsReport');
            }
        }
    }
})();

