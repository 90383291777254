(function () {
    'use strict';

    angular
        .module('app.reports.assetInventoryReportByType')
        .controller('AssetInventoryReportByTypeController', AssetInventoryReportByType);
    AssetInventoryReportByType.$inject = ['logger', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'authenticationService', '$scope', 'log', '$filter', 'paramHelper', '$timeout'];
    /* @ngInject */
    function AssetInventoryReportByType (logger, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, log, $filter, paramHelper) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AssetInventoryReportByType');

        vm.active = false;
        vm.all = true;
        vm.assetTypeId = undefined;
        vm.assetTypeList = [];
        vm.checkboxEnabled = false;
        vm.datasource = [];
        vm.expend = false;
        vm.mainGrid = [];
        vm.childGrid = [];
        vm.selectAll = selectAll;
        vm.AssetTypeDataBound = AssetTypeDataBound;
        vm.show = show;
        vm.gridToolbar = ['search'];

        activate();
        vm.columns = [
            {field: 'EszkozId', visible: false},
            {field: 'EszkozMegnevezes', headerText: gettextCatalog.getString('Megnevezes'), textAlign: 'center', width: 150},
            {field: 'EszkozTipusa', headerText: gettextCatalog.getString('AssetType'), textAlign: 'center', width: 150},
            {field: 'Aktiv', headerText: gettextCatalog.getString('ItemActive'), textAlign: 'center', type: 'boolean', displayAsCheckBox: true, width: 140},
            {field: 'Szabad', headerText: gettextCatalog.getString('Issuable'), textAlign: 'center', type: 'boolean', displayAsCheckBox: true, width: 160},
            {field: 'EszkozLeiras', headerText: gettextCatalog.getString('Leiras'), textAlign: 'center', width: 150},
            {field: 'Azonosito', headerText: gettextCatalog.getString('Azonosito'), textAlign: 'center', width: 150},
            {field: 'Meret', headerText: gettextCatalog.getString('Meret'), textAlign: 'center', width: 150},
            {field: 'Kinel', headerText: gettextCatalog.getString('Kinel'), textAlign: 'center', width: 150},
            {field: 'Ar', headerText: gettextCatalog.getString('Ar'), textAlign: 'center', width: 150}
        ];

        vm.childGridColumns = [
            {field: 'MozgasId', isPrimaryKey: 'true', visible: false},
            {field: 'Kiadva', headerText: gettextCatalog.getString('Kiadva'), textAlign: 'center', width: 150},
            {field: 'Visszaveve', headerText: gettextCatalog.getString('Visszaveve'), textAlign: 'center', width: 150},
            {field: 'Sportolo', headerText: gettextCatalog.getString('Sportolo'), textAlign: 'center', width: 150},
            {field: 'Felhasznalo', headerText: gettextCatalog.getString('Felhasznalo'), textAlign: 'center', width: 150},
            {field: 'MegjegyzesKiadaskor', headerText: gettextCatalog.getString('MegjegyzesKiadaskor'), textAlign: 'center', width: 150},
            {field: 'MegjegyzesVisszavetelkor', headerText: gettextCatalog.getString('MegjegyzesVisszavetelkor'), textAlign: 'center', width: 150},
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            allowSorting: true,
            columns: vm.columns,
            //allowPaging: true,
            //pageSettings: $rootScope.pageSettings,
            toolbar: ['print', 'search'],
            childGrid: {
                dataSource: [],
                queryString: 'EszkozId',
                allowPaging: true,
                allowSorting: true,
                pageSettings: $rootScope.pageSettings,
                columns: vm.childGridColums
            },
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        function activate() {
            $q.all([authenticationService.getRight('ESZKNYILVMEGT')]).then(function (data) {
                vm.ESZKNYILVMEGT = data[0];
                if (vm.ESZKNYILVMEGT) {
                    getAssetType();
                    setDropDowns();
                } else {
                    log.permissionError(true);
                }
            });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                    'airbt.type',
                    'airbt.active',
                    'airbt.expend',
                    'airbt.all'
                ])
            ];
            $q.all(promises).then(function (results) {
                if (results[0]['airbt.type'] != null) {
                    vm.assetTypeId = results[0]['airbt.type'];
                    // vm.active = results[0]['airbt.active'];
                    // vm.expend = results[0]['airbt.expend'];
                    // vm.all = results[0]['airbt.all'];
                    // angular.element("#Active").ejCheckBox ({ enabled: vm.active });
                    // angular.element("#Expend").ejCheckBox ({ enabled: vm.expend });
                    // angular.element("#All").ejCheckBox ({ enabled: vm.all });
                }
            });
        }

        function getAssetType() {
            dataservice.assetTypeList().then(function (data) {
                if (data.itemsList.length > 1) {
                    data.itemsList.unshift({TipusId: 0, TipusNev: gettextCatalog.getString('All')});
                }
                vm.assetTypeList = data.itemsList;
            });
        }

        function selectAll() {
            vm.checkboxEnabled = vm.all ? true : false;
        }

        // Run after data bounded
        function AssetTypeDataBound() {
            if (vm.assetTypeId > 0) {
                angular.element('#AssetTypeDropDown').ejDropDownList('selectItemByValue', vm.assetTypeId);
            } else {
                if (vm.assetTypeList.length === 1) {
                    vm.assetTypeId = vm.assetTypeList[0].TipusId;
                    angular.element('#AssetTypeDropDown').ejDropDownList('selectItemByValue', vm.assetTypeId[0].value);
                } else {
                    vm.assetTypeId = 0;
                    angular.element('#AssetTypeDropDown').ejDropDownList('selectItemByValue', vm.assetTypeId);
                }
            }
        }

        function show() {
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            paramHelper.setParams([
                {'airbt.type': vm.assetTypeId},
                {'airbt.active': vm.active},
                {'airbt.expend': vm.expend},
                {'airbt.all': vm.all}
            ]);
            dataservice.assetInventoryByType(vm.assetTypeId, vm.expend, vm.active, vm.all).then(function (data) {
                grid.dataSource = data.itemsList[0].EszkozAdatok;
                var childGrid = data.itemsList[0].EszkozMozgasa;
                childGrid = $filter('dateTimeStringFilter')(childGrid, 'Kiadva');
                grid.childGrid.dataSource = $filter('dateTimeStringFilter')(childGrid, 'Visszaveve');
            });
        }
    }
})();
