/**
 * = Új sportoló felvétele vagy Meglévő sportoló adatmódosítása =
 *   (AthleteNewController)
 *
 * Vagy csak megtekintése, módosítási jog nélkül
 * jogMODSPORTOLO || jogUJSPORTOLO || jogMEGTSPORTLIST || jogMEGTSPORTLIST
 *
 * @returns {undefined}
 */
(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteNew')
        .controller('AthleteNewController', AthleteNewController);
    AthleteNewController.$inject = ['log', 'dataservice', '$q', 'DOMAIN', '$rootScope', 'gettextCatalog', 'paramHelper', 'authenticationService', '$scope', '$compile', 'VIEW_DATA', '$state', '$timeout'];

    /* @ngInject */
    function AthleteNewController(log, dataservice, $q, DOMAIN, $rootScope, gettextCatalog, paramHelper, authenticationService, $scope, $compile, VIEW_DATA, $state, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AthleteNew');
        // Create variables
        // Default values
        vm.noImgUrl = DOMAIN.baseURL + DOMAIN.userImage; // default image
        vm.photoUrl = vm.noImgUrl; // User image

        // Opened fields in Accordion
        vm.selectedItems = [0, 1];
        //Not used at the moment vm.section = ''; // section (szakosztály)
        //not used at the moment vm.secondSection = ''; // Second section
        vm.thereIsSelectedAthlete = false;
        vm.noSelectedAthlete = true;

        // important the 'underscore' in the .$inject 'underscore'
        // sample xxxController.$inject= [..., 'underscore', ... ] and in the xxxController the "_" (...,  _, ...,  )
        vm.sexList = [{
            text: gettextCatalog.getString('Man'),
            value: 1
        },
            {
                text: gettextCatalog.getString('Woman'),
                value: 0
            }
        ];
        vm.fatherNames = [];
        vm.motherNames = [];
        getParentNames();
        //gettextCatalog.getString('RequiredFieldsError')
        vm.betterHandList = [{
            text: gettextCatalog.getString('Left'),
            value: 1
        },
            {
                text: gettextCatalog.getString('Right'),
                value: 2
            },
            {
                text: gettextCatalog.getString('Both'),
                value: 3
            }
        ];
        vm.betterFootList = [{
            text: gettextCatalog.getString('Left'),
            value: 1
        },
            {
                text: gettextCatalog.getString('Right'),
                value: 2
            },
            {
                text: gettextCatalog.getString('Both'),
                value: 3
            }
        ];
        vm.feeOfEducationList = [
            {text: gettextCatalog.getString('EducationFee'), value: 0},
            {text: gettextCatalog.getString('SportServiceFee'), value: 1}
        ];
        vm.countyList = VIEW_DATA.dropDownList.counties;
        vm.courseList = VIEW_DATA.dropDownList.course;
        vm.classList = VIEW_DATA.dropDownList.class;

        // If changed the fee of education, show that the date is correct or not
        vm.feeOfEducationDateOk = false;
        vm.ageGroupList = []; // recived age group list
        vm.secondAgeGroupList = [];
        vm.sectionList = []; // recived section list
        vm.secondSectionList = [];
        vm.selectedAgeGroupId = '';

        // Athlete parameters
        vm.selectedAgeGroupText = undefined; // selected age group name
        vm.athleteData = {
            Id: undefined,
            AdoSzam: undefined, // String
            Aktiv: undefined, // Boolean
            AktTestmagassag: undefined, // Int32?
            AktTestsuly: undefined, // Int32?
            Allampolgarsag: undefined, // String
            AnyjaLeanykoriNeve: undefined, // String
            AnyjaNeve: undefined, // String
            AnyjaTestmagassaga: undefined, // Int32?
            ApjaTestmagassaga: undefined, // Int32
            BefOktdij: undefined, // Decimal
            BefTagdij: undefined, // Decimal
            DSITagsagKezdete: undefined, // DateTime
            EddigiEdzoi: undefined, // String
            EddigiSpEgyesuletei: undefined, // String
            EdzesreJarasKezdete: undefined, // DateTime
            Egyenleg: undefined, // Decimal
            Elerhetoseg: undefined, // String
            EmailCim: undefined, // String
            EvzarasOsszege: undefined, // Decimal?
            FajiEredet: undefined, // String
            Fenykep: undefined, // Byte[]
            FenykepUt: undefined, // String
            fileData: null,
            FurdokopenyDb: undefined, // String
            FurdokopenyErtek: undefined, // String
            FurdokopenyMerete: undefined, // String
            GyermekkoriSport: undefined, // String
            IdSzakosztaly: undefined, // Int32
            IskolaEvfolyam: undefined, // String
            IskolaHazszam: undefined, // String
            IskolaIgazgatoNeve: undefined, // String
            IskolaIrSzam: undefined, // String
            IskolaNeve: undefined, // String
            IskolaOsztaly: undefined, // String
            IskolaUtca: undefined, // String
            IskolaVaros: undefined, // String
            JelenlegiEdzo: undefined, // int
            KabatDb: undefined, // String
            KabatErtek: undefined, // String
            KabatMerete: undefined, // String
            KilepesDatuma: undefined, // DateTime
            KilepesIndoka: undefined, // String
            Korcsoport: undefined, // Int32
            LakcimHazszam: undefined, // String
            LakcimIrSzam: undefined, // String
            LakcimMegye: undefined, // String
            LakcimUtca: undefined, // String
            LakcimVaros: undefined, // String
            LeanykoriNev: undefined, // String
            MasodlagosSzakosztalyId: undefined, // Int32?
            MasodlagosKorcsoport: undefined,
            MasodlagosSzakosztalyEdzoId: undefined,
            Megjegyzes: undefined, // String
            MelegitoDb: undefined, // String
            MelegitoErtek: undefined, // String
            MelegitoMerete: undefined, // String
            Neme: undefined, // String
            Nev: undefined, // String
            OsztalyFonokNeve: undefined, // String
            PancelNadragDb: undefined, // String
            PancelNadragErtek: undefined, // String
            PancelNadragMerete: undefined, // String
            UszoNadragMerete: undefined, // String
            PapucsDb: undefined, // String
            PapucsErtek: undefined, // String
            PapucsMerete: undefined, // String
            PoloDb: undefined, // String
            PoloErtek: undefined, // String
            PoloMerete: undefined, // String
            PubertasVege: undefined, // DateTime
            RegisztraciosSzam: undefined, // String
            SortDb: undefined, // String
            SortErtek: undefined, // String
            SortMerete: undefined, // String
            SportoloiMultAnya: undefined, // String
            SportoloiMultApa: undefined, // String
            SportolokID: undefined, // Int32
            SzakosztalyNev: undefined, // String
            SzemIgSzam: undefined, // String
            SzuletesiHelyOrszag: undefined,
            SzuletesiHelyVaros: undefined, // String
            SzuletesiIdo: undefined, // DateTime
            Szulo1Elerhetosege: undefined, // String
            Szulo2Elerhetosege: undefined, // String
            AnyjaFoglalkozasa: undefined, // String
            ApjaFoglalkozasa: undefined,
            SzuloNeve: undefined, // String
            SzunideiElfoglaltsag: undefined, // String
            Tagdijas: undefined, // Boolean
            TAJSzam: undefined, // String
            Testmagassag: undefined, // Int32?
            TestneveloElerhetosege: undefined, // String
            TestneveloNeve: undefined, // String
            Testsuly: undefined, // Int32?
            UgyesebbKez: undefined, // String
            UgyesebbLab: undefined, // String
            UtlevelSzam: undefined, // String
            UtolsoFrissites: undefined, // DateTime
            ValasztottSportagak: undefined, // String
            VersSportKezd: undefined, // DateTime
            VisszalepesDatuma: undefined, // DateTime?
            dddd: undefined,
            JatekEngedelySzama: undefined
        };

        vm.RUHA = {
            CIPO: false,
            PAPUCS: false,
            MELEGITO: false,
            FURDOKOPENY: false,
            KABAT: false,
            PANCELNADRAG: false,
            POLO: false,
            SORT: false,
            USZONADRAG: false
        };

        vm.athleteId = -1; // for sql quetion
        // This value show when start the new Fee of education mode
        vm.feeOfEducationDate = undefined;
        // Current coach name in text by Id
        vm.currentCoachNameInText = undefined;

        // Grid's details
        vm.schoolGridSelectedRowData = {};
        vm.gridToolbar = ['printGrid', 'excelExport'];
        // Grid columnsSchool

        vm.columnsSchool = [
            {field: 'IskolaNeve', headerText: gettextCatalog.getString('SchoolName'), textAlign: 'left'},
            {field: 'IskolaIrSzam', headerText: gettextCatalog.getString('SchoolZipCode'), textAlign: 'left'},
            {field: 'IskolaVaros', headerText: gettextCatalog.getString('SchoolCity'), textAlign: 'left'},
            {field: 'IskolaUtca', headerText: gettextCatalog.getString('SchoolStreet'), textAlign: 'left'},
            {field: 'IskolaHazszam', headerText: gettextCatalog.getString('SchoolNumber'), textAlign: 'left'},
            {field: 'IskolaIgazgatoNeve', headerText: gettextCatalog.getString('SchoolDirectorName'), textAlign: 'left'}
        ];

        vm.visibleSchooSelectlGrid = false;

        // Grid columnsAthleteMoveStory
        vm.columnsAthleteMoveStory = [
            {field: 'EdzoNeve', headerText: gettextCatalog.getString('CoachName'), textAlign: 'left'},
            {field: 'KorcsoportNeve', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'left'},
            {field: 'DatumTol', headerText: gettextCatalog.getString('StartTime'), textAlign: 'left'},
            {field: 'DatumIg', headerText: gettextCatalog.getString('EndTime'), textAlign: 'left'},
            {field: 'Belepes', headerText: gettextCatalog.getString('Enter'), textAlign: 'left'},
            {field: 'Kilepes', headerText: gettextCatalog.getString('GoOut'), textAlign: 'left'}
        ];

        // Public functions
        // AthleteNew functions
        // Drop Down List function
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.getSections = getSections;

        vm.secondSectionDataBound = secondSectionDataBound;
        vm.secondSectionSelect = secondSectionSelect;
        vm.secondCoachSelect = secondCoachSelect;

        vm.coachDropDownListDataBound = coachDropDownListDataBound;
        vm.secondCoachDropDownListDataBound = secondCoachDropDownListDataBound;

        vm.ageGroupDataBound = ageGroupDataBound;
        vm.seconAgeGroupDataBound = secondAgeGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.secondAgeGroupSelect = secondAgeGroupSelect;

        // Top menu buttons functions
        vm.goToAthleteResults = goToAthleteResults; // Public Athlete's results

        // Image cropper variables
        vm.deleteImg = deleteImg; // Public, delete the photo from user profile 999 Hofi

        vm.save = save;
        vm.cropper = {}; // Ricsi
        vm.cropper.sourceImage = null; // Ricsi
        vm.cropper.croppedImage = null; // Ricsi
        vm.bounds = {}; // Ricsi
        vm.bounds.left = 0; // Ricsi
        vm.bounds.right = 0; // Ricsi
        vm.bounds.top = 0; // Ricsi
        vm.bounds.bottom = 0; // Ricsi
        vm.cropDialogTitle = gettextCatalog.getString('ChooseImageTitle'); // Ricsi

        vm.openCropDialog = openCropDialog; // Ricsi
        vm.doneCropDialog = doneCropDialog; // Ricsi
        vm.closeCropDialog = closeCropDialog; // Ricsi
        // Change Age Group
        vm.changeAgeGroup = changeAgeGroup;
        // sex (gende) dropdown public function
        vm.sexSelect = sexSelect;
        // Height dropdown public function
        vm.changeHeight = changeHeight;
        // Weight dropdown public function
        vm.changeWeight = changeWeight;
        // better Hand dropdown public function
        vm.betterHandDataBound = betterHandDataBound;
        vm.betterHandSelect = betterHandSelect;
        // better Foot dropdown public function
        vm.betterFootSelect = betterFootSelect;
        vm.feeOfEducationBound = feeOfEducationBound;
        // Social security number required
        vm.socialSecurityNumberRequiredValue = false;
        vm.athleteRegistrationNumberRequiredValue = false;

        // select coach
        //vm.selectCoachBtn = selectCoachBtn;
        // Select Schools
        vm.selectSchools = selectSchools;
        // Public functions for grid databound
        vm.compile = compile;
        vm.schoolList = [];
        // Grid selcted row
        vm.schoolGridSelectedRow = schoolGridSelectedRow;
        // Selected coach
        vm.coachSelect = coachSelect;
        vm.coachList = [];
        vm.secondCoachList = [];

        vm.clearSecondSection = clearSecondSection;
        vm.clearSecondAgeGroup = clearSecondAgeGroup;
        vm.clearSecondCoach = clearSecondCoach;

        // Active
        vm.activeChange = activeChange;
        vm.gridExcelExport = dataservice.getURL() + 'IskolakSelect/ExcelExport/';
        vm.toolbarHandler = toolbarHandler;

        // School Grid datasource initial
        vm.coachListGridData = [];

        vm.ADMIN = false;
        vm.TORLCSOPORT = false;
        vm.MODCSOPORT = false;

        vm.tajkeres = true;

        vm.gymTeacherDatasource = [];
        vm.classMasterDatasource = [];
        vm.schoolNameChanged = schoolNameChanged;
        vm.generateRegNum = generateRegNum;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsSchool,
            rowSelected: schoolGridSelectedRow,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');

        // Activate page
        activate();
        $scope.$on('$viewContentLoaded', function () {
            // Set checkbox
            angular.element('#active').ejCheckBox({checked: true});
        });

        function activate() {
            // need paramHelper params (e.g. athleteId)
            $q.all([paramHelper.getParams([], ['athleteNew.sportolokId', 'athleteNew.ageGroupList', 'athleteNew.selectedSectionId', 'athleteNew.selectedSectionName', 'athleteNew.selectedAgeGroupName', 'athleteNew.selectedAgeGroupId', 'athleteNew.sectionList']), authenticationService.getRight('ADMIN'), authenticationService.getRight('TORLCSOPORT'), authenticationService.getRight('MODCSOPORT'), getSystemSettings('TAJKOTELEZO'), getSystemSettings('SNYSZKOTELEZO'), getSystemSettings('SPORTMINKOTELEZO'), getSystemSettings('NINCSOKTDIJ')]).then(function (results) {
                vm.ADMIN = results[1];
                vm.TORLCSOPORT = results[2];
                vm.MODCSOPORT = results[3];
                vm.NINCSOKTDIJ = results[7];
                if (vm.NINCSOKTDIJ) {
                    vm.feeOfEducationList = [
                        {text: gettextCatalog.getString('SportServiceFee'), value: 1}
                    ];
                }
                vm.athleteId = results[0]['athleteNew.sportolokId'];
                vm.athleteData.IdSzakosztaly = results[0]['athleteNew.selectedSectionId'];
                vm.athleteData.SzakosztalyNev = results[0]['athleteNew.selectedSectionName'];
                vm.athleteData.Korcsoport = results[0]['athleteNew.selectedAgeGroupId'];
                vm.selectedAgeGroupText = results[0]['athleteNew.selectedAgeGroupName'];
                vm.athleteData.KorcsoportName = results[0]['athleteNew.selectedAgeGroupName'];

                vm.photoUrl = DOMAIN.baseURL + DOMAIN.userImage; // User image
                vm.athleteData.Aktiv = true;

                // Get Coach list for dropDown
                vm.socialSecurityNumberRequiredValue = results[4];
                vm.athleteRegistrationNumberRequiredValue = results[5];
                vm.athleteRequiredMinimumDatas = results[6];

                RefreshClothes();
                // This functions runs after thel all datas arrived
                // Get section list
                getSecondSections();
                // set feeOfEducation dropDownList selected value
                feeOfEducationSetSelectedValue();
                // get schools
                //selectSchools();
                $timeout(function () {
                    var t = {
                        text: results[0]['athleteNew.selectedSectionName'],
                        value: results[0]['athleteNew.selectedSectionId']
                    };
                    vm.sectionList = results[0]['athleteNew.sectionList'];

                    vm.ageGroupList = results[0]['athleteNew.ageGroupList'];

                    if (vm.ageGroupList != null && vm.ageGroupList.length > 0 && vm.ageGroupList[0].value === 0) {
                        vm.ageGroupList.splice(0, 1);
                    }
                    $scope.$apply();
                }, 0);
            });
        }

        function RefreshClothes() {
            $timeout(function () {
                dataservice.SzakosztalyRuhai(vm.athleteData.IdSzakosztaly, vm.athleteData.MasodlagosSzakosztaly).then(function (ruhaResponse) {
                    vm.RUHA = ruhaResponse.data;
                });
            }, 100);
        }

        function saveAnyway() {
            var value = $('#feeOfEducation').ejDropDownList('getSelectedValue');
            switch (value) {
                case 0:
                case '0':
                    vm.athleteData.Tagdijas = false; // Oktatási díjas
                    break;
                case 1:
                case '1':
                    vm.athleteData.Tagdijas = true;
                    break;
            }
            vm.athleteData.TAJSzam = angular.element('#socialSecurityNumber').ejMaskEdit('get_StrippedValue');

            // kép küldése, ha választott
            if (angular.element('#fileName').val() !== '' && vm.cropper.croppedImage !== null) {
                vm.athleteData.FenykepUt = angular.element('#fileName').val();
                vm.athleteData.Fenykep = vm.cropper.croppedImage;
            }

            if (vm.photoUrl === vm.noImg) {
                vm.athleteData.fileName = 'avatar_2x.png';
                vm.athleteData.Fenykep = null;
            }
            var dsiTime = new Date(vm.athleteData.DSITagsagKezdete);

            if (dsiTime.getTime() > new Date()) {
                swal({
                    title: gettextCatalog.getString('DateError'),
                    text: gettextCatalog.getString('TheMemberOfDSIDateLateThenNow'),
                    type: 'warning',
                    confirmButtonText: gettextCatalog.getString('Ok')
                });
            } else {
                if (vm.athleteData.MasodlagosSzakosztaly > 0) {
                    if (vm.athleteData.MasodlagosKorcsoport > 0) {
                        if (!(vm.athleteData.MasodlagosSzakosztalyEdzoId > 0)) {
                            log.errorMsg(gettextCatalog.getString('SecondSectionDetailsAreNotComplete'));
                            return;
                        }
                    } else {
                        log.errorMsg(gettextCatalog.getString('SecondSectionDetailsAreNotComplete'));
                        return;
                    }
                }
                dataservice.saveNewAthlete(vm.athleteData)
                    .then(
                        function () {
                            swal({
                                title: gettextCatalog.getString('SaveAthlete'),
                                text: gettextCatalog.getString('SaveAthleteSuccess'),
                                type: 'success',
                                confirmButtonColor: '#DD6B55',
                                confirmButtonText: gettextCatalog.getString('OK'),
                                closeOnConfirm: false,
                                allowOutsideClick: false
                            }).then(
                                function () {
                                    paramHelper.setParams([
                                        {'athleteList.selectedSectionId': vm.athleteData.IdSzakosztaly},
                                        {'athleteList.selectedAgeGroupId': vm.athleteData.Korcsoport},
                                        {'athleteList.inactiveTeams': false}
                                    ]);
                                    $state.go('athletes.athletesManagement.athletesList');
                                });
                        });
            }
        }

        function save() {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
            } else {
                dataservice.SimilarAthleteExist({
                    nev: vm.athleteData.Nev,
                    datum: vm.athleteData.SzuletesiIdo
                }).then(function (response) {
                    if (response.retValue) {
                        swal({
                            title: gettextCatalog.getString('SimilarAthlete'),
                            text: gettextCatalog.getString('WeFoundASimilarAthleteContinueText'),
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText: gettextCatalog.getString('Yes'),
                            cancelButtonText: gettextCatalog.getString('No')
                        }).then(function (isConfirm) {
                            if (isConfirm.value) {
                                saveAnyway();
                            }
                        });
                    } else {
                        saveAnyway();
                    }
                });
            }
        }

        function generateRegNum() {
            dataservice.GenerateRegNum().then(function (data) {
                vm.athleteData.RegisztraciosSzam = data.data;
            });
        }

        function openCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'visible');
            angular.element('#cropDialog').ejDialog('open');
        }

        function closeCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'hidden');
            angular.element('#cropDialog').ejDialog('close');
        }

        function doneCropDialog() {
            vm.photoUrl = vm.cropper.croppedImage;
            angular.element('#cropDialog').ejDialog('close');
        }

        function deleteImg() {
            swal({
                title: gettextCatalog.getString('DeleteProfileImg'),
                text: gettextCatalog.getString('AreYouSureYouWantToDeleteTheProfileImg'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: gettextCatalog.getString('Yes'),
                cancelButtonText: gettextCatalog.getString('No')
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    angular.element('#fileName').val('');
                    vm.photoUrl = vm.noImgUrl;
                    $scope.$apply();
                }
            });
        }

        function getSections() {
            dataservice.sectionDropDownList(vm.athleteData.JelenlegiEdzo)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                });
            getAgeGroups();
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.athleteData.IdSzakosztaly);
            }
        }

        function sectionSelect(args) {
            vm.athleteData.IdSzakosztaly = args.value;
            getAgeGroups();
        }

        function getSecondSections() {
            dataservice.sectionDropDownList(0)
                .then(function (data) {
                    // itt ki kell venni a listából az elsődleges section-t (vm.athleteData.IdSzakosztaly)
                    // for (var i = 0; i < data.itemsList.length; i++) {
                    //     if ((data.itemsList[i].value + '') === vm.athleteData.IdSzakosztaly) {
                    //         data.itemsList.splice(i, 1);
                    //     }
                    // }
                    vm.secondSectionList = data.itemsList;
                });
        }

        function secondSectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#secondSectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#secondSectionDropDown').ejDropDownList('selectItemByValue', vm.athleteData.MasodlagosSzakosztaly);
            }
        }

        function secondSectionSelect(selectedSection) {
            if (selectedSection.value > -1) {
                vm.athleteData.MasodlagosSzakosztalyId = selectedSection.value;
                getSecondAgeGroups();
                RefreshClothes();
            } else {
                vm.athleteData.MasodlagosSzakosztalyId = undefined;
                vm.athleteData.MasodlagosKorcsoport = undefined; // clear list until not selected agegroup
                vm.athleteData.MasodlagosSzakosztalyEdzoId = undefined; // clear list until not selected agegroup
                vm.secondAgeGroupList = [];
                vm.secondCoachList = []; // clear list until not selected agegroup
            }
        }

        function getSecondAgeGroups() {
            dataservice.ageGroupDropDownList(vm.athleteData.MasodlagosSzakosztalyId)
                .then(function (data) {
                    vm.secondAgeGroupList = data.itemsList;
                });
        }

        function secondAgeGroupDataBound() {
            if (vm.secondAgeGroupList.length === 1) {
                angular.element('#secondAgeGroupDropDown').ejDropDownList('selectItemByValue', vm.secondAgeGroupList[0].value);
            } else {
                angular.element('#secondAgeGroupDropDown').ejDropDownList('selectItemByValue', vm.athleteData.MasodlagosKorcsoport);
            }
        }

        function secondAgeGroupSelect(args) {
            if (args.value > -1) {
                vm.athleteData.MasodlagosKorcsoport = args.value;
                getSecondCoachesList();
            } else {
                vm.athleteData.MasodlagosKorcsoport = undefined, // clear list until not selected agegroup
                    vm.athleteData.MasodlagosSzakosztalyEdzoId = undefined, // clear list until not selected agegroup
                    vm.secondCoachList = []; // clear list until not selected agegroup
            }
        }

        function getSecondCoachesList() {
            return dataservice.coachDropDownList(vm.athleteData.MasodlagosSzakosztaly, vm.athleteData.MasodlagosKorcsoport) // Kell a section id (szakosztály)
                .then(function (data) {
                    vm.secondCoachList = data.itemsList;
                });
        }

        function secondCoachDropDownListDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#secondCoachName').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                //vm.selectedCoach = vm.coachList[0].value;
                vm.athleteData.MasodlagosSzakosztalyEdzoId = vm.coachList[0].value;
            } else {
                angular.element('#secondCoachName').ejDropDownList('selectItemByValue', vm.athleteData.JelenlegiEdzo);
            }
        }

        function secondCoachSelect(args) {
            //vm.selectedCoach = args.value;
            if (args.value > -1) {
                vm.athleteData.MasodlagosSzakosztalyEdzoId = args.value;
            } else {
                vm.athleteData.MasodlagosSzakosztalyEdzoId = undefined; // clear list until not selected agegroup
            }
        }

        function changeAgeGroup() {
            paramHelper.setParams([{'ageGroupChange.athleteData': vm.athleteData}]);
            $state.go('settings.ageGroupChange');
        }

        function getAgeGroups() {
            dataservice.ageGroupDropDownList(vm.athleteData.IdSzakosztaly)
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                    //getActualCoachesList();
                    // itt kérjük le az edzőt
                });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.athleteData.Korcsoport);
            }
        }

        function ageGroupSelect(args) {
            vm.athleteData.Korcsoport = args.value;
            getActualCoachesList();
        }

        function getActualCoachesList() {
            return dataservice.coachDropDownList(vm.athleteData.IdSzakosztaly, vm.athleteData.Korcsoport) // Kell a section id (szakosztály)
                .then(function (data) {
                    vm.coachList = data.itemsList;
                });
        }

        function coachDropDownListDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachName').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                //vm.selectedCoach = vm.coachList[0].value;
                vm.athleteData.JelenlegiEdzo = vm.coachList[0].value;
            } else {
                angular.element('#coachName').ejDropDownList('selectItemByValue', vm.athleteData.JelenlegiEdzo);
            }
        }

        function coachSelect(args) {
            vm.athleteData.JelenlegiEdzo = args.value;
        }

        function sexSelect(arg) {
            //vm.sexNum
            vm.athleteData.FerfiB = arg.value === 1;
        }

        function changeHeight(args) {
            vm.athleteData.Testmagassag = args.value;
        }

        function changeWeight(args) {
            vm.athleteData.Testsuly = args.value;
        }

        function betterHandDataBound() {
            angular.element('#betterHandDropDown').ejDropDownList('selectItemByText', vm.athleteData.UgyesebbKez);
        }

        function betterHandSelect(arg) {
            vm.athleteData.UgyesebbKezT = arg.value;
            vm.athleteData.UgyesebbKez = arg.selectedText;
        }

        function betterFootSelect(arg) {
            vm.athleteData.UgyesebbLabT = arg.value;
            vm.athleteData.UgyesebbLab = arg.selectedText;
        }

        function feeOfEducationBound() {
            angular.element('#feeOfEducation').ejDropDownList('selectItemByValue', 1);
        }

        function feeOfEducationSetSelectedValue() {
            angular.element('#feeOfEducation').ejDropDownList('selectItemByValue', 'Sportszolgáltatási díjas');
        }

        function selectSchools() {
            if (vm.visibleSchooSelectlGrid) {
                angular.element('#hidedContent').css('height', '0px');
            } else {
                dataservice.selectSchools()
                    .then(function (data) {
                        // swal dropdownList select shool and fill fields with datas
                        angular.element('#hidedContent').css('height', '100%');
                        grid.dataSource = data.itemsList;
                    });
            }
            vm.visibleSchooSelectlGrid = !vm.visibleSchooSelectlGrid;
        }

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                var grid = angular.element('#Grid').ejGrid('instance');
                grid.ignoreOnExport.splice(grid.ignoreOnExport.indexOf('dataSource'), 1);
                this.export(dataservice.getURL() + 'IskolakSelect/ExcelExport/');
            }
        }

        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }

        function schoolGridSelectedRow(args) {
            // Ha megvan a kiválasztott sor, el kell tüntetni a Gridet
            vm.schoolGridSelectedRowData = args.data;
            vm.athleteData.IskolaEvfolyam = vm.schoolGridSelectedRowData.IskolaEvfolyam; // String
            vm.athleteData.IskolaNeve = vm.schoolGridSelectedRowData.IskolaNeve; // String
            vm.athleteData.IskolaIrSzam = vm.schoolGridSelectedRowData.IskolaIrSzam; // String
            vm.athleteData.IskolaIrSzam = vm.schoolGridSelectedRowData.IskolaIrSzam; // String
            vm.athleteData.IskolaVaros = vm.schoolGridSelectedRowData.IskolaVaros; // String
            vm.athleteData.IskolaUtca = vm.schoolGridSelectedRowData.IskolaUtca; // String
            vm.athleteData.IskolaHazszam = vm.schoolGridSelectedRowData.IskolaHazszam; // String
            vm.athleteData.IskolaOsztaly = vm.schoolGridSelectedRowData.IskolaOsztaly; // String
            vm.athleteData.IskolaIgazgatoNeve = vm.schoolGridSelectedRowData.IskolaIgazgatoNeve; // String
            $scope.$apply();
            // swal dropdownList select shool and fill fields with datas
            angular.element('#hidedContent').css('height', '0px');
            vm.visibleSchooSelectlGrid = !vm.visibleSchooSelectlGrid;
        }

        function activeChange(args) {
            vm.athleteData.Aktiv = args.value;
        }

        function goToAthleteResults() {
            var coachItem = angular.element('#coachName').data('ejDropDownList').getSelectedItem()[0];
            if (angular.isDefined(coachItem)) {
                vm.athleteData.EdzoNev = angular.element('#coachName').data('ejDropDownList').getSelectedItem()[0].innerText;
            } else {
                vm.athleteData.EdzoNev = '';
            }
            vm.athleteData.KorcsoportNev = vm.selectedAgeGroupText;
            paramHelper.setParams([{'raceResultsReport.athleteData': vm.athleteData}]);
            $state.go('reports.raceResultsReport');
        }

        function getSystemSettings(arg) {
            return dataservice.getSystemSettings(arg).then(function (response) {
                return response.retValue;
            });
        }

        function clearSecondSection() {
            vm.athleteData.MasodlagosSzakosztaly = undefined;
            vm.athleteData.MasodlagosKorcsoport = undefined;
            vm.secondAgeGroupList = [];
            vm.athleteData.MasodlagosSzakosztalyEdzoNev = undefined;
            vm.secondCoachList = [];
            $('#secondSectionDropDown').ejDropDownList('clearText');
        }

        function clearSecondAgeGroup() {
            vm.athleteData.MasodlagosKorcsoport = undefined;
            vm.athleteData.MasodlagosSzakosztalyEdzoNev = undefined;
            vm.secondCoachList = [];
            $('#secondAgeGroupDropDown').ejDropDownList('clearText');
        }

        function clearSecondCoach() {
            vm.athleteData.MasodlagosSzakosztalyEdzoNev = undefined;
            $('#secondCoachNameDropDown').ejDropDownList('clearText');
        }

        vm.emptyResultText = gettextCatalog.getString('emptyResultText');

        function schoolNameChanged() {
            if (vm.athleteData.IskolaNeve && vm.athleteData.IskolaNeve.length > 0) {
                dataservice.getGymTeachers(vm.athleteData.IskolaNeve).then(function (data) {
                    vm.gymTeacherDatasource = data.itemsList;
                });
                dataservice.getClassMasters(vm.athleteData.IskolaNeve).then(function (data) {
                    vm.classMasterDatasource = data.itemsList;
                });
            } else {
                vm.gymTeacherDatasource = [];
                vm.classMasterDatasource = [];
            }
        }

        vm.selectOf = selectOf;

        function selectOf(arg) {
            for (var i = 0; i < vm.classMasterDatasource.length; i++) {
                if (arg.value == vm.classMasterDatasource[i].value) {
                    dataservice.getClassMasterContact(arg.value).then(function (data) {
                        if (data.data.tel) {
                            vm.athleteData.OfTel = data.data.tel;
                        }
                        if (data.data.email) {
                            vm.athleteData.OfEmail = data.data.email;
                        }
                    });
                }
            }
        }

        vm.selectTesiTanar = selectTesiTanar;

        function selectTesiTanar(arg) {
            for (var i = 0; i < vm.gymTeacherDatasource.length; i++) {
                if (arg.value == vm.gymTeacherDatasource[i].value) {
                    dataservice.getGymTeacherContact(arg.value).then(function (data) {
                        if (data.data.tel) {
                            vm.athleteData.TestneveloElerhetosege = data.data.tel;
                        }
                        if (data.data.email) {
                            vm.athleteData.TestneveloEmail = data.data.email;
                        }
                    });
                }
            }
        }

        function getParentNames() {
            dataservice.getParentNames().then(function (data) {
                for (var i = 0; i < data.data.father.length; i++) {
                    vm.fatherNames.push({value: data.data.father[i], text: data.data.father[i]});
                }
                for (var i = 0; i < data.data.mother.length; i++) {
                    vm.motherNames.push({value: data.data.mother[i], text: data.data.mother[i]});
                }
            });
        }

        vm.selectMother = selectMother;

        function selectMother(arg) {
            if (arg && arg.value.length > 0) {
                vm.athleteData.Szulo1Elerhetosege = '';
                vm.athleteData.AnyaEmail = '';
                vm.athleteData.AnyjaTestmagassaga = '';
                vm.athleteData.AnyjaFoglalkozasa = '';
                vm.athleteData.AnyaMunkahely = '';
                vm.athleteData.SportoloiMultAnya = '';
                var idx = arg.value.indexOf('(');
                if (idx != -1) {
                    getParentData('mother', arg.value);
                    vm.athleteData.AnyjaNeve = arg.value.substring(0, idx - 1);
                }
            }
        }

        vm.selectFather = selectFather;

        function selectFather(arg) {
            if (arg && arg.value.length > 0) {
                vm.athleteData.Szulo2Elerhetosege = '';
                vm.athleteData.ApaEmail = '';
                vm.athleteData.ApjaTestmagassaga = '';
                vm.athleteData.ApjaFoglalkozasa = '';
                vm.athleteData.ApaMunkahely = '';
                vm.athleteData.SportoloiMultApa = '';
                var idx = arg.value.indexOf('(');
                if (idx != -1) {
                    getParentData('father', arg.value);
                    vm.athleteData.SzuloNeve = arg.value.substring(0, idx - 1);
                }
            }
        }

        function getParentData(parent, arg) {
            dataservice.getParentData(parent, arg).then(function (data) {
                if (parent == 'father') {
                    if (data.data) {
                        if (data.data.tel && data.data.tel.length > 0) {
                            vm.athleteData.Szulo2Elerhetosege = data.data.tel;
                        }
                        if (data.data.email && data.data.email.length > 0) {
                            vm.athleteData.ApaEmail = data.data.email;
                        }
                        if (data.data.height && data.data.height.length > 0) {
                            vm.athleteData.ApjaTestmagassaga = data.data.height;
                        }
                        if (data.data.job && data.data.job.length > 0) {
                            vm.athleteData.ApjaFoglalkozasa = data.data.job;
                        }
                        if (data.data.workplace && data.data.workplace.length > 0) {
                            vm.athleteData.ApaMunkahely = data.data.workplace;
                        }
                        if (data.data.sportpast && data.data.sportpast.length > 0) {
                            vm.athleteData.SportoloiMultApa = data.data.sportpast;
                        }
                    }
                } else if (parent == 'mother') {
                    if (data.data) {
                        if (data.data.tel && data.data.tel.length > 0) {
                            vm.athleteData.Szulo1Elerhetosege = data.data.tel;
                        }
                        if (data.data.email && data.data.email.length > 0) {
                            vm.athleteData.AnyaEmail = data.data.email;
                        }
                        if (data.data.height && data.data.height.length > 0) {
                            vm.athleteData.AnyjaTestmagassaga = data.data.height;
                        }
                        if (data.data.job && data.data.job.length > 0) {
                            vm.athleteData.AnyjaFoglalkozasa = data.data.job;
                        }
                        if (data.data.workplace && data.data.workplace.length > 0) {
                            vm.athleteData.AnyaMunkahely = data.data.workplace;
                        }
                        if (data.data.sportpast && data.data.sportpast.length > 0) {
                            vm.athleteData.SportoloiMultAnya = data.data.sportpast;
                        }
                    }
                }
            });
        }
    }
})();
