(function () {
    'use strict';

    angular
        .module('app.settings.userManagement.modifyUser')
        .controller('modifyUserController', modifyUserController);

    modifyUserController.$inject = ['$q', 'log', 'VIEW_DATA', 'dataservice', 'DOMAIN', 'authenticationService', '$rootScope', 'AUTH_EVENTS', '$scope', 'gettextCatalog', 'paramHelper', '$state', '$filter', '$timeout'];

    /* @ngInject */
    function modifyUserController($q, log, VIEW_DATA, dataservice, DOMAIN, authenticationService, $rootScope, AUTH_EVENTS, $scope, gettextCatalog, paramHelper, $state, $filter, $timeout) {
        var vm = this;
        $rootScope.vm = vm; // jshint ignore: line

        // Page title
        $rootScope.title = gettextCatalog.getString('ModifyUser');

        // Dropdowns
        vm.autoSave = true;
        vm.sectionLeaderChangeEnable = false;
        vm.sectionLeaderList = [];
        vm.ageGroupList = [];
        vm.weightList = dataservice.range(VIEW_DATA.dropDownList.weight.downThreshold, VIEW_DATA.dropDownList.weight.upThreshold);
        vm.heightList = dataservice.range(VIEW_DATA.dropDownList.centimetre.downThreshold, VIEW_DATA.dropDownList.centimetre.upThreshold);
        vm.sexList = [{
            text: gettextCatalog.getString('Man'),
            value: 1
        },
            {
                text: gettextCatalog.getString('Woman'),
                value: 0
            }
        ];
        vm.userGroupList = [];

        // Default variables
        vm.noImg = DOMAIN.baseURL + DOMAIN.userImage;
        vm.photoUrl = vm.noImg;
        vm.passwordAgain = undefined;
        vm.colorValue = '#000000';
        vm.activeValue = true;
        vm.formData = {
            id: undefined,
            name: undefined,
            userGroupId: undefined,
            loginName: undefined,
            email: undefined,
            password: undefined,
            passwordExpiryDate: undefined,
            fileData: null,
            fileName: null,
            address: undefined,
            contact: undefined,
            socialSecurityNumber: undefined,
            color: '#000000',
            monthlyAttendanceRegister: undefined,
            activeUser: true,
            mainWorkPlace: undefined,
            qualifications: undefined,
            country: undefined,
            city: undefined,
            birthDate: undefined,
            citizenship: undefined,
            mothersName: undefined,
            height: undefined,
            weight: undefined,
            identityCardNumber: undefined,
            sex: undefined,
            passportNumber: undefined,
            otherInformation: undefined,
            autoSave: true
        };

        vm.changeUserGroup = changeUserGroup;
        vm.colorChanged = colorChanged;
        vm.monthlyAttendanceRegisterChanged = monthlyAttendanceRegisterChanged;
        vm.activeChange = activeChange;
        vm.autoSaveCheckboxChanged = autoSaveCheckboxChanged;
        vm.sectionLeaderChecked = sectionLeaderChecked;
        vm.changeHeight = changeHeight;
        vm.changeWeight = changeWeight;
        vm.changeSex = changeSex;
        vm.sectionLeader = false;

        vm.selectedItems = [0, 1];
        vm.saveUser = saveUser;
        // Image cropper variables
        vm.cropper = {};
        vm.cropper.sourceImage = null;
        vm.cropper.croppedImage = null;
        vm.bounds = {};
        vm.bounds.left = 0;
        vm.bounds.right = 0;
        vm.bounds.top = 0;
        vm.bounds.bottom = 0;
        vm.cropDialogTitle = gettextCatalog.getString('ChooseImageTitle');

        vm.newAsset = {};
        vm.TakeBackComment = '';
        vm.IssueComment = '';
        vm.chooseEnabled = false;
        vm.takebackEnabled = false;
        vm.issuedAssets = [];
        vm.selectedAssets = [];
        vm.types = [];
        vm.assetColumns = [
            {field: 'EszkozId', visible: false, isPrimaryKey: true, width: 100},
            {type: 'checkbox', textAlign: 'center', width: 100},
            {field: 'Megnevezes', headerText: gettextCatalog.getString('Megnevezes'), textAlign: 'left', width: 150},
            {field: 'Leiras', headerText: gettextCatalog.getString('Leiras'), textAlign: 'left', width: 100},
            {field: 'Azonosito', headerText: gettextCatalog.getString('Azonosito'), textAlign: 'left', width: 100},
            {field: 'Meret', headerText: gettextCatalog.getString('Meret'), textAlign: 'left', width: 100},
            {field: 'Ar', headerText: gettextCatalog.getString('Ar'), textAlign: 'left', width: 100}
        ];

        var usersAssetGrid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            allowTextWrap: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.assetColumns,
            selectionSettings: {type: 'multiple', mode: 'both'},
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(usersAssetGrid, $scope);
                }
            },
            rowSelected: function () {
                var rowSelect = $rootScope.gridSelectedRowIndex(usersAssetGrid);
                if (rowSelect.length > 0) {
                    vm.takebackEnabled = true;
                    $timeout(function () {
                        $scope.$apply();
                    }, 0);
                }
            },
            rowDeselected: function () {
                var rowSelect = $rootScope.gridSelectedRowIndex(usersAssetGrid);
                if (rowSelect.length == 0) {
                    vm.takebackEnabled = false;
                    $timeout(function () {
                        $scope.$apply();
                    }, 0);
                }
            }
        });
        usersAssetGrid.appendTo('#UsersAssetGrid');
        usersAssetGrid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, usersAssetGrid);
        };

        var AssetGrid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            allowTextWrap: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.assetColumns,
            selectionSettings: {type: 'multiple', mode: 'both'},
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(AssetGrid, $scope);
                }
            },
            rowSelected: function () {
                var rowSelect = $rootScope.gridSelectedRowIndex(AssetGrid);
                if (rowSelect.length > 0) {
                    vm.chooseEnabled = true;
                    $timeout(function () {
                        $scope.$apply();
                    }, 0);
                }
            },
            rowDeselected: function () {
                var rowSelect = $rootScope.gridSelectedRowIndex(AssetGrid);
                if (rowSelect.length == 0) {
                    vm.chooseEnabled = false;
                    $timeout(function () {
                        $scope.$apply();
                    }, 0);
                }
            }
        });
        AssetGrid.appendTo('#AssetGrid');
        AssetGrid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, AssetGrid);
        };

        vm.IssueAsset = IssueAsset;
        vm.Issue = Issue;
        vm.IssueNewAsset = IssueNewAsset;
        vm.TakeBack = TakeBack;
        vm.deleteImg = deleteImg;
        vm.openCropDialog = openCropDialog;
        vm.doneCropDialog = doneCropDialog;
        vm.closeCropDialog = closeCropDialog;
        vm.goToFiles = goToFiles;
        vm.socialSecurityNumberRequiredValue = false;
        vm.SPORTOLOADATLAP = false;
        vm.SZULOADATLAP = false;
        vm.GAZDASAGIADATLAP = false;
        vm.LIMITEDUSER = false;
        vm.SZAKOSZTVEZADATLAP = false;
        vm.EDZOADATLAP = false;
        vm.ESZKMOZG = false;

        activate();

        function activate() {
            $q.all([paramHelper.getParams(['newUser.userId'], []),
                authenticationService.getRight('MODFELH'),
                authenticationService.getRight('EDZO'),
                authenticationService.getAuthData(),
                authenticationService.getRight('ADMIN'),
                authenticationService.getRight('MODFELH'),
                authenticationService.getRight('MEGTFELH'),
                authenticationService.getRight('SZULO'),
                authenticationService.getRight('SPORTOLO'),
                authenticationService.getRight('SZAKOSZTVEZ'),
                authenticationService.getRight('ESZKMOZG'),
                getSectionLeaderListBox(),
                getAgeGroupTreeView()
            ])
                .then(function (results1) {
                    if (results1[1] !== true && results1[6] !== true && results1[7] !== true && results1[8] !== true) {
                        log.permissionError(true);
                    } else {
                        $q.all([getUsersUserGroup(results1[0]['newUser.userId'])]).then(function (results2) {
                            $q.all([getUserGroupDropDownList(results2[0])]).then(function () {
                                vm.GROUP = results2[0];
                                vm.EDZO = results1[2];
                                vm.ADMIN = results1[4];
                                vm.MODFELH = results1[5];
                                vm.SZULO = results1[7];
                                vm.SPORTOLO = results1[8];
                                vm.SZAKOSZTVEZ = results1[9];
                                vm.ESZKMOZG = results1[10];
                                vm.user = results1[3];
                                vm.userId = results1[0]['newUser.userId'];
                                getUser(results1[0]['newUser.userId']);
                                IssuedAssets(vm.userId);
                                getSystemSettings('TAJKOTELEZO');
                            });
                        });
                    }
                });
        }

        function getUserGroupDropDownList(group) {
            if (!group || group == '') {
                group = 'showall';
            }
            return dataservice.getFullUserGroupList(group)
                .then(function (data) {
                    vm.userGroupList = data.itemsList;
                });
        }

        function getSystemSettings(arg) {
            dataservice.getSystemSettings(arg).then(function (response) {
                vm.socialSecurityNumberRequiredValue = response.retValue;
            });
        }

        function getUsersUserGroup(userid) {
            return dataservice.UsersUserGroup(userid)
                .then(function (data) {
                    vm.LIMITEDUSER = false;
                    for (var i = 0; i < data.data.length; i++) {
                        switch (data.data[i]) {
                            case 'SZULO':
                                vm.SZULOADATLAP = true;
                                vm.LIMITEDUSER = true;
                                break;
                            case 'SPORTOLO':
                                vm.SPORTOLOADATLAP = true;
                                vm.LIMITEDUSER = true;
                                break;
                            case 'GAZDASAGI':
                                vm.GAZDASAGIADATLAP = true;
                                break;
                            case 'SZAKOSZTVEZ':
                                vm.SZAKOSZTVEZADATLAP = true;
                                break;
                            case 'EDZO':
                                vm.EDZOADATLAP = true;
                                break;
                            default:
                        }
                    }
                    return data.data;
                });
        }

        function getUser(userId) {
            return dataservice.getUserData(userId)
                .then(function (data) {
                    angular.element('#sex').ejDropDownList('selectItemByValue', data.retValue.ferfib);
                    data.retValue.birthDate = $filter('datetimeFilter')(data.retValue.birthDate);
                    data.retValue.passwordExpiryDate = $filter('datetimeFilter')(data.retValue.passwordExpiryDate);
                    vm.formData = data.retValue;
                    angular.element('#userGroup').ejDropDownList('selectItemByValue', vm.formData.userGroupId);
                    vm.photoUrl = DOMAIN.baseURL + DOMAIN.profileImgBaseUrl + vm.formData.fileName;
                    vm.colorValue = vm.formData.color;
                    angular.forEach(vm.sectionLeaderList, function (section, sectionIndex) {
                        angular.forEach(vm.formData.sectionsLeader, function (sectionId) {
                            if (section.value === sectionId) {
                                angular.element('#sectionListBox').ejListBox('checkItemByIndex', sectionIndex);
                            }
                        });
                    });
                    angular.forEach(vm.formData.ageGroups, function (ageGroup) {
                        angular.element('#ageGroupTreeView').ejTreeView('checkNode', angular.element('#' + ageGroup));
                    });
                    angular.element('#activeCheckBox').ejCheckBox({checked: data.retValue.activeUser});
                    angular.element('#monthlyAttendanceRegister').ejCheckBox({checked: data.retValue.monthlyAttendanceRegister});
                    vm.sectionLeaderChangeEnable = true;
                });
        }

        function changeUserGroup(args) {
            vm.formData.userGroupId = args.value;
            dataservice.getSectionHasSectionLeaderRight(vm.formData.userGroupId)
                .then(function (data) {
                    if (data.retValue) {
                        angular.element('#sectionListBox').ejListBox('enable');
                    } else {
                        angular.element('#sectionListBox').ejListBox('disable');
                    }
                });
            dataservice.getSectionHasCoachRight(vm.formData.userGroupId)
                .then(function (data) {
                    if (data.retValue) {
                        angular.element('#ageGroupTreeView').ejTreeView({enabled: true});
                    } else {
                        angular.element('#ageGroupTreeView').ejTreeView({enabled: false});
                    }
                });
        }

        function colorChanged(args) {
            vm.formData.color = args.value;
        }

        function monthlyAttendanceRegisterChanged(args) {
            vm.formData.monthlyAttendanceRegister = args.value;
        }

        function activeChange(args) {
            vm.formData.activeUser = args.value;
        }

        function autoSaveCheckboxChanged(args) {
            vm.formData.autoSave = args.value;
        }

        function getSectionLeaderListBox() {
            return dataservice.getSectionLeaderListBox()
                .then(function (data) {
                    vm.sectionLeaderList = data.itemsList;
                });
        }

        function sectionLeaderChecked(args) {
            if (vm.sectionLeaderChangeEnable) {
                if (args.isChecked === true) {
                    if (!args.data.VezetoNev) {
                        args.data.VezetoNev = gettextCatalog.getString('PrimarySectionLeader');
                    }
                    if (!args.data.VezetoNev2) {
                        args.data.VezetoNev2 = gettextCatalog.getString('SecondarySectionLeader');
                    }
                    swal({
                        title: gettextCatalog.getString('ReplaceQuestion'),
                        html: '<p>' + gettextCatalog.getString('WhichLeaderDoYouWantToReplace') + '</p><p>' + gettextCatalog.getString('ActualSectionLeadersText') + ': </p><div><button class="cts-button" id="leader1">' + args.data.VezetoNev + '</button>' + '<button class="cts-button" id="leader2">' + args.data.VezetoNev2 + '</button></div><div style="text-align: center;"><br><br><button class="cts-button auto-width" id="cancel-button">' + gettextCatalog.getString('Cancel') + '</button></div>',
                        showCancelButton: false,
                        showConfirmButton: false,
                        closeOnCancel: false,
                        allowOutsideClick: false
                    });
                    angular.element('#leader1').ejButton({
                        click: function () {
                            replaceLeader(args.index, 1);
                            swal.close();
                        }
                    });
                    angular.element('#leader2').ejButton({
                        click: function () {
                            replaceLeader(args.index, 2);
                            swal.close();
                        }
                    });
                    angular.element('#cancel-button').ejButton({
                        click: function () {
                            angular.element('#sectionListBox').ejListBox('uncheckItemByIndex', args.index);
                            swal.close();
                        }
                    });
                } else {
                    vm.sectionLeaderList[args.index].replace = 3;
                }
            }
        }

        function replaceLeader(index, replace) {
            vm.sectionLeaderList[index].replace = replace;
        }

        function getAgeGroupTreeView() {
            return dataservice.getAgeGroupTreeView()
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                });
        }

        function changeHeight(args) {
            vm.formData.height = args.value;
        }

        function changeWeight(args) {
            vm.formData.weight = args.value;
        }

        function changeSex(args) {
            vm.formData.sex = args.value;
        }

        function saveUser() {
            if (vm.MODFELH || (vm.SZULOADATLAP && vm.SZULO) || (vm.SPORTOLOADATLAP && vm.SPORTOLO)) {
                // a syncfusion direktívák miatt
                $scope.$broadcast('submitted');
                if ($scope.form.$valid === false) {
                    log.errorMsg(gettextCatalog.getString('RequiredFieldsError'), gettextCatalog.getString('FillTheRequiredFields'));
                } else {
                    if (vm.formData.password != '') {
                        if (vm.formData.password != vm.passwordAgain) {
                            log.errorMsg(gettextCatalog.getString('PasswordsDontMatch'));
                            return;
                        }
                    }
                    // kiválasztott korosztályok
                    vm.formData.ageGroupList = [];
                    var tmp = angular.element('#ageGroupTreeView').ejTreeView('getCheckedNodesIndex');
                    angular.forEach(tmp, function (value) {
                        if (vm.ageGroupList[value].hasChild === false) {
                            vm.formData.ageGroupList.push(vm.ageGroupList[value]);
                        }
                    });
                    // szakosztály, amiknek a vezetője
                    vm.formData.sectionsLeaderList = vm.sectionLeaderList;
                    // kép küldése, ha választott
                    if (angular.element('#fileName').val() !== '' && vm.cropper.croppedImage !== null) {
                        vm.formData.fileData = vm.cropper.croppedImage;
                        vm.formData.fileName = angular.element('#fileName').val();
                    }
                    vm.formData.socialSecurityNumber = angular.element('#socialSecurityNumber').ejMaskEdit('get_StrippedValue');
                    dataservice.modifyUser(vm.formData).then(function (data) {
                        if (data) {
                            var currentId = parseInt(authenticationService.getAuthData().id);
                            if (currentId === vm.formData.id) {
                                $rootScope.$broadcast(AUTH_EVENTS.refreshProfilePic, data.image);
                            }
                            log.successMsg('UserModified');
                            if (vm.SPORTOLOADATLAP || vm.SZULOADATLAP) {
                                $rootScope.back();
                            } else {
                                $state.go('settings.userManagement.userList');
                            }
                        }
                    });
                }
            } else {
                log.permissionError();
            }
        }

        function goToFiles() {
            if (vm.MODFELH) {
                paramHelper.setParams([{'files.tipus': 'Felhasznalok'}, {'files.tablaId': vm.formData.id}]);
                $state.go('other.files');
            } else {
                log.permissionError();
            }
        }

        function openCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'visible');
            angular.element('#cropDialog').ejDialog('open');
        }

        function closeCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'hidden');
            angular.element('#cropDialog').ejDialog('close');
        }

        function doneCropDialog() {
            vm.photoUrl = vm.cropper.croppedImage;
            angular.element('#cropDialog').ejDialog('close');
        }

        function deleteImg() {
            swal({
                title: gettextCatalog.getString('DeleteProfileImg'),
                text: gettextCatalog.getString('AreYouSureYouWantToDeleteTheProfileImg'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: gettextCatalog.getString('Yes'),
                cancelButtonText: gettextCatalog.getString('No')
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    angular.element('#fileName').val('');
                    vm.formData.fileName = 'oldDeleted';
                    vm.photoUrl = vm.noImg;
                    $scope.$apply();
                }
            });
        }

        function IssuedAssets(userid) {
            dataservice.IssuedAssets(0, userid).then(function (response) {
                usersAssetGrid.dataSource = response.data;
            });
        }

        function getSelectedAssets() {
            var row = $rootScope.getSelectedItems(usersAssetGrid);
            usersAssetGrid.refresh();
            return vm.selectedAssets;
        }

        function getSelectedAssetIds(selectGrid) {
            var selectedAssets = $rootScope.getSelectedItems(selectGrid);
            var assetIds = [];
            selectedAssets.forEach(function (asset) {
                assetIds.push(asset.EszkozId);
            });
            return assetIds;
        }

        function IssueAsset() {
            if (vm.ESZKMOZG) {
                angular.element('#issueDialog').data('ejDialog').open();
                dataservice.AssetsByType(0, 'issuable').then(function (data) {
                    AssetGrid.dataSource = data.data;
                });
                dataservice.assetTypeList().then(function (data) {
                    vm.types = data.itemsList;
                    vm.types.forEach(function (item) {
                        item.value = item.TipusId;
                        item.text = item.TipusNev;
                    });
                });
            } else {
                log.permissionError();
            }
        }

        function IssueNewAsset() {
            angular.element('#issueDialog').data('ejDialog').close();
            vm.chooseEnabled = false;
            var HTMLtext = createHtmlSwalContent([
                {id: 'Megnevezes', text: gettextCatalog.getString('Megnevezes'), value: ''},
                {id: 'Leiras', text: gettextCatalog.getString('Leiras'), value: ''},
                {id: 'Azonosito', text: gettextCatalog.getString('Azonosito'), value: ''},
                {id: 'Meret', text: gettextCatalog.getString('Meret'), value: ''},
                {id: 'Ar', text: gettextCatalog.getString('Ar'), value: ''},
                {id: 'darab', text: gettextCatalog.getString('NumberMassAdd'), value: ''},
                {id: 'AssetType', text: gettextCatalog.getString('AssetType'), value: '', ac: true}
            ]);
            swal({
                title: gettextCatalog.getString('CreateAsset'),
                html: HTMLtext,
                type: 'info',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: gettextCatalog.getString('Ok'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                preConfirm: function () {
                    return new Promise(function (resolve) {
                        var TipusNev = document.getElementById('AutoCompleteSwal').value;
                        var Megnevezes = document.getElementById('Megnevezes').value;
                        if (!TipusNev || TipusNev.length === 0) {
                            swal.showValidationError(gettextCatalog.getString('AssetTypeNameMustNotBeEmpty'));
                        }
                        if (!Megnevezes || Megnevezes.length === 0) {
                            swal.showValidationError(gettextCatalog.getString('AssetNameMustNotBeEmpty'));
                        }
                        resolve();
                    });
                }
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    vm.newAsset.EszkozId = -1;
                    vm.newAsset.TipusId = 0;
                    vm.newAsset.TipusNev = '';
                    vm.newAsset.Megnevezes = document.getElementById('Megnevezes').value;
                    vm.newAsset.Leiras = document.getElementById('Leiras').value;
                    vm.newAsset.Azonosito = document.getElementById('Azonosito').value;
                    vm.newAsset.Meret = document.getElementById('Meret').value;
                    vm.newAsset.Ar = document.getElementById('Ar').value;
                    vm.newAsset.darab = document.getElementById('darab').value;
                    vm.newAsset.TipusNev = document.getElementById('AutoCompleteSwal').value;

                    dataservice.AssetManager(vm.newAsset).then(function (response) {
                        dataservice.AssetIssue(response.data.EszkozIdk, vm.userId, 0, vm.IssueComment).then(function () {
                            vm.IssueComment = '';
                            log.successMsg('IssuedSuccessfully');
                            IssuedAssets(vm.userId);
                        });
                    });
                }
            });
            angular.element('#AutoCompleteSwal').ejAutocomplete({
                dataSource: vm.types,
                change: function (arg) {
                    vm.newAsset.TipusId = 0;
                    vm.newAsset.TipusNev = arg.value;
                },
                value: vm.newAsset.TipusId,
                width: '100%'
            });
        }

        function Issue() {
            vm.chooseEnabled = false;
            vm.IssueComment = '';
            angular.element('#issueDialog').data('ejDialog').close();
            dataservice.AssetIssue(getSelectedAssetIds(AssetGrid), vm.userId, 0, vm.IssueComment).then(function () {
                log.successMsg('IssuedSuccessfully');
                IssuedAssets(vm.userId);
            });
        }

        function TakeBack() {
            vm.takebackEnabled = false;
            dataservice.AssetTakeBack(getSelectedAssetIds(usersAssetGrid), vm.TakeBackComment).then(function () {
                vm.TakeBackComment = '';
                log.successMsg('TakenBackSuccessfully');
                IssuedAssets(vm.userId);
            });
        }

        function createHtmlSwalContent(arr) {
            var HTMLtext = '<div style="margin-top: 10px;" >';
            arr.forEach(function (item) {
                HTMLtext += '<div class="row">';
                HTMLtext += '<label class="col-lg-6" style="text-align: left; margin-top:5px;">' + item.text + '</label>';
                if (item.hasOwnProperty('ac')) {
                    HTMLtext += '<input class="col-lg-6 col-lg-offset-6" id="AutoCompleteSwal" ej-autocomplete></input><br>';
                } else if (item.hasOwnProperty('dd')) {
                    HTMLtext += '<input class="col-lg-6 col-lg-offset-6" id="DropDownSwal" ej-dropdownlist></input><br>';
                } else {
                    if (item.value && item.value.length > 0) {
                        HTMLtext += '<input type="text" value="' + item.value + '" id="' + item.id + '"></input><br>';
                    } else {
                        HTMLtext += '<input type="text" value="" id="' + item.id + '"></input><br>';
                    }
                }
                HTMLtext += '</div>';
            });
            HTMLtext += '</div><br>';
            return HTMLtext;
        }
    }
})();
