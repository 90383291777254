(function () {
    'use strict';

    angular
        .module('app.reports.annualPresenceReports.annualPresenceReportsBySections')
        .controller('AnnualPresenceReportsBySectionsController', AnnualPresenceReportsBySectionsController);

    AnnualPresenceReportsBySectionsController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', '$compile', '$scope', 'authenticationService', 'paramHelper', '$state', '$timeout'];

    /* @ngInject */
    function AnnualPresenceReportsBySectionsController(log, dataservice, $q, $rootScope, gettextCatalog, $compile, $scope, authenticationService, paramHelper, $state, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AnnualPresenceReportsBySections');

        //Évek (2010-től az aktuális évig)
        vm.years = [];
        for (var ev = 2010; ev <= new Date().getFullYear(); ev++) {
            vm.years.push(ev + ' / ' + (ev + 1));
        }
        //Kiválasztott év (alapból az aktuális)
        vm.selectedYear = new Date().getFullYear() + ' / ' + (new Date().getFullYear() + 1);
        vm.selectedSection = {};
        vm.sectionDataBound = [];
        vm.stackedHeaderRows = [];
        vm.summaryRows = [];
        vm.columns = [
            {field: 'Id', visible: false, isPrimaryKey: true, isIdentity: true},
            {field: 'Sorszam', headerText: gettextCatalog.getString('RowNumber'), textAlign: 'center', width: 120},
            {field: 'VersenyzoNeve', headerText: gettextCatalog.getString('Name'), textAlign: 'center', width: 250, template: '#columnTemplate'},
            {
                headerText: gettextCatalog.getString('August'), textAlign: 'center', columns: [
                //column: 'AugFlo, AugJelen, AugHianyzik,AugSzazalek',
                {field: 'AugFlo', headerText: gettextCatalog.getString('Sum'), textAlign: 'center', width: 120},
                {field: 'AugJelen', headerText: gettextCatalog.getString('Man'), textAlign: 'center', width: 100},
                {field: 'AugHianyzik', headerText: gettextCatalog.getString('Woman'), textAlign: 'center', width: 100},
                {
                    field: 'AugSzazalek',
                    headerText: gettextCatalog.getString('JelenSzazalek'),
                    textAlign: 'center',
                    width: 120
                }
            ]
            },
            {
                headerText: gettextCatalog.getString('September'), textAlign: 'center', columns: [
                // column: 'SzeptFlo, SzeptJelen, SzeptHianyzik,SzeptSzazalek',
                {field: 'SzeptFlo', headerText: gettextCatalog.getString('Sum'), textAlign: 'center', width: 120},
                {field: 'SzeptJelen', headerText: gettextCatalog.getString('Man'), textAlign: 'center', width: 100},
                {
                    field: 'SzeptHianyzik',
                    headerText: gettextCatalog.getString('Woman'),
                    textAlign: 'center',
                    width: 100
                },
                {
                    field: 'SzeptSzazalek',
                    headerText: gettextCatalog.getString('JelenSzazalek'),
                    textAlign: 'center',
                    width: 120
                }
            ]
            },
            {
                headerText: gettextCatalog.getString('October'), textAlign: 'center', columns: [
                // column: 'OktFlo, OktJelen, OktHianyzik,OktSzazalek',
                {field: 'OktFlo', headerText: gettextCatalog.getString('Sum'), textAlign: 'center', width: 120},
                {field: 'OktJelen', headerText: gettextCatalog.getString('Man'), textAlign: 'center', width: 100},
                {field: 'OktHianyzik', headerText: gettextCatalog.getString('Woman'), textAlign: 'center', width: 100},
                {
                    field: 'OktSzazalek',
                    headerText: gettextCatalog.getString('JelenSzazalek'),
                    textAlign: 'center',
                    width: 120
                }
            ]
            },
            {
                headerText: gettextCatalog.getString('November'), textAlign: 'center', columns: [
                // column: 'NovFlo, NovJelen, NovHianyzik,NovSzazalek',
                {field: 'NovFlo', headerText: gettextCatalog.getString('Sum'), textAlign: 'center', width: 120},
                {field: 'NovJelen', headerText: gettextCatalog.getString('Man'), textAlign: 'center', width: 100},
                {field: 'NovHianyzik', headerText: gettextCatalog.getString('Woman'), textAlign: 'center', width: 100},
                {
                    field: 'NovSzazalek',
                    headerText: gettextCatalog.getString('JelenSzazalek'),
                    textAlign: 'center',
                    width: 120
                }
            ]
            },
            {
                headerText: gettextCatalog.getString('December'), textAlign: 'center', columns: [
                // column: 'DecFlo, DecJelen, DecHianyzik,DecSzazalek',
                {field: 'DecFlo', headerText: gettextCatalog.getString('Sum'), textAlign: 'center', width: 120},
                {field: 'DecJelen', headerText: gettextCatalog.getString('Man'), textAlign: 'center', width: 100},
                {field: 'DecHianyzik', headerText: gettextCatalog.getString('Woman'), textAlign: 'center', width: 100},
                {
                    field: 'DecSzazalek',
                    headerText: gettextCatalog.getString('JelenSzazalek'),
                    textAlign: 'center',
                    width: 120
                }
            ]
            },
            {
                headerText: gettextCatalog.getString('January'), textAlign: 'center', columns: [
                // column: 'JanFlo, JanJelen, JanHianyzik,JanSzazalek',
                {
                    field: 'JanFlo',
                    headerText: gettextCatalog.getString('Sum'),
                    textAlign: 'center',
                    width: 120
                },
                {
                    field: 'JanJelen',
                    headerText: gettextCatalog.getString('Man'),
                    textAlign: 'center',
                    width: 100
                },
                {
                    field: 'JanHianyzik',
                    headerText: gettextCatalog.getString('Woman'),
                    textAlign: 'center',
                    width: 100
                },
                {
                    field: 'JanSzazalek',
                    headerText: gettextCatalog.getString('JelenSzazalek'),
                    textAlign: 'center',
                    width: 120
                }
            ]
            },
            {
                headerText: gettextCatalog.getString('February'), textAlign: 'center', columns: [
                // column: 'FebFlo, FebJelen, FebHianyzik,FebSzazalek',
                {
                    field: 'FebFlo',
                    headerText: gettextCatalog.getString('Sum'),
                    textAlign: 'center',
                    width: 120
                },
                {
                    field: 'FebJelen',
                    headerText: gettextCatalog.getString('Man'),
                    textAlign: 'center',
                    width: 100
                },
                {
                    field: 'FebHianyzik',
                    headerText: gettextCatalog.getString('Woman'),
                    textAlign: 'center',
                    width: 100
                },
                {
                    field: 'FebSzazalek',
                    headerText: gettextCatalog.getString('JelenSzazalek'),
                    textAlign: 'center',
                    width: 120
                }
            ]
            },
            {
                headerText: gettextCatalog.getString('March'), textAlign: 'center', columns: [
                // column: 'MarcFlo, MarcJelen, MarcHianyzik,MarcSzazalek',
                {
                    field: 'MarcFlo',
                    headerText: gettextCatalog.getString('Sum'),
                    textAlign: 'center',
                    width: 120
                },
                {
                    field: 'MarcJelen',
                    headerText: gettextCatalog.getString('Man'),
                    textAlign: 'center',
                    width: 100
                },
                {
                    field: 'MarcHianyzik',
                    headerText: gettextCatalog.getString('Woman'),
                    textAlign: 'center',
                    width: 100
                },
                {
                    field: 'MarcSzazalek',
                    headerText: gettextCatalog.getString('JelenSzazalek'),
                    textAlign: 'center',
                    width: 120
                }
            ]
            },
            {
                headerText: gettextCatalog.getString('April'), textAlign: 'center', columns: [
                // column: 'AprFlo, AprJelen, AprHianyzik,AprSzazalek',
                {
                    field: 'AprFlo',
                    headerText: gettextCatalog.getString('Sum'),
                    textAlign: 'center',
                    width: 120
                },
                {
                    field: 'AprJelen',
                    headerText: gettextCatalog.getString('Man'),
                    textAlign: 'center',
                    width: 100
                },
                {
                    field: 'AprHianyzik',
                    headerText: gettextCatalog.getString('Woman'),
                    textAlign: 'center',
                    width: 100
                },
                {
                    field: 'AprSzazalek',
                    headerText: gettextCatalog.getString('JelenSzazalek'),
                    textAlign: 'center',
                    width: 120
                },
            ]
            },
            {
                headerText: gettextCatalog.getString('May'), textAlign: 'center', columns: [
                // column: 'MajFlo, MajJelen, MajHianyzik,MajSzazalek',

                {
                    field: 'MajFlo',
                    headerText: gettextCatalog.getString('Sum'),
                    textAlign: 'center',
                    width: 120
                },
                {
                    field: 'MajJelen',
                    headerText: gettextCatalog.getString('Man'),
                    textAlign: 'center',
                    width: 100
                },
                {
                    field: 'MajHianyzik',
                    headerText: gettextCatalog.getString('Woman'),
                    textAlign: 'center',
                    width: 100
                },
                {
                    field: 'MajSzazalek',
                    headerText: gettextCatalog.getString('JelenSzazalek'),
                    textAlign: 'center',
                    width: 120
                },
            ]
            },
            {
                headerText: gettextCatalog.getString('June'), textAlign: 'center', columns: [
                // column: 'JunFlo, JunJelen, JunHianyzik,JunSzazalek',
                {
                    field: 'JunFlo',
                    headerText: gettextCatalog.getString('Sum'),
                    textAlign: 'center',
                    width: 120
                },
                {
                    field: 'JunJelen',
                    headerText: gettextCatalog.getString('Man'),
                    textAlign: 'center',
                    width: 100
                },
                {
                    field: 'JunHianyzik',
                    headerText: gettextCatalog.getString('Woman'),
                    textAlign: 'center',
                    width: 100
                },
                {
                    field: 'JunSzazalek',
                    headerText: gettextCatalog.getString('JelenSzazalek'),
                    textAlign: 'center',
                    width: 120
                },
            ]
            },
            {
                headerText: gettextCatalog.getString('July'), textAlign: 'center', columns: [
                // column: 'JulFlo, JulJelen, JulHianyzik,JulSzazalek',
                {
                    field: 'JulFlo',
                    headerText: gettextCatalog.getString('Sum'),
                    textAlign: 'center',
                    width: 120
                },
                {
                    field: 'JulJelen',
                    headerText: gettextCatalog.getString('Man'),
                    textAlign: 'center',
                    width: 100
                },
                {
                    field: 'JulHianyzik',
                    headerText: gettextCatalog.getString('Woman'),
                    textAlign: 'center',
                    width: 100
                },
                {
                    field: 'JulSzazalek',
                    headerText: gettextCatalog.getString('JelenSzazalek'),
                    textAlign: 'center',
                    width: 120
                },
            ]
            },
            {
                headerText: gettextCatalog.getString('12month'), textAlign: 'center', columns: [
                // column: 'OsszFlo, OsszJelen, OsszHianyzik,OsszSzazalek',
                {
                    field: 'OsszFlo',
                    headerText: gettextCatalog.getString('Sum'),
                    textAlign: 'center',
                    width: 120
                },
                {
                    field: 'OsszJelen',
                    headerText: gettextCatalog.getString('Man'),
                    textAlign: 'center',
                    width: 100
                },
                {
                    field: 'OsszHianyzik',
                    headerText: gettextCatalog.getString('Woman'),
                    textAlign: 'center',
                    width: 100
                },
                {
                    field: 'OsszSzazalek',
                    headerText: gettextCatalog.getString('JelenSzazalek'),
                    textAlign: 'center',
                    width: 120
                }
            ]
            },
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            allowPaging: true,
            frozenColumns: 3,
            locale: 'hu-HU',
            gridLines: 'both',
            toolbar: $rootScope.toolbarItemsWithSearch,
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        vm.sectionSelect = sectionSelect;
        vm.goToNewCoach = goToNewCoach;
        vm.reportSelect = reportSelect;
        vm.sectionDataBound = sectionDataBound;

        activate();

        function activate() {
            var promises = [authenticationService.getRight('KMEVESJELEDZO')];
            $q.all(promises).then(function (res) {
                vm.KMEVESJELEDZO = res[0];
                if (!vm.KMEVESJELEDZO) {
                    log.permissionError(true);
                } else {
                    getSections();
                    setDropDowns();
                }
            });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'aprbs.selectedYear',
                'aprbs.selectedSection'
            ])
            ];
            $q.all(promises).then(function (results) {
                if (results[0]['aprbs.selectedYear'] != null) {
                    vm.selectedYear = results[0]['aprbs.selectedYear'];
                    vm.selectedSection = results[0]['aprbs.selectedSection'];
                }
            });
        }

        function sectionDataBound() {
            if (vm.selectedSection > 0) {
                angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.selectedSection);
            } else {
                if (vm.sectionList.length === 1) {
                    angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                    vm.selectedSection = vm.sectionList[0].value;
                } else {
                    angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.selectedSection);
                }
            }
        }

        function getSections() {
            return dataservice.sectionDropDownList(0, true)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                    $timeout(function () {
                        if (!(vm.selectedSection > 0)) {
                            vm.selectedSection = vm.sectionList[0].value;
                        }
                    }, 0);
                });
        }

        function sectionSelect(args) {
            vm.selectedSection = args.value;
        }

        function goToNewCoach(coachId) {
            if (coachId && coachId === 0 && !vm.MEGTHAVIJEL) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{'newUser.userId': coachId}]);
            $state.go('settings.userManagement.modifyUser');
        }

        function reportSelect() {
            paramHelper.setParams([
                {'aprbs.selectedYear': vm.selectedYear},
                {'aprbs.selectedSection': vm.selectedSection}
            ]);
            dataservice.getAnnualPresenceReportBySectionsReport(vm.selectedYear.split('/')[0], vm.selectedSection)
                .then(function (data) {
                    grid.dataSource = data.itemsList;
                });
        }
    }
})();
