(function () {
    'use strict';

    angular
        .module('app.reports.managementReports.annualCompetitivePlans')
        .controller('AnnualCompetitivePlansController', AnnualCompetitivePlansController);

    AnnualCompetitivePlansController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'paramHelper', 'authenticationService', '$state', '$scope', '$compile', '$filter'];
    /* @ngInject */
    function AnnualCompetitivePlansController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $state, $scope, $compile, $filter) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AnnualCompetitivePlans');

        // Create variables
        vm.params = [];
        vm.sectionId = -1;
        vm.coachId = -1;
        vm.coachList = [];
        vm.sectionList = [];
        vm.dateToValue = new Date();
        vm.dateFromValue = new Date(new Date().setMonth(new Date().getMonth() - 6));
        vm.OpenText = gettextCatalog.getString('Open');
        // Grid's variables
        vm.gridToolbar = ['printGrid', 'excelExport'];
        vm.columns = [
            {field: 'Egyeni', headerText: gettextCatalog.getString('Individual'), textAlign: 'center', width: 150, displayAsCheckBox: true},
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), textAlign: 'left', width: 150, template: '#ancopfiltersection'},
            {field: 'KorcsoportNeve', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'left', width: 150},
            {field: 'EdzoNeve', headerText: gettextCatalog.getString('Coach'), textAlign: 'left', width: 150, template: '#ancopopencoach'},
            {field: 'KeszitesDatum', headerText: gettextCatalog.getString('Date'), textAlign: 'left', width: 150},
            {field: 'EdzoLezarta', type: 'boolean', headerText: gettextCatalog.getString('Closed'), textAlign: 'center', width: 150, displayAsCheckBox: true},
            {field: 'EdzoLezartaDatum', headerText: gettextCatalog.getString('ClosedDate'), textAlign: 'left', width: 150},
            {field: 'SzakosztalyVezetoJovahagyta', type: 'boolean', headerText: gettextCatalog.getString('SectionLeaderSigned'), textAlign: 'center', width: 150, displayAsCheckBox: true},
            {field: 'SzakosztalyVezetoJovahagyva', headerText: gettextCatalog.getString('SectionLeaderSignedDate'), textAlign: 'left', width: 150},
            {field: 'Megnyitas', template: '#ancoplcolumnTemplate1', textAlign: 'left', width: 150}
        ];

        // Public functions
        vm.sectionSelect = sectionSelect;
        vm.sectionDataBound = sectionDataBound;
        vm.coachDataBound = coachDataBound;
        vm.search = search;
        vm.goToAnnualSeasonPlan = goToAnnualSeasonPlan;
        vm.openCoach = openCoach;
        vm.filterSection = filterSection;
        vm.coachSelect = coachSelect;
        vm.dateFromChange = dateFromChange;
        vm.dateToChange = dateToChange;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Activate page
        activate();

        function activate() {
            authenticationService.getRight('VEZETOIEV').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([authenticationService.getRight('MEGTEVVERST'), paramHelper.getParams([], ['annualCompetitivePlans.coachId', 'annualCompetitivePlans.sectionId'])]).then(function (results) {
                        vm.MEGTEVVERST = results[0];
                        vm.params = results[1];
                    });
                    getSections();
                }
            });
        }

        // Get sections' list
        function getSections() {
            return dataservice.sectionDropDownList(0, true).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.sectionList = data.itemsList;
            });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.params['annualCompetitivePlans.sectionId']) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.params['annualCompetitivePlans.sectionId']);
                vm.params['annualCompetitivePlans.sectionId'] = null;
            } else if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        // Run after section selected
        function sectionSelect(args) {
            getActualCoachesList(args.value);
        }

        function filterSection(id) {
            vm.sectionId = id;
            search();
        }

        function getActualCoachesList(sectionId) {
            return dataservice.coachDropDownList(sectionId, 0, vm.dateFromValue, vm.dateToValue).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.coachList = data.itemsList;
            });
        }

        // Run after data bounded
        function coachDataBound() {
            if (vm.params['annualCompetitivePlans.coachId']) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.params['annualCompetitivePlans.coachId']);
                vm.params['annualCompetitivePlans.coachId'] = null;
            } else if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
            }
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function coachSelect(args) {
            vm.coachId = args.value;
            search();
        }

        function search() {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            dataservice.getAnnualCompetitiveReports(vm.dateFromValue, vm.dateToValue, vm.sectionId, vm.coachId).then(function (data) {
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'EdzoLezartaDatum');
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'SzakosztalyVezetoJovahagyva');
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'KeszitesDatum');
                grid.dataSource = data.itemsList;
            });
            paramHelper.setParams([
            {
                'annualCompetitivePlans.sectionId': vm.sectionId
            },
            {
                'annualCompetitivePlans.coachId': vm.coachId
            }
            ]);
        }

        function goToAnnualSeasonPlan(args) {
            if (!vm.MEGTEVVERST) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([
                {
                    'AnnualSeasonPlanReport.Id': args
                }]);
            $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage1');
        }

        function dateFromChange(args) {
            vm.dateFromValue = args.value;
            getActualCoachesList(vm.sectionId);
        }

        function dateToChange(args) {
            vm.dateToValue = args.value;
            getActualCoachesList(vm.sectionId);
        }
    }
})();
