(function () {
    'use strict';

    angular
        .module('app.settings.userManagement.newUser')
        .controller('newUserController', newUserController);

    newUserController.$inject = ['$q', 'log', 'VIEW_DATA', 'dataservice', 'DOMAIN', 'authenticationService', '$rootScope', '$scope', 'gettextCatalog', 'paramHelper', '$state'];
    /* @ngInject */
    function newUserController($q, log, VIEW_DATA, dataservice, DOMAIN, authenticationService, $rootScope, $scope, gettextCatalog, paramHelper, $state) {
        var vm = this; $rootScope.vm = vm; // jshint ignore: line

        // Page title
        $rootScope.title = gettextCatalog.getString('NewUser');

        // Dropdowns
        vm.sectionLeaderList = [];
        vm.ageGroupList = [];
        vm.weightList = dataservice.range(VIEW_DATA.dropDownList.weight.downThreshold, VIEW_DATA.dropDownList.weight.upThreshold);
        vm.heightList = dataservice.range(VIEW_DATA.dropDownList.centimetre.downThreshold, VIEW_DATA.dropDownList.centimetre.upThreshold);
        vm.sexList = [{
                text: gettextCatalog.getString('Man'),
                value: 1
            },
            {
                text: gettextCatalog.getString('Woman'),
                value: 0
            }
        ];
        vm.userGroupList = [];
        vm.selectedItems = [0, 1];

        // Default variables
        vm.noImg = DOMAIN.baseURL + DOMAIN.noImage;
        vm.photoUrl = vm.noImg;
        vm.passwordAgain = undefined;
        vm.colorValue = '#000000';
        vm.activeValue = true;
        vm.sectionLeader = false;
        vm.formData = {
            name: undefined,
            userGroupId: undefined,
            loginName: undefined,
            email: undefined,
            password: undefined,
            passwordExpiryDate: undefined,
            fileData: null,
            fileName: null,
            address: undefined,
            contact: undefined,
            socialSecurityNumber: undefined,
            color: '#000000',
            monthlyAttendanceRegister: true,
            activeUser: true,
            mainWorkPlace: undefined,
            qualifications: undefined,
            country: undefined,
            city: undefined,
            birthDate: undefined,
            citizenship: undefined,
            mothersName: undefined,
            height: undefined,
            weight: undefined,
            identityCardNumber: undefined,
            sex: undefined,
            passportNumber: undefined,
            otherInformation: undefined
        };

        vm.changeUserGroup = changeUserGroup;
        vm.colorChanged = colorChanged;
        vm.monthlyAttendanceRegisterChanged = monthlyAttendanceRegisterChanged;
        vm.activeChange = activeChange;
        vm.sectionLeaderChecked = sectionLeaderChecked;
        vm.changeHeight = changeHeight;
        vm.changeWeight = changeWeight;
        vm.changeSex = changeSex;
        vm.saveUser = saveUser;

        // Image cropper variables
        vm.cropper = {};
        vm.cropper.sourceImage = null;
        vm.cropper.croppedImage = null;
        vm.bounds = {};
        vm.bounds.left = 0;
        vm.bounds.right = 0;
        vm.bounds.top = 0;
        vm.bounds.bottom = 0;
        vm.cropDialogTitle = gettextCatalog.getString('ChooseImageTitle');

        vm.deleteImg = deleteImg;
        vm.openCropDialog = openCropDialog;
        vm.doneCropDialog = doneCropDialog;
        vm.closeCropDialog = closeCropDialog;
        vm.socialSecurityNumberRequiredValue = false;

        activate();

        function activate() {
            $q.all([paramHelper.getParams([], []), authenticationService.getRight('UJFELH'), getUserGroupDropDownList(), getSectionLeaderListBox(), getAgeGroupTreeView()])
                .then(function (results) {
                    if (results[1] !== true) {
                        log.permissionError();
                    } else {
                        vm.UJFELH = results[1];
                        getSystemSettings('TAJKOTELEZO');
                    }
                });
        }

        function getUserGroupDropDownList() {
            dataservice.getFullUserGroupList()
                .then(function (data) {
                    vm.userGroupList = data.itemsList;
                });
        }

        function getSystemSettings(arg) {
            dataservice.getSystemSettings(arg).then(function (response) {
                vm.socialSecurityNumberRequiredValue = response.retValue;
            });
        }

        function changeUserGroup(args) {
            vm.formData.userGroupId = args.value;
            dataservice.getSectionHasSectionLeaderRight(vm.formData.userGroupId)
            .then(function (data) {
                if (data.retValue) {
                    angular.element('#sectionListBox').ejListBox('enable');
                } else {
                    angular.element('#sectionListBox').ejListBox('disable');
                }
            });
        }

        function colorChanged(args) {
            vm.formData.color = args.value;
        }

        function monthlyAttendanceRegisterChanged(args) {
            vm.formData.monthlyAttendanceRegister = args.value;
        }

        function activeChange(args) {
            vm.formData.activeUser = args.value;
        }

        function getSectionLeaderListBox() {
            dataservice.getSectionLeaderListBox()
                .then(function (data) {
                    vm.sectionLeaderList = data.itemsList;
                });
        }

        function sectionLeaderChecked(args) {
            if (args.isChecked === true) {
                if (!args.data.VezetoNev) {
                    args.data.VezetoNev = gettextCatalog.getString('PrimarySectionLeader');
                }
                if (!args.data.VezetoNev2) {
                    args.data.VezetoNev2 = gettextCatalog.getString('SecondarySectionLeader');
                }
                swal({
                    title: gettextCatalog.getString('ReplaceQuestion'),
                    html: '<p>' + gettextCatalog.getString('WhichLeaderDoYouWantToReplace') + '</p>' + '<div><button class="cts-button" id="leader1">' + args.data.VezetoNev + '</button>' + '<button class="cts-button" id="leader2">' + args.data.VezetoNev2 + '</button></div><div style="text-align: center;"><button class="cts-button auto-width" id="cancel-button">' + gettextCatalog.getString('Cancel') + '</button></div>',
                    showCancelButton: false,
                    showConfirmButton: false,
                    closeOnCancel: false,
                    allowOutsideClick: false
                });
                angular.element('#leader1').ejButton({
                    click: function () {
                        replaceLeader(args.index, 1);
                        swal.close();
                    }
                });
                angular.element('#leader2').ejButton({
                    click: function () {
                        replaceLeader(args.index, 2);
                        swal.close();
                    }
                });
                angular.element('#cancel-button').ejButton({
                    click: function () {
                        angular.element('#sectionListBox').ejListBox('uncheckItemByIndex', args.index);
                        swal.close();
                    }
                });
            } else {
                vm.sectionLeaderList[args.index].replace = 3;
            }
        }

        function replaceLeader(index, replace) {
            vm.sectionLeaderList[index].replace = replace;
        }

        function getAgeGroupTreeView() {
            dataservice.getAgeGroupTreeView()
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                });
        }

        function changeHeight(args) {
            vm.formData.height = args.value;
        }

        function changeWeight(args) {
            vm.formData.weight = args.value;
        }

        function changeSex(args) {
            vm.formData.sex = args.value;
        }

        function saveUser() {
            if (vm.UJFELH) {
                // a syncfusion direktívák miatt
                $scope.$broadcast('submitted');
                if ($scope.form.$valid === false) {
                    log.errorMsg('FillTheRequiredFields');
                } else {
                    if (vm.formData.password != '') {
                        if (vm.formData.password != vm.passwordAgain) {
                            log.errorMsg(gettextCatalog.getString('PasswordsDontMatch'));
                            return;
                        }
                    }
                    // kiválasztott korosztályok
                    vm.formData.ageGroupList = [];
                    var tmp = angular.element('#ageGroupTreeView').ejTreeView('getCheckedNodesIndex');
                    angular.forEach(tmp, function (value) {
                        if (vm.ageGroupList[value].hasChild === false) {
                            vm.formData.ageGroupList.push(vm.ageGroupList[value]);
                        }
                    });
                    // szakosztály, amiknek a vezetője
                    vm.formData.sectionsLeaderList = vm.sectionLeaderList;
                    // kép küldése, ha választott
                    if (angular.element('#fileName').val() !== '' && vm.cropper.croppedImage !== null) {
                        vm.formData.fileData = vm.cropper.croppedImage;
                        vm.formData.fileName = angular.element('#fileName').val();
                    }
                    if (vm.photoUrl === (DOMAIN.baseURL + DOMAIN.noImage)) {
                        vm.formData.fileData = null;
                        vm.formData.fileName = 'noImg.jpg';
                    }
                    vm.formData.socialSecurityNumber = angular.element('#socialSecurityNumber').ejMaskEdit('get_StrippedValue');
                    dataservice.addUser(vm.formData)
                        .then(function () {
                            log.successMsg('UserAdded');
                            $state.go('settings.userManagement.userList');
                        });
                }
            } else {
                log.permissionError();
            }
        }

        function openCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'visible');
            angular.element('#cropDialog').ejDialog('open');
        }

        function closeCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'hidden');
            angular.element('#cropDialog').ejDialog('close');
        }

        function doneCropDialog() {
            vm.photoUrl = vm.cropper.croppedImage;
            angular.element('#cropDialog').ejDialog('close');
        }

        function deleteImg() {
            swal({
                title: gettextCatalog.getString('DeleteProfileImg'),
                text: gettextCatalog.getString('AreYouSureYouWantToDeleteTheProfileImg'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: gettextCatalog.getString('Yes'),
                cancelButtonText: gettextCatalog.getString('No')
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    angular.element('#fileName').val('');
                    vm.photoUrl = DOMAIN.baseURL + DOMAIN.userImage;
                    $scope.$apply();
                }
            });
        }
    }
})();
