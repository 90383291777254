(function () {
    'use strict';

    angular
        .module('app.athletes.sections')
        .controller('SectionsController', SectionsController);

    SectionsController.$inject = ['logger', 'log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'authenticationService', '$scope', 'paramHelper', '$state'];

    function SectionsController(logger, log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, paramHelper, $state) {
        $rootScope.title = gettextCatalog.getString('Sections');
        var vm = this; $rootScope.vm = vm;

        vm.editEnabled = false;
        vm.editEnabledPerm = false;

        vm.EditSection = EditSection;
        vm.AddSection = AddSection;

        activate();

        vm.columns = [
            {type: 'checkbox', textAlign: 'center', width: '35px'},
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), textAlign: 'left', width: 150},
            {
                field: 'SzakosztalyVezeto1',
                headerText: gettextCatalog.getString('PrimarySectionLeader'),
                textAlign: 'left',
                width: 150
            },
            {
                field: 'SzakosztalyVezeto2',
                headerText: gettextCatalog.getString('SecondarySectionLeader'),
                textAlign: 'left',
                width: 150
            },
            {
                field: 'Egyeni', headerText: gettextCatalog.getString('IndividualSport'), textAlign: 'center',
                type: 'boolean',
                displayAsCheckBox: true,
                width: 75
            },
            {
                field: 'Csapat', headerText: gettextCatalog.getString('TeamSport'), textAlign: 'center',
                type: 'boolean',
                displayAsCheckBox: true,
                width: 75
            },
            {
                field: 'Aktiv', headerText: gettextCatalog.getString('Aktiv'), textAlign: 'center',
                type: 'boolean',
                displayAsCheckBox: true,
                width: 75
            }
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            queryCellInfo: querycellinfo,
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            },
            rowSelecting: function () {
                grid.clearSelection();
            },
            checkBoxChange: function (args) {
                $rootScope.checkBoxChangeOnlyOne(args, grid);
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        function activate() {
            $q.all([authenticationService.getRight('SZAKOSZTALY')]).then(function (result) {
                if (result[0] !== true) {
                    log.permissionError(true);
                } else {
                    vm.userId = authenticationService.getAuthData().id;
                    vm.editEnabledPerm = result[0];
                    getSectionData();
                }
            });
        }

        function getSectionData() {
            dataservice.getSectionData(0).then(function (response) {
                grid.dataSource = response.itemsList;
            });
        }

        function querycellinfo(arg) {
            if (arg.column.field == 'SzakosztalyNeve') {
                var cell = angular.element(arg.cell);
                cell.css('background-color', arg.data.Szin);
                cell.css('color', arg.data.SzovegSzin);
            }
        }

        function AddSection() {
            paramHelper.setParams([{'editSection.SectionId': -1}]);
            $state.go('settings.editSection');
        }

        function EditSection() {
            if ($rootScope.checkRowSelection(grid, true)) {
                var selectedRecords = $rootScope.getSelectedItems(grid);
                vm.editSection = selectedRecords[0];
                paramHelper.setParams([{'editSection.SectionId': vm.editSection.SzakosztalyID}]);
                $state.go('settings.editSection');
            }
        }
    }
})();
