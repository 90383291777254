(function () {
    'use strict';

    angular
        .module('app.settings.createSportSpecificTest')
        .controller('CreateSportSpecificTestController', CreateSportSpecificTestController);

    CreateSportSpecificTestController.$inject = ['log', '$q', 'paramHelper', 'dataservice', '$rootScope', 'gettextCatalog', 'authenticationService', '$scope', 'logger', '$timeout'];

    function CreateSportSpecificTestController(log, $q, paramHelper, dataservice, $rootScope, gettextCatalog, authenticationService, $scope, logger, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('CreateSportSpecificTest');
        vm.columnsFelmeroElemFej = [
            {field: 'Id_FEF', isPrimaryKey: true, isIdentity: true, visible: false},
            {field: 'FelmeroNeve', headerText: gettextCatalog.getString('FelmeroNeve'), textAlign: 'left', width: 250},
            {
                field: 'Aktiv',
                headerText: gettextCatalog.getString('Active'),
                textAlign: 'center',
                width: 30,
                displayAsCheckBox: true
            }
        ];
        vm.columnsFelmeroElem = [
            {field: 'Id_FE', isPrimaryKey: true, isIdentity: true, visible: false},
            {
                field: 'Nev',
                headerText: gettextCatalog.getString('FelmeroElemNeve'),
                textAlign: 'left',
                allowEditing: true,
                width: 220
            }
        ];
        vm.FEEditEnable = true;
        vm.gridToolbarFEF = ['edit'];
        vm.gridToolbarFE = ['add', 'edit', 'update', 'cancel', 'delete'];
        vm.NewFelmeroTipus = NewFelmeroTipus;
        vm.SaveFelmeroTipusok = SaveFelmeroTipusok;
        vm.CopyFelmeroTipus = CopyFelmeroTipus;
        vm.DeleteFelmeroTipus = DeleteFelmeroTipus;
        vm.SelectFelmeroTipus = SelectFelmeroTipus;
        vm.ActivateFelmeroTipus = ActivateFelmeroTipus;
        vm.InactivateFelmeroTipus = InactivateFelmeroTipus;
        vm.SaveFelmeroElemek = SaveFelmeroElemek;
        vm.DiscardFelmeroElem = DiscardFelmeroElem;
        vm.SelectFelmeroElem = SelectFelmeroElem;
        vm.setDefaultColors = setDefaultColors;
        vm.clearColors = clearColors;
        vm.dataFelmeroElemFej = [];
        vm.dataFelmeroElem = [];
        vm.sectionList = [];
        vm.sectionSelect = sectionSelect;
        vm.sectionValue = undefined;
        vm.felmNev = '';
        vm.selectedFelmeroElemFej = undefined;
        vm.selectedFelmeroElem = undefined;
        vm.newFefItemIndex = -1;
        vm.newFeItemIndex = -1;
        vm.felmeroTipusKijelolve = false;
        vm.newTestName = '';
        vm.editableHint = '';
        vm.saveOrDiscardeHint = '';
        vm.modified = false;
        vm.selectedSection = undefined;
        vm.sectionDataBound = sectionDataBound;
        vm.applyColor = applyColor;
        vm.copyButtonEnabled = false;
        vm.deleteButtonEnabled = false;
        vm.selectedFEFRowIndex = undefined;
        vm.selectedFelmeroIsActive = undefined;
        vm.UJSPFELM = false;
        vm.MEGTSPFELM = false;
        vm.typeHint = [];
        vm.typeHint.push(gettextCatalog.getString('SportSpecificTestTypeHint'));
        vm.typeHint.push('');
        vm.typeHint.push(gettextCatalog.getString('SportSpecificTestTypeHint0'));
        vm.typeHint.push(gettextCatalog.getString('SportSpecificTestTypeHint1'));
        vm.typeHint.push(gettextCatalog.getString('SportSpecificTestTypeHint2'));
        vm.typeHint.push(gettextCatalog.getString('SportSpecificTestTypeHint3'));
        vm.typeHint.push(gettextCatalog.getString('SportSpecificTestTypeHint4'));
        vm.typeHint.push(gettextCatalog.getString('SportSpecificTestTypeHint5'));
        vm.typeHint.push(gettextCatalog.getString('SportSpecificTestTypeHint6'));
        vm.selectedFelmeroElemIndex = -1;
        vm.felmElemNev = '';
        vm.defcolor1 = '#990000';
        vm.defcolor12 = '#ff0000';
        vm.defcolor23 = '#ff8000';
        vm.defcolor34 = '#ffff00';
        vm.defcolor45 = '#c4ff00';
        vm.defcolor5 = '#4dff00';
        vm.num0 = -999;
        vm.num6 = 999;
        vm.num1 = 0;
        vm.num2 = 25;
        vm.num3 = 50;
        vm.num4 = 75;
        vm.num5 = 100;
        vm.defnum1 = 0;
        vm.defnum2 = 25;
        vm.defnum3 = 50;
        vm.defnum4 = 75;
        vm.defnum5 = 100;
        vm.defnum1mmss = '01:00';
        vm.defnum2mmss = '02:00';
        vm.defnum3mmss = '03:00';
        vm.defnum4mmss = '04:00';
        vm.defnum5mmss = '05:00';
        vm.defnum1hhmmss = '01:00:00';
        vm.defnum2hhmmss = '02:00:00';
        vm.defnum3hhmmss = '03:00:00';
        vm.defnum4hhmmss = '04:00:00';
        vm.defnum5hhmmss = '05:00:00';
        vm.colordivenabled = false;
        vm.int = false;
        vm.double = false;
        vm.mmss = false;
        vm.hhmmss = false;
        vm.saveBeforeColorWarning = false;
        var GridFelmeroTipus2 = null;
        var GridFelmeroElem2 = null;
        vm.PreloadFelmeroElemFej = -1;
        var types = [
            {text: gettextCatalog.getString('NumberInteger'), value: gettextCatalog.getString('NumberInteger')},
            {text: gettextCatalog.getString('NumberFraction'), value: gettextCatalog.getString('NumberFraction')},
            {text: gettextCatalog.getString('Text'), value: gettextCatalog.getString('Text')},
            {
                text: gettextCatalog.getString('TimeHourMinuteSecHundredthOfASec'),
                value: gettextCatalog.getString('TimeHourMinuteSecHundredthOfASec')
            },
            {
                text: gettextCatalog.getString('TimeMinuteSecHundredthOfASec'),
                value: gettextCatalog.getString('TimeMinuteSecHundredthOfASec')
            },
            {
                text: gettextCatalog.getString('TimeSecHundredthOfASec'),
                value: gettextCatalog.getString('TimeSecHundredthOfASec')
            }
        ];

        CreateGridFelmeroTipus2();

        CreateGridFelmeroElem2();

        activate();

        function activate() {
            var promises = [
                paramHelper.getParams([], ['sectionId', 'FelmeroElemFej']),
                authenticationService.getRight('UJSPFELM'),
                authenticationService.getRight('MEGTSPFELM'),
                getSections()
            ];
            $q.all(promises).then(function (results) {
                vm.UJSPFELM = results[1];
                vm.MEGTSPFELM = results[2];
                resetColumnsFelmeroElem(true);
                if (!vm.MEGTSPFELM) {
                    log.permissionError(true);
                }
                if (!vm.UJSPFELM) {
                    $timeout(function () {
                        GridFelmeroElem2.toolbar = ['edit', 'update', 'cancel'];
                    }, 1000);
                }
                vm.sectionValue = results[0].sectionId;
                vm.selectedSection = results[0].sectionId;
                vm.FelmeroElemFej = results[0].FelmeroElemFej;
                vm.PreloadFelmeroElemFej = results[0].FelmeroElemFej;
                paramHelper.removeParam('sectionId');
                paramHelper.removeParam('FelmeroElemFej');
                if (vm.selectedSection > 0) {
                    if (vm.FelmeroElemFej == 0) {
                        NewFelmeroTipus();
                    }
                }
            });
        }

        function CreateGridFelmeroTipus2() {
            GridFelmeroTipus2 = new ej2.grids.Grid({
                dataSource: vm.dataFelmeroElemFej,
                columns: vm.columnsFelmeroElemFej,
                allowPaging: false,
                allowSorting: false,
                allowTextWrap: false,
                allowExcelExport: false,
                editSettings: {
                    allowEditing: false, allowAdding: false, allowDeleting: false
                },
                locale: 'hu-HU',
                gridLines: 'both',
                selectionSettings: {type: 'single', mode: 'row'},
                rowSelected: function (args) {
                    SelectFelmeroTipus(args);
                },
                dataBound: function () {
                    if (vm.PreloadFelmeroElemFej > 0) {
                        var idx = getFelmeroElemFejIndex(vm.PreloadFelmeroElemFej);
                        GridFelmeroTipus2.selectRow(idx);
                    }
                }
            });
            GridFelmeroTipus2.appendTo('#GridFelmeroTipus2');
        }

        function CreateGridFelmeroElem2() {
            GridFelmeroElem2 = new ej2.grids.Grid({
                dataSource: vm.dataFelmeroElem,
                columns: vm.columnsFelmeroElem,
                toolbar: ['add', 'edit', 'update', 'cancel', 'delete'],
                allowPaging: false,
                allowSorting: false,
                allowTextWrap: false,
                allowExcelExport: false,
                editSettings: {
                    allowEditing: true, allowAdding: true, allowDeleting: true
                },
                locale: 'hu-HU',
                gridLines: 'both',
                rowSelected: function (args) {
                    SelectFelmeroElem(args);
                },
                actionComplete: function (args) {
                    if (args.requestType == 'save' && (args.action == 'add' || args.action == 'save')) {
                        if (!args.data.TipusStr) {
                            log.errorMsg(gettextCatalog.getString('TypeIsIncorrect') + ' ' + gettextCatalog.getString('AtLine') + ': ' + args.data.Nev);
                            args.cancel = true;
                        }
                        if (!args.data.Sorrend) {
                            args.data.Sorrend = getMaxSorrend() + 1;
                            GridFelmeroElem2.refresh();
                        }
                    }
                }
            });
            GridFelmeroElem2.appendTo('#GridFelmeroElem2');
        }

        function resetColumnsFelmeroElem(editable) {
            vm.columnsFelmeroElem = [];
            if (editable) {
                if (vm.UJSPFELM) {
                    GridFelmeroElem2.toolbar = ['add', 'edit', 'update', 'cancel', 'delete'];
                } else {
                    GridFelmeroElem2.toolbar = [];
                }
                vm.columnsFelmeroElem = [
                    {field: 'Id_FE', isPrimaryKey: true, isIdentity: true, visible: false},
                    {
                        field: 'FelmeroElemFejId',
                        headerText: gettextCatalog.getString('FelmeroElemFejId'),
                        textAlign: 'left',
                        allowEditing: false,
                        visible: false
                    },
                    {
                        field: 'Nev',
                        headerText: gettextCatalog.getString('FelmeroElemNeve'),
                        textAlign: 'left',
                        allowEditing: true,
                        width: 220
                    },
                    {
                        field: 'TipusStr',
                        allowEditing: true,
                        headerText: gettextCatalog.getString('Type'),
                        textAlign: 'left',
                        width: 120,
                        editType: 'dropdownedit',
                        edit: {
                            params: {
                                fields: {value: 'value', text: 'text'},
                                dataSource: types,
                                query: new ej2.data.Query()
                            }
                        }
                    },
                    {
                        field: 'ElvartEredmeny',
                        headerText: gettextCatalog.getString('ExpectedResult'),
                        textAlign: 'left',
                        allowEditing: true,
                        width: 150
                    },
                    {
                        field: 'Sorrend',
                        headerText: gettextCatalog.getString('Order'),
                        textAlign: 'left',
                        allowEditing: true,
                        width: 50
                    }
                ];
            } else {
                if (vm.UJSPFELM) {
                    GridFelmeroElem2.toolbar = ['edit', 'update', 'cancel'];
                } else {
                    GridFelmeroElem2.toolbar = [];
                }
                GridFelmeroElem2.refresh();
                vm.columnsFelmeroElem = [
                    {field: 'Id_FE', isPrimaryKey: true, isIdentity: true, visible: false},
                    {
                        field: 'FelmeroElemFejId',
                        headerText: gettextCatalog.getString('FelmeroElemFejId'),
                        textAlign: 'left',
                        allowEditing: false,
                        visible: false
                    },
                    {
                        field: 'Nev',
                        headerText: gettextCatalog.getString('FelmeroElemNeve'),
                        textAlign: 'left',
                        allowEditing: true,
                        width: 220
                    },
                    {
                        field: 'TipusStr',
                        headerText: gettextCatalog.getString('Type'),
                        textAlign: 'left',
                        allowEditing: false,
                        width: 120
                    },
                    {
                        field: 'ElvartEredmeny',
                        headerText: gettextCatalog.getString('ExpectedResult'),
                        textAlign: 'left',
                        allowEditing: false,
                        width: 150
                    },
                    {
                        field: 'Sorrend',
                        headerText: gettextCatalog.getString('Order'),
                        textAlign: 'left',
                        allowEditing: true,
                        width: 50
                    }
                ];
            }
            GridFelmeroElem2.columns = vm.columnsFelmeroElem;
        }

        function NewFelmeroTipus() {
            if (!vm.UJSPFELM) {
                log.permissionError();
            } else {
                swal({
                    title: gettextCatalog.getString('SportSpecificTestTypeName'),
                    type: 'info',
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    confirmButtonText: gettextCatalog.getString('Ok'),
                    cancelButtonText: gettextCatalog.getString('Cancel')
                }).then(function (inputValue) {
                    if (inputValue.dismiss === 'cancel' || inputValue.dismiss === 'esc') {
                        return;
                    }
                    if (!inputValue || !inputValue.value || inputValue.value.length < 1 || inputValue.value.length > 50) {
                        log.errorMsg(gettextCatalog.getString('SportSpecificTestTypeNameMustBeAtLeast2CharactersLong'));
                    } else {
                        vm.dataFelmeroElemFej.push({
                            FelmeroNeve: inputValue.value,
                            Aktiv: true,
                            SzakosztalyId: vm.sectionValue,
                            Id_FEF: vm.newFefItemIndex--
                        });
                        GridFelmeroTipus2.dataSource = vm.dataFelmeroElemFej;
                        SaveFelmeroTipusok();
                    }
                });
            }
        }

        function DeleteFelmeroTipus() {
            if (!vm.UJSPFELM) {
                log.permissionError();
            } else {
                swal({
                    title: gettextCatalog.getString('Are you sure you want to delete the selected test type?'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('Yes, delete this test type'),
                    cancelButtonText: gettextCatalog.getString('Cancel'),
                }).then(function (isConfirm) {
                    if (isConfirm.value) {
                        dataservice.deleteFelmeroElemFej(vm.selectedFelmeroElemFej).then(function () {
                            vm.selectedFelmeroElemFej = undefined;
                            LoadFelmeroTipusok(vm.sectionValue);
                        });
                    }
                });
            }
        }

        function CopyFelmeroTipus() {
            if (!vm.UJSPFELM) {
                log.permissionError();
            } else {
                var HTMLtext = '<div style="margin-top: 10px;" ><label class="col-lg-6" style="text-align: left; margin-top:5px;">' + gettextCatalog.getString('Section') +
                    '</label><input class="col-lg-6 col-lg-offset-6" id="sectionDropDownSwal" ej-dropdownlist></div>' +
                    '<div style="margin-top: 10px;"><label class="col-lg-6" style="text-align: left; margin-top:5px;">' + gettextCatalog.getString('FelmeroNeve') +
                    '</label><input id="copyfelmeroneve" name="copyfelmeroneve"></div>';
                swal({
                    title: gettextCatalog.getString('CreateCopyOfSportSpecific'),
                    html: HTMLtext,
                    type: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('Ok'),
                    cancelButtonText: gettextCatalog.getString('Cancel'),
                }).then(function (isConfirm) {
                    if (isConfirm.value) {
                        var copyName = document.getElementById('copyfelmeroneve').value;
                        if (copyName.length > 0 && vm.athleteDataSwalSzakosztaly > 0) {
                            if (copyName.length <= 1 || copyName.length > 50) {
                                log.errorMsg(gettextCatalog.getString('SportSpecificTestTypeNameMustBeAtLeast2CharactersLong'));
                                return;
                            }
                            dataservice.FelmeroTipusMasolas(vm.selectedFelmeroElemFej, vm.athleteDataSwalSzakosztaly, copyName).then(function () {
                                vm.sectionValue = vm.athleteDataSwalSzakosztaly;
                            });
                        } else {
                            log.errorMsg('FillTheRequiredFieldsSectionAndTestName');
                        }
                    }
                }).then(function () {
                });
                angular.element('#sectionDropDownSwal').ejDropDownList({
                    dataSource: vm.sectionList,
                    select: selectSectionDropDownSwal,
                    value: vm.athleteDataSwalSzakosztaly,
                    databound: sectionSwalDatabound,
                    width: '100%'
                });
            }
        }

        function SaveFelmeroTipusok() {
            if (!vm.UJSPFELM) {
                log.permissionError();
            } else {
                dataservice.saveFelmeroElemFej(vm.dataFelmeroElemFej).then(function () {
                    LoadFelmeroTipusok(vm.sectionValue);
                    vm.selectedFelmeroElemFej = undefined;
                });
            }
        }

        function LoadFelmeroTipusok(section) {
            dataservice.getFelmeroElemFejList(section).then(function (arg) {
                vm.dataFelmeroElemFej = arg.itemsList;
                GridFelmeroTipus2.dataSource = vm.dataFelmeroElemFej;
                if (vm.FelmeroElemFej > 0) {
                    vm.FelmeroElemFej = -1;
                }
            });
        }

        function SelectFelmeroTipus(args) {
            console.log('SelectFelmeroTipus', args);
            log.infoMsg(gettextCatalog.getString('DetailsAreShownInTheGridBelow'));
            vm.selectedFelmeroIsActive = args.data.Aktiv;
            vm.selectedFEFRowIndex = args.rowIndex;
            vm.FEEditEnable = !args.data.Used;
            if (args.data.Used) {
                resetColumnsFelmeroElem(false);
                vm.editableHint = gettextCatalog.getString('ThisTestTypeIsReadOnlyBecouseOneOrMoreTestsAreBasedOnThisTestType');
                GridFelmeroElem2.toolbar = [];
                if (vm.UJSPFELM) {
                    GridFelmeroElem2.toolbar = ['edit', 'update', 'cancel'];
                }
            } else {
                resetColumnsFelmeroElem(true);
                vm.editableHint = '';
                GridFelmeroElem2.toolbar = ['add', 'edit', 'update', 'cancel', 'delete'];
            }
            vm.felmNev = args.data.FelmeroNeve;
            vm.selectedFelmeroElemFej = args.data.Id_FEF;
            vm.felmeroTipusKijelolve = true;
            vm.copyButtonEnabled = true;
            vm.deleteButtonEnabled = vm.FEEditEnable;
            LoadFelmeroElemek(vm.selectedFelmeroElemFej);
        }

        function ActivateFelmeroTipus() {
            vm.dataFelmeroElemFej[vm.selectedFEFRowIndex].Aktiv = true;
            GridFelmeroTipus2.dataSource = vm.dataFelmeroElemFej;
            SaveFelmeroTipusok();
            vm.selectedFEFRowIndex = -1;
            vm.felmeroTipusKijelolve = false;
            vm.copyButtonEnabled = false;
            vm.deleteButtonEnabled = false;
            vm.felmeroTipusKijelolve = false;
        }

        function InactivateFelmeroTipus() {
            vm.dataFelmeroElemFej[vm.selectedFEFRowIndex].Aktiv = false;
            GridFelmeroTipus2.dataSource = vm.dataFelmeroElemFej;
            SaveFelmeroTipusok();
            vm.selectedFEFRowIndex = -1;
            vm.felmeroTipusKijelolve = false;
            vm.copyButtonEnabled = false;
            vm.deleteButtonEnabled = false;
            vm.felmeroTipusKijelolve = false;
        }

        function DiscardFelmeroElem() {
            vm.colordivenabled = false;
            vm.copyButtonEnabled = true;
            enableFelmeroTipusGrid(true);
            LoadFelmeroElemek(vm.selectedFelmeroElemFej);
        }

        function SelectFelmeroElem(args) {
            console.log('SelectFelmeroElem 1', args);
            enableFelmeroTipusGrid(false);
            vm.felmeroTipusKijelolve = false;
            vm.saveBeforeColorWarning = false;
            vm.felmElemNev = args.data.Nev;
            if (!(args.data.Id_FE) || args.data.Id_FE < 0) {
                vm.colordivenabled = false;
                $scope.$apply();
                vm.saveBeforeColorWarning = true;
                return;
            }
            vm.selectedFelmeroElem = args.data.Id_FE;
            vm.selectedFelmeroElemIndex = getFelmeroElemIndex(args.data.Id_FE);

            switch (args.data.Tipus) {
                case 1:
                    vm.int = true;
                    vm.double = false;
                    vm.mmss = false;
                    vm.hhmmss = false;
                    break;
                case 2:
                    vm.int = false;
                    vm.double = true;
                    vm.mmss = false;
                    vm.hhmmss = false;
                    break;
                case 6:
                    vm.int = false;
                    vm.double = true;
                    vm.mmss = false;
                    vm.hhmmss = false;
                    break;
                case 5: // MMSS
                    vm.int = false;
                    vm.double = false;
                    vm.mmss = true;
                    vm.hhmmss = false;
                    break;
                case 4: // HHMMSS
                    vm.int = false;
                    vm.double = false;
                    vm.mmss = false;
                    vm.hhmmss = true;
                    break;
            }
            if (args.data.Tipus == 1 || args.data.Tipus == 2 || args.data.Tipus == 6) {
                if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num1) {
                    vm.num1 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num1;
                } else {
                    vm.num1 = vm.defnum1;
                }
                if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num2) {
                    vm.num2 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num2;
                } else {
                    vm.num2 = vm.defnum2;
                }
                if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num3) {
                    vm.num3 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num3;
                } else {
                    vm.num3 = vm.defnum3;
                }
                if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num4) {
                    vm.num4 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num4;
                } else {
                    vm.num4 = vm.defnum4;
                }
                if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num5) {
                    vm.num5 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num5;
                } else {
                    vm.num5 = vm.defnum5;
                }
            } else if (args.data.Tipus == 4) {
                if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num1) {
                    vm.num1 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num1;
                } else {
                    vm.num1 = vm.defnum1hhmmss;
                }
                if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num2) {
                    vm.num2 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num2;
                } else {
                    vm.num2 = vm.defnum2hhmmss;
                }
                if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num3) {
                    vm.num3 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num3;
                } else {
                    vm.num3 = vm.defnum3hhmmss;
                }
                if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num4) {
                    vm.num4 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num4;
                } else {
                    vm.num4 = vm.defnum4hhmmss;
                }
                if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num5) {
                    vm.num5 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num5;
                } else {
                    vm.num5 = vm.defnum5hhmmss;
                }
            } else if (args.data.Tipus == 5) {
                if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num1) {
                    vm.num1 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num1;
                } else {
                    vm.num1 = vm.defnum1mmss;
                }
                if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num2) {
                    vm.num2 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num2;
                } else {
                    vm.num2 = vm.defnum2mmss;
                }
                if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num3) {
                    vm.num3 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num3;
                } else {
                    vm.num3 = vm.defnum3mmss;
                }
                if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num4) {
                    vm.num4 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num4;
                } else {
                    vm.num4 = vm.defnum4mmss;
                }
                if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num5) {
                    vm.num5 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num5;
                } else {
                    vm.num5 = vm.defnum5mmss;
                }
            }
            if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color1) {
                vm.color1 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color1;
            } else {
                vm.color1 = '#ffffff';
            }
            if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color12) {
                vm.color12 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color12;
            } else {
                vm.color12 = '#ffffff';
            }
            if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color23) {
                vm.color23 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color23;
            } else {
                vm.color23 = '#ffffff';
            }
            if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color34) {
                vm.color34 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color34;
            } else {
                vm.color34 = '#ffffff';
            }
            if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color45) {
                vm.color45 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color45;
            } else {
                vm.color45 = '#ffffff';
            }
            if (vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color5) {
                vm.color5 = vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color5;
            } else {
                vm.color5 = '#ffffff';
            }
            vm.colordivenabled = (args.data.Tipus != 3);
            $scope.$apply();
        }

        function SaveFelmeroElemek() {
            GridFelmeroElem2.endEdit();
            GridFelmeroElem2.closeEdit();
            if (!vm.UJSPFELM) {
                log.permissionError();
            } else {
                vm.saveBeforeColorWarning = false;
                vm.colordivenabled = false;
                vm.dataFelmeroElem = GridFelmeroElem2.dataSource;
                if (vm.dataFelmeroElem.length == 0) {
                    log.errorMsg(gettextCatalog.getString('TestMustNotBeEmpty'));
                    return;
                }
                for (var i = 0; i < vm.dataFelmeroElem.length; i++) {
                    vm.dataFelmeroElem[i].FelmeroElemFejId = vm.selectedFelmeroElemFej;
                    switch (vm.dataFelmeroElem[i].TipusStr) {
                        case gettextCatalog.getString('NumberInteger'):
                            vm.dataFelmeroElem[i].Tipus = 1;
                            break;
                        case gettextCatalog.getString('NumberFraction'):
                            vm.dataFelmeroElem[i].Tipus = 2;
                            break;
                        case gettextCatalog.getString('Text'):
                            vm.dataFelmeroElem[i].Tipus = 3;
                            break;
                        case gettextCatalog.getString('TimeHourMinuteSecHundredthOfASec'):
                            vm.dataFelmeroElem[i].Tipus = 4;
                            break;
                        case gettextCatalog.getString('TimeMinuteSecHundredthOfASec'):
                            vm.dataFelmeroElem[i].Tipus = 5;
                            break;
                        case gettextCatalog.getString('TimeSecHundredthOfASec'):
                            vm.dataFelmeroElem[i].Tipus = 6;
                            break;
                        default:
                            log.errorMsg(gettextCatalog.getString('TypeIsIncorrect') + ' ' + gettextCatalog.getString('AtLine') + ': ' + vm.dataFelmeroElem[i].Nev);
                            return;
                    }
                    if (vm.dataFelmeroElem[i].Sorrend == undefined || vm.dataFelmeroElem[i].Sorrend == null) {
                        log.errorMsg(gettextCatalog.getString('OrderMustNotBeEmpty') + ' ' + gettextCatalog.getString('AtLine') + ': ' + vm.dataFelmeroElem[i].Nev);
                        return;
                    }
                    if (!(vm.dataFelmeroElem[i].Sorrend >= 0)) {
                        log.errorMsg(gettextCatalog.getString('OrderMustBeInteger') + ' ' + gettextCatalog.getString('AtLine') + ': ' + vm.dataFelmeroElem[i].Nev);
                        return;
                    }
                    if (!vm.dataFelmeroElem[i].Nev || (vm.dataFelmeroElem[i].Nev && (vm.dataFelmeroElem[i].Nev.length < 2 || vm.dataFelmeroElem[i].Nev.length > 50))) {
                        log.errorMsg(gettextCatalog.getString('TestNameMustBeBetween2and50Characters') + ' ' + gettextCatalog.getString('AtLine') + ': ' + vm.dataFelmeroElem[i].Nev);
                        return;
                    }
                    if (vm.dataFelmeroElem[i].ElvartEredmeny && vm.dataFelmeroElem[i].ElvartEredmeny.length > 50) {
                        log.errorMsg(gettextCatalog.getString('ExpectedResultMustBeAtMost50Characters') + ' ' + gettextCatalog.getString('AtLine') + ': ' + vm.dataFelmeroElem[i].Nev);
                        return;
                    }
                    for (var j = 0; j < vm.dataFelmeroElem.length; j++) {
                        if (i != j && vm.dataFelmeroElem[i].Sorrend == vm.dataFelmeroElem[j].Sorrend) {
                            log.errorMsg(gettextCatalog.getString('DuplicatesInOrder') + ' ' + gettextCatalog.getString('AtLine') + ': ' + vm.dataFelmeroElem[i].Nev + ', ' + vm.dataFelmeroElem[j].Nev);
                            return;
                        }
                        if (i != j && vm.dataFelmeroElem[i].Nev == vm.dataFelmeroElem[j].Nev) {
                            log.errorMsg(gettextCatalog.getString('DuplicatesNames') + ' ' + gettextCatalog.getString('AtLine') + ': ' + vm.dataFelmeroElem[i].Nev);
                            return;
                        }
                    }
                }
                console.log('vm.dataFelmeroElem', vm.dataFelmeroElem);
                dataservice.saveFelmeroElem(vm.dataFelmeroElem).then(function () {
                    vm.felmeroTipusKijelolve = false;
                    enableFelmeroTipusGrid(true);
                    LoadFelmeroElemek(vm.selectedFelmeroElemFej);
                    log.successMsg('SaveCompleted');
                });
            }
        }

        function LoadFelmeroElemek(FEF_ID) {
            if (!FEF_ID) {
                return;
            }
            dataservice.getFelmeroElemList(FEF_ID).then(function (arg) {
                vm.dataFelmeroElem = arg.itemsList;
                for (var i = 0; i < vm.dataFelmeroElem.length; i++) {
                    switch (vm.dataFelmeroElem[i].Tipus) {
                        case 1:
                            vm.dataFelmeroElem[i].TipusStr = gettextCatalog.getString('NumberInteger');
                            break;
                        case 2:
                            vm.dataFelmeroElem[i].TipusStr = gettextCatalog.getString('NumberFraction');
                            break;
                        case 3:
                            vm.dataFelmeroElem[i].TipusStr = gettextCatalog.getString('Text');
                            break;
                        case 4:
                            vm.dataFelmeroElem[i].TipusStr = gettextCatalog.getString('TimeHourMinuteSecHundredthOfASec');
                            break;
                        case 5:
                            vm.dataFelmeroElem[i].TipusStr = gettextCatalog.getString('TimeMinuteSecHundredthOfASec');
                            break;
                        case 6:
                            vm.dataFelmeroElem[i].TipusStr = gettextCatalog.getString('TimeSecHundredthOfASec');
                            break;
                        default:
                            vm.dataFelmeroElem[i].TipusStr = 'x';
                    }
                }
                GridFelmeroElem2.dataSource = vm.dataFelmeroElem;
                if (!vm.UJSPFELM) {
                    GridFelmeroElem2.editSettings = {
                        allowEditing: false, allowAdding: false, allowDeleting: false
                    };
                    GridFelmeroElem2.selectionSettings = {type: 'none', mode: 'none'};
                }
                GridFelmeroElem2.refresh();
            });
        }

        function getMaxSorrend() {
            var maxSorrend = -1;
            for (var i = 0; i < vm.dataFelmeroElem.length; i++) {
                if (vm.dataFelmeroElem[i].Sorrend > maxSorrend) {
                    maxSorrend = vm.dataFelmeroElem[i].Sorrend;
                }
            }
            return maxSorrend;
        }

        function enableFelmeroTipusGrid(arg) {
            angular.element('#section').ejDropDownList({
                enabled: arg
            });
            var activateButton = angular.element('#activateFelmeroElemFej').data('ejButton');
            var inactivateButton = angular.element('#inactivateFelmeroElemFej').data('ejButton');
            if (arg) {
                GridFelmeroTipus2.selectRow(vm.selectedFEFRowIndex);
                GridFelmeroTipus2.selectionSettings = {type: 'single', mode: 'row'};
                if (activateButton) {
                    activateButton.enable();
                }
                if (inactivateButton) {
                    inactivateButton.enable();
                }
            } else {
                GridFelmeroTipus2.selectionSettings = {type: 'none', mode: 'none'};
                if (activateButton && !vm.selectedFelmeroIsActive && vm.felmeroTipusKijelolve) {
                    activateButton.disable();
                }
                if (inactivateButton && vm.selectedFelmeroIsActive && vm.felmeroTipusKijelolve) {
                    inactivateButton.disable();
                }
            }
            vm.copyButtonEnabled = arg && vm.felmeroTipusKijelolve;
            //angular.element('#copyFelmeroElemFej').ejButton({enabled: arg && vm.felmeroTipusKijelolve});
            vm.deleteButtonEnabled = arg && vm.FEEditEnable;
            //angular.element('#deleteFelmeroElemFej').ejButton({enabled: arg && vm.FEEditEnable});
            angular.element('#saveFelmeroElem').ejButton({enabled: !arg});
            angular.element('#discardFelmeroElem').ejButton({enabled: !arg});
            //vm.colordivenabled = !arg;
        }

        function getSections() {
            dataservice.sectionDropDownList(0, true).then(function (data) {
                vm.sectionList = data.itemsList;
            });
        }

        function sectionSelect(arg) {
            vm.felmeroTipusKijelolve = false;
            vm.selectedFelmeroElemFej = undefined;
            LoadFelmeroTipusok(arg.value);
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#section').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                vm.selectedSection = vm.sectionList[0].value;
            } else {
                angular.element('#section').ejDropDownList('selectItemByValue', vm.selectedSection);
            }
        }

        function sectionSwalDatabound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDownSwal').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDownSwal').ejDropDownList('selectItemByValue', vm.athleteDataSwalSzakosztaly);
            }
        }

        function selectSectionDropDownSwal(arg) {
            vm.athleteDataSwalSzakosztaly = arg.value;
            vm.coachListSwal = [];
            angular.element('#secondSectionDropDownSwalDiv').addClass('ng-hide');
        }

        function applyColor() {
            $timeout(function () {
                if (vm.selectedFelmeroElemIndex >= 0) {
                    vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num1 = vm.num1;
                    vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num2 = vm.num2;
                    vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num3 = vm.num3;
                    vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num4 = vm.num4;
                    vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].num5 = vm.num5;
                    vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color1 = vm.color1;
                    vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color12 = vm.color12;
                    vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color23 = vm.color23;
                    vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color34 = vm.color34;
                    vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color45 = vm.color45;
                    vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color5 = vm.color5;
                } else {
                    swal({
                        title: gettextCatalog.getString('PleaseSelectFelmeroElem'),
                        type: 'info',
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: gettextCatalog.getString('Ok')
                    });
                }
            }, 100);
        }

        function setDefaultColors() {
            vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color1 = vm.defcolor1;
            vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color12 = vm.defcolor12;
            vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color23 = vm.defcolor23;
            vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color34 = vm.defcolor34;
            vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color45 = vm.defcolor45;
            vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color5 = vm.defcolor5;
            vm.color1 = vm.defcolor1;
            vm.color12 = vm.defcolor12;
            vm.color23 = vm.defcolor23;
            vm.color34 = vm.defcolor34;
            vm.color45 = vm.defcolor45;
            vm.color5 = vm.defcolor5;
            $scope.$apply();
        }

        function clearColors() {
            vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color1 = '#ffffff';
            vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color12 = '#ffffff';
            vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color23 = '#ffffff';
            vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color34 = '#ffffff';
            vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color45 = '#ffffff';
            vm.dataFelmeroElem[vm.selectedFelmeroElemIndex].color5 = '#ffffff';
            vm.color1 = '#ffffff';
            vm.color12 = '#ffffff';
            vm.color23 = '#ffffff';
            vm.color34 = '#ffffff';
            vm.color45 = '#ffffff';
            vm.color5 = '#ffffff';
            $scope.$apply();
        }

        function getFelmeroElemIndex(felmeroElemId) {
            for (var i = 0; i < vm.dataFelmeroElem.length; i++) {
                if (vm.dataFelmeroElem[i].Id_FE == felmeroElemId) {
                    return i;
                }
            }
            return -1;
        }

        function getFelmeroElemFejIndex(felmeroElemFejId) {
            for (var i = 0; i < vm.dataFelmeroElemFej.length; i++) {
                if (vm.dataFelmeroElemFej[i].Id_FEF == felmeroElemFejId) {
                    return i;
                }
            }
            return -1;
        }
    }
})();
