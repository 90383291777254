(function () {
    'use strict';

    angular
        .module('app.business.csvPayment')
        .controller('CsvPaymentController', CsvPaymentController);

    CsvPaymentController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'paramHelper', 'authenticationService', '$filter', '$scope', 'DOMAIN', '$timeout', 'logger'];

    /* @ngInject */
    function CsvPaymentController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $filter, $scope, DOMAIN, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('CsvPayment');

        vm.data = [];
        vm.alldata = [];
        vm.acceptedRows = [];
        vm.header = [
            {row: 'elso'},
            {row: 'masodik'}
        ];
        vm.header2 = [];
        vm.gridToolbar = ['printGrid', 'excelExport'];
        vm.querycellinfo = querycellinfo;
        vm.FilterFailedTransactions = FilterFailedTransactions;
        vm.toolbarHandler = toolbarHandler;
        vm.noHeader = noHeader;
        vm.noDepositor = noDepositor;
        vm.headerSelected = headerSelected;
        vm.columnSelected = columnSelected;
        vm.StartAgain = StartAgain;
        vm.Continue = Continue;
        vm.SaveSettings = SaveSettings;
        vm.modeSelect = modeSelect;
        vm.cellSelected = cellSelected;
        vm.save = save;
        vm.cancel = activate;
        vm.printEnabled = false;
        vm.saveEnabled = false;
        vm.firstLineIsHeader = true;
        vm.red = '#eecdcd';
        vm.yellow = '#eeeecd';
        vm.green = '#cdeecd';
        vm.settings = {};
        var grid = null;
        var WGrid2 = null;
        var WGrid3 = null;
        var validSelections = [];
        vm.processModes = [
            {modeName: gettextCatalog.getString('CSV_JustOneLine'), mode: 'JUSTONELINE'},
            {modeName: gettextCatalog.getString('CSV_Merge'), mode: 'MERGE'}
        ];
        vm.wcolumns = [
            {field: 'id', isPrimaryKey: true, visible: false},
            {field: 'enc', visible: false}
        ];

        activate();

        function activate() {
            validSelections = [];
            vm.settings.CsvPresetLoaded = false;
            vm.step = 0;
            vm.selModeArr = ['cell'];
            vm.columns = [
                {field: 'row', isPrimaryKey: true, visible: false},
                {
                    field: 'date',
                    headerText: gettextCatalog.getString('Date'),
                    textAlign: 'left',
                    width: 40
                },
                {
                    field: 'name',
                    headerText: gettextCatalog.getString('Name'),
                    textAlign: 'left',
                    width: 120
                },
                {
                    field: 'comment',
                    headerText: gettextCatalog.getString('PaymentComment'),
                    textAlign: 'left',
                    width: 120
                },
                {
                    field: 'amount',
                    headerText: gettextCatalog.getString('amount'),
                    textAlign: 'left',
                    width: 40
                },
                {
                    field: 'payments',
                    headerText: gettextCatalog.getString('payments'),
                    textAlign: 'left',
                    width: 90
                },
                {
                    field: 'depositor',
                    headerText: gettextCatalog.getString('depositor'),
                    textAlign: 'left',
                    width: 70
                },
                {
                    field: 'accept',
                    type: 'checkbox',
                    //type: 'boolean',
                    headerText: gettextCatalog.getString('Accept'),
                    textAlign: 'left',
                    width: 40,
                    displayAsCheckBox: true,
                    allowEditing: false,
                    visible: true
                }
            ];
            vm.csvAll = [];
            vm.csv = [];
            var promises = [authenticationService.getRight('CSV'), getSystemSettingsValue('CSVSETTINGS')];
            $q.all(promises).then(function (results) {
                if (!results[0]) {
                    log.permissionError(true);
                } else {
                    vm.step = 1;
                    $('#step1').show();
                    console.log('results', results);
                    delete vm.settings.filename;
                    delete vm.settings.mode;
                    delete vm.settings.AmountColumn;
                    delete vm.settings.CommentColumn;
                    delete vm.settings.DateColumn;
                    delete vm.settings.DepositorColumn;
                    delete vm.settings.headerRow;
                    delete vm.settings.encRow;
                    if (results[1]) {
                        var savedSettings = JSON.parse(results[1]);
                        console.log('activate() vm.settings', vm.settings);
                        if (savedSettings && savedSettings.hasOwnProperty('AmountColumn')) {
                            console.log('savedSettings', savedSettings);
                            vm.settings.AmountColumn = savedSettings.AmountColumn;
                            vm.settings.CommentColumn = savedSettings.CommentColumn;
                            vm.settings.DateColumn = savedSettings.DateColumn;
                            vm.settings.DepositorColumn = savedSettings.DepositorColumn;
                            vm.settings.headerRow = savedSettings.headerRow;
                            vm.settings.CsvPresetLoaded = true;
                        }
                    }
                    vm.data = [];
                    vm.alldata = [];
                    vm.acceptedRows = [];
                    vm.printEnabled = false;
                    vm.saveEnabled = false;
                    vm.firstLineIsHeader = true;
                    angular.element('#csvuploadform').show();
                    createUploadButton();
                }
            });
        }

        function CreateGridFinal() {
            var node = document.getElementById('GridFinal');
            while (node && node.hasChildNodes()) {
                node.removeChild(node.lastChild);
            }
            grid = new ej2.grids.Grid({
                dataSource: vm.data,
                columns: vm.columns,
                queryCellInfo: querycellinfo,
                toolbar: $rootScope.toolbarItems,
                allowPaging: false,
                allowSorting: false,
                allowTextWrap: false,
                allowExcelExport: false,
                editSettings: {
                    allowEditing: false, allowAdding: false, allowDeleting: false
                },
                locale: 'hu-HU',
                gridLines: 'both',
                selectionSettings: {type: 'multiple', mode: 'row'},
                rowDataBound: function (args) {
                    $rootScope.compile(args.row, $scope);
                },
                rowSelected: function (arg) {
                    RowSelect(arg.rowIndex, true);
                },
                rowDeselected: function (arg) {
                    if (arg.rowIndex.length == 1) {
                        RowSelect(arg.rowIndex[0], false);
                    } else {
                        RowSelect(-1, false);
                    }
                }
            });
            grid.appendTo('#GridFinal');
            grid.toolbarClick = function (args) {
                $rootScope.toolbarClick(args, grid);
            };
        }

        function RowSelect(idx, select) {
            //console.log('RowSelect', idx, select);
            if (select && idx >= 0) {
                //console.log('RowSelect  vm.data[' + idx + ']', vm.data[idx]);
                if (vm.data[idx].amountParse == -1) {
                    grid.clearSelection();
                    grid.selectRows(validSelections);
                } else if (validSelections.indexOf(idx) == -1) {
                    validSelections.push(idx);
                }
            } else { // Deselect
                var IdxOfDeselected = validSelections.indexOf(idx);
                if (IdxOfDeselected != -1) {
                    //console.log('Deselect idx', idx, ' IdxOfDeselected', IdxOfDeselected);
                    validSelections.splice(IdxOfDeselected, 1);
                    //console.log('Deselect validSelections', validSelections);
                }
            }
        }

        function modeSelect(arg) {
            console.log('modeSelect', arg);
            vm.settings.mode = arg.data.mode;
            CSVSettings(vm.settings);
            vm.step = 10;
        }

        function SetCsvGrid(data) {
            console.log('SetCsvGrid() data', data);
            if (data.warning.length > 0) {
                log.errorMsg(data.warning, gettextCatalog.getString('Warning'));
            }
            vm.settings.filename = data.filename;
            vm.csvAll = [];
            vm.csv = [];
            for (var c = 0; c <= data.numberOfColumns; c++) {
                if (data.columnWidth[c] == 0) {
                    vm.wcolumns.push({field: 'c' + c, headerText: '', visible: false});
                } else {
                    vm.wcolumns.push({field: 'c' + c, headerText: '', textAlign: 'center', width: 120});
                }
            }
            vm.csvAll = data.itemsList;
            vm.csv = data.itemsList;

            var node = document.getElementById('WGrid2');
            while (node && node.hasChildNodes()) {
                node.removeChild(node.lastChild);
            }
            WGrid2 = new ej2.grids.Grid({
                dataSource: vm.csv,
                columns: vm.wcolumns,
                toolbar: $rootScope.toolbarItems,
                allowPaging: false,
                allowSorting: false,
                allowTextWrap: false,
                allowExcelExport: false,
                editSettings: {
                    allowEditing: false, allowAdding: false, allowDeleting: false
                },
                locale: 'hu-HU',
                gridLines: 'both',
                selectionSettings: {type: 'single', mode: 'row'},
                rowSelected: function (arg) {
                    console.log('WGrid2 rowSelected', arg);
                    headerSelected(arg);
                }
            });
            WGrid2.appendTo('#WGrid2');
            $scope.$apply();
        }

        function cellSelected(arg) {
            console.log('cellSelected', arg);
            if (vm.step >= 4) {
                columnChoosen(arg.cellIndex.cellIndex - 2);
            }
            if (vm.step == 3) {
                console.log('STEP3 - ENCODING', arg.selectedRowCellIndex[0].rowIndex);
                dataservice.CSVPreprocessSelectHeader(vm.settings.filename, vm.settings.headerRow, arg.data.enc).then(function (response) {
                    console.log('CSVPreprocessSelectHeader again', response);
                    setHeadersAndWidth(response.headerTexts, response.columnWidth);
                    vm.csv = response.itemsList;
                    $timeout(function () {
                        WGrid3.dataSource = vm.csv;
                        WGrid3.clearSelection();
                        WGrid3.refresh();
                        vm.step++;
                    }, 500);
                });
            }
        }

        function columnSelected(arg) {
            console.log('columnSelected', arg.columnIndex - 2);
            if (vm.step >= 4) {
                columnChoosen(arg.columnIndex - 2);
            }
        }

        function noDepositor() {
            columnChoosen(-1);
        }

        function columnChoosen(columnIndex) {
            console.log('step=' + vm.step, '  columnSelected', columnIndex);
            $timeout(function () {
                switch (vm.step) {
                    case 4:
                        vm.settings.CommentColumn = columnIndex;
                        vm.step++;
                        break;
                    case 5:
                        vm.settings.DateColumn = columnIndex;
                        vm.step++;
                        break;
                    case 6:
                        vm.settings.DepositorColumn = columnIndex;
                        vm.step++;
                        break;
                    case 7:
                        vm.settings.AmountColumn = columnIndex;
                        vm.step++;
                        break;
                }
                WGrid3.clearSelection();
            }, 500);
        }

        function CSVSettings(settings) {
            console.log('CSVSettings', settings);
            dataservice.CSVSettings(settings).then(function (response) {
                console.log('CSVSettings response', response);
                vm.data = response.data.data;
                vm.data = $filter('dateStringFilter')(vm.data, 'date');
                CreateGridFinal();
            });
        }

        function noHeader() {
            headerSelected({rowIndex: -1});
            vm.settings.headerRow = -1;
        }

        function headerSelected(header) {
            console.log('headerSelected', header);
            vm.step = 3;
            vm.settings.headerRow = header.rowIndex;
            dataservice.CSVPreprocessSelectHeader(vm.settings.filename, vm.settings.headerRow, 'HEADERSELECT').then(function (response) {
                console.log('CSVPreprocessSelectHeader', response);
                if (header.rowIndex >= 0) {
                    setHeadersAndWidth(vm.csvAll[header.rowIndex], response.columnWidth);
                }
                $timeout(function () {
                    vm.csv = [];
                    var rowCntr = 0;
                    response.itemsList.forEach(function (row) {
                        if (rowCntr++ >= header.rowIndex) {
                            vm.csv.push(row);
                        }
                    });

                    var node = document.getElementById('WGrid3');
                    while (node && node.hasChildNodes()) {
                        node.removeChild(node.lastChild);
                    }
                    WGrid3 = new ej2.grids.Grid({
                        dataSource: vm.csv,
                        columns: vm.wcolumns,
                        toolbar: $rootScope.toolbarItems,
                        allowPaging: false,
                        allowSorting: false,
                        allowTextWrap: false,
                        allowExcelExport: false,
                        editSettings: {
                            allowEditing: false, allowAdding: false, allowDeleting: false
                        },
                        locale: 'hu-HU',
                        gridLines: 'both',
                        selectionSettings: {type: 'single', mode: 'cell'},
                        cellSelected: function (arg) {
                            cellSelected(arg);
                        }
                    });
                    WGrid3.appendTo('#WGrid3');

                    $scope.$apply();
                }, 50);
            });
        }

        function setHeadersAndWidth(headerRow, columnWidth) {
            console.log('SET HEADERS', headerRow, columnWidth);
            var colCntr = 0;
            vm.wcolumns.forEach(function (column) {
                if (colCntr++ > 1) {
                    column.headerText = headerRow['c' + (colCntr - 3)];
                    column.width = columnWidth[colCntr - 3] * 10.5;
                }
            });
        }

        function getSystemSettingsValue(valtozo) {
            return dataservice.getSystemSettingsValue(valtozo).then(function (response) {
                return response.retValue;
            });
        }

        function createUploadButton() {
            angular.element('#UploadButton').ejUploadbox({
                saveUrl: DOMAIN.baseURL + DOMAIN.webService + 'CSVPreprocessSelectHeader',
                uploadName: 'Uploadbox',
                allowDragAndDrop: false,
                autoUpload: true,
                buttonText: {
                    browse: gettextCatalog.getString('ChooseFile'),
                    upload: gettextCatalog.getString('UploadFile'),
                    cancel: gettextCatalog.getString('CancelUpload')
                },
                dialogAction: {
                    modal: true,
                    closeOnComplete: true,
                    resize: false,
                    drag: false
                },
                dialogText: {
                    title: gettextCatalog.getString('UploadingAndProcessingCSV'),
                    name: gettextCatalog.getString('FileName'),
                    size: gettextCatalog.getString('FileSize'),
                    status: gettextCatalog.getString('FileStatus')
                },
                dropAreaText: gettextCatalog.getString('DropFileToUpload'),
                extensionsAllow: '.csv',
                complete: function (args) {
                    vm.printEnabled = false;
                    vm.saveEnabled = true;
                    vm.data = [];
                    var idx = args.responseText.indexOf('ExceptionMessage":');
                    if (idx > 0) {
                        idx += 19;
                        var last = args.responseText.indexOf(',', idx) - 1;
                        log.htmlError(args.responseText.substring(idx, last));
                    } else {
                        $('#step1').hide();
                        console.log('vm.settings...', vm.settings);
                        if (vm.settings.CsvPresetLoaded) {
                            vm.step = 8;
                        } else {
                            vm.step = 2;
                        }
                        log.successMsg(gettextCatalog.getString('UploadSuccessed'));
                        var response = JSON.parse(args.responseText);
                        SetCsvGrid(response);
                    }
                },
                error: function () {
                    log.htmlError(gettextCatalog.getString('UploadError'));
                },
                beforeSend: function () {
                },
                begin: function (args) {
                    args.data = {file: '', headerRow: 0};
                }
            });
        }

        function FilterFailedTransactions() {
            vm.alldata = vm.data;
            vm.data = [];
            $timeout(function () {
                vm.alldata.forEach(function (row) {
                    if (!row.accept) {
                        vm.data.push(row);
                    }
                });
                grid.dataSource = vm.data;
                grid.selectionSettings = {type: 'none', mode: 'none'};
                vm.columns[7].visible = false;
                grid.refreshColumns();
                grid.refreshHeader();
                $scope.$apply();
            }, 100);
        }

        function save() {
            var selectedRecords = grid.getSelectedRecords();
            console.log('save selectedRecords', selectedRecords);
            selectedRecords.forEach(function (r) {
                vm.data[r.row].accept = true;
                //console.log('save r', r, vm.data[r.row]);
            });
            console.log('save vm.data', vm.data);
            vm.saveEnabled = false;
            angular.element('#csvuploadform').show();
            angular.element('#Grid').ejGrid('batchSave');
            angular.element('#Grid').ejGrid('endEdit');
            var param = {};
            param.NemElfogadottLista = [];
            param.ElfogadottLista = [];
            vm.data.forEach(function (row) {
                if (row.accept) {
                    param.ElfogadottLista.push(row);
                } else {
                    param.NemElfogadottLista.push(row);
                }
            });
            vm.step++;
            swal({
                title: gettextCatalog.getString('MailWillBeSentToYouContainingUnprocessedAndProcessedCSVLines'),
                type: 'question',
                showCancelButton: false,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Ok')
            }).then(function () {
                AcceptCSVPayment(param);
            }, function () {
                AcceptCSVPayment(param);
            });
        }

        function AcceptCSVPayment(param) {
            param.filename = vm.settings.filename;
            console.log('AcceptCSVPayment', param);
            dataservice.AcceptCSVPayment(param).then(function () {
                console.log('AcceptCSVPayment OK');
                vm.printEnabled = true;
                FilterFailedTransactions();
            }, function (arg) {
                vm.printEnabled = false;
                vm.saveEnabled = true;
                vm.step--;
                console.log('AcceptCSVPayment ERR', arg);
            });
        }

        function SaveSettings() {
            var fileNameTemp = vm.settings.filename;
            delete vm.settings.filename;
            delete vm.settings.mode;
            delete vm.settings.CsvPresetLoaded;
            var SettingsJSON = JSON.stringify(vm.settings);
            console.log('saveSystemSettingsValue', vm.settings, SettingsJSON);
            dataservice.saveSystemSettingsValue('CSVSETTINGS', SettingsJSON).then(function () {
                log.successMsg(gettextCatalog.getString('CSVSettingsSavedSuccessed'));
            });
            vm.settings.filename = fileNameTemp;
        }

        function Continue() {
            vm.step++;
        }

        function StartAgain() {
            dataservice.saveSystemSettingsValue('CSVSETTINGS', '{}').then(function () {
                log.successMsg(gettextCatalog.getString('CSVSettingsSavedSuccessed'));
                vm.settings.CsvPresetLoaded = false;
                delete vm.settings.mode;
                delete vm.settings.AmountColumn;
                delete vm.settings.CommentColumn;
                delete vm.settings.DateColumn;
                delete vm.settings.DepositorColumn;
                delete vm.settings.headerRow;
                activate();
            });
        }

        function querycellinfo(arg) {
            var cell = angular.element(arg.cell);
            switch (arg.column.field) {
                case 'date':
                case 'comment':
                case 'name':
                    switch (arg.data.sportoloParse) {
                        case -2:
                        case -1:
                            cell.css('background-color', vm.red);
                            break;
                        case 0:
                            cell.css('background-color', vm.yellow);
                            break;
                        case 1:
                            cell.css('background-color', vm.green);
                            break;
                    }
                    break;
                case 'depositor':
                case 'amount':
                case 'payments':
                    switch (arg.data.amountParse) {
                        case -1:
                            cell.css('background-color', vm.red);
                            break;
                        case 0:
                            cell.css('background-color', vm.yellow);
                            break;
                        case 1:
                            cell.css('background-color', vm.green);
                            break;
                    }
                    break;
                case 'accept':
                    if (arg.data.sportoloParse == 1 && arg.data.amountParse == 1) {
                        cell.css('background-color', vm.green);
                    }
                    if (arg.data.sportoloParse == 0 || arg.data.amountParse == 0) {
                        cell.css('background-color', vm.yellow);
                    }
                    if (arg.data.sportoloParse == -1 || arg.data.amountParse == -1) {
                        cell.css('background-color', vm.red);
                    }
                    break;
            }
        }

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                var grid = angular.element('#Grid').ejGrid('instance');
                grid.ignoreOnExport.splice(grid.ignoreOnExport.indexOf('dataSource'), 1);
                this.export(dataservice.getURL() + 'CSVExcelExport/ExcelExport/');
            }
        }
    }
})();
