(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.health.healthList')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'athletes.athletesManagement.athleteModify.health.healthList',
                config: {
                    url: '/healthList',
                    templateUrl: 'app/athletes/athletesManagement/athleteModify/health/healthList/healthList.html',
                    controller: 'HealthListController',
                    controllerAs: 'vm',
                    title: 'HealthList'
                }
            }
        ];
    }
})();
