(function () {
    'use strict';

    angular
        .module('app.business.annualEducationalFeeByAthletes')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'business.annualEducationalFeeByAthletes',
                config: {
                    url: '/annualEducationalFeeByAthletes',
                    templateUrl: 'app/business/annualEducationalFeeByAthletes/annualEducationalFeeByAthletes.html',
                    controller: 'AnnualEducationalFeeByAthletesController',
                    controllerAs: 'vm',
                    title: 'AnnualEducationalFeeByAthletes',
                    settings: {
                        navId: 2163,
                        level: 2,
                        order: 16,
                        orderTitle_hu: 'Oktatási díj összesítés sportolónként',
                        orderTitle_en: 'Annual Educational Fee by Coach',
                        parentId: 3,
                        content: 'AnnualEducationalFeeByAthletes',
                        permission: ['7'],
                        activatorPermission: '"OKTOSSZSP"'
                    }
                }
            }
        ];
    }
})();
