(function () {
    'use strict';

    angular
        .module('app.schedule')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'eventsCalendar',
                config: {
                    url: '/schedule',
                    templateUrl: 'app/schedule/schedule.html',
                    controller: 'ScheduleController',
                    controllerAs: 'vm',
                    title: 'EventsCalendar',
                    settings: {
                        navId: 6,
                        level: 1,
                        order: 6,
                        parentId: 0,
                        content: 'EventsCalendar',
                        permission: ['0', '1', '2', '3', '4', '5', '6'],
                        activatorPermission: '"MEGTESEMNAPT"'
                    }
                }
            }
        ];
    }
})();
