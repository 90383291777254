(function () {
    'use strict';

    angular
        .module('app.business.dailySportServiceFeePayments')
        .controller('DailySportServiceFeePaymentsController', DailySportServiceFeePaymentsController);

    DailySportServiceFeePaymentsController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'paramHelper', 'authenticationService', '$scope', '$compile', '$state', '$timeout'];

    /* @ngInject */
    function DailySportServiceFeePaymentsController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $scope, $compile, $state) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('DailySportServiceFeePayments');

        var printOpened = false;
        // Create variables
        // Dropdownlist's sources
        vm.sectionList = [];
        vm.coachList = [];
        vm.numberList = [];
        vm.dateValue = new Date();
        vm.atletesList = [];
        vm.selectedCoach = vm.coachId = 0;
        vm.selectedSection = 0;
        vm.showAddNumber = false;
        vm.reportParameters = {};
        vm.dateEnabled = false;

        // Grid's variables
        vm.osszesFizetett = function () {
            var gridObj = angular.element('#Grid').data('ejGrid');
            return ej.sum(gridObj.getCurrentViewData(), 'osszesFizetett');
        };

        vm.rowIndexUnderEditing = -1;
        vm.columns = [
            {field: 'Id', isPrimaryKey: true, visible: false},
            {field: 'AdottNapiSorszamok', headerText: gettextCatalog.getString('Number'), width: 70},
            {
                headerText: gettextCatalog.getString('Name'), textAlign: 'left', width: 150,
                template: '#dailysportservicefeepaymentscolumnTemplate1'
            },
            {
                headerText: gettextCatalog.getString('CoachName'), textAlign: 'left', width: 150,
                template: '#dailysportservicefeepaymentscolumnTemplate2'
            },
            {field: 'AdottNapiAtveteliElismervenyek', headerText: gettextCatalog.getString('AtveteliElismervenyek'), width: 150},
            {field: 'AdottNapiTemaszamok', headerText: gettextCatalog.getString('Temaszam'), textAlign: 'left', width: 100},
            {field: 'AdottNapiKoltseghelyek', headerText: gettextCatalog.getString('Koltseghelyek'), textAlign: 'left', width: 150},
            {field: 'AdottNapiBefizetesHonapok', headerText: gettextCatalog.getString('BefizetesekHonap'), textAlign: 'left', width: 100},
            {field: 'AdottNapiBefizdatumok', headerText: gettextCatalog.getString('Date'), textAlign: 'left', width: 100},
            {field: 'osszesFizetett', headerText: gettextCatalog.getString('Fizetett'), textAlign: 'right', width: 100, format: 'N0'}
        ];

        // Public functions
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.getAthletes = getAthletes;
        vm.numberDataBound = numberDataBound;
        vm.numberSelect = numberSelect;
        vm.addNumbers = addNumbers;
        vm.print = print;
        vm.compile = compile;
        vm.goToAthlete = goToAthlete;
        vm.openCoach = openCoach;

        vm.summaryRows = [{
            columns: [
                {
                    type: 'sum',
                    field: 'osszesFizetett',
                    footerTemplate: '${sum}',
                    format: 'N0'
                }
            ]
        }];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            allowDeleting: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            aggregates: vm.summaryRows,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Activate page
        activate();

        function activate() {
            var promises = [paramHelper.getParams([], ['dailySportServiceFeePayments.sectionId', 'dailySportServiceFeePayments.number', 'dailySportServiceFeePayments.coachId', 'dailySportServiceFeePayments.date']), authenticationService.getRight('MEGTNAPITAGD')];
            $q.all(promises).then(function (results) {
                if (results[1] !== true) {
                    log.permissionError(true);
                } else {
                    vm.defaultsectionId = results[0]['dailySportServiceFeePayments.sectionId'];
                    // angular.element('#number').ejDropDownList('selectItemByValue', results[0]['dailySportServiceFeePayments.number']);
                    vm.defaultNumber = results[0]['dailySportServiceFeePayments.number'] !== null ? results[0]['dailySportServiceFeePayments.number'] : 0;
                    vm.defaultCoachId = results[0]['dailySportServiceFeePayments.coachId'] !== null ? results[0]['dailySportServiceFeePayments.coachId'] : 0;
                    getcoaches();
                }
            });
        }

        /**
         * Betölti az edzőket
         * @author Richard Keki
         * @returns {promise} A http promisszal tér vissza
         */
        function getcoaches() {
            return dataservice.coachSelect(vm.sectionId).then(function (data) {
                data.itemsList.unshift({value: '0', text: gettextCatalog.getString('All')});
                vm.coachList = data.itemsList;
            });
        }

        /**
         * Edzők betöltése után beállítja az alapértelmezett edzőt
         * @author Richard Keki
         */
        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                vm.coachId = vm.coachList[0].value;
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.defaultCoachId);
                vm.coachId = vm.defaultCoachId;
            }
        }

        function coachSelect(args) {
            vm.coachId = args.value;
            getSections();
        }

        //szakosztályok lekérése
        function getSections() {
            dataservice.sectionDropDownList(vm.coachId).then(function (data) {
                data.itemsList.unshift({value: '0', text: gettextCatalog.getString('All')});
                vm.sectionList = data.itemsList;
            });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                vm.sectionId = vm.sectionList[0].value;
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.defaultsectionId);
                vm.sectionId = vm.defaultsectionId;
            }
        }

        // Run after selection
        function sectionSelect(args) {
            vm.sectionId = args.value;
            getServiceFeePaymentsNumbers(args.value);
        }

        function getServiceFeePaymentsNumbers(sectionId) {
            return dataservice.getServiceFeePaymentsNumbers(sectionId, vm.dateValue).then(function (data) {
                vm.numberList = data.itemsList;
                vm.numberList.unshift({value: '0', text: gettextCatalog.getString('All')});
            });
        }

        // Run after data bounded
        function numberDataBound() {
            if (vm.numberList.length === 1) {
                angular.element('#number').ejDropDownList('selectItemByValue', vm.numberList[0].value);
                vm.selectedNumber = vm.numberList[0].value;
            } else {
                angular.element('#number').ejDropDownList('selectItemByValue', vm.defaultNumber);
                vm.selectedNumber = vm.defaultNumber;
            }
        }

        function numberSelect(args) {
            vm.selectedNumber = args.value;
            vm.dateEnabled = !(args.value !== 0 && args.value !== '0');
        }

        function getAthletes() {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            paramHelper.setParams([{'dailySportServiceFeePayments.sectionId': vm.sectionId}, {'dailySportServiceFeePayments.number': vm.selectedNumber}, {'dailySportServiceFeePayments.coachId': vm.coachId}]);
            dataservice.getAthletesForDailyPayments(vm.sectionId, vm.coachId, vm.dateValue, vm.selectedNumber).then(function (data) {
                vm.atletesList = data.itemsList;
                grid.dataSource = vm.atletesList;
                grid.refresh();
                vm.showAddNumber = vm.selectedNumber === '-1';
            });
        }

        function addNumbers() {
            return dataservice.updateTagdijfizetesek(vm.atletesList, true).then(function () {
                log.successMsg('Saved');
                getServiceFeePaymentsNumbers(vm.sectionId, true);
                getAthletes();
                return true;
            });
        }

        function print() {
            vm.reportParameters = {
                'edzoId': vm.coachId,
                'szakosztalyId': vm.sectionId,
                'datum': vm.dateValue,
                'sorszam': vm.selectedNumber,
                'cimszoveg': gettextCatalog.getString('DailySportServiceFeePayments'),
                'fejlec': '',
                'edzokapja': false,
                'sportszolg': true
            };
            $scope.$apply();
            if (printOpened) {
                angular.element('#printDialog').ejDialog('open');
            } else {
                printOpened = true;
                angular.element('#printDialog').ejDialog({height: '99%', width: '99%', enablemodal: 'true', 'position-x': '0', 'position-y': '1', target: '#mainContent', enableresize: 'false'});
            }
        }

        // Compile links in grid
        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }
    }
})();
