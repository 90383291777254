(function () {
    'use strict';

    angular
        .module('app.administration.monthlyAttendance.monthlyAttendanceList')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.monthlyAttendance.monthlyAttendanceList',
                config: {
                    url: '/monthlyAttendanceList',
                    templateUrl: 'app/administration/monthlyAttendance/monthlyAttendanceList/monthlyAttendanceList.html',
                    controller: 'MonthlyAttendanceListController',
                    controllerAs: 'vm',
                    title: 'MonthlyAttendanceList',
                    settings: {
                        navId: 274,
                        level: 2,
                        order: 7,
                        orderTitle_hu: 'Korábbi havi jelenléti összesítők',
                        orderTitle_en: 'Previous Monthly Attendance Sheet Report',
                        parentId: 4,
                        content: 'MonthlyAttendanceList',
                        activatorPermission: '"MEGTHAVIJEL"'
                    }
                }
            }
        ];
    }
})();
