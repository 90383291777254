(function () {
    'use strict';

    angular
        .module('app.settings.createSportSpecificTest')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'settings.createSportSpecificTest',
                config: {
                    url: '/createSportSpecificTest',
                    templateUrl: 'app/settings/createSportSpecificTest/createSportSpecificTest.html',
                    controller: 'CreateSportSpecificTestController',
                    controllerAs: 'vm',
                    title: 'CreateSportSpecificTest',
                    settings: {
                        navId: 275,
                        level: 2,
                        order: 7,
                        orderTitle_hu: 'Sportágspecifikus felmérőtípusok',
                        orderTitle_en: 'Sport Specific Test',
                        parentId: 5,
                        content: 'CreateSportSpecificTest',
                        activatorPermission: '"MEGTSPFELM"'
                    }
                }
            }
        ];
    }
})();
