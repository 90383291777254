(function () {
    'use strict';

    angular
        .module('app.reports.statisticalReports.activeAthletesBySection')
        .controller('ActiveAthletesBySectionController', ActiveAthletesBySectionController);

    ActiveAthletesBySectionController.$inject = ['log', 'dataservice', '$rootScope', 'gettextCatalog', 'authenticationService', '$scope', '$compile', '$state', 'paramHelper'];

    /* @ngInject */
    function ActiveAthletesBySectionController(log, dataservice, $rootScope, gettextCatalog, authenticationService, $scope, $compile, $state, paramHelper) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('ActiveAthletesBySection');
        vm.athleteList = [];
        vm.queryDate = new Date();
        vm.dateChange = dateChange;
        vm.openSection = openSection;

        vm.columns = [{
            field: 'SzakosztalyNeve',
            headerText: gettextCatalog.getString('Section')
        }, {
            field: 'Szam',
            headerText: gettextCatalog.getString('NumberOfAthletes'), template: '#acatbysetemplateDataColumn1'
        }, {
            field: 'SzamFiu',
            headerText: gettextCatalog.getString('NumberOfBoys'), template: '#acatbysetemplateDataColumn2'
        }, {
            field: 'SzamLany',
            headerText: gettextCatalog.getString('NumberOfGirls'), template: '#acatbysetemplateDataColumn3'
        }];

        vm.sectionList = [];
        vm.athleteListFull = [];
        vm.showEachSections = false;
        vm.accessibleSections = [];
        vm.checkboxChanged = checkboxChanged;
        vm.checkboxEnabled = true;
        vm.checkboxchangedonce = false;
        vm.checked = false;
        vm.isAdmin = false;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function openSection(SectionId, gender) {
            new ej2.data.DataManager(grid.dataSource).executeQuery(new ej2.data.Query().where('SzakosztalyId', 'equal', SectionId)).then(function (data) {
                var SectionName = data.result[0].SzakosztalyNeve;
                var accessGranted = false;
                for (var i = 0; i < vm.accessibleSections.length; i++) {
                    if (vm.accessibleSections[i] === SectionId) {
                        accessGranted = true;
                        break;
                    }
                }
                if (accessGranted || vm.isAdmin) {
                    paramHelper.setParams([
                        {'state': 'activeAthletes'},
                        {'sectionId': SectionId},
                        {'sectionName': SectionName},
                        {'dateFrom': vm.queryDate},
                        {'dateTo': vm.queryDate},
                        {'gender': gender === 0 ? 'allgender' : (gender === 1 ? 'male' : 'female')}
                    ]);
                    $state.go('reports.statisticalReports.listAthletes');
                } else {
                    log.htmlWarning(gettextCatalog.getString('You do not have permission to access the selected section details!') + '<br><br>' + gettextCatalog.getString(SectionName));
                }
            });
        }

        function activate() {
            authenticationService.getRight('KMSPORTSZAK').then(function (results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    getNumbers(vm.queryDate);
                }
            });
        }

        function checkboxChanged(args) {
            vm.showEachSections = args.value;
            filterSections();
        }

        function filterSections() {
            vm.athleteList = [];
            if (!vm.showEachSections) {
                for (var i = 0; i < vm.athleteListFull.length; i++) {
                    for (var s = 0; s < vm.accessibleSections.length; s++) {
                        if (vm.athleteListFull[i].SzakosztalyId == vm.accessibleSections[s]) {
                            vm.athleteList.push(vm.athleteListFull[i]);
                        }
                    }
                }
            } else {
                vm.athleteList = vm.athleteListFull;
                grid.dataSource = vm.athleteListFull;
            }
        }

        function getNumbers(date) {
            dataservice.athletesBySection(date)
                .then(function (data) {
                    vm.athleteListFull = data.itemsList;
                    vm.accessibleSections = data.felhasznaloSzakosztalyai;
                    vm.isAdmin = data.isAdmin;
                    if (data.isAdmin == true) {
                        vm.checked = true;
                    }
                    filterSections();
                });
        }

        function dateChange(data) {
            getNumbers(data.value);
        }
    }
})();
