(function () {
    'use strict';

    angular
        .module('app.settings.userManagement.modifyUser')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'settings.userManagement.modifyUser',
                config: {
                    url: '/modifyUser',
                    templateUrl: 'app/settings/userManagement/modifyUser/modifyUser.html',
                    controller: 'modifyUserController',
                    controllerAs: 'vm',
                    title: 'ModifyUser'
                }
            }
        ];
    }
})();
