(function () {
    'use strict';

    angular
        .module('app.athletes.teams.teamMembersList')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'athletes.teams.teamMembersList',
                config: {
                    url: '/teamMembersList',
                    templateUrl: 'app/athletes/teams/teamMembersList/teamMembersList.html',
                    controller: 'TeamMembersListController',
                    controllerAs: 'vm',
                    title: 'TeamMembersList'
                }
            }
        ];
    }
})();
