(function () {
    'use strict';

    angular
        .module('app.messageCenter')
        .controller('MessageCenterController', MessageCenterController);

    MessageCenterController.$inject = ['$q', '$rootScope', 'gettextCatalog', 'dataservice', '$compile', '$scope', 'log', 'authenticationService', 'paramHelper', '$state', '$timeout'];
    /* @ngInject */
    function MessageCenterController($q, $rootScope, gettextCatalog, dataservice, $compile, $scope, log, authenticationService, paramHelper, $state, $timeout) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('MessageCenterRecievedMessages');
        //Grid nyomtatás, exportálás nyomógomb
        vm.gridToolbar = ['printGrid', 'excelExport'];
        vm.gridExcelExport = dataservice.getURL() + 'getFelhasznaloUzenetei/ExcelExport/';
        vm.toolbarHandler = toolbarHandler;
        vm.goToUser = goToUser;
        vm.compile = compile;
        vm.gridRowSelected = gridRowSelected;
        vm.SelectedMessage = {};
        vm.toolbarClick = toolbarClick;
        vm.loadMessageId = -1;

        initGrid();

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            allowDeleting: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsMessages,
            rowSelected: vm.gridRowSelected,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        vm.EditMessageText = gettextCatalog.getString('Directory');
        vm.Uzenet = gettextCatalog.getString('Message'); // cts-textarea-hoz kell, onnan nem veszi fel a fordítást...

        function activate() {
            vm.toolbarItems = [
                {
                    empid: 1,
                    tooltiptext: gettextCatalog.getString('msgSendNew'),
                    spriteCss: 'msgtools newmsg',
                    group: '1'
                },
                {
                    empid: 2,
                    tooltiptext: gettextCatalog.getString('msgReply'),
                    spriteCss: 'msgtools msgreply',
                    group: '2'
                },
                {
                    empid: 3,
                    tooltiptext: gettextCatalog.getString('msgForward'),
                    spriteCss: 'msgtools msgforward',
                    group: '2'
                },
                {
                    empid: 4,
                    tooltiptext: gettextCatalog.getString('Remove'),
                    spriteCss: 'msgtools msgdelete',
                    group: '2'
                },
                {empid: 5, tooltiptext: gettextCatalog.getString('Print'), spriteCss: 'msgtools msgprint', group: '3'},
                {
                    empid: 6,
                    tooltiptext: gettextCatalog.getString('Refresh'),
                    spriteCss: 'msgtools msgrefresh',
                    group: '3'
                }, {
                    empid: 7,
                    tooltiptext: gettextCatalog.getString('Sent'),
                    spriteCss: 'msgtools msgsent',
                    group: '3'
                }
            ];
            //Grid nyomtatás, exportálás nyomógomb
            vm.dataMessages = [];

            var selectThisRow = -1;
            var selectThisPage = 0;
            var pageSize = 5;
            var elem = {};
            $q.all([paramHelper.getParams([], ['messageId']), authenticationService.getRight('MEGTFELH'), authenticationService.getRight('UZENETMEGT'), authenticationService.getRight('UZENETTORLES')]).then(function (results) {
                vm.MEGTFELH = results[1];
                vm.UZENETMEGT = results[2];
                vm.UZENETTORLES = results[3];
                if (!vm.UZENETMEGT) {
                    log.permissionError(true);
                    return;
                }
                vm.loadMessageId = results[0].messageId;
                paramHelper.removeParam('messageId');
                initGrid();
                paramHelper.removeParam('messageId');
                dataservice.getMessages().then(function (data) {
                    vm.dataMessages = data.itemsList;
                    for (var i = 0; i < vm.dataMessages.length; i++) {
                        vm.dataMessages[i].index = i;
                        vm.dataMessages[i].clicked = 0;
                    }
                    grid.dataSource = vm.dataMessages;
                    $timeout(function () {
                        if (vm.loadMessageId != -1 || vm.loadMessageId !== null) {
                            elem = vm.dataMessages.filter(function (a) {
                                return a.Id === vm.loadMessageId;
                            });
                            if (vm.loadMessageId > 0) {
                                for (var i = 0; i < vm.dataMessages.length; i++) {
                                    if (elem[0].Id === vm.dataMessages[i].Id) {
                                        break;
                                    }
                                }
                                selectThisRow = i;
                                selectThisPage = parseInt((selectThisRow / pageSize));
                                selectThisRow = selectThisRow - selectThisPage * pageSize;
                                selectThisPage++;
                                if (selectThisRow !== -1) {
                                    vm.selectThisRow = selectThisRow;
                                    selectRow(selectThisRow);
                                }
                            }
                        }
                    }, 0);
                });
            });
        }

        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }

        function goToUser(edzoId) {
            if (!vm.MEGTFELH) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{'newUser.userId': edzoId}]);
            $state.go('settings.userManagement.modifyUser');
        }

        function initGrid() {
            vm.columnsMessages = [];
            vm.columnsMessages = [
                {field: 'Id', visible: false, isPrimaryKey: true, isIdentity: true},
                {
                    field: 'Olvasott',
                    headerText: '',
                    textAlign: 'center',
                    width: '40px',
                    allowEditing: false,
                    type: 'string',
                    template: '#mecetemplateDataColumn1'
                },
                {
                    field: 'FeladoNev',
                    headerText: gettextCatalog.getString('Sender'),
                    textAlign: 'center',
                    width: '150px',
                },
                {
                    field: 'Datum',
                    headerText: gettextCatalog.getString('Date'),
                    textAlign: 'center',
                    width: '200px',
                },
            ];
        }

        function gridRowSelected(params) {
            vm.SelectedMessage = params.data;
            vm.selectThisRow = params.rowIndex; // A gridben hanyadik elem
            var msgIndex = params.data.index; // hanyadik levele a usernek
            if (!params.data.Olvasott) {
                dataservice.messageReaded(params.data.Id).then(function () {
                    vm.SelectedMessage.Olvasott = true;
                    grid.selectedRowIndex = params.rowIndex;
                    grid.dataSource = vm.dataMessages;
                    grid.refresh();
                });
            } else {
                setTimeout(function () {
                    $scope.$apply();
                }, 10);
            }
        }

        function selectRow() {
            grid.selectRows(vm.selectThisRow);
        }

        function toolbarClick(params) {
            switch (params.currentTarget.id) {
                case '1':
                    paramHelper.setParams([{
                        'newMessage.Message': '',
                        'newMessage.OriginalSenderId': 0,
                        'newMessage.OriginalSenderName': ''
                    }
                    ]);
                    $state.go('messageCenter.newMessage');
                    break;
                case '2':
                    if (!vm.SelectedMessage.Id) {
                        log.errorMsg('Válasszon ki egy üzenetet!');
                        return;
                    }
                    paramHelper.setParams([{
                        'newMessage.Message': '\r\n\r\n--- ' + gettextCatalog.getString('ReplyThis') + ' ---\r\n' + vm.SelectedMessage.Uzenet,
                        'newMessage.OriginalSenderId': vm.SelectedMessage.FeladoId,
                        'newMessage.OriginalSenderName': vm.SelectedMessage.FeladoNev,
                    }]);
                    $state.go('messageCenter.newMessage');
                    break;
                case '3':
                    if (!vm.SelectedMessage.Id) {
                        log.errorMsg('Válasszon ki egy üzenetet!');
                        return;
                    }
                    paramHelper.setParams([{
                        'newMessage.Message': '\r\n\r\n--- ' + gettextCatalog.getString('OriginalMessage') + ' ---\r\n' +
                         gettextCatalog.getString('Sender') + ': ' + vm.SelectedMessage.FeladoNev + '\r\n' +
                         gettextCatalog.getString('Addressee') + ': ' + vm.SelectedMessage.CimzettNev + '\r\n' +
                         gettextCatalog.getString('Time') + ': ' + vm.SelectedMessage.Datum + '\r\n\r\n\r\n' +
                         vm.SelectedMessage.Uzenet,
                        'newMessage.OriginalSenderId': 0,
                        'newMessage.OriginalSenderName': '',
                    }]);
                    $state.go('messageCenter.newMessage');
                    break;
                case '4':
                    if (!vm.SelectedMessage.Id) {
                        log.errorMsg('Válasszon ki egy üzenetet!');
                        return;
                    }
                    swal({title: gettextCatalog.getString('DoYouWantDelete'),
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: gettextCatalog.getString('YesIWantDelete'),
                        cancelButtonText: gettextCatalog.getString('Cancel'),
                    }).then(function(isConfirm) {
                        if (!vm.UZENETTORLES) {
                            log.errorMsg('Nincs joga az üzenetet törléséhez!');
                            return;
                        }
                        if (isConfirm.value) {
                            dataservice.messageDelete(vm.SelectedMessage.Id).then(function () {
                                log.infoMsg('MessageMarkAsDeleted');
                                refreshList();
                                $timeout(function() {
                                    $scope.$apply();
                                }, 10);
                                vm.SelectedMessage = {};
                            });
                        }
                    });
                    break;
                case '5':
                    if (!vm.SelectedMessage.Id) {
                        log.errorMsg('Válasszon ki egy üzenetet!');
                        return;
                    }
                    var newWindow = window.open();
                    var x = document.getElementById('printDiv').innerHTML;
                    newWindow.document.write(x);
                    newWindow.print();
                    newWindow.close();
                    // angular.element("#printDiv").addClass("printable");
                    // window.print();
                    break;
                case '6':
                    vm.SelectedMessage.Id = null;
                    vm.SelectedMessage.Uzenet = '';
                    vm.SelectedMessage.FeladoNev = '';
                    vm.SelectedMessage.Datum = '';
                    refreshList();
                    break;
                case '7':
                    $state.go('messageCenter.sentMessages');
            }
        }

        function refreshList() {
            dataservice.getMessages().then(function (data) {
                vm.dataMessages = [];
                vm.dataMessages = data.itemsList;
                grid.dataSource = vm.dataMessages;
            });
            initGrid();
        }

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                var grid = angular.element('#Grid').ejGrid('instance');
                grid.ignoreOnExport.splice(grid.ignoreOnExport.indexOf('dataSource'), 1);
                this.export(dataservice.getURL() + 'getFelhasznaloUzenetei/ExcelExport/');
            }
        }
    }
})();
