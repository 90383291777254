(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.health.newHealthEvent')
        .controller('newHealthEventController', NewHealthEventController);

    NewHealthEventController.$inject = ['log', 'logger', '$state', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'paramHelper', 'authenticationService', '$timeout', '$scope'];
    /* @ngInject */
    function NewHealthEventController(log, logger, $state, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $timeout, $scope) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('newHealthEvent');

        // Create variables
        vm.EGESZSMEGT = false;
        vm.EGESZSMOD = false;
        vm.documents = '';
        vm.params = {
            SportoloId: null,
            Mettol: null,
            Meddig: null,
            Tipus: null,
            AlTipus: null,
            Leiras: null,
            Kezeles: null
        };

        vm.healthMainType = [];
        vm.healthSubType = [];
        vm.SubType = [];
        // Public functions
        vm.saveDatas = saveDatas;
        vm.fileUpload = fileUpload;
        vm.MainTypeSelected = MainTypeSelected;

        function MainTypeSelected(arg) {
            var mainId = 0;
            vm.SubType = [];
            for (var i = 0; i < vm.healthMainType.length; i++) {
                if (vm.healthMainType[i].text === arg.value) {
                    mainId = vm.healthMainType[i].value;
                }
            }
            for (var i = 0; i < vm.healthSubType.length; i++) {
                if (vm.healthSubType[i].value === mainId) {
                    vm.SubType.push(vm.healthSubType[i]);
                    vm.params.AlTipus = null;
                }
            }
        }

        // Activate page
        activate();

        function activate() {
            $q.all([paramHelper.getParams([], ['newHealthAthleteId', 'newHealthAthleteName', 'newHealthAthleteSocialSecurity', 'newHealthAthleteBirthDate']),
                authenticationService.getRight('EGESZSMEGT'),
                authenticationService.getRight('EGESZSMOD')])
                .then(function (results) {
                    $timeout(function () {
                        vm.params.SportoloId = results[0]['newHealthAthleteId'];
                        vm.athleteName = results[0]['newHealthAthleteName'];
                        vm.athleteSocialSecurity = results[0]['newHealthAthleteSocialSecurity'];
                        vm.athleteBirthDate = results[0]['newHealthAthleteBirthDate'];
                    }, 0);
                    vm.EGESZSMEGT = results[1];
                    vm.EGESZSMOD = results[2];

                    if (!vm.EGESZSMEGT) {
                        log.permissionError();
                        $rootScope.back();
                    }
                });
        }

        function fileUpload() {
            paramHelper.setParams([{'files.tipus': 'HealthEvent'}, {'files.tablaId': vm.params.SportoloId}]);
            $state.go('other.files');
        }

        getHealthMainTypes();
        function getHealthMainTypes() {
            dataservice.healthMainTypesList()
                .then(function (response) {
                    for (var i = 0; i < response.itemsList.length; i++) {
                        vm.healthMainType.push({
                            text: response.itemsList[i].Nev,
                            value: response.itemsList[i].Id
                        });
                    }
                });
        }

        getHealthSubTypes();
        function getHealthSubTypes() {
            dataservice.healthSubTypesList()
                .then(function (response) {
                    for (var i = 0; i < response.itemsList.length; i++) {
                        vm.healthSubType.push({
                            text: response.itemsList[i].AltipusNev,
                            value: response.itemsList[i].FotipusId,
                        });
                    }
                });
        }

        function saveDatas() {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
            } else {
                console.log('vm.params', vm.params);
                if (vm.params.Meddig) {
                    var from = new Date(vm.params.Mettol);
                    var to = new Date(vm.params.Meddig);
                    if (from > to) {
                        log.errorMsg(gettextCatalog.getString('DateToMustNotBeBeforeDateFrom'));
                    } else {
                        dataservice.saveHealthData(vm.params)
                            .then(function () {
                                $rootScope.back();
                            });
                    }
                } else {
                    dataservice.saveHealthData(vm.params)
                        .then(function () {
                            $rootScope.back();
                        });
                }
            }
        }
    }
})();
