(function() {
    'use strict';

    angular
        .module('app.reports.statisticalReports.activeAthletesByMonth')
        .controller('ActiveAthletesByMonthController', ActiveAthletesByMonthController);

    ActiveAthletesByMonthController.$inject = ['log', 'dataservice', '$rootScope', 'gettextCatalog', 'authenticationService', '$scope', '$compile', '$state', 'paramHelper', '$filter'];
    /* @ngInject */
    function ActiveAthletesByMonthController(log, dataservice, $rootScope, gettextCatalog, authenticationService, $scope, $compile, $state, paramHelper, $filter) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('ActiveAthletesByMonth');
        vm.sectionList = [];
        vm.athleteList = [];
        vm.gridToolbar = ['printGrid', 'excelExport'];

        initColumns();

        vm.gridExcelExport = dataservice.getURL() + 'SportolokSzamanakAlakulasa/ExcelExport/';
        vm.toolbarHandler = toolbarHandler;
        vm.DateFrom = new Date();
        vm.DateFrom.setMonth(vm.DateFrom.getMonth() - 1);
        vm.DateTo = new Date();
        vm.compile = compile;
        vm.dateFromChanged = dateFromChanged;
        vm.dateToChanged = dateToChanged;
        vm.openSection = openSection;
        vm.openAthletes = openAthletes;
        vm.openJoined = openJoined;
        vm.openLeaving = openLeaving;

        vm.accessibleSections = [];
        vm.accessibleSectionsStr = [];
        vm.userId = -1;
        vm.isSectionLeader = false;
        vm.isAdmin = false;

        activate();

        function activate() {
            authenticationService.getRight('KMSPORTALAK').then(function(results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    getSectionList().then(function() {
                        getNumbers(vm.DateFrom, vm.DateTo);
                        initColumns();
                    });
                }
            });
        }

        function initColumns() {
            vm.columns = [
                {field: 'SzuletesiEv', headerText: gettextCatalog.getString('Month'), textAlign: 'left', width: 115, cssClass: 'rotate90degreegridheader4'},
                {field: 'Szam', headerText: gettextCatalog.getString('NumberOfAthletes'), textAlign: 'left', width: 40, cssClass: 'rotate90degreegridheader4', template: true, templateID: '#aabm_allgender'},
                {field: 'SzamFiu', headerText: gettextCatalog.getString('NumberOfBoys'), textAlign: 'left', width: 40, cssClass: 'rotate90degreegridheader4', template: true, templateID: '#aabm_male'},
                {field: 'SzamLany', headerText: gettextCatalog.getString('NumberOfGirls'), textAlign: 'left', width: 40, cssClass: 'rotate90degreegridheader4', template: true, templateID: '#aabm_female'},
                {field: 'SzamErkezett', headerText: gettextCatalog.getString('Belepett'), textAlign: 'left', width: 40, cssClass: 'rotate90degreegridheader4', template: true, templateID: '#aabm_joined'},
                {field: 'SzamKilepett', headerText: gettextCatalog.getString('Kilepett'), textAlign: 'left', width: 40, cssClass: 'rotate90degreegridheader4', template: true, templateID: '#aabm_leaving'}
            ];
            for (var i = 0; i < vm.sectionList.length; i++) {
                vm.columns.push({field: 'c' + vm.sectionList[i].value, headerText: vm.sectionList[i].text, textAlign: 'left', width: 40, cssClass: 'rotate90degreegridheader4', template: true, templateID: '#aabm_ops' + vm.sectionList[i].value});
            }
        }

        function getNumbers(dateFrom, dateTo) {
            dateFrom = $filter('dateToISOFilter')(dateFrom);
            dateTo = $filter('dateToISOFilter')(dateTo);
            dataservice.activeAthletesByMonth(dateFrom, dateTo)
                .then(function(data) {
                    vm.athleteList = data.itemsList;
                    vm.accessibleSections = data.felhasznaloSzakosztalyai;
                    vm.accessibleSectionsStr = data.felhasznaloSzakosztalyaiStr;
                    vm.userId = data.userId;
                    vm.isAdmin = data.isAdmin;
                    vm.isSectionLeader = data.isSectionLeader;
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'SzuletesiIdo');
                });
        }

        function openAthletes (date, gender) {
            paramHelper.setParams([
                {'state': 'monthlyStat'},
                {'sectionId': 0},
                {'sectionName': gettextCatalog.getString('All sections')},
                {'dateFrom': date},
                {'dateTo': date},
                {'gender': gender}
            ]);
            $state.go('reports.statisticalReports.listAthletes');
        }

        function openLeaving (date) {
            authenticationService.getRight('SPSZAMALAKRESZL').then(function(results) {
                if (results !== true) {
                    log.permissionError();
                } else {
                    paramHelper.setParams([
                        {'state': 'monthlyStatLeaving'},
                        {'sectionId': 0},
                        {'dateFrom': date},
                        {'dateTo': date},
                        {'gender': 'allgender'}
                    ]);
                    $state.go('reports.statisticalReports.listAthletes');
                }
            });
        }

        function openJoined (date) {
            authenticationService.getRight('SPSZAMALAKRESZL').then(function(results) {
                if (results !== true) {
                    log.permissionError();
                } else {
                    paramHelper.setParams([
                        {'state': 'monthlyStatJoined'},
                        {'sectionId': 0},
                        {'dateFrom': date},
                        {'dateTo': date},
                        {'gender': 'allgender'}
                    ]);
                    $state.go('reports.statisticalReports.listAthletes');
                }
            });
        }

        function openSection(sectionID, date) {
            var sectionBelongsToUser = false;
            for (var i = 0; i < vm.accessibleSections.length; i++) {
                if (vm.accessibleSections[i] == sectionID) {
                    sectionBelongsToUser = true;
                    break;
                }
            }
            if (sectionBelongsToUser || vm.isAdmin) {
                paramHelper.setParams([
                    {'sectionID': sectionID},
                    {'date': (date)}
                ]);
                $state.go('reports.statisticalReports.activeAthletesInSectionByMonth');
            } else {
                log.warningMsg('You do not have permission to access the selected section details!');
            }
        }

        function dateFromChanged(data) {
            getNumbers(data.value, vm.DateTo);
        }

        function dateToChanged(data) {
            getNumbers(vm.DateFrom, data.value);
        }

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                var grid = angular.element('#Grid').ejGrid('instance');
                grid.ignoreOnExport.splice(grid.ignoreOnExport.indexOf('dataSource'), 1);
                this.export(dataservice.getURL() + 'SportolokSzamanakAlakulasa/ExcelExport/');
            }
        }

        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }

        function getSectionList() {
            return dataservice.sectionDropDownListAll()
                .then(function(data) {
                    vm.sectionList = data.itemsList;
                });
        }

        function ParseDate(strDate) { // "2016. június" --> Date
            var idxOfSpace = strDate.indexOf(' ');
            var strYear = strDate.slice(0, idxOfSpace - 1);
            var strMonth = strDate.slice(idxOfSpace + 1, idxOfSpace + 4);
            var year = parseInt(strYear);
            var month = 0;
            switch (strMonth){
                case 'jan' : month = 1; break;
                case 'feb' : month = 2; break;
                case 'már' : month = 3; break;
                case 'ápr' : month = 4; break;
                case 'máj' : month = 5; break;
                case 'jún' : month = 6; break;
                case 'júl' : month = 7; break;
                case 'aug' : month = 8; break;
                case 'sze' : month = 9; break;
                case 'okt' : month = 10; break;
                case 'nov' : month = 11; break;
                case 'dec' : month = 12; break;
            }
            return new Date(year, month - 1, 1, 3);
        }
    }
})();
