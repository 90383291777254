(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.athleteLog')
        .controller('AthleteLogController', AthleteLogController);

    AthleteLogController.$inject = ['dataservice', 'log', 'authenticationService', '$q', '$rootScope', 'gettextCatalog', 'paramHelper', '$filter', '$scope'];
    /* @ngInject */
    function AthleteLogController(dataservice, log, authenticationService, $q, $rootScope, gettextCatalog, paramHelper, $filter, $scope) {
        var vm = this; $rootScope.vm = vm;

        vm.sportoloId = null;
        vm.sportoloNev = null;
        vm.logAll = [];
        vm.dateList = [];

        vm.dateFrom = new Date();
        vm.dateFrom.setMonth(vm.dateFrom.getMonth() - 1);
        vm.dateTo = new Date();
        vm.dateFromChanged = dateFromChanged;
        vm.dateToChanged = dateToChanged;

        vm.columns = [
            {field: 'strtimestamp', headerText: gettextCatalog.getString('TimeStamp'), headerTextAlign: 'center', textAlign: 'left', width: '100'},
            {field: 'field', headerText: gettextCatalog.getString('Field'), headerTextAlign: 'center', width: '100'},
            {field: 'oldvalue', headerText: gettextCatalog.getString('OldValue'), textAlign: 'center', width: '150'},
            {field: 'newvalue', headerText: gettextCatalog.getString('NewValue'), textAlign: 'center', width: '150'}
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            var promises = [authenticationService.getRight('LOG')];
            $q.all(promises).then(function (res) {
                if (!res[0]) {
                    log.permissionError(true);
                } else {
                    $q.all([paramHelper.getParams(['athleteModify.sportoloId', 'athleteModify.sportoloNev'], [])]).then(function (results) {
                        vm.sportoloId = results[0]['athleteModify.sportoloId'];
                        vm.sportoloNev = results[0]['athleteModify.sportoloNev'];
                        $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('AthleteLog');
                        getLog(vm.sportoloId, vm.dateFrom, vm.dateTo);
                    });
                }
            })
            .catch(function () {
                log.permissionError(true);
            });
        }

        function getLog(sportoloid, from, to) {
            dataservice.getLog('Sportolok', sportoloid, from, to).then(function (response) {
                grid.dataSource = response.data.List;
                vm.logAll = response.data.List;
                vm.dateList = response.data.DatesStr;
                vm.dateList.unshift({value: '0', text: gettextCatalog.getString('All')});
                setTimeout(function() {
                    angular.element('#date').ejDropDownList('selectItemByValue', '0');
                    $scope.$apply();
                }, 10);
            });
        }

        function dateFromChanged(arg) {
            getLog(vm.sportoloId, arg.value, vm.dateTo);
        }

        function dateToChanged(arg) {
            getLog(vm.sportoloId, vm.dateFrom, arg.value);
        }
    }
})();
