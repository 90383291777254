(function() {
    'use strict';

    angular
        .module('app.reports.statisticalReports.activeAthletesInSectionByMonth')
        .controller('ActiveAthletesInSectionByMonthController', ActiveAthletesInSectionByMonthController);

    ActiveAthletesInSectionByMonthController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'authenticationService', '$scope', '$compile', '$state', 'paramHelper', '$filter', '$timeout'];
    /* @ngInject */
    function ActiveAthletesInSectionByMonthController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $compile, $state, paramHelper, $filter, $timeout) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('ActiveAthletesInSectionByMonth');
        vm.sectionList = [];
        vm.athleteList = [];
        vm.gridToolbar = ['printGrid', 'excelExport'];
        vm.columns = [{
            field: 'SzuletesiEv',
            headerText: gettextCatalog.getString('Month'),
            width: 60,
            textAlign: 'left'
        }, {
            field: 'Szam',
            headerText: gettextCatalog.getString('NumberOfAthletes'), template: true, templateID: '#acatinsebymotemplateDataColumn1',
            width: 60,
            textAlign: 'left'
        }, {
            field: 'SzamFiu',
            headerText: gettextCatalog.getString('NumberOfBoys'), template: true, templateID: '#acatinsebymotemplateDataColumn2',
            width: 60,
            textAlign: 'left'
        }, {
            field: 'SzamLany',
            headerText: gettextCatalog.getString('NumberOfGirls'), template: true, templateID: '#acatinsebymotemplateDataColumn3',
            width: 60,
            textAlign: 'left',
        }, {
            field: 'SzamErkezett',
            headerText: gettextCatalog.getString('Joined'),
            width: 60,
            textAlign: 'left'
        }, {
            field: 'SzamKilepett',
            headerText: gettextCatalog.getString('Leave'),
            width: 60,
            textAlign: 'left'
        }, {
            field: 'SzamAtjott',
            headerText: gettextCatalog.getString('MoveIn'),
            width: 60,
            textAlign: 'left'
        }, {
            field: 'SzamAtment',
            headerText: gettextCatalog.getString('MoveOut'),
            width: 60,
            textAlign: 'left'
        }];
        vm.gridExcelExport = dataservice.getURL() + 'SportolokSzamanakAlakulasaSzakosztalyonkent/ExcelExport/';
        vm.toolbarHandler = toolbarHandler;
        vm.dateFrom = new Date();
        vm.dateFrom.setMonth(vm.dateFrom.getMonth() - 1);
        vm.dateTo = new Date();
        vm.compile = compile;
        vm.dateFromChanged = dateFromChanged;
        vm.dateToChanged = dateToChanged;
        vm.sectionList = [];
        vm.sectionValue = '';
        vm.selectedSectionName = '';
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.selectedSectionID = '';
        vm.showOnlyOneMonth = false;
        vm.openAthletes = openAthletes;
        vm.openBoys = openBoys;
        vm.openGirls = openGirls;
        vm.date = '';
        vm.sectionId = '';

        activate();

        function getSections() {
            return dataservice.sectionDropDownList(0, true)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                vm.selectedSectionName = vm.sectionList[0].value;
                vm.sectionId = vm.sectionList[0].value;
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        function ParseDate(strDate) { // "2016. június" --> Date
            var idxOfSpace = strDate.indexOf(' ');
            var strYear = strDate.slice(0, idxOfSpace - 1);
            var strMonth = strDate.slice(idxOfSpace + 1, idxOfSpace + 4);
            var year = parseInt(strYear);
            var month = 0;
            switch (strMonth){
                case 'jan' : month = 1; break;
                case 'feb' : month = 2; break;
                case 'már' : month = 3; break;
                case 'ápr' : month = 4; break;
                case 'máj' : month = 5; break;
                case 'jún' : month = 6; break;
                case 'júl' : month = 7; break;
                case 'aug' : month = 8; break;
                case 'sze' : month = 9; break;
                case 'okt' : month = 10; break;
                case 'nov' : month = 11; break;
                case 'dec' : month = 12; break;
            }
            return new Date(year, month - 1, 1, 2);
        }

        function activate() {
            authenticationService.getRight('KMSPORTALAKSZO').then(function (results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([getSections(), paramHelper.getParams([], ['sectionID', 'date'])]).then(function (results) {
                        vm.params = results[1];
                        paramHelper.removeParam('sectionID');
                        paramHelper.removeParam('date');
                        if (vm.params.sectionID != null && vm.params.date != null) {
                            var date = vm.params.date.slice(0, 10);
                            var SectionItem = ej.DataManager(vm.sectionList).executeLocal(new ej.Query().where('value', 'equal', vm.params.sectionID))[0];
                            if (!SectionItem) {
                                $rootScope.back();
                            } else {
                                vm.selectedSectionName = SectionItem.text;
                                $rootScope.title = gettextCatalog.getString('ActiveAthletesInSectionByMonth') + ' (' + gettextCatalog.getString(vm.selectedSectionName) + ', ' + $filter('dateYearMonthLongStrFilter')(date) + ')';
                            }
                            $timeout(function () {
                                vm.dateFrom = date;
                                vm.dateTo = date;
                                vm.sectionValue = vm.params.sectionID;
                                if (SectionItem) {
                                    getNumbers(date, date, SectionItem.text, vm.params.sectionID);
                                }
                            }, 0);
                        }
                    });
                }
            });
        }

        function getNumbers(date1, date2, sectionName, sectionId) {
            if (!sectionName) {
                return;
            }
            dataservice.activeAthletesInSectionByMonth(date1, date2, sectionName, sectionId)
                .then(function(data) {
                    vm.athleteList = data.itemsList;
                });
        }

        function openAthletes(date) {
            paramHelper.setParams([
                {'state': 'monthlyStat'},
                {'sectionId': vm.selectedSectionID},
                {'sectionName': vm.selectedSectionName},
                {'dateFrom': ParseDate(date)},
                {'dateTo': ParseDate(date)},
                {'gender': 'allgender'}
            ]);
            $state.go('reports.statisticalReports.listAthletes');
        }

        function openBoys(date) {
            paramHelper.setParams([
                {'state': 'monthlyStat'},
                {'sectionId': vm.selectedSectionID},
                {'sectionName': vm.selectedSectionName},
                {'dateFrom': ParseDate(date)},
                {'dateTo': ParseDate(date)},
                {'gender': 'male'}
            ]);
            $state.go('reports.statisticalReports.listAthletes');
        }

        function openGirls(date) {
            paramHelper.setParams([
                {'state': 'monthlyStat'},
                {'sectionId': vm.selectedSectionID},
                {'sectionName': vm.selectedSectionName},
                {'dateFrom': ParseDate(date)},
                {'dateTo': ParseDate(date)},
                {'gender': 'female'}
            ]);
            $state.go('reports.statisticalReports.listAthletes');
        }

        function sectionSelect(args) {
            vm.selectedSectionName = args.text.toString();
            vm.selectedSectionID = args.value;
            vm.showOnlyOneMonth = false;
            getNumbers(vm.dateFrom, vm.dateTo, vm.selectedSectionName, vm.selectedSectionID);
        }

        function dateFromChanged(data) {
            if (data && data.value && vm.dateTo) {
                vm.showOnlyOneMonth = false;
                getNumbers(data.value, vm.dateTo, vm.selectedSectionName, vm.selectedSectionID);
            }
        }

        function dateToChanged(data) {
            if (data && data.value && vm.dateTo) {
                vm.showOnlyOneMonth = false;
                getNumbers(vm.dateFrom, data.value, vm.selectedSectionName, vm.selectedSectionID);
            }
        }

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                var grid = angular.element('#Grid').ejGrid('instance');
                grid.ignoreOnExport.splice(grid.ignoreOnExport.indexOf('dataSource'), 1);
                this.export(dataservice.getURL() + 'SportolokSzamanakAlakulasaSzakosztalyonkent/ExcelExport/');
            }
        }

        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }
    }
})();
