(function () {
    'use strict';

    angular
        .module('app.administration.exercises.exercisesList')
        .controller('ExercisesListController', ExercisesListController);

    ExercisesListController.$inject = ['log', 'paramHelper', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'DOMAIN', '$compile', '$scope', '$timeout', 'authenticationService', '$state', '$filter'];

    /* @ngInject */
    function ExercisesListController(log, paramHelper, dataservice, $q, $rootScope, gettextCatalog, DOMAIN, $compile, $scope, $timeout, authenticationService, $state, $filter) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('ExercisesList');

        vm.imageRoot = DOMAIN.baseURL + DOMAIN.exerciseBaseUrl;
        vm.youtubeIcon = DOMAIN.baseURL + DOMAIN.youtubeIcon;

        vm.SzakosztalyId = 0;
        vm.KorosztalyId = 0;
        vm.EdzoId = 0;
        vm.dataSource = [];
        vm.MODGYAKORLAT = false;
        vm.UJGYAKORLAT = false;

        // autoComplete
        vm.bodyPartValue = '';
        vm.TypeValue = '';
        vm.individualTeamValue = '';
        vm.AssetValue = '';
        vm.difficultyValue = '';
        vm.necessaryAreaValue = '';
        vm.withVideo = false;
        vm.withPattern = false;
        vm.withPaint = false;

        vm.columns = [
            {type: 'checkbox', headerText: '', textAlign: 'center', width: 55},
            {field: 'GyakorlatId', isPrimaryKey: true, visible: false},
            {field: 'Nev', headerText: gettextCatalog.getString('Name'), width: 150, textAlign: 'center', template: '#wtpropentemp'},
            {field: 'Mintakep', headerText: gettextCatalog.getString('Pattern'), width: 180, textAlign: 'center', template: '#imagetemplateGrid'},
            {field: 'Video', headerText: gettextCatalog.getString('Video'), width: 115, type: 'boolean', displayAsCheckBox: true, textAlign: 'center'},
            {field: 'Szakosztaly', headerText: gettextCatalog.getString('Section'), width: 150, textAlign: 'center'},
            {field: 'SzakosztalyId', visible: false},
            {field: 'Korosztaly', headerText: gettextCatalog.getString('AgeGroup'), width: 150, textAlign: 'center'},
            {field: 'KorosztalyId', visible: false},
            {field: 'Edzo', headerText: gettextCatalog.getString('Coach'), width: 150, textAlign: 'center'},
            {field: 'EdzoId', visible: false},
            {field: 'Testresz', headerText: gettextCatalog.getString('BodyParts'), width: 150, textAlign: 'center'},
            {field: 'Tipus', headerText: gettextCatalog.getString('Type'), width: 150, textAlign: 'center'},
            {field: 'Jellege', headerText: gettextCatalog.getString('individualTeam'), width: 150, textAlign: 'center'},
            {field: 'Felszerelesek', headerText: gettextCatalog.getString('Assets'), width: 150, textAlign: 'center'},
            {field: 'Publikus', headerText: gettextCatalog.getString('Public'), width: 150, type: 'boolean', displayAsCheckBox: true, textAlign: 'center'},
            {field: 'Kifesto', headerText: gettextCatalog.getString('Paint'), width: 150, textAlign: 'center'},
            {field: 'JatekosokSzama', headerText: gettextCatalog.getString('Players'), width: 150, textAlign: 'center'},
            {field: 'Nehezseg', headerText: gettextCatalog.getString('Difficulty'), width: 150, textAlign: 'center'},
            {field: 'SzuksegesTerulet', headerText: gettextCatalog.getString('Space'), width: 150, textAlign: 'center'},
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            allowTextWrap: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.getDatas = getDatas;
        vm.compile = compile;
        vm.goToExercise = goToExercise;
        vm.deleteExercise = deleteExercise;
        vm.create = create;

        activate();

        function activate() {
            $q.all([getSections(), paramHelper.getParams([], ['exercisesList.SzakosztalyId', 'exercisesList.KorosztalyId', 'exercisesList.EdzoId']), authenticationService.getRight('MODGYAKORLAT'), authenticationService.getRight('UJGYAKORLAT')]).then(function (results) {
                if (results[1]['exercisesList.SzakosztalyId']) {
                    vm.SzakosztalyId = results[1]['exercisesList.SzakosztalyId'];
                    vm.SzakosztalyId = results[1]['exercisesList.SzakosztalyId'];
                }
                if (results[1]['exercisesList.KorosztalyId']) {
                    vm.KorosztalyId = results[1]['exercisesList.KorosztalyId'];
                    vm.KorosztalyId = results[1]['exercisesList.KorosztalyId'];
                }
                if (results[1]['exercisesList.EdzoId']) {
                    vm.EdzoId = results[1]['exercisesList.EdzoId'];
                    vm.EdzoId = results[1]['exercisesList.EdzoId'];
                }
                vm.MODGYAKORLAT = results[2];
                vm.UJGYAKORLAT = results[3];
                getAutoComplete();
            });
        }

        function getSections() {
            return dataservice.sectionDropDownList(0)
                .then(function (data) {
                    if (data.itemsList.length > 1) {
                        data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    }
                    vm.sectionList = data.itemsList;
                });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                $('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                $('#sectionDropDown').ejDropDownList('selectItemByValue', vm.SzakosztalyId);
            }
        }

        function sectionSelect(args) {
            vm.SzakosztalyId = args.value;
            if (!vm.KorosztalyId) {
                vm.KorosztalyId = 0;
            }
            if (!vm.EdzoId) {
                vm.EdzoId = 0;
            }
            getAgeGroups(args.value);
            getCoachList();
            paramHelper.setParams([{'exercisesList.SzakosztalyId': args.value}]);
        }

        function getAgeGroups(sectionId) {
            dataservice.ageGroupDropDownList(sectionId)
                .then(function (data) {
                    if (data.itemsList.length > 1) {
                        data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    }
                    vm.ageGroupList = data.itemsList;
                });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                $('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                $('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.KorosztalyId);
            }
        }

        function ageGroupSelect(args) {
            vm.KorosztalyId = args.value;
            getCoachList(args.value);
            paramHelper.setParams([{'exercisesList.KorosztalyId': args.value}]);
        }

        function getCoachList(KorosztalyId) {
            return dataservice.coachDropDownList(vm.SzakosztalyId, KorosztalyId, new Date(1754, 1, 1), new Date(3000, 1, 1))
                .then(function (data) {
                    if (data.itemsList.length > 1) {
                        data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    }
                    vm.coachList = data.itemsList;
                });
        }

        function coachDataBound() {
            if (vm.coachList.length === 1) {
                $('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                $('#coachDropDown').ejDropDownList('selectItemByValue', vm.EdzoId);
            }
        }

        function coachSelect(args) {
            vm.EdzoId = args.value;
            paramHelper.setParams([{'exercisesList.EdzoId': args.value}]);
        }

        // function bottom
        function getAutoComplete() {
            dataservice.getTrainingPracticeAutoComplete()
                .then(function (result) {
                    vm.autoComplete = result.data;
                });
        }

        function getDatas() {
            dataservice.getExercises(vm.SzakosztalyId, vm.KorosztalyId, vm.EdzoId, vm.bodyPartValue, vm.TypeValue, vm.individualTeamValue, vm.AssetValue, vm.difficultyValue, vm.necessaryAreaValue, vm.withVideo, vm.withPattern, vm.withPaint).then(function (response) {
                grid.dataSource = $filter('addImagePathToObject')(response.itemsList, 'Mintakep', vm.imageRoot);
                $timeout(function () {
                    $('.thumbnail-item').magnificPopup({
                        type: 'image'
                    });
                }, 100);
            });
        }

        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }

        function goToExercise(id) {
            if (vm.MODGYAKORLAT) {
                paramHelper.setParams([
                    {'exercises.exerciseId': id},
                    {'exercisesList.SzakosztalyId': vm.SzakosztalyId},
                    {'exercisesList.KorosztalyId': vm.KorosztalyId},
                    {'exercisesList.EdzoId': vm.EdzoId}
                    ]);
                $state.go('administration.exercises.exercisesCreate');
            } else {
                log.permissionError();
            }
        }

        function deleteExercise(id) {
            if (vm.MODGYAKORLAT) {
                swal({
                        title: gettextCatalog.getString('AreYouSureToDelete'),
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: gettextCatalog.getString('Yes'),
                        cancelButtonText: gettextCatalog.getString('No')
                    }).then(function (isConfirm) {
                        if (isConfirm.value) {
                            dataservice.DeleteExercise(id).then(function (response) {
                                if (response.retValue === true) {
                                    getDatas();
                                    log.htmlSuccess(gettextCatalog.getString('ExerciseDeleted'));
                                }
                            });
                        }
                    });
            } else {
                log.permissionError();
            }
        }

        function create() {
            if (vm.UJGYAKORLAT) {
                paramHelper.setParams([
                    {'exercisesList.SzakosztalyId': vm.SzakosztalyId},
                    {'exercisesList.KorosztalyId': vm.KorosztalyId},
                    {'exercisesList.EdzoId': vm.EdzoId}
                ]);
                $state.go('administration.exercises.exercisesCreate');
            } else {
                log.permissionError();
            }
        }
    }
})();
