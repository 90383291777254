(function () {
    'use strict';

    angular
        .module('app.reports.centralReport.centralReportAllIn')
        .controller('CentralReportAllInController', CentralReportAllInController);

    CentralReportAllInController.$inject = ['dataservice', '$q', 'log', 'paramHelper', 'authenticationService', '$rootScope', 'gettextCatalog', '$scope', '$compile', 'VIEW_DATA', '$timeout'];
    /* @ngInject */
    function CentralReportAllInController(dataservice, $q, log, paramHelper, authenticationService, $rootScope, gettextCatalog, $scope, $compile) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('CentralReportAllIn');
        vm.years = [];
        vm.yearsBirth = dataservice.range(1960, new Date().getFullYear());
        vm.sexList = [
            {text: gettextCatalog.getString('All'), value: 'allgender'},
            {text: gettextCatalog.getString('Male'), value: 'male'},
            {text: gettextCatalog.getString('Female'), value: 'female'}
        ];
        vm.sectionList = [];
        vm.yearList = [],
        vm.gridToolbar = ['printGrid', 'excelExport'];
        vm.gridExcelExport = dataservice.getURL() + 'SzakosztalySportolokSzama/ExcelExport/';
        vm.toolbarHandler = toolbarHandler;
        vm.tests = [];
        vm.columns = [{
                field: 'EvFelmeresi',
                headerText: gettextCatalog.getString('TestYear'),
                textAlign: 'left'
            }, {
                field: 'TestResztvevo',
                headerText: gettextCatalog.getString('Participants'),
                textAlign: 'left'
            }, {
                field: 'TestOsszAtlag',
                headerText: gettextCatalog.getString('AverageHeightOfAthlete'),
                textAlign: 'left'
            }, {
                field: 'TestAtlag',
                headerText: gettextCatalog.getString('AverageRank'),
                textAlign: 'left'
            }, {
                field: 'SulypontOsszAtlag',
                headerText: gettextCatalog.getString('AverageVerticalJumpOfAthlete'),
                textAlign: 'left'
            }, {
                field: 'SulypontAtlag',
                headerText: gettextCatalog.getString('AverageRank'),
                textAlign: 'left'
            }, {
                field: 'SprintOsszAtlag',
                headerText: gettextCatalog.getString('AverageSprintAthlete'),
                textAlign: 'left'
            }, {
                field: 'SprintAtlag',
                headerText: gettextCatalog.getString('AverageRank'),
                textAlign: 'left'
            }, {
                field: 'MeterOsszAtlagStr',
                headerText: gettextCatalog.getString('Average2800mRunning'),
                textAlign: 'left'
            }, {
                field: 'MeterAtlagStr',
                headerText: gettextCatalog.getString('AverageRank'),
                textAlign: 'left'
            }];

        vm.stackedHeaderRows = [
            {stackedHeaderColumns: [
                {headerText: '', column: 'EvFelmeresi, TestResztvevo', textAlign: 'center'},
                {headerText: gettextCatalog.getString('Height'), column: 'TestOsszAtlag, TestAtlag', textAlign: 'center'},
                {headerText: gettextCatalog.getString('VerticalJump'), column: 'SulypontOsszAtlag, SulypontAtlag', textAlign: 'center'},
                {headerText: gettextCatalog.getString('30mSprint'), column: 'SprintOsszAtlag, SprintAtlag', textAlign: 'center'},
                {headerText: gettextCatalog.getString('2800mRunning'), column: 'MeterOsszAtlagStr, MeterAtlagStr', textAlign: 'center'},
            ]}];

        vm.summaryRows = [{
            title: gettextCatalog.getString('Average'),

            summaryColumns: [
                {
                    summaryType: ej.Grid.SummaryType.Average,
                    displayColumn: 'TestResztvevo',
                    dataMember: 'TestResztvevo',
                    format: '{0:N2}'
                },
                {
                    summaryType: ej.Grid.SummaryType.Average,
                    displayColumn: 'TestOsszAtlag',
                    dataMember: 'TestOsszAtlag',
                    format: '{0:N2}'
                },
                {
                    summaryType: ej.Grid.SummaryType.Average,
                    displayColumn: 'TestAtlag',
                    dataMember: 'TestAtlag',
                    format: '{0:N2}'
                },
                {
                    summaryType: ej.Grid.SummaryType.Average,
                    displayColumn: 'SulypontOsszAtlag',
                    dataMember: 'SulypontOsszAtlag',
                    format: '{0:N2}'
                },
                {
                    summaryType: ej.Grid.SummaryType.Average,
                    displayColumn: 'SulypontAtlag',
                    dataMember: 'SulypontAtlag',
                    format: '{0:N2}'
                },
                {
                    summaryType: ej.Grid.SummaryType.Average,
                    displayColumn: 'SprintOsszAtlag',
                    dataMember: 'SprintOsszAtlag',
                    format: '{0:N2}'
                },
                {
                    summaryType: ej.Grid.SummaryType.Average,
                    displayColumn: 'SprintAtlag',
                    dataMember: 'SprintAtlag',
                    format: '{0:N2}'
                }
            ]
        }];

        vm.gridExcelExport = dataservice.getURL() + 'SzakosztalyokDijak/ExcelExport';
        vm.queryDate = new Date();
        vm.compile = compile;
        vm.clearYear = clearYear;
        vm.sectionSelect = sectionSelect;
        vm.sectionDataBound = sectionDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.coachSelect = coachSelect;
        vm.coachDataBound = coachDataBound;
        vm.getData = getData;
        vm.sexSelect = sexSelect;
        vm.birthDateChange = birthDateChange;
        vm.toolbarHandler = toolbarHandler;
        vm.selectedSex = 2;
        vm.selectedBirthDate = 0;
        vm.selectedAgeGroup = 0;
        vm.selectedCoach = 0;
        vm.selectedSection = 0;
        vm.coachList = [];
        vm.ageGroupList = [];

        activate();

        function activate() {
            var promises = [authenticationService.getRight('MEGTKPFELM')];
            $q.all(promises).then(function (res) {
                if (!res[0]) {
                    log.permissionError(true);
                } else {
                    getSections();
                    getFelmerokDatumaiDropDownList();
                    setDropDowns();
                }
            })
            .catch(function () {
                log.permissionError(true);
            });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'centralReportAllIn.sectionId',
                'centralReportAllIn.ageGroupId',
                'centralReportAllIn.coachId',
                'centralReportAllIn.birthDate',
                'centralReportAllIn.sex'
            ])
            ];
            $q.all(promises).then(function (results) {
                vm.selectedSection = results[0]['centralReportAllIn.sectionId'];
                vm.selectedAgeGroup = results[0]['centralReportAllIn.ageGroupId'];
                vm.selectedCoach = results[0]['centralReportAllIn.coachId'];
                vm.selectedBirthDate = results[0]['centralReportAllIn.birthDate'];
                vm.sex = results[0]['centralReportAllIn.sex'];
            });
        }

        function clearYear() {
            vm.selectedBirthDate = undefined;
            $('#BirthDate').ejDropDownList('clearText');
        }

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                var grid = angular.element('#Grid').ejGrid('instance');
                grid.ignoreOnExport.splice(grid.ignoreOnExport.indexOf('dataSource'), 1);
                this.export(dataservice.getURL() + 'KEredmenyFelmerokOsszesitett/ExcelExport/');
            }
        }

        function getData() {
            paramHelper.setParams([
                {'centralReportAllIn.sectionId': vm.selectedSection},
                {'centralReportAllIn.ageGroupId': vm.selectedAgeGroup},
                {'centralReportAllIn.coachId': vm.selectedCoach},
                {'centralReportAllIn.birthDate': vm.selectedBirthDate},
                {'centralReportAllIn.sex': vm.sex},
            ]);
            dataservice.getCentralReportAllIn(vm.selectedSection, vm.selectedAgeGroup, vm.selectedCoach, vm.selectedBirthDate, vm.selectedSex)
                .then(function (data) {
                    vm.tests = data.itemsList;
                });
        }

        function sectionSelect(data) {
            vm.selectedSection = data.value;
            getAgeGroups(data.value);
            getCoaches(data.value, 0);
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.selectedSection);
            }
        }

        function ageGroupSelect(args) {
            vm.selectedAgeGroup = args.value;
            getCoaches(vm.selectedSection, args.value);
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.selectedAgeGroup);
                if (vm.selectedAgeGroup) {
                    getCoaches(vm.selectedSection, 0);
                }
            }
        }

        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachName').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachName').ejDropDownList('selectItemByValue', vm.selectedCoach);
            }
        }

        function coachSelect(args) {
            vm.selectedCoach = args.value;
        }

        function getSections() {
            dataservice.sectionDropDownList(0, true)
                .then(function (data) {
                    data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    vm.sectionList = data.itemsList;
                });
        }

        function getAgeGroups(sectionId) {
            return dataservice.ageGroupDropDownList(sectionId)
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: 0, text: gettextCatalog.getString('All')};
                        vm.ageGroupList.splice(0, 0, all);
                    }
                });
        }

        function getCoaches(sectionId, ageGroupId) {
            if (!ageGroupId || ageGroupId == -1) {
                ageGroupId = 0;
            }
            return dataservice.coachDropDownList(sectionId, ageGroupId)
                .then(function (data) {
                    vm.coachList = data.itemsList;
                    if (data.itemsList.length > 1) {
                        var all = {value: 0, text: gettextCatalog.getString('All')};
                        vm.coachList.splice(0, 0, all);
                    }
                });
        }

        function getFelmerokDatumaiDropDownList() {
            dataservice.getFelmerokDatumaiDropDownList()
                .then(function (data) {
                    vm.years = data.itemsList;
                });
        }

        function sexSelect(args) {
            vm.selectedSex = args.text;
        }

        function birthDateChange(args) {
            vm.selectedBirthDate = args.value;
        }

        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }
    }
})();

