(function () {
    'use strict';

    angular
        .module('app.reports.managementReports')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.managementReports',
                config: {
                    url: '/managementReports',
                    templateUrl: 'app/reports/managementReports/managementReports.html',
                    title: 'ManagementReports',
                    settings: {
                        navId: 244,
                        level: 2,
                        order: 4,
                        orderTitle_hu: 'Vezetői kimutatások',
                        orderTitle_en: 'Management Reports',
                        parentId: 4,
                        content: 'ManagementReports',
                        activatorPermission: '"VEZETOIHJ"'
                    }
                }
            }
        ];
    }
})();
