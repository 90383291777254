(function() {
    'use strict';

    angular
        .module('app.reports.statisticalReports.athletesByYear')
        .controller('AthletesByYearController', AthletesByYearController);

    AthletesByYearController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'authenticationService', '$scope', '$compile', '$state', 'paramHelper', '$filter'];
    /* @ngInject */
    function AthletesByYearController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $compile, $state, paramHelper, $filter) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AthletesByYear');
        vm.sectionList = [];
        vm.athleteList = [];
        vm.gridToolbar = ['printGrid', 'excelExport'];

        vm.columns = [{
            field: 'Nev',
            headerText: gettextCatalog.getString('Name'),
            width: 150,
            textAlign: 'left',
            template: true, templateID: '#atbyyetemplateDataColumn1'
        }, {
            field: 'SzuletesiIdo',
            headerText: gettextCatalog.getString('BirthDate'),
            width: 150,
            textAlign: 'left'
        }, {
            field: 'Aktiv',
            headerText: gettextCatalog.getString('Active'),
            width: 50,
            textAlign: 'left',
            template: true, templateID: '#atbyyetemplateDataColumn2'
        }, {
            field: 'EdzoNeve',
            headerText: gettextCatalog.getString('Coach'),
            width: 150,
            textAlign: 'left',
            template: true, templateID: '#atbyyetemplateDataColumn3'
        }, {
            field: 'SzakosztalyNeve',
            headerText: gettextCatalog.getString('Section'),
            width: 150,
            textAlign: 'left'
        }, {
            field: 'KorcsoportNeve',
            headerText: gettextCatalog.getString('AgeGroup'),
            width: 150,
            textAlign: 'left'
        }, {
            field: 'Tagdij',
            headerText: gettextCatalog.getString('PayedSportServiceFee'),
            width: 150,
            textAlign: 'left'
        }, {
            field: 'Oktatasidij',
            headerText: gettextCatalog.getString('PayedEducationalFee'),
            width: 150,
            textAlign: 'left'
        }, {
            field: 'Egyenleg',
            headerText: gettextCatalog.getString('Balance'),
            width: 150,
            textAlign: 'left'
        }, {
            field: 'Megjegyzes',
            headerText: gettextCatalog.getString('Comment'),
            width: 150,
            textAlign: 'left'
        }];

        vm.gridExcelExport = dataservice.getURL() + 'SportolokEvszamSzerint/ExcelExport/';
        vm.compile = compile;
        vm.toolbarHandler = toolbarHandler;
        vm.Date = new Date();
        vm.selectedSection = 0;
        vm.goToAthlete = goToAthlete;
        vm.goToCoach = goToCoach;

        activate();

        function activate() {
            authenticationService.getRight('KMSPORTELETKOR').then(function (results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([paramHelper.getParams([], ['year'])]).then(function (results) {
                        vm.params = results[0];
                        getNumbers(vm.params.year, 0);
                    });
                }
            });
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function goToCoach(coachId) {
            $q.all([authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([
                        {'newUser.userId': coachId}
                    ]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function getNumbers(year, sectionID) {
            dataservice.activeAthletesBornAtSpecificYear(year, sectionID)
                .then(function(data) {
                    vm.athleteList = data.itemsList;
                    vm.athleteList = $filter('dateStringFilter')(vm.athleteList, 'SzuletesiIdo');
                });
        }

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                var grid = angular.element('#Grid').ejGrid('instance');
                grid.ignoreOnExport.splice(grid.ignoreOnExport.indexOf('dataSource'), 1);
                this.export(dataservice.getURL() + 'SportolokEvszamSzerint/ExcelExport/');
            }
        }

        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }
    }
})();
