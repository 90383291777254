(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.health.modifyHealthEvent')
        .controller('modifyHealthEventController', ModifyHealthEventController);
    ModifyHealthEventController.$inject = ['log', 'logger', '$state', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'paramHelper', 'authenticationService', '$filter', '$timeout', '$scope'];
    /* @ngInject */
    function ModifyHealthEventController(log, logger, $state, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $filter, $timeout, $scope) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('ModifyHealthEvent');
        // Create variables
        vm.EGESZSMEGT = false;
        vm.EGESZSMOD = false;
        vm.documents = '';
        vm.params = {
            Id: null,
            SportoloId: null,
            Mettol: null,
            Meddig: null,
            Tipus: null,
            AlTipus: null,
            Leiras: null,
            Kezeles: null
        };

        vm.healthMainType = [];
        vm.healthSubType = [];
        vm.SubType = [];

        // Public functions
        vm.saveDatas = saveDatas;
        vm.fileUpload = fileUpload;
        vm.MainTypeSelected = MainTypeSelected;

        function MainTypeSelected(arg) {
            var mainId = 0;
            vm.SubType = [];
            for (var i = 0; i < vm.healthMainType.length; i++) {
                if (vm.healthMainType[i].text === arg.value) {
                    mainId = vm.healthMainType[i].value;
                }
            }
            dataservice.healthSubTypesList()
                .then(function (response) {
                    for (var i = 0; i < response.itemsList.length; i++) {
                        if (response.itemsList[i].FotipusId === mainId) {
                            vm.SubType.push({
                                text: response.itemsList[i].AltipusNev,
                                value: response.itemsList[i].FotipusId,
                            });
                        }
                    }
                });
        }

        // Activate page
        activate();
        function activate() {
            $q.all([paramHelper.getParams([],
                [
                    'mofifyHealthId',
                    'mofifyHealthAthleteId',
                    'mofifyHealthAthleteName',
                    'mofifyHealthAthleteSocialSecurity',
                    'mofifyHealthAthleteBirthDate',
                    'mofifyHealthKezeles',
                    'mofifyHealthLeiras',
                    'mofifyHealthLetrehozas',
                    'mofifyHealthMettol',
                    'mofifyHealthMeddig',
                    'mofifyHealthTipus',
                    'mofifyHealthAlTipus'
                ]),

                authenticationService.getRight('EGESZSMEGT'),
                authenticationService.getRight('EGESZSMOD')])
                .then(function (results) {
                    $timeout(function () {
                        vm.params.Id = results[0]['mofifyHealthId'];
                        vm.params.SportoloId = results[0]['mofifyHealthAthleteId'];
                        vm.athleteName = results[0]['mofifyHealthAthleteName'];
                        vm.athleteSocialSecurity = results[0]['mofifyHealthAthleteSocialSecurity'];
                        vm.athleteBirthDate = results[0]['mofifyHealthAthleteBirthDate'];
                        vm.params.Kezeles = results[0]['mofifyHealthKezeles'];
                        vm.params.Leiras = results[0]['mofifyHealthLeiras'];
                        vm.params.Mettol = results[0]['mofifyHealthMettol'];
                        vm.params.Meddig = results[0]['mofifyHealthMeddig'];
                        $('#MainType').ejAutocomplete({
                            value: results[0]['mofifyHealthTipus']
                        });
                        vm.params.AlTipus = results[0]['mofifyHealthAlTipus'];
                    }, 1);
                    vm.EGESZSMEGT = results[1];
                    vm.EGESZSMOD = results[2];
                    if (!vm.EGESZSMEGT) {
                        log.permissionError();
                        $rootScope.back();
                    }
                });
        }

        function fileUpload() {
            //TablaId = szerkSportolo.SportolokID, TablaNev = "Sportolok"
            paramHelper.setParams([{'files.tipus': 'HealthEvent'}, {'files.tablaId': vm.params.SportoloId}]);
            $state.go('other.files');
        }

        function saveDatas() {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
            } else {
                dataservice.modifyHealthData(vm.params)
                        .then(function () {
                            if (vm.params.Meddig) {
                                var from = new Date(vm.params.Mettol);
                                var to = new Date(vm.params.Meddig);
                                if (from > to) {
                                    log.errorMsg(gettextCatalog.getString('DateToMustNotBeBeforeDateFrom'));
                                    return;
                                }
                            }
                            $rootScope.back();
                        });
            }
        }

        // meglévő adatlekérés
        getHealthMainTypes();
        function getHealthMainTypes() {
            dataservice.healthMainTypesList()
                .then(function (response) {
                    for (var i = 0; i < response.itemsList.length; i++) {
                        vm.healthMainType.push({
                            text: response.itemsList[i].Nev,
                            value: response.itemsList[i].Id
                        });
                    }
                    MainTypeSelected({value: vm.params.Tipus});
                });
        }
    }
})();
