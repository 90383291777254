(function () {
    'use strict';

    angular
        .module('app.athletes.ageGroups.ageGroupsList')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'athletes.ageGroups.ageGroupsList',
                config: {
                    url: '/ageGroupsList',
                    templateUrl: 'app/athletes/ageGroups/ageGroupsList/ageGroupsList.html',
                    controller: 'AgeGroupsListController',
                    controllerAs: 'vm',
                    title: 'AgeGroups',
                    settings: {
                        navId: 231,
                        level: 2,
                        order: 3,
                        orderTitle_hu: 'Korosztályok',
                        orderTitle_en: 'Age Groups',
                        parentId: 1,
                        content: 'AgeGroups',
                        activatorPermission: '"MEGTKOROKEZ"'
                    }
                }
            }
        ];
    }
})();
