(function () {
    'use strict';

    angular
        .module('app.reports.duplicatedAthletes')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.duplicatedAthletes',
                config: {
                    url: '/duplicatedAthletes',
                    templateUrl: 'app/reports/duplicatedAthletes/duplicatedAthletes.html',
                    controller: 'DuplicatedAthletesController',
                    controllerAs: 'vm',
                    title: 'DuplicatedAthletes',
                    settings: {
                        navId: 2134,
                        level: 2,
                        order: 13,
                        orderTitle_hu: 'Duplázott sportolók kimutatás',
                        orderTitle_en: 'Duplicated Athletes Report',
                        parentId: 4,
                        content: 'DuplicatedAthletes',
                        activatorPermission: '"DUPLIKALTMEGT"'
                    }
                }
            }
        ];
    }
})();
