(function () {
    'use strict';

    angular
        .module('app.settings.athleteTransfer')
        .controller('AthleteTransferController', AthleteTransferController);

    AthleteTransferController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'paramHelper', 'authenticationService', '$filter', '$state', '$compile', '$scope'];

    /* @ngInject */
    function AthleteTransferController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $filter, $state, $compile, $scope) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AthleteTransfer');

        // Create variables
        // Dropdownlist's sources
        vm.actualSectionList = [];
        vm.actualCoachList = [];
        vm.sectionList = [];
        vm.coachList = [];
        vm.ageGroupList = [];
        vm.athleteData = undefined;
        vm.selectedRows = [];
        vm.columns = [
            {type: 'checkbox', textAlign: 'center', width: 100},
            {
                field: 'Nev',
                headerText: gettextCatalog.getString('Name'),
                textAlign: 'left',
                template: '#athltransfercolumnTemplate2',
                width: 100
            },
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('CoachName'),
                textAlign: 'left',
                template: '#athltransfercolumnTemplate1',
                width: 100
            },
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), textAlign: 'left', width: 100},
            {
                field: 'SzuletesiIdo',
                headerText: gettextCatalog.getString('BirthDate'),
                textAlign: 'left',
                format: '{0:yyyy-MM-dd}',
                width: 100
            },
            {field: 'KorcsoportNeve', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'left', width: 100},
            {field: 'Megjegyzes', headerText: gettextCatalog.getString('Comment'), textAlign: 'left', width: 100},
            {field: 'Tagdij', headerText: gettextCatalog.getString('SportServiceFee'), textAlign: 'left', width: 100},
            {field: 'Oktatasidij', headerText: gettextCatalog.getString('SchoolFee'), textAlign: 'left', width: 100},
            {field: 'Egyenleg', headerText: gettextCatalog.getString('Balance'), textAlign: 'left', width: 100},
            {field: 'Aktiv', headerText: gettextCatalog.getString('Active'), textAlign: 'center', width: 100},
            {
                field: 'jelenKorcsoportbanMikortolVan',
                headerText: gettextCatalog.getString('InAgeGroupFromDate'),
                textAlign: 'left',
                format: '{0:yyyy-MM-dd}',
                width: 100
            }
        ];

        var Grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            allowTextWrap: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            selectionSettings: {type: 'multiple', mode: 'both'},
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(Grid, $scope);
                }
            },
            rowSelected: function () {
                vm.selectedRows = $rootScope.getSelectedItems(Grid);
            },
            dataBound: function () {
                if (Grid.dataSource.length === 1) {
                    Grid.selectRow(0);
                }
            }
        });
        Grid.appendTo('#Grid');
        Grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, Grid);
        };

        //Other variables
        vm.dateValue = new Date();
        vm.date = vm.dateValue;

        vm.athleteData = undefined;
        vm.actualAthleteId = 0;
        vm.actualSectionId = undefined;
        vm.actualCoachId = 0;

        //Public functions
        vm.actualSectionDataBound = actualSectionDataBound;
        vm.actualSectionSelect = actualSectionSelect;
        vm.actualCoachDataBound = actualCoachDataBound;
        vm.actualCoachSelect = actualCoachSelect;
        vm.dateChange = dateChange;
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.transferAthletes = transferAthletes;
        vm.openCoach = openCoach;
        vm.goToAthlete = goToAthlete;
        vm.previousState = ''; // athleteNew és athleteModify felületekről érkezhet

        //Activate page
        activate();

        function activate() {
            var promises = [authenticationService.getRight('MEGTEDZVALT'), authenticationService.getRight('MEGTKORVALT'), paramHelper.getParams([], ['athleteTransfer.athleteData', 'previousState']), authenticationService.getRight('UJEDZVALT')];
            $q.all(promises).then(function (results) {
                vm.UJEDZVALT = results[3];
                if (results[2] ['athleteTransfer.athleteData']) {
                    vm.previousState = results[2]['previousState'];
                    vm.athleteData = results[2] ['athleteTransfer.athleteData'];
                    vm.actualAthleteId = vm.athleteData.SportolokID;
                    paramHelper.removeParam('athleteTransfer.athleteData'); // Clear paramHelper's athleteData
                } else {
                    vm.actualAthleteId = 0;
                }

                if (results[0] !== true && results[1] !== true && results[3] !== true) {
                    log.permissionError(true);
                } else if (vm.actualAthleteId > 0) {
                    if (vm.athleteData.ModifyCoach === 1) {
                        vm.actualSectionId = vm.athleteData.IdSzakosztaly;
                        vm.actualCoachId = vm.athleteData.JelenlegiEdzo;
                    } else if (vm.athleteData.ModifyCoach === 2) {
                        vm.actualSectionId = vm.athleteData.MasodlagosSzakosztaly;
                        vm.actualCoachId = vm.athleteData.MasodlagosSzakosztalyEdzoId;
                    }
                    athletesSelect();
                    getActualSections();
                    getSections();
                    getActualCoaches();
                } else {
                    getActualSections();
                    getSections();
                }
            });
        }

        /**
         * Betölti a szakosztályok listáját
         * @author Richard Keki
         * @returns {promise} A http promisszal tér vissza
         */
        function getActualSections() {
            return dataservice.sectionDropDownList(vm.actualCoachId)
                .then(function (data) {
                    vm.actualSectionList = data.itemsList;
                    if (vm.actualSectionId > 0) {
                        vm.defaultSection = vm.actualSectionId; // Set default section
                        angular.element('#actualSectionDropDown').ejDropDownList({enabled: false});
                        Grid.selectRow(0);
                    }
                });
        }

        /**
         * Szakosztályok betöltése után beállítja az alapértelmezett szakosztályt
         * @author Richard Keki
         */
        function actualSectionDataBound() {
            if (vm.actualSectionList.length === 1) {
                angular.element('#actualSectionDropDown').ejDropDownList('selectItemByValue', vm.actualSectionList[0].value);
                vm.actualSectionId = vm.actualSectionList[0].value;
            } else {
                angular.element('#actualSectionDropDown').ejDropDownList('selectItemByValue', vm.actualSectionId);
            }
        }

        /**
         * Szakosztály kiválasztásakor fut le és lekéri a szakosztály korosztályait
         * @author Richard Keki
         */
        function actualSectionSelect(args) {
            vm.actualSectionId = args.value;
            vm.actualCoachId = 0;
            getActualCoaches();
            if (vm.actualAthleteId == 0) {
                athletesSelect();
            }
        }

        /**
         * Betölti az edzőket
         * @author Richard Keki
         * @returns {promise} A http promisszal tér vissza
         */
        function getActualCoaches() {
            return dataservice.coachDropDownList(vm.actualSectionId)
                .then(function (data) {
                    vm.actualCoachList = data.itemsList;
                    vm.actualCoachList.unshift({value: '-1', text: gettextCatalog.getString('All')});
                    if (vm.actualAthleteId > 0) {
                        angular.element('#actualCoachDropDown').ejDropDownList({enabled: false});
                    }
                });
        }

        /**
         * Edzők betöltése után beállítja az alapértelmezett edzőt
         * @author Richard Keki
         */
        function actualCoachDataBound() {
            if (vm.actualCoachList.length === 1) {
                angular.element('#actualCoachDropDown').ejDropDownList('selectItemByValue', vm.actualCoachList[0].value);
                vm.actualCoachId = vm.actualCoachList[0].value;
            } else if (vm.athleteData !== undefined) {
                if (vm.athleteData.ModifyCoach == 1 && vm.athleteData.JelenlegiEdzo > 0) {
                    vm.actualCoachId = vm.athleteData.JelenlegiEdzo;
                    angular.element('#actualCoachDropDown').ejDropDownList('selectItemByValue', vm.actualCoachId);
                } else if (vm.athleteData.ModifyCoach == 2 && vm.athleteData.MasodlagosSzakosztalyEdzoId > 0) {
                    vm.actualCoachId = vm.athleteData.MasodlagosSzakosztalyEdzoId;
                    angular.element('#actualCoachDropDown').ejDropDownList('selectItemByValue', vm.actualCoachId);
                }
            }
        }

        function actualCoachSelect(args) {
            vm.actualCoachId = args.value;
            if (vm.actualAthleteId == 0) {
                athletesSelect();
            }
        }

        function athletesSelect() {
            return dataservice.getAthletesEV(vm.actualSectionId, (vm.actualCoachId == -1 ? 0 : vm.actualCoachId), vm.actualAthleteId, true)
                .then(function (data) {
                    Grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'jelenKorcsoportbanMikortolVan');
                    Grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'SzuletesiIdo');
                });
        }

        function dateChange(args) {
            vm.date = args.value;
        }

        /**
         * Betölti a szakosztályok listáját
         * @author Richard Keki
         * @returns {promise} A http promisszal tér vissza
         */
        function getSections() {
            return dataservice.sectionDropDownList(0)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                });
        }

        /**
         * Szakosztályok betöltése után beállítja az alapértelmezett szakosztályt
         * @author Richard Keki
         */
        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                vm.sectionId = vm.sectionList[0].value;
            }
        }

        /**
         * Szakosztály kiválasztásakor fut le és lekéri a szakosztály korosztályait
         * @author Richard Keki
         */
        function sectionSelect(args) {
            vm.sectionId = args.value;
            vm.coachId = 0;
            vm.ageGroupId = 0;
            vm.ageGroupList = [];
            getcoaches();
        }

        /**
         * Betölti az edzőket
         * @author Richard Keki
         * @returns {promise} A http promisszal tér vissza
         */
        function getcoaches() {
            return dataservice.coachDropDownList(vm.sectionId)
                .then(function (data) {
                    vm.coachList = data.itemsList;
                });
        }

        /**
         * Edzők betöltése után beállítja az alapértelmezett edzőt
         * @author Richard Keki
         */
        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                vm.coachId = vm.coachList[0].value;
            }
        }

        function coachSelect(args) {
            vm.coachId = args.value;
            vm.ageGroupId = 0;
            getAgeGroups();
        }

        function getAgeGroups() {
            return dataservice.ageGroupSelect(vm.coachId, vm.sectionId)
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
                vm.selectedAgeGroupId = vm.ageGroupList[0].value;
            }
        }

        // Run after age group selected
        function ageGroupSelect(args) {
            vm.ageGroupId = args.value;
        }

        function transferAthletes() {
            if (vm.UJEDZVALT !== true) {
                log.permissionError();
                return;
            }
            if (vm.selectedRows.length !== 0) {
                if (vm.sectionId !== 0 && vm.coachId !== 0 && vm.ageGroupId) {
                    var message = '';
                    angular.forEach(vm.selectedRows, function (value) {
                        if (value.EdzoId === vm.coachId) {
                            message += value.Nev + '<br>';
                        }
                    });
                    if (message !== '') {
                        log.htmlError(gettextCatalog.getString('DestinationCoachSame') + '<br>' + message);
                    } else {
                        swal({
                            title: gettextCatalog.getString('AreYouSureToTransferAthletes'),
                            text: gettextCatalog.getString('AreYouSureToTransferAthletesText'),
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText: gettextCatalog.getString('OK'),
                            cancelButtonText: gettextCatalog.getString('Cancel'),
                            closeOnConfirm: false
                        }).then(function (args) {
                            if (args.value) {
                                dataservice.transferAthletes(vm.selectedRows, vm.coachId, vm.sectionId, vm.ageGroupId, vm.date).then(function () {
                                    athletesSelect();
                                    Grid.refresh();

                                    swal.close();
                                    paramHelper.setParams([{'athleteModify.sportoloId': vm.actualAthleteId}]);

                                    if (vm.previousState !== '') {
                                        $state.go(vm.previousState);
                                    }
                                });
                            }
                        });
                    }
                } else {
                    log.errorMsg(gettextCatalog.getString('YouHaveToSelectTransferDestination'));
                }
            } else {
                log.errorMsg(gettextCatalog.getString('YouHaveToSelectLeastAthlete'));
            }
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }
    }
})
();

