(function () {
    'use strict';

    angular
        .module('app.administration.awayBudgetPlan.awayBudgetPlanList')
        .controller('AwayBudgetPlanListController', AwayBudgetPlanListController);

    AwayBudgetPlanListController.$inject = ['log', '$timeout', 'paramHelper', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'authenticationService', '$compile', '$scope', '$state', '$filter'];

    /* @ngInject */
    function AwayBudgetPlanListController(log, $timeout, paramHelper, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $compile, $scope, $state, $filter) {
        var vm = this;
        $rootScope.vm = vm;

        // Title
        $rootScope.title = gettextCatalog.getString('AwayBudgetPlanList');

        // Section DropDown
        vm.sectionList = [];
        vm.sectionId = 0;
        // Age Group DropDown
        vm.ageGroupList = [];
        vm.ageGroupId = 0;
        // Coach DropDown
        vm.coachList = [];
        vm.coachId = 0;
        // DatePicker
        vm.typeId = 0;
        vm.typeList = [
            {value: 0, text: gettextCatalog.getString('All')},
            {value: 1, text: gettextCatalog.getString('Plan')},
            {value: 2, text: gettextCatalog.getString('Fact')}
        ];
        $timeout(function () {
            vm.dateFrom = new Date();
            vm.dateFrom.setMonth(vm.dateFrom.getMonth() - 1);
            vm.dateTo = new Date();
            vm.dateTo.setMonth(vm.dateTo.getMonth() + 1);
        }, 300);
        // Checkbox
        vm.onlyStartDate = false;
        // Plans Grid
        vm.columns = [
            {
                field: 'Id',
                visible: false,
                isPrimaryKey: true,
                isIdentity: true
            },
            {
                field: 'Sorszam',
                headerText: gettextCatalog.getString('PlanNumber'),
                textAlign: 'center',
                width: 110
            },
            {
                field: 'VersenyNeve',
                headerText: gettextCatalog.getString('CompetitionName'),
                textAlign: 'center',
                width: 125
            },
            {
                field: 'VersenyHelye',
                headerText: gettextCatalog.getString('CompetitionPlace'),
                textAlign: 'center',
                width: 125
            },
            {
                field: 'Szakosztaly',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'center',
                width: 125
            },
            {
                field: 'Korosztaly',
                headerText: gettextCatalog.getString('AgeGroup'),
                textAlign: 'center',
                width: 120
            },
            {
                field: 'Edzo',
                headerText: gettextCatalog.getString('Coach'),
                textAlign: 'center',
                width: 120
            },
            {
                field: 'CompetitionStart',
                headerText: gettextCatalog.getString('CompetitionStart'),
                textAlign: 'center',
                width: 120
            },
            {
                field: 'CompetitionEnd',
                headerText: gettextCatalog.getString('CompetitionEnd'),
                textAlign: 'center',
                width: 120
            },
            {
                field: 'Lezarva',
                headerText: gettextCatalog.getString('Closed'),
                textAlign: 'center',
                type: 'boolean', displayAsCheckBox: true,
                width: 120
            },
            {
                field: 'Vegleges',
                headerText: gettextCatalog.getString('Final'),
                textAlign: 'center',
                type: 'boolean', displayAsCheckBox: true,
                width: 120
            },
            {
                field: 'Jovahagyva1',
                headerText: gettextCatalog.getString('ProfessionalApproved'),
                textAlign: 'center',
                type: 'boolean', displayAsCheckBox: true,
                width: 120
            },
            {
                field: 'Jovahagyva2',
                headerText: gettextCatalog.getString('EconomicApproved'),
                textAlign: 'center',
                type: 'boolean', displayAsCheckBox: true,
                width: 120
            },
            {
                field: 'Jovahagyva3',
                headerText: gettextCatalog.getString('LeaderApproved'),
                textAlign: 'center',
                type: 'boolean', displayAsCheckBox: true,
                width: 120
            },
            {
                headerText: gettextCatalog.getString('Open'),
                template: '#PlanOpenTemplate',
                width: 120
            }
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            allowTextWrap: true,
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');

        // Public functions
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.typeDataBound = typeDataBound;
        vm.typeSelect = typeSelect;

        vm.compile = compile;
        vm.search = search;
        vm.openPlan = openPlan;
        vm.create = create;
        vm.delete = deletePlan;

        vm.selectedCoach = 0;
        vm.selectedSection = 0;
        vm.selectedAgeGroup = 0;
        vm.selectedType = 0;
        vm.selectedOnlyStartedDate = false;
        vm.selectedFromDate = vm.dateFrom;
        vm.selectedToDate = vm.dateTo;

        // Activate page
        activate();

        function activate() {
            var promises = [
                authenticationService.getRight('MEGTIDEGENKOLTSTERV'),
                authenticationService.getRight('MODIDEGENKOLTSTERV'),
                authenticationService.getRight('UJIDEGENKOLTSTERV'),
                authenticationService.getRight('TORLIDEGENKOLTSTERV'),
                getSections(),
                paramHelper.getParams([], ['awayBudgetPlanList.edzoId', 'awayBudgetPlanList.szakosztalyId', 'awayBudgetPlanList.korosztalyId', 'awayBudgetPlanList.tipus', 'awayBudgetPlanList.csakKezdoDatum', 'awayBudgetPlanList.datumTol', 'awayBudgetPlanList.datumIg', 'awayBudgetPlanList.versenyHelye']),
                getCompetitionPlaces()
            ];
            $q.all(promises).then(function (results) {
                if (!results[0]) {
                    log.permissionError(true);
                }
                vm.MODIDEGENKOLTSTERV = results[1];
                vm.UJIDEGENKOLTSTERV = results[2];
                vm.TORLIDEGENKOLTSTERV = results[3];
                if (results[5]['awayBudgetPlanList.edzoId'] !== undefined) {
                    vm.selectedCoach = results[5]['awayBudgetPlanList.edzoId'];
                }
                if (results[5]['awayBudgetPlanList.szakosztalyId'] !== undefined) {
                    vm.selectedSection = results[5]['awayBudgetPlanList.szakosztalyId'];
                }
                if (results[5]['awayBudgetPlanList.korosztalyId'] !== undefined) {
                    vm.selectedAgeGroup = results[5]['awayBudgetPlanList.korosztalyId'];
                }
                if (results[5]['awayBudgetPlanList.tipus'] !== undefined) {
                    vm.selectedType = results[5]['awayBudgetPlanList.tipus'];
                }
                if (results[5]['awayBudgetPlanList.csakKezdoDatum'] !== undefined) {
                    vm.selectedOnlyStartedDate = results[5]['awayBudgetPlanList.csakKezdoDatum'];
                }
                if (results[5]['awayBudgetPlanList.datumTol'] !== undefined) {
                    vm.dateFrom = results[5]['awayBudgetPlanList.datumTol'];
                }
                if (results[5]['awayBudgetPlanList.datumIg'] !== undefined) {
                    vm.dateTo = results[5]['awayBudgetPlanList.datumIg'];
                }
                if (results[5]['awayBudgetPlanList.versenyHelye'] !== undefined) {
                    vm.competitionPlace = results[5]['awayBudgetPlanList.versenyHelye'];
                }

                if (vm.selectedOnlyStartedDate) {
                    angular.element('#onlyStartDate').ejCheckBox({checked: vm.selectedOnlyStartedDate});
                }
            });
        }

        function getCompetitionPlaces() {
            dataservice.getCompetitionPlaces().then(function (data) {
                vm.competitionPlaceList = data.itemsList;
            });
        }

        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }

        function typeDataBound() {
            if (!vm.selectedType) {
                vm.selectedType = 0;
            }
            angular.element('#typeDropDown').ejDropDownList('selectItemByValue', vm.selectedType);
        }

        function typeSelect(args) {
            vm.typeId = args.value;
        }

        function getSections() {
            dataservice.sectionDropDownList(0)
                .then(function (data) {
                    if (data.itemsList.length > 1) {
                        data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    }
                    vm.sectionList = data.itemsList;
                });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                $('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                $('#sectionDropDown').ejDropDownList('selectItemByValue', vm.selectedSection);
            }
        }

        function sectionSelect(args) {
            vm.sectionId = args.value;
            vm.coachList = [];
            getAgeGroups();
        }

        function getAgeGroups() {
            dataservice.ageGroupDropDownList(vm.sectionId)
                .then(function (data) {
                    if (data.itemsList.length > 1) {
                        data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    }
                    vm.ageGroupList = data.itemsList;
                });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                $('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                $('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.selectedAgeGroup);
            }
        }

        function ageGroupSelect(args) {
            vm.ageGroupId = args.value;
            getCoachList();
        }

        // Section dropDownList function Get coach' list
        function getCoachList() {
            return dataservice.coachDropDownList(vm.sectionId, vm.ageGroupId, vm.dateFrom, vm.dateTo)
                .then(function (data) {
                    if (data.itemsList.length > 1) {
                        data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    }
                    vm.coachList = data.itemsList;
                });
        }

        // Run after data bounded
        function coachDataBound() {
            if (vm.coachList.length === 1) {
                $('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                $('#coachDropDown').ejDropDownList('selectItemByValue', vm.selectedCoach);
            }
        }

        // Run after age group selected
        function coachSelect(args) {
            vm.coachId = args.value;
        }

        function search() {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            paramHelper.setParams([
                {'awayBudgetPlanList.edzoId': vm.coachId},
                {'awayBudgetPlanList.szakosztalyId': vm.sectionId},
                {'awayBudgetPlanList.korosztalyId': vm.ageGroupId},
                {'awayBudgetPlanList.tipus': vm.typeId},
                {'awayBudgetPlanList.csakKezdoDatum': $('#onlyStartDate').ejCheckBox('isChecked')},
                {'awayBudgetPlanList.datumTol': vm.dateFrom},
                {'awayBudgetPlanList.datumIg': vm.dateTo},
                {'awayBudgetPlanList.versenyHelye': vm.competitionPlace}
            ]);

            return dataservice.getAwayBudgetPlanList(vm.sectionId, vm.ageGroupId, vm.coachId, vm.dateFrom, vm.dateTo, vm.onlyStartDate, vm.typeId, vm.competitionPlace).then(function (data) {
                var planList = $filter('dateStringFilter')(data.itemsList, 'CompetitionEnd');
                grid.dataSource = $filter('dateStringFilter')(planList, 'CompetitionStart');
            });
        }

        function openPlan(planId) {
            paramHelper.setParams([{
                'awayBudgetPlan.planId': planId,
                'awayBudgetPlan.planSection': $('#sectionDropDown').ejDropDownList('getSelectedValue'),
                'awayBudgetPlan.planAgeGroup': $('#ageGroupDropDown').ejDropDownList('getSelectedValue')
            }]);
            paramHelper.setParams([
                {'awayBudgetPlanList.edzoId': vm.coachId},
                {'awayBudgetPlanList.szakosztalyId': vm.sectionId},
                {'awayBudgetPlanList.korosztalyId': vm.ageGroupId},
                {'awayBudgetPlanList.tipus': vm.typeId},
                {'awayBudgetPlanList.csakKezdoDatum': $('#onlyStartDate').ejCheckBox('isChecked')},
                {'awayBudgetPlanList.datumTol': vm.dateFrom},
                {'awayBudgetPlanList.datumIg': vm.dateTo}
            ]);
            $state.go('administration.awayBudgetPlan.awayBudgetPlanCreate');
        }

        function create() {
            if (!vm.UJIDEGENKOLTSTERV) {
                log.permissionError();
                return;
            }
            openPlan(-1);
        }

        function deletePlan() {
            if (!vm.TORLIDEGENKOLTSTERV) {
                log.permissionError();
                return;
            }
            var selected = $rootScope.getSelectedItems(grid);
            if (selected.length != 1) {
                log.errorMsg(gettextCatalog.getString('SelectAPlanToDelete'));
            } else if (selected.length == 1) {
                swal({
                    title: gettextCatalog.getString('Are you sure you want to delete the selected away budget Plan?'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('Yes'),
                    cancelButtonText: gettextCatalog.getString('Cancel'),
                    closeOnConfirm: true,
                    closeOnCancel: true
                }).then(function (isConfirm) {
                    if (isConfirm.value) {
                        return dataservice.deleteAwayBudgetPlan(selected[0].Id).then(function () {
                            search();
                            log.infoMsg(gettextCatalog.getString('PlanDeleted'));
                        });
                    }
                }, function () {
                });
            }
        }
    }
})();
