(function () {
    'use strict';

    angular
        .module('app.administration.weeklyTrainingPlan.weeklyTrainingPlanList')
        .controller('WeeklyTrainingPlanListController', WeeklyTrainingPlanListController);

    WeeklyTrainingPlanListController.$inject = ['dataservice', 'log', '$q', '$state', 'gettextCatalog', 'paramHelper', '$scope', '$compile', 'authenticationService', '$rootScope', '$timeout'];

    /* @ngInject */
    function WeeklyTrainingPlanListController(dataservice, log, $q, $state, gettextCatalog, paramHelper, $scope, $compile, authenticationService, $rootScope, $timeout) {
        var vm = this;
        $rootScope.vm = vm;

        function setInitVals() {
            $rootScope.title = gettextCatalog.getString('WeeklyTrainingPlan');
            //grid adatai
            vm.columns = [];

            vm.coachList = [];
            vm.selectedCoach = 0;

            vm.sectionList = [];
            vm.selectedSection = 0;

            vm.ageGroupList = [];
            vm.selectedAgeGroup = 0;

            vm.years = [];
            var evIg = new Date().getFullYear();
            var december = (new Date().getMonth() === 11);
            if (december) {
                evIg++;
            }
            for (var ev = 2010; ev <= evIg; ev++) {
                vm.years.push(ev);
            }

            //Kiválasztott év (alapból az aktuális)
            vm.selectedYear = new Date().getFullYear();
            vm.selectedRowId = -1;

            vm.textSearch = '';

            vm.delEnabled = false;
        }

        setInitVals();

        vm.coachChanged = coachChanged;
        vm.coachDataBound = coachDataBound;
        vm.sectionChanged = sectionChanged;
        vm.ageGroupChanged = ageGroupChanged;
        vm.yearChanged = yearChanged;
        vm.sectionDataBound = sectionDataBound;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.refreshData = refreshData;
        vm.goToNewUser = goToNewUser;
        vm.goToNewAgeGroup = goToNewAgeGroup;
        vm.goToWeeklyTrainingPlan = goToWeeklyTrainingPlan;
        vm.createNewWeeklyTrainingPlan = goToNewWeeklyTrainingPlan;
        vm.deleteWeeklyTrainingPlan = deleteWeeklyTrainingPlan;

        activate();

        function activate() {
            var promises = [paramHelper.getParams([], ['weeklyTrainingPlan.edzoId', 'weeklyTrainingPlan.szakosztalyId', 'weeklyTrainingPlan.korosztalyId', 'weeklyTrainingPlan.ev', 'weeklyTrainingPlan.szoveg']),
                authenticationService.getRight('UJHETIEDZTERV'),
                authenticationService.getRight('MODHETIEDZTERV'),
                authenticationService.getRight('MEGTHETIEDZTERV'),
                authenticationService.getRight('MEGTFELH'),
                authenticationService.getRight('MEGTKOROKEZ'),
                authenticationService.getRight('TORLHETIEDZTERV')];

            return $q.all(promises).then(function (results) {
                vm.UJHETIEDZTERV = results[1];
                vm.MODHETIEDZTERV = results[2];
                vm.MEGTHETIEDZTERV = results[3];
                vm.MEGTFELH = results[4];
                vm.MEGTKOROKEZ = results[5];
                vm.TORLHETIEDZTERV = results[6];
                if (!vm.MEGTHETIEDZTERV) {
                    log.permissionError(true);
                    return;
                }
                getCoaches();

                if (results[0]['weeklyTrainingPlan.edzoId'] !== undefined) {
                    vm.selectedCoach = results[0]['weeklyTrainingPlan.edzoId'];
                }
                if (results[0]['weeklyTrainingPlan.szakosztalyId'] !== undefined) {
                    vm.selectedSection = results[0]['weeklyTrainingPlan.szakosztalyId'];
                }
                if (results[0]['weeklyTrainingPlan.korosztalyId'] !== undefined) {
                    vm.selectedAgeGroup = results[0]['weeklyTrainingPlan.korosztalyId'];
                }
                if (results[0]['weeklyTrainingPlan.szoveg'] !== undefined) {
                    vm.textSearch = results[0]['weeklyTrainingPlan.szoveg'];
                }
                if (results[0]['weeklyTrainingPlan.ev']) {
                    vm.selectedYear = results[0]['weeklyTrainingPlan.ev'];
                } else {
                    vm.selectedYear = new Date().getFullYear();
                }
                $timeout(function () {
                    //                    refreshData();
                    angular.element('#maEv').ejDropDownList({value: vm.selectedYear});
                    $scope.$apply();
                }, 500);
            });
        }

        //edzők lekérése
        function getCoaches() {
            return dataservice.coachSelect().then(
                function (data) {
                    vm.coachList = data.itemsList;

                    angular.element('#maEdzo').ejDropDownList({dataSource: vm.coachList});
                    if (vm.coachList.length === 1) {
                        vm.selectedCoach = vm.coachList[0].value;
                    }
                }
            );
        }

        //Grid oszlopainak definíciója
        vm.columns = [
            {
                field: 'Id', visible: false, isPrimaryKey: true, isIdentity: true
            },
            {
                field: 'Edzo', headerText: gettextCatalog.getString('Coach'),
                textAlign: 'center',
                allowEditing: false,
                template: '#wetrpllitemplateDataColumn1',
                width: 150
            },
            {
                field: 'Szakosztaly',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'center',
                allowEditing: false,
                width: 150
            },
            {
                field: 'Korosztaly', headerText: gettextCatalog.getString('AgeGroup'),
                textAlign: 'center',
                allowEditing: false,
                template: '#wetrpllitemplateDataColumn2',
                width: 150
            },
            {
                field: 'FelkHetSzama',
                headerText: gettextCatalog.getString('FelkHetSzam'),
                textAlign: 'center',
                allowEditing: false,
                width: 150
            },
            {
                field: 'MakrociklusSzama',
                headerText: gettextCatalog.getString('Makrociklus'),
                textAlign: 'center',
                allowEditing: false,
                width: 150
            },
            {
                field: 'Hetfo',
                headerText: gettextCatalog.getString('Monday'),
                textAlign: 'center',
                allowEditing: false,
                width: 120
            },
            {
                field: 'Vasarnap',
                headerText: gettextCatalog.getString('Sunday'),
                textAlign: 'center',
                allowEditing: false,
                width: 120
            },
            {
                field: 'Sportolok',
                headerText: gettextCatalog.getString('Athletes'),
                textAlign: 'center',
                allowEditing: false,
                width: 120
            },
            {
                field: 'Jovahagyott',
                headerText: gettextCatalog.getString('Approved'),
                textAlign: 'center',
                allowEditing: false,
                template: '#wetrpllitemplateDataColumn4',
                width: 150
            },
            {
                field: 'Megnyitas',
                headerText: '',
                textAlign: 'center',
                allowEditing: false,
                template: '#wetrpllitemplateDataColumn3',
                width: 150
            }
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            },
            rowSelected: function (args) {
                vm.selectedRowId = args.data.Id;
                vm.delEnabled = vm.MODHETIEDZTERV;
                $scope.$apply();
            },
            rowDeselected: function () {
                vm.delEnabled = false;
                vm.selectedRowId = -1;
                $scope.$apply();
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        //edző dropdownlist selectionchanged
        function coachChanged(args) {
            getSectionList(args.value);
        }

        function getSectionList(coachId) {
            return dataservice.sectionDropDownList(coachId).then(
                function (data) {
                    vm.sectionList = data.itemsList;
                }
            );
        }

        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#maEdzo').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                vm.selectedCoach = vm.coachList[0].value;
            } else {
                angular.element('#maEdzo').ejDropDownList('selectItemByValue', vm.selectedCoach);
            }
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                vm.selectedSection = vm.sectionList[0].value;
            } else {
                angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.selectedSection);
            }
        }

        function sectionChanged() {
            if (vm.selectedCoach == null) {
                vm.selectedCoach = 0;
            }
            return dataservice.ageGroupSelectYear(vm.selectedCoach, vm.selectedSection, vm.selectedYear).then(
                function (data) {
                    vm.ageGroupList = data.itemsList;
                    if (vm.ageGroupList.length > 1) {
                        vm.ageGroupList.unshift({value: -1, text: gettextCatalog.getString('All')});
                        /*
                            $timeout(function() {
                                angular.element('#maKorosztaly').ejDropDownList('selectItemByValue', -1);
                                $scope.$apply();
                            }, 0);
                        */
                    }
                }
            );
        }

        function ageGroupChanged(args) {
            vm.selectedAgeGroup = args.value;
        }

        // Run after data bounded
        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#maKorosztaly').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
                vm.selectedAgeGroup = vm.ageGroupList[0].value;
            } else {
                angular.element('#maKorosztaly').ejDropDownList('selectItemByValue', vm.selectedAgeGroup);
            }
        }

        function yearChanged(args) {
            vm.selectedYear = args.value;
            return dataservice.ageGroupSelectYear(vm.selectedCoach, vm.selectedSection, vm.selectedYear).then(
                function (data) {
                    vm.ageGroupList = data.itemsList;
                    if (vm.ageGroupList.length > 1) {
                        vm.ageGroupList.unshift({value: -1, text: gettextCatalog.getString('All')});
                    }
                }
            );
        }

        function refreshData() {
            dataservice.getWeeklyTrainingPlanList(vm.selectedCoach, vm.selectedSection, vm.selectedAgeGroup, vm.selectedYear, vm.textSearch).then(
                function (data) {
                    grid.dataSource = data.itemsList;
                    vm.delEnabled = false;
                }
            );
            paramHelper.setParams([{'weeklyTrainingPlan.edzoId': vm.selectedCoach},
                {'weeklyTrainingPlan.szakosztalyId': vm.selectedSection},
                {'weeklyTrainingPlan.korosztalyId': vm.selectedAgeGroup},
                {'weeklyTrainingPlan.ev': vm.selectedYear},
                {'weeklyTrainingPlan.szoveg': vm.textSearch}]);
        }

        function goToNewUser(edzoId) {
            if (!vm.MEGTFELH) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{'weeklyTrainingPlan.edzoId': vm.selectedCoach}, {'weeklyTrainingPlan.szakosztalyId': vm.selectedSection}, {'weeklyTrainingPlan.korosztalyId': vm.selectedAgeGroup}, {'weeklyTrainingPlan.ev': vm.selectedYear}, {'newUser.userId': edzoId}, {'weeklyTrainingPlan.szoveg': vm.textSearch}]);
            $state.go('settings.userManagement.modifyUser');
        }

        function goToNewAgeGroup(ageGroupId) {
            if (!vm.MEGTKOROKEZ) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{'weeklyTrainingPlan.edzoId': vm.selectedCoach}, {'weeklyTrainingPlan.szakosztalyId': vm.selectedSection}, {'weeklyTrainingPlan.korosztalyId': vm.selectedAgeGroup}, {'weeklyTrainingPlan.ev': vm.selectedYear}, {'ageGroups.ageGroup': ageGroupId}, {'weeklyTrainingPlan.szoveg': vm.textSearch}]);
            $state.go('athletes.ageGroups.modifyAgeGroup');
        }

        function goToWeeklyTrainingPlan(trainingPlanId) {
            if (!vm.MEGTHETIEDZTERV) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{'weeklyTrainingPlan.edzoId': vm.selectedCoach},
                {'weeklyTrainingPlan.szakosztalyId': vm.selectedSection},
                {'weeklyTrainingPlan.korosztalyId': vm.selectedAgeGroup},
                {'weeklyTrainingPlan.ev': vm.selectedYear},
                {'weeklyTrainingPlan.trainingPlanId': trainingPlanId},
                {'weeklyTrainingPlan.edzoNev': getNameByID(vm.coachList, vm.selectedCoach)},
                {'weeklyTrainingPlan.szakosztalyNev': getNameByID(vm.sectionList, vm.selectedSection)},
                {'weeklyTrainingPlan.korosztalyNev': getNameByID(vm.ageGroupList, vm.selectedAgeGroup)},
                {'weeklyTrainingPlan.szoveg': vm.textSearch}
            ]);
            $state.go('administration.weeklyTrainingPlan.weeklyTrainingPlanReport');
        }

        function goToNewWeeklyTrainingPlan() {
            if (!vm.UJHETIEDZTERV) {
                log.permissionError();
                return;
            }
            if (vm.selectedCoach == undefined || vm.selectedCoach == null || vm.selectedCoach <= 0) {
                log.errorMsg(gettextCatalog.getString('SelectCoach'), gettextCatalog.getString('Error'));
                return;
            }
            if (vm.selectedSection === undefined || vm.selectedSection == null || vm.selectedSection <= 0) {
                log.errorMsg(gettextCatalog.getString('SelectSection'), gettextCatalog.getString('Error'));
                return;
            }
            if (vm.selectedAgeGroup === undefined || vm.selectedAgeGroup == null || vm.selectedAgeGroup <= 0) {
                log.errorMsg(gettextCatalog.getString('SelectAgeGroup'), gettextCatalog.getString('Error'));
                return;
            }
            if (vm.selectedYear == undefined || vm.selectedYear == null || vm.selectedYear <= 0) {
                log.errorMsg(gettextCatalog.getString('SelectYear'), gettextCatalog.getString('Error'));
                return;
            }
            paramHelper.setParams([{'weeklyTrainingPlan.edzoId': vm.selectedCoach},
                {'weeklyTrainingPlan.szakosztalyId': vm.selectedSection},
                {'weeklyTrainingPlan.korosztalyId': vm.selectedAgeGroup},
                {'weeklyTrainingPlan.ev': vm.selectedYear},
                {'weeklyTrainingPlan.trainingPlanId': -1},
                {'weeklyTrainingPlan.edzoNev': getNameByID(vm.coachList, vm.selectedCoach)},
                {'weeklyTrainingPlan.szakosztalyNev': getNameByID(vm.sectionList, vm.selectedSection)},
                {'weeklyTrainingPlan.korosztalyNev': getNameByID(vm.ageGroupList, vm.selectedAgeGroup)},
                {'weeklyTrainingPlan.szoveg': vm.textSearch}
            ]);
            $state.go('administration.weeklyTrainingPlan.weeklyTrainingPlanReport');
        }

        function getNameByID(arr, id) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].value == id) {
                    return arr[i].text;
                }
            }
        }

        function deleteWeeklyTrainingPlan() {
            if (vm.selectedRowId === undefined || vm.selectedRowId <= 0 && vm.delEnabled) {
                return;
            }
            swal({
                title: gettextCatalog.getString('Are you sure you want to delete the selected weekly Training Plan?'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                closeOnConfirm: true,
                closeOnCancel: true
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    dataservice.deleteWeeklyTrainingPlan(vm.selectedRowId).then(
                        function () {
                            refreshData();
                            $timeout(function () {
                                vm.delEnabled = false;
                                $scope.$apply();
                            }, 10);
                        });
                }
            });
        }
    }
})();
