(function () {
    'use strict';

    angular
        .module('app.settings.userManagement.userList')
        .controller('UserManagementController', userManagementController);

    userManagementController.$inject = ['logger', 'log', '$q', '$state', 'dataservice', 'authenticationService', '$rootScope', 'gettextCatalog', 'paramHelper', '$scope'];
    /* @ngInject */
    function userManagementController(logger, log, $q, $state, dataservice, authenticationService, $rootScope, gettextCatalog, paramHelper, $scope) {
        var vm = this;
        $rootScope.vm = vm; // jshint ignore: line

        // Page title
        $rootScope.title = gettextCatalog.getString('UserManagement');

        // Grid's details
        vm.gridToolbar = ['printGrid', 'excelExport', 'divider', 'search'];
        vm.columns = [
            {
                field: 'TeljesNev',
                headerText: gettextCatalog.getString('Name'),
                textAlign: 'left',
                template: '#uslitemplateDataColumn1', width: 122
            },
            {field: 'Login', headerText: gettextCatalog.getString('Username'), textAlign: 'left', width: 122},
            {
                field: 'CsoportNeve',
                headerText: gettextCatalog.getString('Group'),
                textAlign: 'left',
                template: '#uslitemplateDataColumn2', width: 122
            },
            {
                field: 'Aktiv',
                headerText: gettextCatalog.getString('Active'),
                textAlign: 'center',
                type: 'boolean',
                displayAsCheckBox: true, width: 122
            },
            {field: 'Elerhetosege', headerText: gettextCatalog.getString('Contact'), textAlign: 'left', width: 122},
            {field: 'Email', headerText: gettextCatalog.getString('Email'), textAlign: 'left', width: 122},
            {
                field: 'HaviJelenletiKorosztalyonkent',
                headerText: gettextCatalog.getString('MonthlyAttendanceByAgeGroups'),
                textAlign: 'center',
                type: 'boolean',
                displayAsCheckBox: true, width: 122
            }
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#UsersAssetGrid'), $scope);
                }
            },
            rowSelecting: function (args) {
                grid.clearSelection();
                vm.selectedRowData = args.data;
                vm.thereIsSelectedTeam = true;
            },
            checkBoxChange: function (args) {
                $rootScope.checkBoxChangeOnlyOne(args, grid);
            }
        });
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Public functions
        vm.goToNewUser = goToNewUser;
        vm.goToGroup = goToGroup;
        vm.goToUser = goToUser;
        vm.goToUser2 = goToUser2;
        vm.details = details;

        activate();

        function activate() {
            $q.all([authenticationService.getRight('UJFELH'), authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')])
                .then(function (results) {
                    if (results[2] !== true) {
                        log.permissionError(true);
                    } else {
                        vm.UJFELH = results[0];
                        vm.MODFELH = results[1];
                        vm.MEGTFELH = results[2];
                        setTimeout(function () {
                            getUsers();
                        }, 100);
                    }
                });
        }

        function getUsers() {
            dataservice.usersListSelect()
                .then(function (data) {
                    grid.dataSource = data.itemsList;
                    grid.appendTo('#Grid');
                });
        }

        function details() {
            log.infoMsg('Details');
        }

        function goToNewUser() {
            if (vm.UJFELH) {
                $state.go('settings.userManagement.newUser');
            } else {
                log.permissionError();
            }
        }

        function goToUser() {
            if (vm.MODFELH) {
                if (vm.selectedRowData && vm.selectedRowData.Id) {
                    paramHelper.setParams([{'newUser.userId': vm.selectedRowData.Id}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.errorMsg('YouHaveToSelectUser');
                }
            } else {
                log.permissionError();
            }
        }

        function goToUser2(userId) {
            if (vm.MODFELH || vm.MEGTFELH) {
                if (userId) {
                    paramHelper.setParams([{'newUser.userId': userId}]);
                    $state.go('settings.userManagement.modifyUser');
                }
            } else {
                log.permissionError();
            }
        }

        function goToGroup(group) {
            paramHelper.setParams([{'usergroupid': group}]);
            $state.go('settings.userRights');
        }
    }
})();
