(function () {
    'use strict';

    angular
        .module('app.search.user.searchuser')
        .controller('SearchUsersController', SearchUsersController);

    SearchUsersController.$inject = ['log', 'dataservice', '$q', '$rootScope', 'gettextCatalog', '$filter', 'authenticationService', 'paramHelper', '$state', '$scope', '$compile'];
    /* @ngInject */
    function SearchUsersController(log, dataservice, $q, $rootScope, gettextCatalog, $filter, authenticationService, paramHelper, $state, $scope, $compile) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('SearchUsers');

        // Create variables
        vm.userList = [];
        vm.yesno = [
            {text: gettextCatalog.getString(''), value: null},
            {text: gettextCatalog.getString('Yes'), value: true},
            {text: gettextCatalog.getString('No'), value: false}
        ];
        vm.genderList = [
            {text: gettextCatalog.getString(''), value: undefined},
            {text: gettextCatalog.getString('Male'), value: 'Male'},
            {text: gettextCatalog.getString('Female'), value: 'Female'}
        ];
        vm.columns = [
            {field: 'Id', headerText: gettextCatalog.getString('Id'), textAlign: 'left', width: '90'},
            {field: 'Nev', headerText: gettextCatalog.getString('LoginName'), textAlign: 'left', width: '150', template: true, templateID: '#searchusergotouser'},
            {field: 'TeljesNev', headerText: gettextCatalog.getString('FullName'), textAlign: 'left', width: '150'},
            {field: 'SzulIdo', headerText: gettextCatalog.getString('BirthDate'), textAlign: 'left', width: '120', type: 'date', format: '{0:yyyy.MM.dd.}'},
            {field: 'Lakcim', headerText: gettextCatalog.getString('Address'), textAlign: 'left', width: '150'},
            {field: 'FelhasznaloCsoport', headerText: gettextCatalog.getString('Usergroup'), textAlign: 'left', width: '150'},
            {field: 'Elerhetosege', headerText: gettextCatalog.getString('Contact'), textAlign: 'left', width: '150'},
            {field: 'Aktiv', headerText: gettextCatalog.getString('Active'), textAlign: 'left', width: '70'}
        ];
        vm.gridToolbar = ['printGrid', 'excelExport'];

        // Public functions
        vm.search = search;
        vm.reset = reset;
        vm.compile = compile;
        vm.goToUser = goToUser;
        vm.toolbarHandler = toolbarHandler;
        vm.gridExcelExport = dataservice.getURL() + 'FelhasznaloKeresesExcelExport/ExcelExport/';

        vm.formData = {};
        vm.usergroups = [];

        resetQueryDetails ();

        function resetQueryDetails () {
            vm.formData.nev = undefined;
            vm.formData.loginNev = undefined;
            vm.formData.idCsoport = undefined;
            vm.formData.szulHelyOrszag = undefined;
            vm.formData.szulHelyVaros = undefined;
            vm.formData.szulTol = undefined;
            vm.formData.szulIg = undefined;
            vm.formData.email = undefined;
            vm.formData.lakcim = undefined;
            vm.formData.elerhetoseg = undefined;
            vm.formData.neme = undefined;
            vm.formData.allamp = undefined;
            vm.formData.anyjaNeve = undefined;
            vm.formData.edzKep = undefined;
            vm.formData.foallMh = undefined;
            vm.formData.magTol = undefined;
            vm.formData.magIg = undefined;
            vm.formData.testTol = undefined;
            vm.formData.testIg = undefined;
            vm.formData.szemSzam = undefined;
            vm.formData.tajSzam = undefined;
            vm.formData.utlevel = undefined;
            vm.formData.aktiv = undefined;
            vm.formData.egyeniSport = true;
            vm.formData.csapatSport = true;
        }

        // Activate page
        activate();

        function activate() {
            var promises = [authenticationService.getRight('EDZOKERESO'), paramHelper.getParams([], ['search.user.formdata'])];
            getUserGroups();
            $q.all(promises)
                .then(function (results) {
                    if (results[0] !== true) {
                        log.permissionError(true);
                    }
                    if (results[1] && results[1]['search.user.formdata']) {
                        vm.formData = results[1]['search.user.formdata'];
                        search();
                    }
                });
        }

        function getUserGroups() {
            return dataservice.userGroupList()
                .then(function (data) {
                    vm.usergroups = data.itemsList;
                    vm.usergroups.unshift({value: undefined, text: ''});
                });
        }

        function search() {
            paramHelper.setParams([{'search.user.formdata': vm.formData}]);
            vm.userList = [];
            dataservice.searchUser(vm.formData).then(function (data) {
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'SzulIdo');
                vm.userList = data.itemsList;
            });
        }

        function goToUser(edzoId) {
            authenticationService.getRight('MEGTFELH').then(function (result) {
                if (result !== true) {
                    log.permissionError();
                    return;
                }
                paramHelper.setParams([{'newUser.userId': edzoId}]);
                $state.go('settings.userManagement.modifyUser');
            });
        }

        function reset() {
            resetQueryDetails();
        }

        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
            //$rootScope.refreshGridSize();
        }

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                var grid = angular.element('#Grid').ejGrid('instance');
                grid.ignoreOnExport.splice(grid.ignoreOnExport.indexOf('dataSource'), 1);
                this.export(dataservice.getURL() + 'FelhasznaloKeresesExcelExport/ExcelExport/');
            }
        }

        vm.userGroupDatabound = userGroupDatabound;
        function userGroupDatabound () {
            if (vm.usergroups.length === 1) {
                angular.element('#usergroup').ejDropDownList('selectItemByValue', vm.usergroups[0].value);
                vm.formData.idCsoport = vm.usergroups[0].value;
            } else {
                angular.element('#usergroup').ejDropDownList('selectItemByValue', vm.formData.idCsoport);
            }
        }
    }
})();
