(function () {
    'use strict';

    angular
        .module('app.business.annualSportServiceFeeBySection')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'business.annualSportServiceFeeBySection',
                config: {
                    url: '/annualSportServiceFeeBySection',
                    templateUrl: 'app/business/annualSportServiceFeeBySection/annualSportServiceFeeBySection.html',
                    controller: 'AnnualSportServiceFeeBySectionController',
                    controllerAs: 'vm',
                    title: 'AnnualSportServiceFeeBySection',
                    settings: {
                        navId: 2103,
                        level: 2,
                        order: 10,
                        orderTitle_hu: 'Sportszolgáltatási díj összesítés szakosztályonként',
                        orderTitle_en: 'Annual Sport Service Fee by Section',
                        parentId: 3,
                        content: 'AnnualSportServiceFeeBySection',
                        activatorPermission: '"MEGTTAGDSZO"'
                    }
                }
            }
        ];
    }
})();
