(function () {
    'use strict';

    angular .module('app.administration.annualSeasonPlan.annualSeasonPlanReportPage6')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.annualSeasonPlan.annualSeasonPlanReportPage6',
                config: {
                    url: '/annualSeasonPlanReportPage6',
                    templateUrl: 'app/administration/annualSeasonPlan/annualSeasonPlanReportPage6/annualSeasonPlanReportPage6.html',
                    controller: 'AnnualSeasonPlanReportPage6Controller',
                    controllerAs: 'vm',
                    title: 'AnnualSeasonPlan'
                }
            }
        ];
    }
})();
