(function () {
    'use strict';

    angular
        .module('app.reports.centralReport.centralReportByRating')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.centralReport.centralReportByRating',
                config: {
                    url: '/centralReportByRating',
                    templateUrl: 'app/reports/centralReport/centralReportByRating/centralReportByRating.html',
                    controller: 'CentralReportByRatingController',
                    controllerAs: 'vm',
                    title: 'CentralReportByRating',
                    settings: {
                        navId: 32234,
                        level: 3,
                        order: 2,
                        orderTitle_hu: 'Központi felmérők ranglista kimutatás',
                        orderTitle_en: 'Central Test Ranking List Report',
                        parentId: 234,
                        content: 'CentralReportByRating',
                        activatorPermission: '"MEGTKPFELM"'
                    }
                }
            }
        ];
    }
})();
