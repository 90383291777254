(function () {
    'use strict';

    angular
        .module('app.settings.userGroupsManagement')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'settings.userGroupsManagement',
                config: {
                    url: '/userGroups',
                    templateUrl: 'app/settings/userGroups/userGroupsManagement.html',
                    controller: 'UserGroupsManagementController',
                    controllerAs: 'vm',
                    title: 'UserGroupsManagement',
                    settings: {
                        navId: 225,
                        level: 2,
                        order: 2,
                        orderTitle_hu: 'Felhasználói csoportok kezelése',
                        orderTitle_en: 'User Groups Management',
                        parentId: 5,
                        content: 'UserGroupsManagement',
                        activatorPermission: '"MEGTFELHCSOP"'
                    }
                }
            }
        ];
    }
})();
