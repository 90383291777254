(function () {
    'use strict';

    angular
        .module('app.athletes.ageGroups')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'athletes.ageGroups',
                config: {
                    url: '/ageGroups',
                    templateUrl: 'app/athletes/ageGroups/ageGroups.html'
                }
            }
        ];
    }
})();
