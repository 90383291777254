/* global Aes */

(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.psychology.pieronTestEvaluation')
        .controller('PieronTestEvaluation', PieronTestEvaluation);

    PieronTestEvaluation.$inject = ['$q', 'gettextCatalog', 'dataservice', 'paramHelper', 'log', '$state', '$rootScope', 'authenticationService'];
    /* @ngInject */
    function PieronTestEvaluation($q, gettextCatalog, dataservice, paramHelper, log, $state, $rootScope, authenticationService) {
        var vm = this; $rootScope.vm = vm;

        vm.questions = [
            [
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron5.png'}
            ],
            [
                {url: 'images/pieron7.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},

                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron4.png'}
            ],
            [
                {url: 'images/pieron1.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron6.png'}
            ],
            [
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'}
            ],
            [
                {url: 'images/pieron2.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'}
            ],
            [// 6
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron3.png'}
            ],
            [// 7
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron6.png'}
            ],
            [// 8
                {url: 'images/pieron8.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron5.png'}
            ],
            [// 9
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'}
            ],
            [// 10
                {url: 'images/pieron5.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron8.png'}
            ],
            [// 11
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron4.png'}
            ],
            [// 12
                {url: 'images/pieron7.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron2.png'}
            ],
            [// 13
                {url: 'images/pieron8.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron2.png'}
            ],
            [// 14
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron6.png'}
            ],
            [// 15
                {url: 'images/pieron6.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron5.png'}
            ],
            [// 16
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'}
            ],
            [// 17
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron5.png'}
            ],
            [// 18
                {url: 'images/pieron7.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron4.png'}
            ],
            [// 19
                {url: 'images/pieron1.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron5.png'}
            ],
            [// 20
                {url: 'images/pieron7.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron2.png'},
                {url: 'images/pieron3.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron4.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron5.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron6.png'},
                {url: 'images/pieron1.png'},
                {url: 'images/pieron7.png'},
                {url: 'images/pieron8.png'},
                {url: 'images/pieron8.png'}
            ]
        ];

        vm.answers = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        ];

        vm.saveEval = saveEval;

        activate();

        function activate() {
            $q.all([paramHelper.getParams(['psychology.surveyId', 'psychology.mode', 'athleteModify.sportoloNev'], ['psychology.surveyPassword']), authenticationService.getRight('PSYCHO')])
                .then(function (results) {
                if (results[1] !== true) {
                    log.permissionError(true);
                } else {
                    vm.surveyId = results[0]['psychology.surveyId'];
                    vm.password = results[0]['psychology.surveyPassword'];
                    vm.sportoloNev = results[0]['athleteModify.sportoloNev'];
                    $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('Psychology') + ' - ' + gettextCatalog.getString('PieronTestEvaluation');
                    vm.edit = results[0]['psychology.mode'] === 'edit';
                    if (vm.edit) {
                        getSurveyProtected();
                    } else {
                        getSurvey();
                    }
                }
            });
        }

        function getSurvey() {
            dataservice.getPieronTest(vm.surveyId, true)
                .then(function (data) {
                vm.answers = data.kitoltottKerdoiv;
                vm.sportolo = {};
                vm.sportolo.nev = data.sportoloNeve;
                vm.sportolo.szuletesiDatum = data.sportoloSzuletesiDatuma;
                vm.sportolo.anyjaNeve = data.sportoloAnyjaNeve;
                vm.kiertekelesSzam = data.kiertekelesSzam;
                vm.seen = data.kiertekelesSzam[1];
                vm.age = data.kiertekelesSzam[2];
                vm.tNumber = data.kiertekelesSzam[0];
                vm.wrongAnswersNumber = data.kiertekelesSzam[3];
                if (vm.wrongAnswersNumber === undefined) {
                    vm.wrongAnswersNumber = 0;
                    angular.forEach(vm.answers, function (value) {
                        angular.forEach(value, function (value2) {
                            if (value2 === -1) {
                                vm.wrongAnswersNumber++;
                            }
                        });
                    });
                }
                vm.ertek = [];
                if (vm.age < 15) {
                    if (vm.seen < 159) {
                        vm.ertek.push('Nagyon rossz');
                    } else if (vm.seen < 201) {
                        vm.ertek.push('Átlag alatti');
                    } else if (vm.seen < 296) {
                        vm.ertek.push('Átlagos');
                    } else if (vm.seen < 376) {
                        vm.ertek.push('Átlag feletti');
                    } else if (vm.seen >= 376) {
                        vm.ertek.push('Kiváló');
                    }

                    if (vm.tNumber <= 90.3) {
                        vm.ertek.push('Nagyon rossz');
                    } else if (vm.tNumber <= 95.7) {
                        vm.ertek.push('Átlag alatti');
                    } else if (vm.tNumber <= 98.5) {
                        vm.ertek.push('Átlagos');
                    } else if (vm.tNumber <= 99.4) {
                        vm.ertek.push('Átlag feletti');
                    } else if (vm.tNumber > 99.4) {
                        vm.ertek.push('Kiváló');
                    }
                } else if (vm.age > 14 && vm.age < 19) {
                    if (vm.seen < 201) {
                        vm.ertek.push('Nagyon rossz');
                    } else if (vm.seen < 256) {
                        vm.ertek.push('Átlag alatti');
                    } else if (vm.seen < 345) {
                        vm.ertek.push('Átlagos');
                    } else if (vm.seen < 396) {
                        vm.ertek.push('Átlag feletti');
                    } else if (vm.seen > 395) {
                        vm.ertek.push('Kiváló');
                    }

                    if (vm.tNumber <= 93.3) {
                        vm.ertek.push('Nagyon rossz');
                    } else if (vm.tNumber <= 97.0) {
                        vm.ertek.push('Átlag alatti');
                    } else if (vm.tNumber <= 98.8) {
                        vm.ertek.push('Átlagos');
                    } else if (vm.tNumber <= 99.6) {
                        vm.ertek.push('Átlag feletti');
                    } else if (vm.tNumber > 99.6) {
                        vm.ertek.push('Kiváló');
                    }
                } else if (vm.age > 18) {
                    if (vm.seen < 178) {
                        vm.ertek.push('Nagyon rossz');
                    } else if (vm.seen < 214) {
                        vm.ertek.push('Átlag alatti');
                    } else if (vm.seen < 261) {
                        vm.ertek.push('Átlagos');
                    } else if (vm.seen < 323) {
                        vm.ertek.push('Átlag feletti');
                    } else if (vm.seen > 322) {
                        vm.ertek.push('Kiváló');
                    }

                    if (vm.tNumber <= 88.2) {
                        vm.ertek.push('Nagyon rossz');
                    } else if (vm.tNumber <= 93.7) {
                        vm.ertek.push('Átlag alatti');
                    } else if (vm.tNumber <= 96.2) {
                        vm.ertek.push('Átlagos');
                    } else if (vm.tNumber <= 97.7) {
                        vm.ertek.push('Átlag feletti');
                    } else if (vm.tNumber > 99.7) {
                        vm.ertek.push('Kiváló');
                    }
                }

                vm.kitoltesDatuma = data.kitoltesDatuma;
            });
        }

        function getSurveyProtected() {
            swal({
                title: gettextCatalog.getString('Password'),
                text: gettextCatalog.getString('SelectPasswordForTheTest'),
                //type: 'input',
                input: 'password',
                animation: 'slide-from-top',
                inputValue: vm.password,
                closeOnConfirm: false,
                allowEscapeKey: false
            }).then(function (inputValue) {
                if (!inputValue || !inputValue.value || inputValue.dismiss === 'cancel' || inputValue.dismiss === 'esc') {
                    return false;
                } else {
                    vm.password = inputValue.value;
                    dataservice.getPieronTest(vm.surveyId, false)
                        .then(function (data) {
                        vm.answers = data.kitoltottKerdoiv;
                        vm.sportolo = {};
                        vm.sportolo.nev = data.sportoloNeve;
                        vm.sportolo.szuletesiDatum = data.sportoloSzuletesiDatuma;
                        vm.sportolo.anyjaNeve = data.sportoloAnyjaNeve;
                        vm.kitoltesDatuma = data.kitoltesDatuma;
                        vm.kiertekelesDatuma = data.kiertekelesDatuma;
                        vm.kiertekelesSzam = data.kiertekelesSzam;
                        vm.kiertekelesSzoveg = data.kiertekelesSzoveg;
                        vm.seen = data.kiertekelesSzam[1];
                        vm.age = data.kiertekelesSzam[2];
                        vm.tNumber = data.kiertekelesSzam[0];
                        vm.wrongAnswersNumber = data.kiertekelesSzam[3];
                        vm.ertek = [];
                        if (vm.age < 15) {
                            if (vm.seen < 159) {
                                vm.ertek.push('Nagyon rossz');
                            } else if (vm.seen < 201) {
                                vm.ertek.push('Átlag alatti');
                            } else if (vm.seen < 296) {
                                vm.ertek.push('Átlagos');
                            } else if (vm.seen < 376) {
                                vm.ertek.push('Átlag feletti');
                            } else if (vm.seen >= 376) {
                                vm.ertek.push('Kiváló');
                            }

                            if (vm.tNumber <= 90.3) {
                                vm.ertek.push('Nagyon rossz');
                            } else if (vm.tNumber <= 95.7) {
                                vm.ertek.push('Átlag alatti');
                            } else if (vm.tNumber <= 98.5) {
                                vm.ertek.push('Átlagos');
                            } else if (vm.tNumber <= 99.4) {
                                vm.ertek.push('Átlag feletti');
                            } else if (vm.tNumber > 99.4) {
                                vm.ertek.push('Kiváló');
                            }
                        } else if (vm.age > 14 && vm.age < 19) {
                            if (vm.seen < 201) {
                                vm.ertek.push('Nagyon rossz');
                            } else if (vm.seen < 256) {
                                vm.ertek.push('Átlag alatti');
                            } else if (vm.seen < 345) {
                                vm.ertek.push('Átlagos');
                            } else if (vm.seen < 396) {
                                vm.ertek.push('Átlag feletti');
                            } else if (vm.seen > 395) {
                                vm.ertek.push('Kiváló');
                            }

                            if (vm.tNumber <= 93.3) {
                                vm.ertek.push('Nagyon rossz');
                            } else if (vm.tNumber <= 97.0) {
                                vm.ertek.push('Átlag alatti');
                            } else if (vm.tNumber <= 98.8) {
                                vm.ertek.push('Átlagos');
                            } else if (vm.tNumber <= 99.6) {
                                vm.ertek.push('Átlag feletti');
                            } else if (vm.tNumber > 99.6) {
                                vm.ertek.push('Kiváló');
                            }
                        } else if (vm.age > 18) {
                            if (vm.seen < 178) {
                                vm.ertek.push('Nagyon rossz');
                            } else if (vm.seen < 214) {
                                vm.ertek.push('Átlag alatti');
                            } else if (vm.seen < 261) {
                                vm.ertek.push('Átlagos');
                            } else if (vm.seen < 323) {
                                vm.ertek.push('Átlag feletti');
                            } else if (vm.seen > 322) {
                                vm.ertek.push('Kiváló');
                            }

                            if (vm.tNumber <= 88.2) {
                                vm.ertek.push('Nagyon rossz');
                            } else if (vm.tNumber <= 93.7) {
                                vm.ertek.push('Átlag alatti');
                            } else if (vm.tNumber <= 96.2) {
                                vm.ertek.push('Átlagos');
                            } else if (vm.tNumber <= 97.7) {
                                vm.ertek.push('Átlag feletti');
                            } else if (vm.tNumber > 99.7) {
                                vm.ertek.push('Kiváló');
                            }
                        }

                        if (vm.kiertekelesDatuma !== '') {
                            vm.kiertekelesSzoveg = Aes.Ctr.decrypt(vm.kiertekelesSzoveg, inputValue.value, 256);
                        }
                        angular.element('#rteSample').ejRTE({value: vm.kiertekelesSzoveg});
                        swal.close();
                    });
                }
            });
        }

        function saveEval() {
            swal({
                title: gettextCatalog.getString('Password'),
                text: gettextCatalog.getString('SelectPasswordForTheTest'),
                //type: 'input',
                input: 'password',
                animation: 'slide-from-top',
                inputValue: vm.password,
                allowEscapeKey: false
            }).then(function (inputValue) {
                if (!inputValue || !inputValue.value || inputValue.dismiss === 'cancel' || inputValue.dismiss === 'esc') {
                    return false;
                } else {
                    vm.password = inputValue.value;
                    paramHelper.setParams([{'psychology.surveyPassword': inputValue.value}]);
                    vm.encryptedHtml = Aes.Ctr.encrypt(angular.element('#rteSample').ejRTE('getHtml'), inputValue.value, 256);
                    dataservice.savePerformanceMotivationSurveyEval(vm.surveyId, vm.encryptedHtml)
                        .then(function () {
                            log.successMsg('TestSaved');
                            $state.go('athletes.athletesManagement.athleteModify.psychology.summary');
                        });
                }
            });
        }
    }
})();
