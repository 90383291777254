(function () {
    'use strict';

    angular
        .module('blocks.logger')
        .factory('logger', loggers)
        .provider('log', log);

    loggers.$inject = ['$log', 'toastr'];
    /* @ngInject */
    function loggers($log, toastr) {
        return {
            error: error,
            info: info,
            success: success,
            warning: warning
        };
        /////////////////////

        /**
         * Logging erros to console and sweetalert and toastr
         * @author Richard Keki
         * @param {string}   message
         * @param {string}   title
         * @param {string}   data
         */
        function error(message, title, data) {
            toastr.error(message);
            $log.error('Error: ', message, data);
            if (angular.isUndefined(title)) {
                title = 'Error';
            }
            swal({title: title, html: message, type: 'error'});
        }

        function info(message) {
            toastr.info(message);
            $log.info('Info: ', message);
        }

        function success(message) {
            toastr.success(message);
            $log.info('Success: ', message);
        }

        function warning(message) {
            toastr.warning(message);
            $log.warn('Warning: ', message);
        }
    }

    log.$inject = [];
    /* @ngInject */
    function log() {
        this.environment = ''; // jshint ignore: line

        this.setEnvironment = function (env) { // jshint ignore: line
            this.environment = env;
        };

        this.$get = function ($injector) { // jshint ignore: line
            var gettextCatalog = $injector.get('gettextCatalog');
            var $log = $injector.get('$log');
            var toastr = $injector.get('toastr');
            var env = this.environment;
            var $rootScope = $injector.get('$rootScope');
            return {
                permissionError: function (back) {
                    var message = gettextCatalog.getString('YouHaveNoPermissionToThisFunction');
                    var title = gettextCatalog.getString('PermissionDenied');
                    swal({title: title, html: message, type: 'error'});
                    if (env === 'development') {
                        $log.error(title + ': ', message);
                        toastr.error(message);
                    }
                    if (back === true) {
                        $rootScope.fromBack = true;
                        $rootScope.back();
                    }
                },

                errorMsg: function (message, data) {
                    message = gettextCatalog.getString(message);
                    var title = gettextCatalog.getString('Error');
                    if (env === 'development') {
                        toastr.error(message);
                        $log.error(title + ': ', message, data);
                    }
                    swal({title: title, html: message, type: 'error'});
                },

                htmlError: function (htmlMessage) {
                    var title = gettextCatalog.getString('Error');
                    if (env === 'development') {
                        toastr.error(htmlMessage);
                        $log.error(title + ': ' + htmlMessage);
                    }
                    swal({title: title, html: htmlMessage, type: 'error'});
                },

                infoMsg: function (message) {
                    message = gettextCatalog.getString(message);
                    var title = gettextCatalog.getString('Info');
                    toastr.info(message);
                    if (env === 'development') {
                        $log.info(title + ': ', message);
                    }
                },

                helpMsg: function (message) {
                    var title = '';
                    //var title = gettextCatalog.getString('Help');
                    swal({title: title, html: gettextCatalog.getString(message), type: 'info'});
                    if (env === 'development') {
                        $log.info(title + ': ', message);
                    }
                },

                helpMsgSmall: function (message) {
                    var title = '';
                    //var title = gettextCatalog.getString('Help');
                    swal({title: title, html: '<div style="font-size: 0.9em;">' + gettextCatalog.getString(message) + '</div>', type: 'info'});
                    if (env === 'development') {
                        $log.info(title + ': ', message);
                    }
                },

                successMsg: function (message) {
                    message = gettextCatalog.getString(message);
                    var title = gettextCatalog.getString('Success');
                    toastr.success(message);
                    if (env === 'development') {
                        $log.info(title + ': ', message);
                    }
                },

                htmlSuccess: function (htmlMessage) {
                    // = gettextCatalog.getString(message);
                    var title = gettextCatalog.getString('Success');
                    toastr.success(htmlMessage);
                    if (env === 'development') {
                        $log.info(title + ': ', htmlMessage);
                    }
                },

                warningMsg: function (message) {
                    message = gettextCatalog.getString(message);
                    var title = gettextCatalog.getString('Warning');
                    toastr.warning(message);
                    if (env === 'development') {
                        $log.warn(title + ': ', message);
                    }
                    swal({title: title, html: message, type: 'warning'});
                },

                htmlWarning: function (message) {
                    //message = gettextCatalog.getString(message);
                    var title = gettextCatalog.getString('Warning');
                    toastr.warning(message);
                    if (env === 'development') {
                        $log.warn(title + ': ', message);
                    }
                    swal({title: title, html: message, type: 'warning'});
                }
            };
        };
    }
}());
