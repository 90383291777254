(function () {
    'use strict';

    angular
        .module('app.administration.test.newTest')
        .controller('NewTestController', NewTestController);

    NewTestController.$inject = ['log', 'dataservice', 'authenticationService', '$q', '$state', '$scope', '$rootScope', 'gettextCatalog', '$filter', 'paramHelper', '$compile'];
    /* @ngInject */
    function NewTestController(log, dataservice, authenticationService, $q, $state, $scope, $rootScope, gettextCatalog, $filter, paramHelper, $compile) {
        var vm = this; $rootScope.vm = vm;

        // Page title
        $rootScope.title = gettextCatalog.getString('NewTest');

        vm.dataSource = [];
        vm.dateValue = vm.date = new Date();
        vm.getTestAthletesList = getTestAthletesList;

        // Grid's variables
        var columnsize = 40;
        vm.gridToolbar = ['printGrid', 'excelExport'];
        vm.columns = [
            {field: 'sportoloId', headerText: 'Id', textAlign: 'left', isPrimaryKey: true, visible: false},
            {
                field: 'sportoloNeve',
                headerText: gettextCatalog.getString('AthleteName'),
                textAlign: 'left',
                allowEditing: false,
                template: true,
                width: 120,
                templateID: '#newtestcolumnTemplate1'
            },
            {
                field: 'szuletesiDatum',
                headerText: gettextCatalog.getString('BirthDate'),
                textAlign: 'center',
                allowEditing: false,
                width: 120
            },
            {
                field: 'szakosztalyNeve',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'left',
                allowEditing: false,
                width: 120
            },
            {
                field: 'megjelent',
                headerText: gettextCatalog.getString('Appeared'),
                textAlign: 'center',
                width: 90,
                editType: ej.Grid.EditingType.Boolean
            },
            {
                field: 'testmagassag',
                headerText: gettextCatalog.getString('Height'),
                textAlign: 'center',
                editType: ej.Grid.EditingType.Numeric,
                width: 90,
                editParams: {minValue: 0}
            },
            {
                field: 'testsuly',
                headerText: gettextCatalog.getString('Weight'),
                textAlign: 'center',
                editType: ej.Grid.EditingType.Numeric,
                width: 90,
                editParams: {minValue: 0}
            },
            {
                field: 'sulypontEmelkedes1',
                headerText: gettextCatalog.getString('VerticalJump') + ' 1',
                textAlign: 'center',
                editType: ej.Grid.EditingType.Numeric,
                width: 90,
                editParams: {decimalPlaces: 2, minValue: 0}
            },
            {
                field: 'sulypontEmelkedes2',
                headerText: gettextCatalog.getString('VerticalJump') + ' 2',
                textAlign: 'center',
                editType: ej.Grid.EditingType.Numeric,
                width: 90,
                editParams: {decimalPlaces: 2, minValue: 0}
            },
            {
                field: 'sulypontEmelkedes3',
                headerText: gettextCatalog.getString('VerticalJump') + ' 3',
                textAlign: 'center',
                editType: ej.Grid.EditingType.Numeric,
                width: 90,
                editParams: {decimalPlaces: 2, minValue: 0}
            },
            {
                field: 'sulypontEmelkedesLegjobb',
                headerText: gettextCatalog.getString('Best') + ' ' + gettextCatalog.getString('VerticalJump'),
                textAlign: 'center',
                template: true,
                width: 90,
                templateID: '#maxSelect'
            },
            {
                field: 'f30mSprint1',
                headerText: gettextCatalog.getString('30mSprint') + ' 1',
                textAlign: 'center',
                editType: ej.Grid.EditingType.Numeric,
                width: 90,
                editParams: {decimalPlaces: 2, minValue: 0}
            },
            {
                field: 'f30mSprint2',
                headerText: gettextCatalog.getString('30mSprint') + ' 2',
                textAlign: 'center',
                editType: ej.Grid.EditingType.Numeric,
                width: 90,
                editParams: {decimalPlaces: 2, minValue: 0}
            },
            {
                field: 'f30mSprint3',
                headerText: gettextCatalog.getString('30mSprint') + ' 3',
                textAlign: 'center',
                editType: ej.Grid.EditingType.Numeric,
                width: 90,
                editParams: {decimalPlaces: 2, minValue: 0}
            },
            {
                field: 'f30mSprintLegjobb',
                headerText: gettextCatalog.getString('Best') + ' ' + gettextCatalog.getString('30mSprint'),
                textAlign: 'center',
                template: true,
                width: 90,
                templateID: '#minSelect'
            },
            {
                field: 'meterPerMeter',
                headerText: gettextCatalog.getString('2800mRunning'),
                textAlign: 'center',
                editTemplate: {
                    create: function () {
                        return '<input>';
                    },
                    read: function (args) {
                        return args.ejTimePicker()[0].value;
                    },
                    write: function (args) {
                        args.element.ejTimePicker({
                            width: '100%',
                            timeFormat: 'HH:mm:ss',
                            interval: 1,
                            enableStrictMode: false
                        });
                    }
                },
                width: 120
            },
            {field: 'megjegyzes', headerText: gettextCatalog.getString('Comment'), textAlign: 'left', width: 100}
        ];

        // Publikus funkciók
        vm.calculateBests = calculateBests;
        vm.saveGrid = saveGrid;
        vm.actionBegin = actionBegin;
        vm.goToAthlete = goToAthlete;
        vm.compile = compile;
        vm.toolbarHandler = toolbarHandler;

        activate();

        function activate() {
            authenticationService.getRight('MEGTKPFELM').then(function (results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([getTestAthletesList()]).then(function () {
                    });
                }
            });
        }

        function actionBegin(args) {
            if (args.requestType && (args.requestType === 'paging' || args.requestType === 'sorting')) {
                args.cancel = true;
                angular.element('#Grid').ejGrid('batchSave');
            }
        }

        function calculateBests() {
            angular.element('#Grid').ejGrid('batchSave');
        }

        function getTestAthletesList() {
            dataservice.getTestAthletesList(vm.dateValue).then(function (data) {
                vm.dataSource = $filter('dateStringFilter')(data.itemsList, 'szuletesiDatum');
            });
        }

        function saveGrid() {
            angular.element('#Grid').ejGrid('batchSave');
            return dataservice.saveTest(vm.dataSource, vm.dateValue).then(function () {
                log.successMsg('TestResultsSaved');
                //$state.go('administration.test.testList');
            });
        }

        // Compile links in grid
        function compile(args) {
            $compile(args.row.find('.bind'))($scope);
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST'), authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function toolbarHandler(args) {
            if (args.itemName === 'Excel Export') {
                args.cancel = true;
                var grid = angular.element('#Grid').ejGrid('instance');
                grid.ignoreOnExport.splice(grid.ignoreOnExport.indexOf('dataSource'), 1);
                this.export(dataservice.getURL() + 'FelmerokKozpontiSelect/ExcelExport/');
            }
        }
    }
})();
