(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.psychology.performanceMotivationTest')
        .controller('PerformanceMotivationTestController', PerformanceMotivationTestController);

    PerformanceMotivationTestController.$inject = ['$q', 'gettextCatalog', 'dataservice', 'paramHelper', 'log', '$state', '$rootScope', 'authenticationService'];
    /* @ngInject */
    function PerformanceMotivationTestController($q, gettextCatalog, dataservice, paramHelper, log, $state, $rootScope, authenticationService) {
        var vm = this; $rootScope.vm = vm;

        vm.questions = [
            gettextCatalog.getString('PerformanceMotivationTestQ1'),
            gettextCatalog.getString('PerformanceMotivationTestQ2'),
            gettextCatalog.getString('PerformanceMotivationTestQ3'),
            gettextCatalog.getString('PerformanceMotivationTestQ4'),
            gettextCatalog.getString('PerformanceMotivationTestQ5'),
            gettextCatalog.getString('PerformanceMotivationTestQ6'),
            gettextCatalog.getString('PerformanceMotivationTestQ7'),
            gettextCatalog.getString('PerformanceMotivationTestQ8'),
            gettextCatalog.getString('PerformanceMotivationTestQ9'),
            gettextCatalog.getString('PerformanceMotivationTestQ10'),
            gettextCatalog.getString('PerformanceMotivationTestQ11'),
            gettextCatalog.getString('PerformanceMotivationTestQ12'),
            gettextCatalog.getString('PerformanceMotivationTestQ13'),
            gettextCatalog.getString('PerformanceMotivationTestQ14'),
            gettextCatalog.getString('PerformanceMotivationTestQ15'),
            gettextCatalog.getString('PerformanceMotivationTestQ16'),
            gettextCatalog.getString('PerformanceMotivationTestQ17'),
            gettextCatalog.getString('PerformanceMotivationTestQ18'),
            gettextCatalog.getString('PerformanceMotivationTestQ19'),
            gettextCatalog.getString('PerformanceMotivationTestQ20'),
            gettextCatalog.getString('PerformanceMotivationTestQ21'),
            gettextCatalog.getString('PerformanceMotivationTestQ22'),
            gettextCatalog.getString('PerformanceMotivationTestQ23'),
            gettextCatalog.getString('PerformanceMotivationTestQ24'),
            gettextCatalog.getString('PerformanceMotivationTestQ25'),
            gettextCatalog.getString('PerformanceMotivationTestQ26'),
            gettextCatalog.getString('PerformanceMotivationTestQ27'),
            gettextCatalog.getString('PerformanceMotivationTestQ28'),
            gettextCatalog.getString('PerformanceMotivationTestQ29'),
            gettextCatalog.getString('PerformanceMotivationTestQ30'),
            gettextCatalog.getString('PerformanceMotivationTestQ31'),
            gettextCatalog.getString('PerformanceMotivationTestQ32'),
            gettextCatalog.getString('PerformanceMotivationTestQ33'),
            gettextCatalog.getString('PerformanceMotivationTestQ34'),
            gettextCatalog.getString('PerformanceMotivationTestQ35'),
            gettextCatalog.getString('PerformanceMotivationTestQ36'),
            gettextCatalog.getString('PerformanceMotivationTestQ37'),
            gettextCatalog.getString('PerformanceMotivationTestQ38'),
            gettextCatalog.getString('PerformanceMotivationTestQ39'),
            gettextCatalog.getString('PerformanceMotivationTestQ40'),
            gettextCatalog.getString('PerformanceMotivationTestQ41')
        ];
        vm.answers = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        // Public functions
        vm.saveSurvey = saveSurvey;

        activate();

        function activate() {
            $q.all([paramHelper.getParams(['athleteModify.sportoloId', 'athleteModify.sportoloNev']), authenticationService.getRight('PSYCHO')])
                .then(function (results) {
                    if (results[1] !== true) {
                        log.permissionError(true);
                    } else {
                        vm.sportoloId = results[0]['athleteModify.sportoloId'];
                        vm.sportoloNev = results[0]['athleteModify.sportoloNev'];
                        $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('Psychology') + ' - ' + gettextCatalog.getString('PerformanceMotivationTest');
                    }
                });
        }

        function saveSurvey() {
            var hiba = false;
            if (vm.sportoloId) {
                angular.forEach(vm.answers, function (value, key) {
                    if (value === 0) {
                        vm.answers[key] = -1;
                        hiba = true;
                    }
                });
            } else {
                hiba = true;
            }
            if (hiba) {
                log.errorMsg('SurveyNotFilled');
            } else {
                dataservice.savePerformanceMotivationSurvey(vm.sportoloId, vm.answers)
                    .then(function () {
                        log.successMsg('TestSaved');
                        $state.go('athletes.athletesManagement.athleteModify.psychology.summary');
                    });
            }
        }
    }
})();
