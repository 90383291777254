(function () {
    'use strict';

    angular
        .module('app.athletes.ageGroups.modifyAgeGroup')
        .controller('ModifyAgeGroupController', ModifyAgeGroupController);

    ModifyAgeGroupController.$inject = ['log', '$state', 'dataservice', 'authenticationService', '$q', 'gettextCatalog', 'paramHelper', '$window', '$rootScope', '$scope', '$timeout'];
    /* @ngInject */
    function ModifyAgeGroupController(log, $state, dataservice, authenticationService, $q, gettextCatalog, paramHelper, $window, $rootScope, $scope) {
        var vm = this; $rootScope.vm = vm;

        // Page title
        $rootScope.title = gettextCatalog.getString('ModifyAgeGroup');

        // Dropdownlist's sources
        vm.sectionList = [];
        var date = new Date().getFullYear();
        vm.yearList = dataservice.range(date + 5, 1900);
        vm.yearListTo = dataservice.range(date + 10, 1900);
        vm.colorValue = '#000000';
        vm.activeValue = true;

        vm.formData = {
            color: '#000000',
            activeAgeGroup: true,
            selectedYearFrom: undefined,
            selectedYearTo: undefined,
            comment: undefined,
            ageGroupName: undefined,
            sectionId: undefined,
            id: undefined
        };

        vm.clearDropdown = clearDropdown;
        vm.sectionSelect = sectionSelect;
        vm.modifyAgeGroup = modifyAgeGroup;
        vm.yearFromDropDownChanged = yearFromDropDownChanged;
        vm.yearToDropDownChanged = yearToDropDownChanged;
        vm.sectionDataBound = sectionDataBound;
        vm.colorChanged = colorChanged;
        vm.activeCheckboxChanged = activeCheckboxChanged;

        activate();

        function activate() {
            $q.all([authenticationService.getRight('MODKOROKEZ'), authenticationService.getRight('MEGTKOROKEZ')]).then(function (results) {
                if (results[0] !== true && results[1] !== true) {
                    log.permissionError(true);
                } else {
                    vm.MODKOROKEZ = results[0];
                    $q.all([paramHelper.getParams(['ageGroups.ageGroup'])]).then(function (results) {
                        vm.ageGroupId = results[0]['ageGroups.ageGroup'];
                        getAgeGroup().then(function () {
                            getSections();
                        });
                    });
                }
            });
        }

        function clearDropdown() {
            vm.formData.selectedYearTo = null;
        }

        function getAgeGroup() {
            return dataservice.getAgeGroup(vm.ageGroupId).then(function (data) {
                vm.formData.ageGroupName = data.ageGroupName;
                vm.formData.comment = data.comment;
                vm.formData.selectedYearFrom = data.selectedYearFrom;
                vm.formData.selectedYearTo = data.selectedYearTo;
                vm.formData.color = data.color;
                vm.formData.activeAgeGroup = data.activeAgeGroup;
                vm.formData.id = data.id;
                vm.formData.sectionId = data.sectionId;
            });
        }

        function getSections() {
            return dataservice.sectionDropDownList(0).then(function (data) {
                vm.sectionList = data.itemsList;
            });
        }

        function sectionDataBound() {
            angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.formData.sectionId);
        }

        function sectionSelect(selectedSection) {
            vm.formData.sectionId = selectedSection.value;
        }

        function yearFromDropDownChanged(args) {
            vm.formData.selectedYearFrom = args.value;
        }

        function yearToDropDownChanged(args) {
            vm.formData.selectedYearTo = args.value;
        }

        function colorChanged(args) {
            vm.formData.color = args.value;
        }

        function activeCheckboxChanged(args) {
            vm.formData.activeAgeGroup = args.value;
        }

        function modifyAgeGroup() {
            if (vm.MODKOROKEZ) {
                // a syncfusion direktívák miatt
                $scope.$broadcast('submitted');
                if ($scope.form.$valid === false) {
                    log.errorMsg('RequiredFieldsError');
                } else {
                    dataservice.modifyAgeGroup(vm.formData).then(function () {
                        log.successMsg('AgeGroupModified');
                        paramHelper.setParams([{'ageGroups.section': vm.formData.sectionId}]);
                        $state.go('athletes.ageGroups.ageGroupsList');
                    });
                }
            } else {
                log.permissionError();
            }
        }
    }
})();
