(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.raceResultsReport')
        .controller('RaceResultsReport2Controller', RaceResultsReport2Controller);

    RaceResultsReport2Controller.$inject = ['log', 'logger', 'dataservice', '$q', '$rootScope', 'gettextCatalog', 'paramHelper', 'authenticationService', '$scope', '$filter', '$state'];

    /* @ngInject */
    function RaceResultsReport2Controller(log, logger, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $scope, $filter, $state) {
        var vm = this;
        $rootScope.vm = vm;
        vm.params = [];

        // For print
        vm.reportParameters = {};

        // GRID
        vm.schoolGridSelectedRowData = {};
        vm.gridToolbar = ['printGrid', 'excelExport'];
        vm.columnsAthlete = [
            {
                field: 'SportoloNev',
                headerText: gettextCatalog.getString('AthleteName'),
                headerTextAlign: 'center',
                textAlign: 'left',
                width: '150',
                template: '#rareretemplateDataColumn1'
            },
            {
                field: 'EredmenySzoveg',
                headerText: gettextCatalog.getString('ResultText'),
                textAlign: 'left',
                width: '350'
            },
            {
                field: 'EredmenyPont',
                headerText: gettextCatalog.getString('ResultScore'),
                headerTextAlign: 'center',
                width: '150'
            },
            {
                field: 'EredmenyIdo',
                headerText: gettextCatalog.getString('ResultTime'),
                headerTextAlign: 'center',
                width: '150'
            },
            {
                field: 'EredmenyTavolsag',
                headerText: gettextCatalog.getString('ResultDistance'),
                headerTextAlign: 'center',
                width: '150'
            },
            {
                field: 'SzakosztalyNeve',
                headerText: gettextCatalog.getString('Section'),
                headerTextAlign: 'center',
                width: '100'
            },
            {
                field: 'KorcsoportNeve',
                headerText: gettextCatalog.getString('AgeGroup'),
                headerTextAlign: 'center',
                width: '100'
            },
            {
                field: 'DatumTol',
                headerText: gettextCatalog.getString('DateFrom'),
                headerTextAlign: 'center',
                width: '90'
            },
            {field: 'DatumIg', headerText: gettextCatalog.getString('DateTo'), headerTextAlign: 'center', width: '90'},
            {
                field: 'Helyszin',
                headerText: gettextCatalog.getString('Location'),
                headerTextAlign: 'center',
                width: '140'
            },
            {
                field: 'Helyezes',
                headerText: gettextCatalog.getString('Helyezes'),
                headerTextAlign: 'center',
                width: '120'
            },
            {field: 'VersenyLeiras', headerText: gettextCatalog.getString('VersenyLeiras'), width: '250'},
            {
                field: 'Ertekeles',
                headerText: gettextCatalog.getString('CoachRating'),
                headerTextAlign: 'center',
                width: '400'
            },
            {
                field: 'Jellege',
                headerText: gettextCatalog.getString('Jellege'),
                headerTextAlign: 'center',
                width: '250'
            },
            {field: 'Eredmeny', headerText: gettextCatalog.getString('Result'), headerTextAlign: 'center', width: '90'}, //int Edzői értékelés (Eredmeny = -2 => 1;Eredmeny =2=> 5 )
            {
                field: 'Versenyszam',
                headerText: gettextCatalog.getString('Versenyszam'),
                headerTextAlign: 'center',
                width: '200'
            },
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                headerTextAlign: 'center',
                width: '110',
                template: '#rareretemplateDataColumn2'
            },
            {
                headerText: gettextCatalog.getString('OpenIt'),
                headerTextAlign: 'center',
                width: '100',
                template: '#rareretemplateDataColumn3'
            },
            {
                headerText: gettextCatalog.getString('Print'),
                headerTextAlign: 'center',
                width: '100',
                template: '#rareretemplateDataColumn4'
            }
        ];
        vm.teamsLists = [];
        vm.columnsTeam = [
            {
                field: 'CsapatNev',
                headerText: gettextCatalog.getString('TeamName'),
                width: '150',
                template: '#rareretemplateDataColumn5'
            },
            {field: 'EredmenySzoveg', headerText: gettextCatalog.getString('ResultText'), width: '350'},
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), width: '100'},
            {field: 'KorcsoportNeve', headerText: gettextCatalog.getString('AgeGroup'), width: '100'},
            {field: 'DatumTol', headerText: gettextCatalog.getString('DateFrom'), width: '90'},
            {field: 'DatumIg', headerText: gettextCatalog.getString('DateTo'), width: '90'},
            {field: 'Helyszin', headerText: gettextCatalog.getString('Location'), width: '140'},
            {field: 'Helyezes', headerText: gettextCatalog.getString('AthleteRanking'), width: '120'},
            {field: 'VersenyLeiras', headerText: gettextCatalog.getString('VersenyLeiras'), width: '250'},
            {field: 'Ertekeles', headerText: gettextCatalog.getString('CoachRating'), width: '400'},
            {field: 'Jellege', headerText: gettextCatalog.getString('Jellege'), width: '250'},
            {field: 'Eredmeny', headerText: gettextCatalog.getString('Result'), headerTextAlign: 'center', width: '90'}, //int Edzői értékelés (Eredmeny = -2 => 1;Eredmeny =2=> 5 )
            {
                field: 'Versenyszam',
                headerText: gettextCatalog.getString('Versenyszam'),
                headerTextAlign: 'center',
                width: '200'
            },
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                width: '110',
                template: '#rareretemplateDataColumn6'
            },
            {
                headerText: gettextCatalog.getString('OpenIt'),
                width: '100',
                template: '#rareretemplateDataColumn7'
            },
            {
                headerText: gettextCatalog.getString('Print'),
                width: '100',
                template: '#rareretemplateDataColumn8'
            }
        ];

        // Public functions
        vm.openCoach = openCoach;
        vm.openAthlete = openAthlete;
        vm.openTeam = openTeam;

        vm.openResultReportAthletes = openResultReportAthletes;
        vm.openResultReportTeam = openResultReportTeam;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsAthlete,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        var grid2 = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsTeam,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid2'), $scope);
                }
            }
        });
        grid2.appendTo('#Grid2');
        grid2.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid2);
        };

        activate();

        function activate() {
            $q.all([
                authenticationService.getRight('KMVERSEREDM'),
                paramHelper.getParams(['athleteModify.sportoloId', 'athleteModify.sportoloNev'], [])
            ]).then(function (results) {
                if (!results[0]) {
                    log.permissionError(true);
                } else {
                    vm.sportoloId = results[1]['athleteModify.sportoloId'];
                    vm.sportoloNev = results[1]['athleteModify.sportoloNev'];
                    searchAthlete(vm.sportoloId, vm.sportoloNev);
                    $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('RaceResultsReport');
                }
            });
        }

        function searchAthlete(id, nev) {
            dataservice.getResultsReportsAthlete({
                    SportoloId: id,
                    SportoloNev: nev
                })
                .then(function (data) {
                    vm.athletesLists = data.itemsList;
                    vm.athletesLists = $filter('dateStringFilter')(vm.athletesLists, 'SzuletesiIdo');
                    vm.athletesLists = $filter('dateStringFilter')(vm.athletesLists, 'DatumTol');
                    vm.athletesLists = $filter('dateStringFilter')(vm.athletesLists, 'DatumIg');
                    vm.athletesLists = $filter('valueChangeFromObject')(vm.athletesLists, 'Eredmeny');
                    grid.dataSource = vm.athletesLists;

                    vm.teamsLists = data.itemsList2;
                    vm.teamsLists = $filter('dateStringFilter')(vm.teamsLists, 'DatumTol');
                    vm.teamsLists = $filter('dateStringFilter')(vm.teamsLists, 'DatumIg');
                    vm.teamsLists = $filter('valueChangeFromObject')(vm.teamsLists, 'Eredmeny');
                    grid2.dataSource = vm.teamsLists;
                });
        }

        function openAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function openTeam(id) {
            $q.all([authenticationService.getRight('MEGTCSOPORT')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([
                        {'teams.sectionId': '0'},
                        {'teams.ageGroupId': '0'},
                        {'teams.team': id}
                    ]);
                    $state.go('athletes.teams.teamMembersList');
                } else {
                    log.permissionError();
                }
            });
        }

        function openResultReportAthletes(id) {
            $q.all([authenticationService.getRight('MEGTEERJEL')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'resultReportAthletes.Id': id}]);
                    $state.go('administration.resultReportAthletes');
                } else {
                    log.permissionError();
                }
            });
        }

        function openResultReportTeam(id) {
            $q.all([authenticationService.getRight('MEGTCSERJEL')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'resultReportTeam.Id': id}]);
                    $state.go('administration.resultReportTeam');
                } else {
                    log.permissionError();
                }
            });
        }
    }
})();

