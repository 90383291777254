(function () {
    'use strict';

    angular
        .module('app.login')
        .directive('ngEnter', ngEnter);

    /* @ngInject */
    function ngEnter() {
        return {
            link: link,
            restrict: 'EA'
        };

        function link(scope, element, attrs) {
            element.bind('keydown keypress', function (event) {
                if (event.which === 13) {
                    scope.$apply(function () {
                        scope.$eval(attrs.ngEnter, {'event': event});
                    });
                    event.preventDefault();
                }
            });
        }
    }
})();
