(function () {
    'use strict';

    angular
        .module('app.administration.awayBudgetPlan.awayBudgetPlanCreate')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.awayBudgetPlan.awayBudgetPlanCreate',
                config: {
                    url: '/awayBudgetPlanCreate',
                    templateUrl: 'app/administration/awayBudgetPlan/awayBudgetPlanCreate/awayBudgetPlanCreate.html',
                    controller: 'AwayBudgetPlanCreateController',
                    controllerAs: 'vm',
                    title: 'AwayBudgetPlanCreate',
                }
            }
        ];
    }
})();
