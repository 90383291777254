(function () {
    'use strict';

    angular
        .module('app.reports.anthropometryTestPrinting')
        .controller('AnthropometryTestPrintingController', AnthropometryTestPrintingController);

    AnthropometryTestPrintingController.$inject = ['log', 'dataservice', '$rootScope', 'gettextCatalog', 'authenticationService', '$filter', '$scope', '$compile', '$state', 'paramHelper', 'DOMAIN', '$q', '$timeout'];

    /* @ngInject */
    function AnthropometryTestPrintingController(log, dataservice, $rootScope, gettextCatalog, authenticationService, $filter, $scope, $compile, $state, paramHelper, DOMAIN, $q, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AnthropometryTestPrinting');

        // Create variables
        vm.dateToValue = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        vm.dateFromValue = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate());
        vm.printEnabled = false;
        // Grid's variables
        vm.columns = [
            {type: 'checkbox', headerText: '', textAlign: 'center', width: 55},
            {field: 'SportoloId', isPrimaryKey: true, visible: false},
            {
                field: 'SportoloNeve',
                headerText: gettextCatalog.getString('Name'),
                textAlign: 'left',
                template: '#anthropometryTestPrintingcolumnTemplate'
            },
            {field: 'SportoloLakcim', headerText: gettextCatalog.getString('Address'), textAlign: 'left'},
            {field: 'Felmeres', headerText: gettextCatalog.getString('Test'), textAlign: 'left'},
            {field: 'Csoport', headerText: gettextCatalog.getString('Group'), textAlign: 'left'},
            {field: 'MeresDatuma', headerText: gettextCatalog.getString('Date'), textAlign: 'left'}
        ];
        vm.reportParameters = {};

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            selectionSettings: {type: 'multiple', mode: 'both'},
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            },
            rowSelecting: function () {
                $timeout(function () {
                    var selectedRow = grid.getSelectedRecords();
                    if (selectedRow && selectedRow.length > 0) {
                        vm.printEnabled = true;
                    }
                    $scope.$apply();
                }, 100);
            },
            rowDeselected: Deselect,
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        function Deselect() {
            if (grid.getSelectedRecords().length === 0) {
                vm.printEnabled = false;
                $scope.$apply();
            }
        }

        // Public functions
        vm.search = search;
        vm.printSelection = printSelection;
        vm.goToAthlete = goToAthlete;

        // Activate page
        activate();

        function activate() {
            authenticationService.getRight('TomAntr').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    search();
                }
            });
        }

        function search() {
            dataservice.getAnthropometryListForPrinting(vm.dateFromValue, vm.dateToValue).then(function (data) {
                grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'MeresDatuma');
            });
        }

        function printSelection() {
            var selectedAthletes = $rootScope.getSelectedItems(grid);
            var Tomeges = '';
            if (selectedAthletes.length == 0) {
                log.errorMsg('NoSelectedAthlete');
                return;
            }
            angular.forEach(selectedAthletes, function (value) {
                if (Tomeges.length > 0) {
                    Tomeges += ';';
                }
                Tomeges += value.Id + ',' + value.SportoloId;
            });
            vm.reportParameters = {
                'Tomeges': Tomeges,
                'SportoloId': 0,
                'AntropometriaId': 0,
                'SportiskolaLogo': (grid.dataSource[0] && grid.dataSource[0].SportiskolaLogo) ? DOMAIN.baseURL + DOMAIN.anthropometryPhotosUrl + grid.dataSource[0].SportiskolaLogo : undefined
            };
            if (Tomeges !== '') {
                angular.element('#printDialoganthtest').ejDialog({
                    'height': '95%',
                    'width': '95%',
                    'showoninit': 'false',
                    'enablemodal': 'true',
                    'position-x': '0',
                    'position-y': '0',
                    'target': '#mainContent',
                    'enableresize': 'false'
                });
                angular.element('#printDialoganthtest').ejDialog('open');
            }
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }
    }
})
();
